import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Space ,Tag, Typography, InputNumber, message} from 'antd';
import { CheckOutlined } from "@ant-design/icons";

//componentes
import { IconDelete } from '../../../Widgets/Iconos';

//modal
import ModalMatriz from '../Modals/ModalMatriz';

const axios = require('axios').default;
const { Text } = Typography;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    number,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    /**
     * Calcula el nuevo total del registro cuando cambia el la cantidad, solo de forma local 
     **/
    const save = async () => {
        try {
            const values = await form.validateFields();

            if(values.cantidad){
                values.total = values.cantidad * record.costo
            }
            toggleEdit();
            handleSave({ ...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {

        if(editing){

            let input = <Input ref={inputRef} onPressEnter={save} onBlur={save} className="table-input-editable" />

            if(number){
                input = <InputNumber
                            onPressEnter={save}
                            onBlur={save}
                            ref={inputRef}
                            size="small"
                            //controls={false}
                            className="table-input-editable width-100"
                        />
            }

            childNode = <Form.Item
                name={dataIndex}
                style={{margin: 0, padding: 0}}
                rules={[
                    {
                        required: true,
                        message: `requerido`,
                    },
                ]}
            >
                {input}
            </Form.Item>
        }else{
            childNode = <div
                className="editable-cell-value-wrap"
                onClick={toggleEdit}
            >
                {children}
            </div>
        }
    }

    return <td {...restProps}>{childNode}</td>;
};

export default function ServiciosTableEditable(props) {

    const { servicios } = props

    //variables
    const [count, setCount] = useState(0);
    const [visible, setVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    
    /**
     * Elimina un elemento del datasource
     * */
    const handleDelete = (registro_id) => {
        const newData = dataSource.filter((item) => item._id !== registro_id);
        setDataSource([...newData]);
        deleteRegistro(registro_id)
    };

    /**
     * Elimina un elemento de la BD
     * */
    const deleteRegistro = (registro_id) => {
        axios.delete(`/mantenimiento/delete/${registro_id}`)
        .then(response => {
            props.updateTotal(response.data.total?.total ?? 0)
            console.log('Eliminado')
        })
        .catch(error => console.log('Error', error))
    }

    /**
     * Actualiza la informacion en el state 
     **/
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row._id === item._id);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
    };

    /**
     * actualiza la informacion de un registro en BD
     **/
    const saveRegistro = (newData) => {
        axios.put('/mantenimiento/update/registro',{
            ...newData
        }).then(response => {
            message.success('Registro actualizado')
            props.updateTotal( response.data.total)
        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
     * actualiza la informacion de un registro en BD
     **/
    const getDetalles = () => {
        axios.get('/mantenimiento/matriz',{
            params:{
                mantenimiento_id: props.mantenimiento_id,
                tipo: 2, //materiales
            }
        }).then(response => {
            props.updateTotal(response.data.total ?? 0)
            setDataSource(response.data?.elementos?.length > 0 ? response.data.elementos : [])

        }).catch(error => {
            console.log("error", error);

        })
    }

    const defaultColumns = [
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            width: 100,
            editable: true,
            number: true
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion',
            width: 200,
            ellipsis: true,
            editable: false,
            render:( _, record, index) => {
                return <Text ellipsis>{record.material_id?.descripcion ? record.material_id?.descripcion : '-'}</Text>
            }
        },
        {
            title: 'Proveedor',
            dataIndex: 'proveedor',
            width: 150,
            ellipsis: true,
            editable: false,
            render:( _, record, index) => {
                return <Text ellipsis>{record.proveedor_id?.alias ? record.proveedor_id?.alias : '-'}</Text>
            }
        },
        {
            title: 'Código Cliente',
            dataIndex: 'codigo_cliente',
            width: 150,
            editable: false,
            render:( _, record, index) => {
                return <Text ellipsis>{record.material_id?.codigo_cliente ? record.material_id?.codigo_cliente : '-'}</Text>
            }
        },
        {
            title: 'Monto Adicional Prov.',
            dataIndex: 'monto_adicional',
            width: 100,
            editable: true,
            number: true
        },
        {
            title: 'Cobros',
            dataIndex: 'cobros',
            width: 100,
            editable: false,
            render: (_, record) => (
                <>
                    {record.cobros?.map((tag) => {
                        return (
                            <Tag color={tag.color} key={tag._id}>
                                {tag.nombre}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Utilidad',
            dataIndex: 'utilidad',
            width: 100,
            editable: true,
            number: true
        },
        {
            title: 'Ganancia',
            dataIndex: 'ganancia',
            width: 100,
            editable: true,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width: 150,
            editable: false,
            render:( _, record, index) => {
                return <Text ellipsis>$ {record.total ? record.total.toMoney(true) : 0 }</Text>
            }
        },
        {
            title: '',
            dataIndex: 'operation',
            width: 100,
            render: (_, record) =>
                <Space>
                    <Button
                        type="primary"
                        size="small"
                        className="ant-btn-primary-green"
                        icon={<CheckOutlined className=" text-white " />}
                        onClick={()=>saveRegistro(record)}
                        title="Guardar"
                    />
                    <Popconfirm
                        placement="topRight"
                        title="¿Eliminar?"
                        okText="Si"
                        cancelText="No"
                        onConfirm={()=>handleDelete(record._id)}
                    >
                        <Button
                            danger
                            type="primary"
                            size="small"
                            icon={<IconDelete />}
                            title="Eliminar"
                        />
                    </Popconfirm>
                </Space>

        },
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {

        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                number: col.number,
                handleSave,
            }),
        };
    });


    //componentDidMount
    useEffect(() => {
        getDetalles()  
    }, [])

    return (
        <>
            <Table
                components={components}
                //rowClassName={() => 'editable-row'}
                className="table-editable"
                scroll={{ x: 1000 }}
                bordered={false}
                size='small'
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    className:"flex-end",
                    position: ['bottomLeft'],
                    hideOnSinglePage:true
                }}
                footer={() => <Button 
                        type="dashed" 
                        size="middle" 
                        className='flex-end text-blue' 
                        onClick={()=>setVisible(true)}
                    > + Agregar Nuevo</Button>
                }
            />
            <ModalMatriz
                visible={visible}
                tipo="Material"
                onClose={({new_element, total})=>{
                    if(new_element?._id){
                        let new_data = [...dataSource]
                        new_data.push({...new_element})
                        setDataSource([...new_data])
                        props.updateTotal(total)
                    }
                    setVisible(false)
                }}
                mantenimiento_id={props.mantenimiento_id}
            />
        </>
    );
};

import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Pedidos from '../components/Cliente/MisPedidos/Dashboard'
import PM from '../components/Cliente/MisPedidos/Main'

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterPedidos(props) {
    return (
        <Routes>
            <Route path="" element={<Pedidos {...props} />} />
            <Route path="pm/:proyecto_id/*" element={<PM {...props} />} />
        </Routes>
    )
}

export default RouterPedidos;

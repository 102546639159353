import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const UsuarioSelect
 * @description Select para los usuarios registrados en el sistema
 */
const UsuarioSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el usuario", onSelect = () => {}, disabled = false, className = "" } = props

    const [ usuarios, setUsuarios ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getClientes
     * @description Obitiene los usuarios
     */
    const getClientes = ({page, limit, search} = usuarios) => {

        axios.get('/usuarios', {
            params: { page, limit, search },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setUsuarios({
                ...usuarios,
                data: response.data.data.itemsList,
                total: response.data.data.itemCount,
            })

        }).catch(error => {
            message('Error al obtener los usuarios')
        })
    }

    //componentDidMount
    useEffect(() => {
        getClientes()
    }, [])

    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getClientes({search})}
            onSelect={(usuario)=> {
                onChange(usuario.value)
                onSelect(usuario.value)
            }}
            value={value}
        >
            {
                usuarios.data.map(usuario => <Option value={usuario._id} key={usuario._id}>{usuario.nombre} {usuario.apellido}</Option>)
            }
        </Select>
    );
}



export default UsuarioSelect;
import React, { Component } from "react";
import { Button, Col, Row, Typography, message, Space, Tabs, Modal } from "antd";

import {
	CardEspecificaciones,
	CardCostos,
	CardProjectInfo,
	CardUtilidad,
	CardOpcionesMatriz,
} from "../../Widgets/Cards";
import { useNavigate, useParams } from "react-router-dom";

import TableMatrizCostos from "../ProjectManager/Secciones/TableMatrizCostos";

import "../../../Styles/Modules/ProjectManager/ProjectManager.scss";

import CardMatrizNacional from "./Cards/CardMatrizNacional";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

const axios = require("axios");

/**
 *
 *
 * @export
 * @class MatrizCostos
 * @extends {Component}
 * @description Vista de Matriz de Costos
 */
class MatrizCostos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			current: 0,
			proyecto_id: this.props.params.proyecto_id,
			valoresIniciales: {},
			productos: [],
			proyecto: {},
			margen: 0,
			visible_preview: false,
			tab: 'internacional'
		};
	}

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
		this.getProyecto();
		//actualizo el proyecto_id en el router principal para cargar el steptracker
		this.props.setProyectoID(this.props.params.proyecto_id)
	}

	/**
	 *
	 *
	 * @memberof MatrizCostos
	 *
	 * @method getProyecto
	 * @description obetenmos la informacion del proyecto
	 */
	getProyecto = () => {
		this.setState({ loading: true });
		axios.get("/project-manager/proyecto", {
			params: {
				id: this.state.proyecto_id,
				detalles: true,
				proveedor: true
			},
		})
			.then((response) => {
				console.clear()

				let proyecto = response.data
				let ingresos = proyecto.margen?.ingresos
					? proyecto.margen.ingresos
					: 1;
				let costos = proyecto.margen?.costos
					? proyecto.margen?.costos
					: 1;

				let margen = ((ingresos - costos) / ingresos) * 100;

				this.props.setEstructura(proyecto.estructura)
				this.setState({
					proyecto: proyecto,
					productos: proyecto.rfq_detalles,
					margen,
					valoresIniciales: {
						...proyecto,
						rfq_detalles: undefined
					},
					tab: proyecto.tipo === 0 ? 'internacional' : 'nacional'

				});
			})
			.catch((error) => {
				console.log(error);
				message.error("Error al cargar Proyecto en la matriz");
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 *
	 *
	 * @memberof MatrizCostos
	 *
	 * @method getPDF
	 * @description Generamos el PDF de la contizacion
	 */
	getPDF = () => {
		let urlPDF = new URL(
			axios.defaults.baseURL + "/proyecto/cotizacion/pdf"
		);
		urlPDF.searchParams.append(
			"Authorization",
			sessionStorage.getItem("token")
		);
		urlPDF.searchParams.append("proyecto_id", this.state.proyecto._id);
		urlPDF.searchParams.append("contenedor", this.props.estructura);
		window.open(urlPDF.href, '_blank');
	};

	/**
	 *
	 *
	 * @memberof MatrizCostos
	 *
	 * @method sendPDF
	 * @description Enviamos la cotizacion al cliente
	 */
	sendPDF = () => {
		this.setState({ loading_enviar: true });
		return axios
			.get("/proyecto/cotizacion/pdf", {
				params: {
					proyecto_id: this.state.proyecto_id,
					enviar: true,
				},
			})
			.then((response) => {
				message.success("Cotizacion Enviada");
				this.getProyecto();
			})
			.catch((error) => {
				console.log(error);
				message.error("Error al enviar la cotizacion");
			})
			.finally(() => {
				this.setState({ loading_enviar: false });
			});
	};

	back = () => {
		this.props.navigate(-1);
	};

	/**
	 * @memberof MatrizCostos
	 *
	 * @method onChangeData
	 * @description Se ejecuta cuando se realiza un cambio en la informacionde la orden, y la guarda automaticamente
	 */
	onChangeData = (valueChanged, allValues) => {
		axios.put('/proyectos', {
			...valueChanged,
			id: this.state.proyecto_id
		}).then(response => {
		}).catch(error => {
			console.log(error)
		})
	}


	/**
	 * @memberof MatrizCostos
	 *
	 * @method setValidationMatriz
	 * @description Se ejecuta cuando se realiza un cambio en la informacionde la orden, y la guarda automaticamente
	 */
	setValidationMatriz = () => {
		this.setState({ visible_preview: true })
	}

	/**
	 * @memberof MatrizCostos
	 *
	 * @method renderColorEstatus
	 * @description Se ejecuta cuando se realiza un cambio en la informacionde la orden, y la guarda automaticament
			Verde ➝ En proceso
			Amarillo ➝ Enviada, en espera de respuesta
			Rojo ➝ Rechazada / Recotizada
			Azul ➝ Aprobada 
	*/
	renderColorEstatus = () => {
		if (this.state.proyecto?.status_cliente) {
			switch (parseInt(this.state.proyecto?.status_cliente)) {
				case 1: return "ant-btn-primary-green";
				case 2: return "ant-btn-primary-yellow";
				case 3: return "ant-btn-primary-blue";
				case 4: return "ant-btn-primary-red"
				case 5: return "ant-btn-primary-red";
				default: return "ant-btn-primary-green"
			}
		}
	}

	render() {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col md={24} lg={24} xxl={this.state.tab === 'internacional' ? 8 : 12}>
						<CardProjectInfo
							quote_day={this.state.proyecto?.createdAt}
							nombre={`${this.state.proyecto?.cliente_id?.usuario?.nombre} ${this.state.proyecto?.cliente_id?.usuario?.apellido}`}
							empresa={this.state.proyecto?.cliente_id?.razon_social}
							telefono={this.state.proyecto?.cliente_id?.telefono}
							correo={this.state.proyecto?.cliente_id?.usuario?.email}
							avatar_props={{
								_id: this.state.proyecto?.agente_compras_id?._id,
								image: this.state.proyecto?.agente_compras_id?.avatar,
								name: this.state.proyecto?.agente_compras_id?.nombre + " " + this.state.proyecto?.agente_compras_id?.apellido,
							}}
							rfq={this.state.proyecto.rfq_id}
						/>
					</Col>

					<Col xs={24} lg={24} xxl={this.state.tab === 'internacional' ? 8 : 12}>
						<Row gutter={[0, 16]}>
							<Col span={24}>
								<CardUtilidad margen={this.state.margen ?? 0} />
							</Col>
							<Col span={24}>
								<CardEspecificaciones
									proyecto_id={this.state.proyecto_id}
									onChange={this.onChangeData}
									proyecto={this.state.proyecto}
								/>
							</Col>
						</Row>
					</Col>

					{
						this.state.tab === 'internacional' && (
							<Col xs={24} lg={24} xxl={8}>
								<Row gutter={[0, 16]}>
									<Col span={24}>
										<CardCostos
											onChange={this.onChangeData}
											proyecto={this.state.proyecto}
										/>
									</Col>
									<Col span={24}>
										<CardOpcionesMatriz
											proyecto_id={this.state.proyecto_id}
											onChange={this.onChangeData}
											proyecto={this.state.proyecto}
										/>
									</Col>
								</Row>
							</Col>
						)
					}
				</Row>
				<Row gutter={[16, 0]}>
					<Row justify="space-between " align="middle" className="width-100 pd-1">
						<Col xs={12} md={5}>
							<Title level={3}>Matriz de costos</Title>
						</Col>
						<Col xs={12} md={7}>
							<Tabs
								onChange={(key) => {
									Modal.confirm({
										title: `¿Deseas cambiar el tipo de proyecto a ${key}?`,
										content: key === 'internacional' ? 'Los datos registrados para nacional serán eliminados' : '',
										icon: <ExclamationCircleOutlined />,
										okText: 'Sí',
										cancelText: 'No',
										onOk: () => {
											this.onChangeData({
												tipo: key === "internacional" ? 0 : 1
											}, {})
											this.setState({ tab: key })
										}
									})
								}}
								activeKey={this.state.tab}
							>
								<Tabs.TabPane tab="INTERNACIONAL" key="internacional"></Tabs.TabPane>
								<Tabs.TabPane tab="NACIONAL" key="nacional"></Tabs.TabPane>
							</Tabs>
						</Col>
						<Col xs={12} md={12}>
							<Space style={{ float: "right" }}>
								<Button
									type="primary"
									className="ant-btn-primary-green"
									size="large"
									onClick={this.getPDF}
									disabled={!this.state.visible_preview}
								>
									<span style={{ fontWeight: 400 }}>Previsualizar Cotización</span>
								</Button>
								<Button
									type="primary"
									className={this.renderColorEstatus()}
									size="large"
									onClick={this.sendPDF}
									loading={this.state.loading_enviar}
									disabled={!this.state.visible_preview}

								>
									<span style={{ fontWeight: 400 }}>Enviar Cotización</span>
								</Button>
							</Space>
						</Col>
					</Row>
					{
						this.state.tab === "internacional" ? (
							<Col span={24}>
								<TableMatrizCostos
									proyecto_id={this.state.proyecto_id}
									proyecto={this.state.proyecto}
									productos={this.state.productos}
									valoresIniciales={this.state.valoresIniciales}
									setProductos={(productos) => this.setState({ productos })}
									setVisiblePreview={(visible_preview) => this.setState({ visible_preview })}
									setEstructura={this.props.setEstructura}
									estructura={this.props.estructura}
								/>
							</Col>
						) : (
							<Col span={24}>
								<CardMatrizNacional
									proyecto_id={this.state.proyecto._id}
									setVisiblePreview={(visible_preview) => this.setState({ visible_preview })}
								/>
							</Col>
						)
					}
				</Row>
			</>
		);
	}
}

export default function (props) {
	return (<MatrizCostos {...props} params={useParams()} navigate={useNavigate()} />)
}

import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, Upload, List } from 'antd';
import { PlusOutlined, UploadOutlined, LoadingOutlined, } from '@ant-design/icons';

import { IconTemplate } from '../../../Widgets/Iconos';


const { Title, Text } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalClientesMultiples
 * @extends {Component}
 */
class ModalClientesMultiples extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalClientesMultiples = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     * @memberof ModalClientesMultiples
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

    }

    normFile = (e) => {
        this.setState({ loading: true })
        const { file, fileList } = e;
        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        console.log("file.status", file.status);

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({ loading: false })

            this.props.onClose()
            const blob = new Blob([e.file.response], { type: 'text/csv; charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'templateClientes.csv');
            link.click();
            message.info(`Se ha descargado el informe de resultados de la carga.`)
        }

        if (file.status === "error") {
            this.setState({ loading: false })

            message.error(`Transacciones no fueron creadas`)
            this.props.onClose(true)
        }

        return e && e.fileList;

    };


    render() {


        return (
            <Form
                layout="vertical"
                ref={this.ModalClientesMultiples}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <List
                            style={{ justifyContent: "center" }}
                            align="center"
                            size="small"
                            header={<div>Toma en cuenta las siguientes consideraciones.</div>}
                            footer={<div>Descarga el template csv para subir múltiples Clientes.</div>}
                            dataSource={[
                                <Text style={{ justifyContent: 'center', width: '100%' }}>- No cambies el nombre de las columnas</Text>,
                                <Text style={{ justifyContent: 'center', width: '100%' }}>- Solamente se guardara la información de las columnas predefinidas </Text>,
                                <Text style={{ justifyContent: 'center', width: '100%' }} >- Asegurarse que el dato esté escrito correctamente  igual que en el sistema (incluyendo mayúsculas y acentos)</Text>,
                                <Text style={{ justifyContent: 'center', width: '100%' }} strong>- Cuando se vayan a ingresar multiples centros de distribución, asegurese que la información pertenciente al cliente sea IDENTICA en todos los centros de distribución a agregar. De otra manera, si es diferente se crearan multiples registros del mismo cliente.</Text>,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />

                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <a
                            onClick={() => {
                                axios.get(axios.defaults.baseURL + "/files/templateClientes.csv")
                                    .then(res => {
                                        const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8' });
                                        const url = window.URL.createObjectURL(blob);
                                        const link = document.createElement('a');
                                        link.setAttribute('href', url);
                                        link.setAttribute('download', 'templateClientes.csv');
                                        link.click();
                                    })

                            }}

                        >
                            <IconTemplate />
                        </a>
                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <label> Una vez que hayas guardado tu archivo .csv con los clientes, súbelo aqui. </label>
                    </Row>

                    <Row className="ant-row-center">

                        <Col xs={24} lg={20} className="mt-3 mb-3" >
                            <Form.Item
                                label="Archivo (.csv)"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    accept={'csv'}
                                    headers={{ Authorization: axios.defaults.headers.common['Authorization'] }}
                                    action={axios.defaults.baseURL + "/voucher/add/csv/clientes"}
                                    max={1}
                                    className="width-100"
                                >
                                    <Button className="width-100">
                                        <UploadOutlined /> Agregar archivos
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}> Clientes Múltiples</Title>
        </div>
        <ModalClientesMultiples {...props} />
    </Modal>

}
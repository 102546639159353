import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, } from 'antd';


const { Title } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalRecotizar
 * @extends {Component}
 */
class ModalRecotizar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            premium: false
        }
    }

    ModalRecotizar = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     * @memberof Cotizacion
     * 
     * @method updateProyecto
     * @description Actualiza la información del proyecto
     */
    updateProyecto = (values) => {
        this.setState({loading: true})
        axios.put('/customer/proyecto',{
            ...values,
            status_cliente: 5,
            proyecto_id: this.props.proyecto_id,
        }).then(response => {
            message.success('Cotización actualizada')
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar la cotización')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {


        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.updateProyecto}
                >
                    <Row className="mt-1">
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Descripción"
                                name="motivos"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la razón"
                                }]}
                            >
                                <Input.TextArea placeholder="Describe de forma detallada la razón de la recotización" className="width-100" rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Razón para Recotizar </Title>
        </div>
        <ModalRecotizar {...props} />
    </Modal>

}
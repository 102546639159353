import React, { Component } from "react";
import { Row, Col, Button, List, Typography, Card, Space, message, Modal } from "antd";
import { EditFilled, WarningOutlined } from "@ant-design/icons";
import { IconMail, IconAceptarOrden, IconPDF, IconProveedores } from '../../../Widgets/Iconos';
const { Text, Title, Paragraph } = Typography;

const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes del Cliente de CRM
 */
class Transacciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ordenes: {

                data: [{},{},{}],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                cliente_id: this.props.cliente_id
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
    }

    /**
    *
    *
    * @memberof AccountPurchases
    * @method getOrdenesCompra
    * @description Obtiene las ordenes de compra de la orden correspondiente
    */
    getOrdenesCompra = (page = this.state.page) => {

        const orden_id = this.props.orden_id;
        const orden_compra_id = this.props.orden_compra_id;

        if (!orden_id) return;
        this.setState({ loading: true })
        axios.get('/ordenesCompra/getListaCompras', {
            params: {
                orden_id: orden_id,
                orden_compra_id,
                page: page
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => {

                this.setState({
                    ordenes: data.ordenes_compra,

                })
            })
            .catch(err => {
                if (err.response?.status === 403) {
                    this.setState({ text: 'No tienes permisos para Ordenes de Compras' })
                }
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof AccountPurchases
     * @method renderEstatus
     * @description Obtiene las ordenes de compra de la orden correspondiente
     */
     renderEstatus = (estatus) => {

        let estatus_label = {
            0: 'Creada',
            1: 'Aceptada',
            2: 'Confirmada',
            3: 'Incompleta',
            4: 'Completa',
            5: 'En entrega',
            6: 'Entregada',
            7: 'Completa',
            8: 'Enviada'
        }

        return (estatus !== null || estatus !== undefined) ? estatus_label[estatus] : 'N/A'
    }



    render() {

        const { premium, orden } = this.props;

        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Compras " }}
                    dataSource={this.state.ordenes.data}
                    pagination={{
                        current: this.state.ordenes.page,
                        pageSize: 10,
                        total: this.state.ordenes.total,
                        position: 'bottom',
                        className: "flex-left",
                        onChange: (page) => this.getOrdenesCompra(page)
                    }}
                    header={<Row className="width-100">

                        {/* Fecha */}
                        <Col span={2} className="center">
                            <Text strong>Fecha</Text>
                        </Col>

                        {/* Folio */}
                        <Col span={3} className="center">
                            <Text strong>Folio</Text>
                        </Col>

                        {/* Proveedor */}
                        <Col span={5} className="center">
                            <Text strong>Proveedor</Text>
                        </Col>

                        {/* Estatus */}
                        <Col span={4} className="center">
                            <Text strong>Estatus</Text>
                        </Col>

                        {/* Monto total */}
                        <Col span={3} className="center">
                            <Text strong>Monto Total</Text>
                        </Col>

                        {/* Monto pagado */}
                        <Col span={3} className="center">
                            <Text strong>Monto Pagado</Text>
                        </Col>

                        {/* Acciones */}
                        <Col span={4} className="center">

                            <Button danger size="small"
                                onClick={() => Modal.confirm({

                                    okButtonProps: { danger: true },
                                    icon: <WarningOutlined style={{ color: "#F50" }} />,
                                    title: <><Title level={5}>¿Está seguro de volver a generar las Ordenes de Compra?</Title></>,
                                    content: <>
                                        <Paragraph>
                                            Al realizar este proceso, <strong>TODAS</strong> las Ordenes de Compra, así como los recibos y entregas relacionadas a las mismas serán <strong>ELIMINADAS</strong> para volver generar nuevas con los proveedores y precios actualizados.
                                        </Paragraph>
                                        <Title level={5}>ESTE PROCESO ES IRREVERSIBLE</Title>
                                    </>,
                                    onOk: () => {
                                        this.setState({ loading: true })
                                        /* axios.get("/ordenesCompra/generateListaCompra", {
                                            params: {
                                                orden_id: orden?._id
                                            }
                                        })
                                            .then(e => {
                                                this.getOrdenesCompra();
                                                this.setState({ loading: false })
                                            })
                                            .catch(err => {
                                                this.setState({ loading: false })
                                                message.error('Error al generar lista de compra')
                                            }) */
                                        console.log('Debe de generar ordenes de compra')
                                    }
                                })}
                            >Generar Ordenes de Compra</Button>
                        </Col>
                    </Row>}

                    renderItem={item => (
                        <List.Item>
                            <Card className="card-list">
                                <Row className="width-100">

                                    {/* Fecha */}
                                    <Col span={2} className="center">
                                        <Text strong>{moment(item.fecha_vigencia).format('DD/MM/YYYY')}</Text>
                                    </Col>

                                    {/* Folio */}
                                    <Col span={3} className="center">
                                        <Text strong>{item.folio}</Text>
                                    </Col>

                                    {/* Proveedor */}
                                    <Col span={5} className="center">
                                        <Text className="text-gray-dark">{item.proveedor?.nombre_comercial}</Text>
                                    </Col>

                                    {/* Estatus */}
                                    <Col span={4} className="center">
                                        <Text style={{ minWidth: "80px", textAlign: "center", background: "#C4C4C4", color: "#222222", padding: "5px  15px", borderRadius: "20px" }}>{this.renderEstatus(item.estatus)}</Text>
                                    </Col>

                                    {/* Monto total */}
                                    <Col span={3} className="center">
                                        <Text className="text-gray-dark">{item.monto_total ? `$ ${(item.monto_total).toMoney(true)} MXN` : "$ 0 MXN"}</Text>
                                    </Col>

                                    {/* Monto pagado */}
                                    <Col span={3} className="center">
                                        <Text className="text-gray-dark">{item.monto_pagado ? `$ ${(item.monto_pagado).toMoney(true)} MXN` : "$ 0 MXN"}</Text>
                                    </Col>

                                    {/* Acciones */}
                                    <Col span={4} className="center">
                                        <Space>
                                            <Button
                                                icon={<IconProveedores />}
                                                type="primary"
                                                title="IVA"
                                                onClick={() => this.setState({ modalProveedorVisible: true, proveedor_id: item.proveedor_id })}

                                            >
                                            </Button>

                                            <Button
                                                icon={<IconMail />}
                                                type="primary"
                                                title="Enviar"
                                                onClick={() => this.sendEmail(item._id)}
                                                style={{
                                                    backgroundColor: "#B845FF",
                                                    borderColor: "#B845FF"
                                                }}
                                            >
                                            </Button>

                                            {(this.props.user?.tipo == 1) ? (
                                                <Button
                                                    icon={<IconAceptarOrden />}
                                                    type="primary"
                                                    title="Aceptar"
                                                    onClick={() => this.aceptarOrden(item._id)}
                                                    disabled={item.estatus !== 0 || !(this.props.user?.tipo === 1) || (!item.almacen_id && orden?.tipo_orden === 2)}
                                                    style={item.estatus !== 0 ? {
                                                        backgroundColor: "#6CE634",
                                                        borderColor: "#6CE634",
                                                        paddingTop: '10px'
                                                    } : {
                                                        backgroundColor: "#FFCB45",
                                                        borderColor: "#FFCB45"
                                                    }}
                                                >
                                                </Button>
                                            ) : <Button
                                                icon={<IconMail />}
                                                onClick={() => this.setState({ modalNotificarVisible: true, orden_compra_id: item._id, admin: this.props.user, folio: item.folio })}
                                                type="primary"
                                                title="Nofiticar a Dueño"
                                                disabled={item.estatus !== 0}
                                                style={item.estatus !== 0 ? {
                                                    backgroundColor: "#6CE634",
                                                    borderColor: "#6CE634",
                                                } : {
                                                    backgroundColor: "#FFCB45",
                                                    borderColor: "#FFCB45"
                                                }}
                                            ></Button>}


                                            <Button
                                                icon={<IconPDF />}
                                                type="primary"
                                                title="PDF"
                                                onClick={() => {
                                                    let url = new URL(axios.defaults.baseURL + '/ordenesCompra/purchase-order')
                                                    url.searchParams.append('orden_compra_id', item._id)
                                                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                                                    window.open(url, '_blank')
                                                }}
                                                style={{
                                                    backgroundColor: "#54FFD6",
                                                    borderColor: "#54FFD6",
                                                }}
                                            >
                                            </Button>

                                            {(!item.almacen_id && orden?.tipo_orden === 2) ?
                                                <Button
                                                    icon={<WarningOutlined style={{ color: "#FFF" }} />}
                                                    type="primary"
                                                    title="Agregar Almacen"
                                                    onClick={() => {
                                                        this.setState({
                                                            modalAlmacenVisible: true,
                                                            orden_compra_id: item._id
                                                        })
                                                    }}
                                                />
                                                : null}
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {
    return (<Transacciones {...props} />)
}
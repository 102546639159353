import React, { Component, } from "react";
import { Spin, Table, Layout, Select, Menu, Tag, Checkbox, Form, Row, Col, message, InputNumber } from "antd";
import axios from "axios";
import { CardProductoMatriz } from "../../../Widgets/Cards";


import MatrizJson from "./Matriz.json"

const { Content } = Layout;
const { Option } = Select;

export default class Matriz extends Component {


	constructor(props) {
		super(props)
		this.state = {
			fixedColumns: {},
			data: [],
			loading: false,
			fixedCount: [],
		}
	}

	formRef = React.createRef();

	componentDidMount() {
		this.getProductosMatriz()

	}

	/**
	 * @memberof getProductosMatriz
	 *
	 * @method updateMatriz
	 * @description Obtiene la informacion de la matriz
	 */
	getProductosMatriz = () => {
		this.setState({ loading: true })
		axios.get('/proyecto/matriz', {
			params: {
				proyecto_id: this.props.proyecto_id
			}
		}).then(response => {
			console.log('get matriz proyecto', response)
			this.setState({ data: [] }, () => {
				this.setState({ data: response.data.matriz })
			})

			console.log('props getproductosmatriz',this.props)
			this.props.updateEstructura(response.data.proyecto.estructura)

			this.props.setVisiblePreview(this.validateMatriz(response.data.matriz))
			this.formRef.current.setFieldsValue({
				...response.data.proyecto
			})
		}).catch(error => {
			console.log("error", error);
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	 * @memberof Matriz
	 *
	 * @method updateMatriz
	 * @description actualiza la informacion de la parte de la matriz
	 */
	updateMatriz = () => {
		this.setState({ loading: true })
		axios.post('/proyecto/matriz/update', {
			proyecto_id: this.props.proyecto_id,
		}).then(response => {
			message.success('Matriz Actualizada')
			this.getProductosMatriz()
		}).catch(error => {
			message.error('Error al actualizar')
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	 * @memberof Matriz
	 *
	 * @method updateMatrizDetalle
	 * @description actualiza la informacion de solo una detalle de la matriz
	 */
	updateMatrizDetalle = (values) => {
		console.log("values", values);
		axios.post('/proyecto/matriz-detalle/update', {
			...values
		}).then(response => {


		}).catch(error => {
			message.error('Error al actualizar')
		})
	}

	/**
	 * @memberof Matriz
	 *
	 * @method onChangeData
	 * @description Se ejecuta cuando se realiza un cambio en la informacionde la orden, y la guarda automaticamente
	 */
	onChangeData = (valueChanged, allValues) => {
		axios.put('/proyectos', {
			...valueChanged,
			id: this.props.proyecto_id
		}).then(response => {
			this.props.updateEstructura(valueChanged.estructura)

			console.log('Actualizado con éxito')
		}).catch(error => console.log(error))
	}

	onChangeCheckbox = (name, order) => {
		let newFixedColumns = { ...this.state.fixedColumns }
		let fixedCount = this.state.fixedCount

		let index = fixedCount.findIndex(e => e.name === name)

		if (this.state.fixedColumns[name] === true) {
			delete newFixedColumns[name]
			fixedCount.splice(index, 1)
		}
		else {
			newFixedColumns[name] = true
			fixedCount.push({ name, order })
		}

		fixedCount.sort((a, b) => a.order - b.order);

		this.setState({
			fixedColumns: newFixedColumns

		})
	}


	validateMatriz = (data) => {
		console.log('validate matriz',data)
		let validation = false;
		data.map(element => {
			console.log('element matriz', element);

			if (element.total_venta != 0) {
				validation = true;
			}
			else {
				validation = false;
			}

		})
		console.log('validation', validation)
		return validation

	}

	/**
	 * @memberof Matriz
	 *
	 * @method renderColor
	 * @description Renderiza la fila del color correspondiente
	 */
	renderColor = (value, row, index, moneda, name) => {

		if (value) {
			value = moneda ? `$ ${value.toMoney(true)}` : value
		}
		//Cada 5 es una nueva fila 
		let scale = index % 6

		if (name === 'prorrateo_importe' || name === 'prorrateo_piezas' || name === 'prorrateo_cubicaje') {
			return <InputNumber
				defaultValue={value ?? 0}
				className={`width-100 ${scale === 3 ? 'input-red' : (scale === 4 || scale === 5) ? 'input-blue' : ''}`}
				min={0}
				max={100}
				precision={2}
				onChange={(value) => {
					this.updateMatrizDetalle({
						matriz_detalle_id: row._id,
						[name]: value
					})
				}}
				style={{ height: '32px' }}
			/>
		}

		if (name === 'prorrateo') {
			return <Select
				className={`width-100 select-matriz ${scale === 3 ? 'input-select-red' : (scale === 4 || scale === 5) ? 'input-select-blue' : 'ppppppppppppp'}`}
				defaultValue={value ?? 0}
				onSelect={(value) => {
					this.updateMatrizDetalle({
						matriz_detalle_id: row._id,
						prorrateo: value
					})
				}}
			>
				<Option value={0}>Importe</Option>
				<Option value={1}>Piezas</Option>
				<Option value={2}>Cubicaje</Option>
			</Select>
		}

		//Cuando el resiudo es 2, significa que es la fila numero 4, es la penultima (va de color rojo )
		if (scale === 3) return <Tag color="#D12C38" className="width-100">{value ?? 0}</Tag>
		//Cuando el resiudo es 3 o 4, significa que es la fila numero 5 (la ultima) va de color azul
		if (scale === 4 || scale === 5) return <Tag color="#0C3D5C" className="width-100">{value ?? 0}</Tag>



		return <Tag color="#F2F4FB" className="width-100" style={{ color: "black" }} >{value ?? 0}</Tag>
	}


	render() {
		//iterar las columnas 
		let columns = MatrizJson.reduce((previousValue, currentValue) => previousValue.concat(currentValue.columns.map((e, index) => {

			let className = currentValue.name;


			//Aqui es para dibujar la linea del fondo del  header de las columnas
			if (index === 0)
				className += " start-line"

			if ((index + 1) === currentValue.columns.length)
				className += " end-line"

			let index_l = this.state.fixedCount.findIndex(f => f.name === e.name)
			if (index_l != -1) {
				className += ` flexed-${index_l}`
			}


			//El checbkbox lo que hace es agregar un elemento posiisionado para siempre
			//Falta validar que siempre te lo coloque al inciio, y que al quitar te lo vuelva a poner en la posición original.
			let cell = {
				title: <div className={"column-title "}>
					<Checkbox
						value={this.state.fixedColumns[e.name] == true}
						onChange={() => this.onChangeCheckbox(e.name, e.order)}
					/> {e.label}</div>,
				dataIndex: e.name,
				fixed: (this.state.fixedColumns[e.name] === true ? 'left' : false),
				width: 200,
				className: className,

				onCell(record, index) {
					//La primera linea siempre define el primer producto. 
					const props = { rowSpan: 1 };
					props.className = "currentValue.name"
					if (index % 6 === 0) {
						props.className += " first-item"
					}
					return props;
				},
				render: (value, row, index) => this.renderColor(value, row, index, e.moneda, e.name),

			}

			return cell
		})), [
			{
				title: <div className={"column-title "}>Productos</div>,
				width: 400,
				dataIndex: "productos",
				key: 'productos',
				fixed: 'left',
				onCell(_, index) {
					const props = { className: "first-item", };
					if (index % 6 === 0) {
						props.rowSpan = 6
					} else props.rowSpan = 0
					return props;
				},
				render: (value, row, index) => <CardProductoMatriz value={value} row={row} />
			}
		])

		return (

			<Content className="matriz-costos">
				<Spin spinning={false}>

					<Form
						layout="vertical"

						onValuesChange={this.onChangeData}
						ref={this.formRef}
					>
						<Row style={{ margin: "1em" }}>
							<Col span={16} className="flex-left">
								{/*<Button 
									type="primary" 
									style={{marginBottom: '1rem'}}
									onClick={()=>this.updateMatriz()}
								>
									Actualizar Matriz
								</Button>*/}
							</Col>
							<Col span={8}>
								<Form.Item name="estructura" label="Tipo de Logística">
									<Select>
										<Option value={0}>Contenedor 20DP</Option>
										<Option value={1}>Contenedor 40DP</Option>
										<Option value={2}>Contenedor 40HQ</Option>
										<Option value={3}>MOQ</Option>
										<Option value={4}>Piezas por Modelo</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Form>

					<Menu mode="horizontal" style={{
						width: "100%",
						justifyContent: "center",
						margin: "1em 0 2em "
					}} onClick={e => {
						try {
							document.querySelector(".matriz-costos .table-matriz-costos .ant-table-body").scrollTo({
								top: 0,
								left: document.querySelector("." + e.key).offsetLeft - document.querySelector(".first-item > div").clientWidth - 24,
								behavior: 'smooth'
							})
						} catch (error) {

						}
					}}>
						<Menu.Item key="general">GENERAL</Menu.Item>
						<Menu.Item key="incoterm">INCOTERM</Menu.Item>
						<Menu.Item key="prorrateo">PRORRATEO</Menu.Item>
						<Menu.Item key="pedimentos_impuestos">PEDIMINETOS E IMPUESTOS</Menu.Item>
						<Menu.Item key="logistica">LOGÍSTICA</Menu.Item>
						<Menu.Item key="corporativo">CORPORATIVO</Menu.Item>
					</Menu>
					<Table
						loading={this.state.loading}
						bordered={false}
						className="table-matriz-costos"
						size="small"
						scroll={{ x: 1000, y: 1000 }}
						columns={columns}
						pagination={false}
						dataSource={this.state.data}
						locale={{ emptyText: "Sin datos disponibles" }}
					/>
				</Spin>
			</Content>

		);
	}


}

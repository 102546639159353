import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const MaterialSelect
 * @description Select para los materials registrados en el sistema
 */
const MaterialSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el material" } = props

    const [ materiales, setMateriales ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    })


    /**
     * @const getMateriales
     * @description Obitiene los materiales
     */
    const getMateriales = ({page, limit, search} = materiales) => {

        axios.get('/materiales/list', {
            params: { page, limit, search },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setMateriales({
                ...materiales,
                data: response.data.data.data,
                total: response.data.data.total,
            })

        }).catch(error => {
            message('Error al obtener los materiales')
        })
    }

    //componentDidMount
    useEffect(() => {
        getMateriales()
    }, [])

    return (
        <Select
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getMateriales({search})}
            onSelect={(material)=> {
                onChange(material.value)
            }}
            value={value}
        >
            {
                materiales.data.map(material => <Option value={material._id} key={material._id}>{material.nombre}</Option>)
            }
        </Select>
    );
}



export default MaterialSelect;
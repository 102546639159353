import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Typography, Space, List } from 'antd'
import axios from 'axios';
import {  useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import ModalServicios from './ModalServicios';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Servicios
 * @extends {Component}
 * @description Vista de Servicios
 */
class Servicios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            servicio_id: undefined,
            servicios: [],
            area: [],
            limit: this.props.searchParams.get("limit") ?? 10,
            page: this.props.searchParams.get("page") ?? 1,
            search: this.props.searchParams.get("search") ?? null,
            total: 0
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getServicios();
    }

    /**
     * @memberof Usuarios
     *
     * @method   componentDidUpdate
     * @description Si se realiza una un busqueda por la barra, actuliza el contenido
     *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search }, () => { this.getServicios() })
        }
    }


    /**
     * @memberof Rubros
     * @method getRubros
     * @description Obtiene los rubros relacionados al area
     */
    getServicios = (page = this.state.page, limit = this.state.limit, search = this.props.search) => {

        axios.get('/servicios/list', {
            params: {
                page,
                limit,
                proveedor: true,
                search,
            }
        }).then(response => {
            this.props.setSearchParams({ page, limit })
            this.setState({
                servicios: response.data.data.data,
                page: response.data.data.page,
                total: response.data.data.total,
                searching: true,
                filtroSearch: search
            });

        }).catch(error => {
            message.error('Error al traer la información')
        })
    }



    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Servicios"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Servicios " }}
                            dataSource={this.state.servicios}
                            pagination={{
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getServicios(page)
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >

                                <Col span={5} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Código Servicio</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Precio</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Proveedor</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={5} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item.codigo_servicio}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item.precio}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item.proveedor_id.alias}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, servicio_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este servicio?"
                                                        onConfirm={() => axios.delete('/servicios/delete',
                                                            { data: { id: item._id } }).then(() => {
                                                                this.getServicios()
                                                                message.success('Servicio eliminado')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalServicios
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, servicio_id: undefined })
                            this.getServicios()
                        }}
                        servicio_id={this.state.servicio_id}
                    />
                </Spin>
            </>
        )
    }

}

export default function (props) {
    let [searchParams, setSearchParams] = useSearchParams();

    return <Servicios
     {...props} 
     params={useParams()} 
     navigate={useNavigate()} 
     searchParams={searchParams}
     setSearchParams={setSearchParams}/>

}
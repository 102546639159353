import React from 'react'
import { Route, Routes } from "react-router-dom";

import RazonesSociales from '../components/Admin/Razones Sociales/RazonesSociales';


/**
 *
 *
 * @export
 * @function RouterRazonesSociales
 */
function RouterRazonesSociales(props) {

   
    return (
        <Routes>
            <Route path="" element={<RazonesSociales {...props} />} />

        </Routes>
    )
}

export default RouterRazonesSociales;

import React, { Component } from "react";

import {
    Layout, Row, Col, PageHeader, Space, Button, List,
    Tag, Pagination, Typography, Card, message, Spin,
    Tooltip,
    Statistic
} from "antd";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import { EyeOutlined, EditFilled } from "@ant-design/icons";
import { IconDelete, IconMoney, IconEye } from "../../../Widgets/Iconos";
const { Content } = Layout;
const { Text } = Typography;

const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista del listado de Proyectos
 */
class Proyectos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            facturas: {

                data: [],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                //filtros
                
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    }


    render() {

        // console.log('state', this.state)
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Proyectos" }}
                    dataSource={[{},{},{},{},{}]}
                    pagination={{
                        current: this.state.page,
                        pageSize: 10,
                        total: this.state.total,
                        position: 'bottom',
                        className: "flex-left",
                        /* onChange: (page) => this.getFacturas(page) */
                    }}
                    header={<Row className="header-list width-100 pr-1 pl-1" >
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Fecha</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Folio</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Estatus</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Pagado</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Monto</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Acciones</Text>
                        </Col>
                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 ">
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{'{{Fecha}}'}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{'{{Folio}}'}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Tag ellipsis strong>{'{{Estatus}}'}</Tag>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{'{{Pagado}}'}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{'{{Monto}}'}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Button
                                            style={{ marginRight: '0.5rem' }}
                                            type="primary"
                                            icon={<IconEye />}
                                            title="Detalle"
                                        />
                                        <Button
                                            style={{ marginRight: '0.5rem' }}
                                            type="primary"
                                            icon={<IconMoney />}
                                            title="Factura"
                                            
                                        />
                                        <Button
                                            style={{ marginRight: '0.5rem' }}
                                            type="primary"
                                            icon={<IconDelete />}
                                            title="Eliminar"
                                            
                                        />

                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton title="Nuevo registro" /* onClick={() => this.setState({ modalVisible: true })} */ />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Proyectos {...props} />)
}
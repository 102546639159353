import React, { Component } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Layout,  Col, Row, message,Spin, Typography, Tabs, Card } from 'antd'
import axios from 'axios'

//Modales
import Pagos from './Pagos'
import Facturas from './Facturas'
import Ordenes from './Ordenes'

const { Text, Title } = Typography;
const { TabPane } = Tabs;


/**
 * @export
 * @class Accounting
 * @extends {Component}
 */
class Accounting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    /**
     * @memberof Accounting
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
       
    }

    back = () => {
        this.props.history.goBack();
    }

    render() {
        const { proyecto } = this.props

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={24} className="mt-05">
                            <Title level={3}>Accounting</Title>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card
                                className="card-shadow card-account-purchases width-100" >
                                <Row>
                                    <Col span={8}>
                                        <Row justify="center">
                                            <Col span={24}>
                                                <Title className="card-title-purple" level={5}>Monto Total</Title>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="card-monto-gray">$ {proyecto.total?.toMoney(true)} MXN</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row justify="center">
                                            <Col span={24}>
                                                <Title className="card-title-purple" level={5}>Monto Pagado</Title>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="card-monto-gray">$ {proyecto?.ingresos?.toMoney(true)} MXN</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row justify="center">
                                            <Col span={24}>
                                                <Title className="card-title-purple" level={5}>Monto Pendiente</Title>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="card-monto-gray">$ {(proyecto.total - proyecto.ingresos).toMoney(true)} MXN</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Tabs defaultActiveKey="pagos" className="tabs-inventario">
                                <TabPane tab="Pagos" key="pagos">
                                    <Pagos proyecto_id={this.props.proyecto._id}/>
                                </TabPane>
                                <TabPane tab="Ordenes" key="ordenes">
                                    <Ordenes proyecto_id={this.props.proyecto._id}/>
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas">
                                    <Facturas proyecto_id={this.props.proyecto._id}/> 
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Spin>
            </>

        )
    }
}

export default function Vista(props){
    return <Accounting  {...props} params={useParams()} navigate={useNavigate()}/>
}

import React, { createRef, useEffect, useRef, useState } from "react"
import { Card, Col, Row, Typography, Form, InputNumber, Input, Table, Statistic, Button, Tag, Switch } from "antd"
import { EditableProTable } from "@ant-design/pro-table"

import { IconDelete } from '../../../Widgets/Iconos';
import iniciales from "./Iniciales.json"
import axios from "axios"
import Decimal from "decimal.js"
import "./CardMatrizNacional.scss"
import { DeleteOutlined, WindowsFilled } from "@ant-design/icons";

const { Title, Text } = Typography

const CardMatrizNacional = (props) => {

	const [editableKeys, setEditableRowKeys] = useState(() => iniciales.map((item) => item.id));
	const [dataSource, setDataSource] = useState([]);

	const [form] = Form.useForm();

	const [formVol] = Form.useForm();
	const [formFooter] = Form.useForm();
	const [totales, setTotales] = useState({})


	/**
	 * @description Valida los campos del fob
	 */
	useEffect(() => {
		console.log('actualizar steptracker', totales.final?.total)
			props.setVisiblePreview(totales.final?.total != undefined )
	}, [totales])
	const handleSave = (row) => {
		let volumenOpcional = formVol.getFieldValue("volumen") ?? 1

		if (row) {

			row.unitario = row.unitario ? new Decimal(row.unitario) : new Decimal(0);
			row.total = row.total ? new Decimal(row.total) : new Decimal(0);

			row.switch ? row.base = new Decimal(row.base) : row.base = new Decimal(0)

			let newUnitario = row.base.toNumber() === 0 ? row.unitario : row.base.div(volumenOpcional)
			let newTotal = newUnitario.mul(volumenOpcional)


			row.unitario = newUnitario.toDecimalPlaces(2).toNumber()
			row.total = newTotal.toDecimalPlaces(2).toNumber()
			row.base = row.base.toDecimalPlaces(2).toNumber()

			return row
		} else return null

	}

	const onValuesChange = async (row, list) => {
		setDataSource(list)

		if (!row) return saveNacional(list)

		row = await handleSave(row);
		let temp = {};
		temp[row.id] = row;

		form.setFieldsValue(temp);
		saveNacional(list)

		calcularTotales(list)

	}

	const calcularTotales = (data = dataSource) => {

		let { iva, margen } = formFooter.getFieldsValue()
		let { volumen } = formVol.getFieldsValue()

		let tempForm = {}
		let sumaUnitarios = 0;
		let sumaTotales = 0;

		data.every((item, index) => {
			sumaUnitarios += item.unitario
			sumaTotales += item.total


			data[index] = handleSave(item)
			tempForm[item.id] = data[index]
			return true
		})

		let temp = {}


		temp.costo = {
			unitario: Number(sumaUnitarios).toFixed(2),
			total: Number(sumaTotales).toFixed(2)
		}

		temp.margen = {
			unitario: Number((sumaUnitarios * margen) / 100).toFixed(2),
			total: Number((sumaTotales * margen) / 100).toFixed(2)
		}

		temp.cliente = {
			unitario: (Number(temp.costo.unitario) + Number(temp.margen.unitario)).toFixed(2),
			total: (Number(temp.costo.total) + Number(temp.margen.total)).toFixed(2)
		}

		temp.iva = {
			unitario: Number((temp.cliente.unitario * iva) / 100).toFixed(2),
			total: Number((temp.cliente.total * iva) / 100).toFixed(2)
		}

		temp.final = {
			unitario: (Number(temp.cliente.unitario) + Number(temp.iva.unitario)).toFixed(2),
			total: (Number(temp.cliente.total) + Number(temp.iva.total)).toFixed(2)
		}

		setTotales(temp)
		setDataSource([...data])
		form.setFieldsValue(tempForm)

	}



	const onFormValuesChange = (value) => {


		let { iva, margen } = formFooter.getFieldsValue()
		let { volumen } = formVol.getFieldsValue()



		axios.put('/proyectos', {
			iva, volumen, margen,
			id: props.proyecto_id
		}).then(res => {

		}).catch(err => console.log(err))

		calcularTotales()

	}



	const getProyecto = () => {
		axios.get("/project-manager/proyecto", {
			params: {
				id: props.proyecto_id,
				detalles: true,
				proveedor: true
			},
		}).then((res) => {

			res.data.nacional.length ? setDataSource(res.data.nacional) : setDataSource(iniciales)


			formVol.setFieldsValue({ volumen: res.data.volumen })
			formFooter.setFieldsValue({ margen: res.data.margen, iva: res.data.iva })

			res.data.nacional.length ? setEditableRowKeys(res.data.nacional.map((item) => item.id)) : setEditableRowKeys(iniciales.map((item) => item.id))

			calcularTotales(res.data.nacional)
		}).catch((err) => {
			console.log(err);
		})


	}

	useEffect(() => {

		getProyecto()

	}, [])

	const saveNacional = async (values) => {
		axios.post('/project-manager/nacional', {
			matriz: values,
			proyecto_id: props.proyecto_id,
		}).then(res => {
			console.log(res)
		}).catch(err => console.log(err))
	}






	const columns = [
		{
			title: '',
			dataIndex: 'concepto',
			renderFormItem: (
				row,
				{ type, defaultRender, record, ...rest },
				form) => {
				return (
					<Input placeholder="Ingrese el nombre del costo" />
				);
			},
			formItemProps: (form, { rowIndex }) => {
				return {
					className: "form-item-matriz-nacional",
				};
			},
			width: '25%',
		},
		{
			title: '',
			dataIndex: 'switch',
			renderFormItem: (row,
				{ type, defaultRender, record, ...rest },
				form) => {

				return <Switch
					checked={record.switch}
					size="small"
					{...rest}
				/>
			},
			width: '4%',
		},
		{
			title: 'Costo Base',
			dataIndex: 'base',

			renderFormItem: (
				row,
				{ type, defaultRender, record, ...rest },
				form) => {
				return (
					<InputNumber
						controls={false}

						formatter={(value) =>
							`$ ${value.replace("$", "").replace(" ", "")}`.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								","
							)
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						className="width-100"
						{...rest}
					/>
				);
			},
			formItemProps: (form, { entity }) => {
				return entity.switch === false ? {
					className: "form-item-matriz-nacional",
					hidden: true
				} : null;
			},
			width: '21%',

		},

		{
			title: 'Costo Unitario',
			dataIndex: 'unitario',
			renderFormItem: (row,
				{ type, defaultRender, record, ...rest },
				form) => {
				return <InputNumber
					controls={false}

					formatter={(value) =>
						`$ ${value.replace("$", "").replace(" ", "")}`.replace(
							/\B(?=(\d{3})+(?!\d))/g,
							","
						)
					}
					parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
					className="width-100"
					{...rest}
				/>
			},
			width: '20.83%',
		},
		{
			title: 'Costo Total',
			dataIndex: 'total',
			renderFormItem: (row,
				{ type, defaultRender, record, ...rest },
				form) => {
				return <InputNumber
					controls={false}

					formatter={(value) =>
						`$ ${value.replace("$", "").replace(" ", "")}`.replace(
							/\B(?=(\d{3})+(?!\d))/g,
							","
						)
					}
					className="width-100"
				/>;
			},
			width: '25%',
		},
		{
			title: '',
			valueType: 'option',
			width: '4.16%',
			render: () => {
				return null;
			},
		},
	];

	return (
		<Card className="card-shadow card-matriz-nacional">
			<Row className="mb-1">
				<Col span={23}>
					<Title level={4}>Margen de Utilidad</Title>
				</Col>
			</Row>
			<Col xs={24}>
				<Row align="middle" className="width-100">
					<Col xs={6} className="card-matriz-nacional-label">
						<Text>Volumen</Text>
					</Col>
					<Col xs={6} className="card-matriz-nacional-input">
						<Form
							// ref={ref => {formVol.current = ref}}
							form={formVol}
							name="volumen"
							onValuesChange={onFormValuesChange}
						// initialValues={{ volumen: volumen }}
						>
							<Form.Item
								name="volumen">
								<InputNumber
									// onChange={(val) => onVolumenChange(val)}
									placeholder="0"
									min={1}
									className="width-100"
								/>
							</Form.Item>
						</Form>

					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<EditableProTable
					className="nacional-table"
					rowKey="id"
					scroll={{
						x: 960,
					}}
					recordCreatorProps={{
						newRecordType: 'dataSource',
						position: 'bottom',
						creatorButtonText: 'Agregar un gasto a la cotización',
						record: () => ({ id: Date.now().toString(), base: 1, unitario: 1, total: 1, switch: true }),
					}
					}

					loading={false}
					columns={columns}
					value={dataSource}
					onChange={setDataSource}
					editable={{
						form,
						type: 'multiple',
						editableKeys,
						deletePopconfirmMessage: '¿Está seguro de eliminar este cargo?',
						deleteText: <Button
							type="primary"
							icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
							title="Eliminar"
							size='small'
							danger
						/>,
						actionRender: (row, config, defaultDoms) => {
							return [defaultDoms.delete];
						},
						onValuesChange,
						onChange: setEditableRowKeys,
					}}


				/>
			</Col>
			<Form
				// ref={ref => {formFooter.current = ref}}
				name="formFooter"
				form={formFooter}
				onValuesChange={onFormValuesChange}
			>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Tag className="width-100 matriz-nacional-label center">
							<Text>Costo</Text>
						</Tag>
					</Col>
					<Col xs={5}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.costo?.unitario ? totales.costo?.unitario : 0}</Text>
						</Tag>
					</Col>
					<Col xs={6}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.costo?.total ? totales.costo?.total : 0}</Text>
						</Tag>
					</Col>

					<Col span={6}>
						<Tag className="width-100 matriz-nacional-label center">
							<Text>Margen</Text>
						</Tag>
					</Col>
					<Col xs={6}>

						<Form.Item
							name="margen">
							<InputNumber
								// onChange={(margen) => {onMargenChange(margen)}}
								controls={false}
								placeholder="0"
								min={1}
								className="width-100"
							/>
						</Form.Item>

					</Col>
					<Col xs={5}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.margen?.unitario ? totales.margen?.unitario : 0}</Text>
						</Tag>
					</Col>
					<Col xs={6}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.margen?.total ? totales.margen?.total : 0}</Text>
						</Tag>
					</Col>

					<Col span={12}>
						<Tag className="width-100 matriz-nacional-label center">
							<Text>Precio a Cliente</Text>
						</Tag>
					</Col>
					<Col xs={5}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.cliente?.unitario ? totales.cliente?.unitario : 0}</Text>
						</Tag>
					</Col>
					<Col xs={6}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.cliente?.total ? totales.cliente?.total : 0}</Text>
						</Tag>
					</Col>

					<Col span={6}>
						<Tag className="width-100 matriz-nacional-label center">
							<Text>IVA</Text>
						</Tag>
					</Col>
					<Col xs={6}>

						<Form.Item
							name="iva">
							<InputNumber
								controls={false}
								placeholder="0"
								min={1}
								className="width-100"
							/>
						</Form.Item>

					</Col>
					<Col xs={5}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.iva?.unitario ? totales.iva?.unitario : 0}</Text>
						</Tag>
					</Col>
					<Col xs={6}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.iva?.total ? totales.iva?.total : 0}</Text>
						</Tag>
					</Col>

					<Col span={12}>
						<Tag className="width-100 matriz-nacional-label center">
							<Text>Precio Final + IVA</Text>
						</Tag>
					</Col>
					<Col xs={5}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.final?.unitario ? totales.final?.unitario : 0}</Text>
						</Tag>
					</Col>
					<Col xs={6}>
						<Tag className="width-100 matriz-nacional-data">
							<Text>$ {totales.final?.total ? totales.final?.total : 0}</Text>
						</Tag>
					</Col>
				</Row>
			</Form>

		</Card>
	);
}

export default CardMatrizNacional;
import React, { Component } from 'react';
import { Divider, Spin, PageHeader, Layout, message, Row, Col, Card, Typography, Tabs } from 'antd'
import axios from 'axios';

import { IconArrowBack } from '../../Widgets/Iconos';
import Transacciones from '../CRM/Clientes/DetalleClienteSecciones/Transacciones';
import Facturas from '../CRM/Clientes/DetalleClienteSecciones/Facturas';
import { useParams, useNavigate } from 'react-router-dom';


const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;
const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class CentroDistribucion
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class CentroDistribucion extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cliente: {},
            centro: {},
            pagos: {},
            proveedor: {}
        }
    }

    componentDidMount() {
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.params.cliente_id) {
            this.getCliente()
            this.getCentrocliente();

        }
        if (this.props.params.proveedor_id) {
            this.getProveedor();
            this.getCentroProveedor();

        }
    }

    /**
       * @memberof CentroDistribucion
       * @method getCliente
       * @description Obtiene un cliente de la DB
       */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/get', {
            params: {
                id: this.props.params.cliente_id
            }
        })
            .then(response => {
                this.setState({ cliente: response.data.data, })
            })
            .catch(error => {
                message.error(error.response.data.message)
                console.log('error al traer usuario', error)
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof CentroDistribucion
    * @method getProveedor
    * @description  Obtiene la informacion del proveedor seleccionado

    **/
    getProveedor = () => {
        axios.get('/proveedores/get', {
            params: {
                id: this.props.params.proveedor_id
            }
        }).then(response => {
            console.clear()
            console.log('proveedor', response.data.data)
            this.setState({
                proveedor: response.data.data
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información del proveedor')
        })
    }

    /**
     *
     *
     * @memberof CentroDistribucion
     * @method getCliente
     * @description Obtiene un centro de distribucion de un cliente de la DB
     */
    getCentrocliente = () => {
        axios.get('/clientes/centros-distribucion/get', {
            params: {
                id: this.props.params.centro_id
            }
        })
            .then(res => {
                this.setState({ centro: res.data.data })
            })
            .catch(error => {
                message.error('No se pudo obtener centro de distribucion')
            })
    }


    /**
    *
    *
    * @memberof CentroDistribucion
    * @method getCentroProveedor
    * @description Obtiene un centro de distribucion de un proveedor de la DB
    */
    getCentroProveedor = () => {
        axios.get('/proveedores/centros-distribucion/get', {
            params: {
                id: this.props.params.centro_id
            }
        })
            .then(res => {
                this.setState({ centro: res.data.data })
            })
            .catch(error => {
                message.error('No se pudo obtener centro de distribucion')
            })
    }

    title = () => {

        let view_name = '';
        let { cliente, proveedor } = this.state;

        if (cliente._id) {
            let direccion = (cliente?.cliente_id?.direccion) ? cliente.cliente_id.direccion : 'Sin Dirección'
            view_name = `Información de ${cliente?.nombre} ${cliente?.apellido ? cliente?.apellido : ''} / ${direccion}`
        }

        else if (proveedor._id) {
            let direccion = (proveedor?.direccion) ? proveedor.direccion : 'Sin Dirección'
            view_name = `Información de ${proveedor?.razon_social} /  ${direccion}`
        }
        else view_name = this.props.title

        return view_name
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        backIcon={<IconArrowBack />}
                        onBack={this.back}
                        title={this.title()}

                    />

                    <Content className="admin-content content-bg pd-1">
                        <Row>
                            <Col span={24}>
                                <Card bordered >
                                    <Row>
                                        <Title level={4}>Información del Centro de Distribución</Title>
                                    </Row>
                                    <Row>
                                        {(this.state.centro?.identificador) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Identificador / Alias</label>
                                                <p className="pInfo"><strong>
                                                    {this.state.centro?.identificador}
                                                </strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.centro?.razon_social) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Razón Social</label>
                                                <p className="pInfo"><strong>{this.state.centro?.razon_social}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.centro?.rfc) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">RFC</label>
                                                <p className="pInfo"><strong>{this.state.centro?.rfc}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.centro?.direccion) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Dirección</label>
                                                <p className="pInfo"><strong>{this.state.centro?.direccion}</strong> </p>
                                            </Col>
                                            : null}

                                    </Row>

                                    <Row>
                                        <Title level={4}>Información del {this.props.title}</Title>
                                    </Row>

                                    {(this.state.cliente != []) ?
                                        <Row>
                                            {(this.state.cliente?.nombre || this.state.cliente?.apellido) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Nombre</label>
                                                    <p className="pInfo"><strong>
                                                        {this.state.cliente?.nombre} {this.state.cliente?.apellido}
                                                    </strong> </p>
                                                </Col>
                                                : null}

                                            {(this.state.cliente?.email) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Correo Electrónico</label>
                                                    <p className="pInfo"><strong>{this.state.cliente?.email}</strong> </p>
                                                </Col>
                                                : null}
                                            {(this.state.cliente?.cliente_id?.telefono) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Número Telefónico</label>
                                                    <p className="pInfo"><strong>{this.state.cliente?.cliente_id?.telefono}</strong> </p>
                                                </Col>
                                                : null}

                                            {(this.state.cliente?.cliente_id?.rfc) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Registro Federal de Contribuyentes</label>
                                                    <p className="pInfo"><strong>{this.state.cliente?.cliente_id?.rfc}</strong> </p>
                                                </Col>
                                                : null}
                                        </Row>
                                        :
                                        null}
                                    {(this.state.proveedor != []) ?
                                        <Row>
                                            {(this.state.proveedor?.nombre || this.state.proveedor?.alias) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Nombre</label>
                                                    <p className="pInfo"><strong>
                                                        {this.state.proveedor?.nombre || this.state.proveedor?.alias}
                                                    </strong> </p>
                                                </Col>
                                                : null}

                                            {(this.state.proveedor?.email) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Correo Electrónico</label>
                                                    <p className="pInfo"><strong>{this.state.proveedor?.email}</strong> </p>
                                                </Col>
                                                : null}
                                            {(this.state.proveedor?.telefono) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Número Telefónico</label>
                                                    <p className="pInfo"><strong>{this.state.proveedor?.telefono}</strong> </p>
                                                </Col>
                                                : null}

                                            {(this.state.proveedor?.rfc) ?
                                                <Col xs={24} lg={12} xl={6}>
                                                    <label className="lblTitulo">Registro Federal de Contribuyentes</label>
                                                    <p className="pInfo"><strong>{this.state.proveedor?.rfc}</strong> </p>
                                                </Col>
                                                : null}
                                        </Row> : null}
                                    <Row>
                                        <Divider />
                                        <Title level={4}>Información de Pagos</Title>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Monto Venta</label>
                                            <p className="pInfo"><strong>$ {(this.state.centro?.total_vendido) ? this.state.centro.total_vendido.toMoney(true) : 0.00} mxn</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Monto Pagado</label>
                                            <p className="pInfo"><strong>$ {(this.state.centro?.total_pagado) ? this.state.centro.total_pagado.toMoney(true) : 0.00} mxn</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo por Pagar</label>
                                            <p className="pInfo"><strong>$ {(this.state.centro?.saldo_pendiente) ? this.state.centro.saldo_pendiente.toMoney(true) : 0.00} mxn</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Total Facturado</label>
                                            <p className="pInfo"><strong>$ {(this.state.centro?.total_facturado) ? this.state.centro.total_facturado.toMoney(true) : 0.00} mxn</strong> </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Tabs type="card" className="width-100">
                                <TabPane tab="Transacciones" key="transacciones" >
                                    <Transacciones
                                        cliente_id={this.props.params.id}
                                        cliente={this.state?.cliente}

                                        centro_id={this.props.params.centro_id}
                                        centro={this.state?.centro}

                                        proveedor_id={this.props.params.proveedor_id}
                                        proveedor={this.state?.proveedor}
                                    />
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas">
                                    <Facturas
                                        cliente_id={this.props.params.id}
                                        cliente={this.state?.cliente}

                                        centro_id={this.props.params.centro_id}
                                        centro={this.state?.centro}

                                        proveedor_id={this.props.params.proveedor_id}
                                        proveedor={this.state?.proveedor}
                                    />
                                </TabPane>
                            </Tabs>

                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}


export default function (props) {
    return <CentroDistribucion {...props} params={useParams()} navigate={useNavigate()} />
}
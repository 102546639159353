import React, { Children, Component } from "react";
import {
  Button,
  Col,
  Row,
  Popconfirm,
  Spin,
  Table,
  PageHeader,
  Layout,
  message,
  Space,
  Tag,
  Image,
  Typography,
  Select,
  Form,
  InputNumber,
  Input,
  List,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default class FOB extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <List
          loading={this.state.loading}
          className="component-list width-100 form-list"
          locale={{ emptyText: "No hay proveedores" }}
          dataSource={this.props.contenido}
          header={
            <Row className="header-list width-100 pr-1 pl-1">
              <Col span={4}>
                <Text strong>Concepto</Text>              
              </Col>
              <Col span={4} className="center">
                <Text strong>Tarifa Base</Text>
              </Col>
              <Col span={4} className="center">
                <Text strong>Proporción</Text>
              </Col>
              <Col span={4} className="center">
                <Text strong>Tarifa</Text>
              </Col>
              <Col span={8} className="center">
                <Text strong>Especificaciones</Text>
              </Col>
            </Row>
          }
          renderItem={(item) => (
            <Row className="width-100 pr-1 pl-1" align="middle">
              <Col span={4}>
                <Text>{item.concepto}</Text>
              </Col>
              <Col span={4} className="center">
                <Form.Item
                  name={`${item.tarifa_base_field}`}
                  
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese una tarifa base",
                    },
                  ]}
                >
                  <InputNumber placeholder="0" />
                </Form.Item>
              </Col>
              <Col span={4} className="center">
                <Form.Item
                  name={`${item.proporcion_field}`}
                  
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese una proporción",
                    },
                  ]}
                >
                  <InputNumber placeholder="0" />
                </Form.Item>
              </Col>
              <Col span={4} className="center">
                <Text>{"{{Valor Calculado}}"}</Text>
              </Col>
             
              <Col span={8} className="center">
                <Form.Item name={`${item.especificaciones_field}`} className="width-95" rules={[{}]}>
                  <TextArea rows="2" />
                </Form.Item>
              </Col>
            </Row>
          )}
        />
      </>
    );
  }
}

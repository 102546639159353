import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Refacciones from '../components/Admin/Refacciones/Refacciones'



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterRefacciones(props) {

    return (
        <Routes>
            <Route path="" element={<Refacciones {...props} />} />
        </Routes>
    )
}

export default RouterRefacciones;

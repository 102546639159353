import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Modal, Spin, Divider, Image, Alert } from 'antd';
import { Navigate, Link } from "react-router-dom";
import { User, SetUser } from '../../Hooks/Logged';
import { Auth } from '../../Middleware/AuthVerify';
import '../../Styles/Global/auth.css';
const axios = require("axios").default;

/**
 *
 *
 * @class Login
 * @extends {Component}
 */
class Login extends Component {

    NavigateLink = "/admin/dashboard";
    recoveryPassword = React.createRef();

    /**
     *Creates an instance of Login.
     * @param {*} props
     * @memberof Login
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            loading: false,
            error: {
                success: null,
                message: null
            },
            statusLoading: 0,
            isRecovery: false,
            isRecoveryLoading: false
        }
    }

    componentDidMount() {

    }



    /**
     * @memberof Login
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al usuario
     *
     * @returns response (array)
     **/
    handleSubmit = (values) => {
        this.setState({ loading: true })
        axios.post('/login', {
            email: values.email,
            password: values.password
        })
            .then(({ data, headers }) => {

                console.log('props', this.props)
                const { setUser } = this.props;

                axios.defaults.headers.post["Authorization"] = headers.authorization;

                sessionStorage.setItem('token', headers.authorization);
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

                setUser(data.user);
                if(data.user.tipo >= 3)
                    this.NavigateTo('/customer/pedidos');
                else
                    this.NavigateTo('/admin/dashboard');

            })
            .catch(({ response }) => {
                console.log('error', response)
                this.setState({ error: response?.data })
            })
            .finally(f => {
                this.setState({ loading: false });
            })
    };


    /**
     * @memberof Login
     *
     * @method renderNavigate
     * @description  Activa el redireccionamiento si el formulario se envio con éxito
     *
     **/
    renderNavigate = () => {
        if (this.state.log)
            return <Navigate to={this.NavigateLink} />
    };


    /**
     * @memberof Login
     *
     * @method NavigateTo
     * @description  Redirecciona a cierto link.
     *
     **/
    NavigateTo = (to) => {
        this.NavigateLink = to;
        this.setState({ log: true });
    };

    /**
    * @memberof Login
    * @method   handleRecoveryPassword
    * @description Metodo de recuperación de contraseña
    *
    **/
    handleRecoveryPassword = async value => {
        await this.setState({
            isRecoveryLoading: true
        })
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                this.setState({
                    isRecovery: false
                })
                Modal.success({
                    title: 'Restablece tu contraseña.',
                    content: '¡El correo ha sido enviado! Revisa tu bandeja de entrada.'
                })
                this.recoveryPassword.current.resetFields();
            })
            .catch(res => {
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que sea el mismo con el que te registraste.'
                });
            })
            .finally(() => {
                this.setState({
                    isRecoveryLoading: false
                })
            })
    }


    render() {
        return (
            <>
                {this.renderNavigate()}
                <Row style={{ height: '100vh' }}>
                    <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 10, order: 1 }} xl={{ span: 10, order: 1 }} xxl={{ span: 10, order: 1 }} >
                        <Spin spinning={this.state.loading}>
                            <Row justify="center" gutter={[0, 0]}>
                                <Col span={24} >
                                    <Row justify="center" gutter={[0, 0]}>
                                        <Col xs={{ span: 20, offset: 2, pull: 1 }} sm={{ span: 24, offset: 0, pull: 0 }} md={{ span: 20, offset: 0 }} lg={{ span: 20, offset: 1, pull: 1 }} xl={{ span: 20, offset: 0, pull: 0 }} xxl={{ span: 12, offset: 0, pull: 0 }} >

                                            <Image src={'images/logo-blue.png'} className="mt-3 mb-3" wrapperClassName="center" preview={false} />
                                            <h3 className="h3-title mt-2">Iniciar Sesión</h3>
                                            <Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false}>
                                                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Por favor ingresa tu  correo' }]}>
                                                    <Input placeholder="E-mail" size="large" className="input-login" />
                                                </Form.Item>
                                                <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Por favor ingresa tu  contraseña' }]}>
                                                    <Input type="password" placeholder="Contraseña" size="large" className="input-login" />
                                                </Form.Item>
                                                <Link to={'#'} onClick={() => this.setState({ isRecovery: true })} className="mb-1"> Olvide mi contraseña </Link>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" block size="large" className="button-login" >Iniciar sesión </Button>
                                                </Form.Item>
                                                <Divider />
                                            </Form>

                                            {this.state.error?.success == false ?
                                                <Alert
                                                    message="¡Ha ocurrido un error!"
                                                    description={this.state.error.message}
                                                    type="error"
                                                    closable
                                                    className="pd-1"

                                                    onClose={() => this.setState({ error: { success: null, description: null } })}

                                                />
                                                : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Spin>
                    </Col>
                    <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 14, order: 2 }} xl={{ span: 14, order: 2 }} xxl={{ span: 14, order: 2 }} style={{ background: "url('images/bg-1.jpg')" }} >
                    </Col>
                </Row >
                <Modal
                    title="Olvidé mi contraseña"
                    visible={this.state.isRecovery}
                    onOk={() => this.recoveryPassword.current.submit()}
                    onCancel={() => this.setState({ isRecovery: false })}
                    maskClosable={!this.state.isRecoveryLoading}
                    okText="Enviar"
                    cancelText="Cancelar"
                >
                    <Form onFinish={this.handleRecoveryPassword} layout={"vertical"} className="pd-2" ref={this.recoveryPassword}>
                        <Form.Item
                            name="email"
                            label="Escribe tu correo electrónico."
                            rules={[{
                                required: true,
                                message: 'Por favor, ingresa tu correo electronico.'
                            }]}>
                            <Input type="email" size="large" placeholder="Escribe tu correo electrónico" />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
    }
}


export default function (props) {

    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <Login {...props} user={user} setUser={setUser} />
}
import React, { Component } from "react";
import { Layout, Row, Col, Divider, PageHeader, DatePicker, Form, message, Modal, Pagination } from "antd";
import { CardTaskClient, CardTaskStep } from '../../Widgets/Cards';
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons'
import axios from 'axios'

import User from '../../../Hooks/Logged'

const { Content } = Layout;



/**
 *
 *
 * @export
 * @class ProjectManager
 * @extends {Component}
 * @description Vista de Project Manager
 */
class ProjectManager extends Component {

    constructor(props) {
        super(props)
        this.state = {
            step1: [
                {
                    title: 'Formulario de Cliente',
                    section:'quotation',
                    name:'form',
                },
                {
                    title: 'Cotización Preeliminar',
                    section:'quotation',
                    name:'cotizacion',
                },
                {
                    title: 'Matriz de Costos',
                    section:'quotation',
                    name:'matriz_costos',
                },
                {
                    title: 'Envio de Cotización Final',
                    section:'quotation',
                    name:'envio_cotizacion',
                },
                {
                    title: 'Aprobación o Rechazo',
                    section:'quotation',
                    name:['aprobacion','rechazo','recotizo'],
                }
            ],
            step2: [
                {
                    title: 'Elaboracion de Contrato',
                    section:'legal',
                    name:'contrato',
                },
                {
                    title: 'Vo. Bo. Contrato',
                    section:'legal',
                    name:'firmado_empresa_contrato',
                },
                {
                    title: 'Envio de Contrato',
                    section:'legal',
                    name:'envio_contrato',
                },
                {
                    title: 'Firma del cliente',
                    section:'legal',
                    name:'firmado_cliente',
                },
            ],
            step3: [
                {
                    title: 'Anticipo de Cliente',
                    section:'accounting',
                    name:'anticipo_cliente',
                },
                {
                    title: 'Factura del Anticipo',
                    section:'accounting',
                    name:'factura_anticipo',
                },
                {
                    title: 'Orden de Compra',
                    section:'accounting',
                    name:'orden_compras',
                },
                {
                    title: 'Plan de Pagos',
                    section:'accounting',
                }, {
                    title: 'Liquidacion a Proveedor',
                    section:'accounting',
                    name:'liquidacion_proveedor',
                },

            ],
            step4: [
                {
                    title: 'Reservacion del Flete Maritimo',
                    section:'logistica',
                    name:'reservacion_flete'
                },
                {
                    title: 'Embarcación de Mercancia',
                    section:'logistica',
                    name:'embarcacion'
                },
                {
                    title: 'Pago de impuestos',
                    section:'logistica',
                    name:'impuestos'
                }, {
                    title: 'Transportacion Maritima de Mercancia',
                    section:'logistica',
                    name:'transporte_mercancia'
                }, {
                    title: 'Aduana',
                    section:'logistica',
                    name:'aduana'
                }, {
                    title: 'Flete',
                    section:'logistica',
                    name:'flete'
                }, {
                    title: 'Terminacion de Proyecto',
                    section:'logistica',
                    name:'terminacion'
                },
            ],

            loading: false,
            proyectos: {
                quotation: {
                    data: []
                },
                legal:{
                    data:[]
                },
                accounting:{
                    data:[]
                },
                logistica:{
                    data:[]
                },
                limit: 10,
                page: 1,
                total: 0,
                loading: false
            },
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProyectos();


    }

    /**
    * @memberof Cuentas
    * @method getProyectos
    * @description Trae los proyectos 
    * 
    */
    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.props.search
    } = this.state.proyectos) => {

        axios.post('/project-manager', {
            page,
            limit,
            search
        }).then(({ data }) => {

            console.log('data get project manager',data)


             this.setState({
                 proyectos: {
                     ...this.state.proyectos,
                     ...data,
                     total: data.total,
                     page:page,
                     loading: false
                 },
                 loading: false
             });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }

    /**
    * @memberof Cuentas
    * @method asignarAseser
    * @description Trae las cuentas 
    * 
    */
    asignarAgente = (proyecto_id) => {

        if(this.props.user.tipo)

        Modal.confirm({
            title: '¿Quieres ser el Asesor de este Proyecto?',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                axios.post('/project-manager/agente', {
                    proyecto_id: proyecto_id,
                }).then(response => {
                    message.success('Asesor Asignado')
                    this.getProyectos()
                }).catch(error => {
                    console.log(error)
                    message.error('Error al asignar el Asesor')
                })
            },
        });
    }

    /**
    * @memberof Cuentas
    * @method redirect
    * @description Redirige a la vista para visualizar los datos del proyecto
    * 
    */
    redirect = (proyecto_id) => {
        this.props.navigate(`/admin/project-manager/manager/matriz-costos/${proyecto_id}`);
    }

    onCardClick = (proyecto) => {
        let {tipo} = this.props.user

        if(!(proyecto.agente_compras_id?.nombre) && tipo === 2){
            this.asignarAgente(proyecto._id)
            return
        }
        
        this.redirect(proyecto._id)
    }

    render() {

        const { step1, step2, step3, step4, proyectos } = this.state;
        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Form
                            noStyle
                            className="form-filters"
                            onValuesChange={this.filter}
                        >
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 8, order: 1 }} xl={{ span: 8, order: 1 }} lg={{ span: 17, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 4, order: 1 }} xs={{ span: 14, order: 1 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Project Manager
                                    </span>
                                </Col>

                            </Row>
                        </Form>
                    }
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[8, 8]} className="width-100" align="start" justify="space-between">

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep
                                    color={'#0089ED'}
                                    title={"Cotización"}
                                    position={"Realización de esquema de costos para cotización"}
                                />
                                <Divider className="border-task" />
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep
                                    color={'#DAC401'}
                                    title={"Legales"}
                                    position="Firma del Contrato"
                                />
                                <Divider className="border-task" />
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep
                                    color={'#5C54E0'}
                                    title={"Cuentas y Compras"}
                                    position={"Listado de pagos, facturas y ordenes de compra"}
                                />
                                <Divider className="border-task" />
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep
                                    color={'#D6009A'}
                                    title={"Logística"}
                                    position={"Entrega de material, recibos y embarques"}
                                />
                                <Divider className="border-task" />
                            </div>
                        </Col>

                    </Row>

                    <Row gutter={[8, 8]} className="width-100" align="start" justify="space-between">
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                {this.state.proyectos.quotation.data.map(proyecto => {
                                    return <CardTaskClient
                                        key={proyecto._id}
                                        task={step1}
                                        color={'#0089ED'}
                                        proyecto={proyecto}
                                        onClick={() => this.onCardClick(proyecto)}
                                    />
                                })}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                {this.state.proyectos.legal.data.map(proyecto => {
                                    return <CardTaskClient
                                        key={proyecto._id}
                                        task={step2}
                                        color={'#DAC401'}
                                        proyecto={proyecto}
                                        onClick={() => this.onCardClick(proyecto)}
                                    />
                                })}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                {this.state.proyectos.accounting.data.map(proyecto => {
                                    return <CardTaskClient
                                        key={proyecto._id}
                                        task={step3}
                                        color={'#5C54E0'}
                                        proyecto={proyecto}
                                        onClick={() => this.onCardClick(proyecto)}
                                    />
                                })}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                {this.state.proyectos.logistica.data.map(proyecto => {
                                    return <CardTaskClient
                                        key={proyecto._id}
                                        task={step4}
                                        color={'#D6009A'}
                                        proyecto={proyecto}
                                        onClick={() => this.onCardClick(proyecto)}
                                    />
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]} className="width-100" >
                        <Col>
                            <Pagination
                                current={this.state.proyectos.page}
                                total={this.state.proyectos.total}
                                onChange={(page, limit) => this.getProyectos({ page, limit })}
                            />
                        </Col>
                    </Row>

                </Content>
            </>
        )
    }
}


export default function (props) {

    let navigate = useNavigate()
    let user = React.useContext(User)

    return <ProjectManager {...props} navigate={navigate} user={user} />
}

import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Radio } from 'antd'
import { useNavigate, useParams, Link, Route, Routes } from "react-router-dom";

//componentes
import StepTracker from './StepTracker'
import Entrega from './Logistica/Entrega';
import Embarque from './Logistica/Embarque';
import Produccion from './Logistica/Produccion';
import Accounting from './Accounting/Accounting';
import Cotizacion from './Cotizaciones/Cotizacion';
import { CardProyecto, CardTaskProgress } from "../../Widgets/Cards";

//css
import '../../../Styles/Modules/Customers/pedidos.css';

const { Content } = Layout;
const { Text, Title } = Typography 
const moment = require('moment')
const axios = require('axios')

class Main extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            proyecto:{},
            proyecto_id: this.props.params.proyecto_id,
        }
    }

    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProyecto()
    }


    /**
     *
     *
     * @memberof MatrizCostos
     *
     * @method getProyecto
     * @description obetenmos la informacion del proyecto
     */
    getProyecto = () => {
        this.setState({ loading: true });
        axios.get("/project-manager/proyecto", {
                params: {
                    id: this.state.proyecto_id,
                    contabiliad: true, //Informacion sobre ingresos y egresos
                },
            })
            .then((response) => {
                console.clear()
                console.log("response", response.data);
                this.setState({
                    proyecto: response.data
                })
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al cargar Proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };


	render(){

        const { proyecto } = this.state

		return (
			<>
				<Spin spinning={this.state.loading}>

                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Mis Pedidos"
                    />
                    <Content className="admin-content content-bg pd-1">

                    	<Row gutter={[16, 16]}>
                    		<Col xs={24} md={12} lg={7}>
                                <StepTracker proyecto_id={this.state.proyecto_id} proyecto={proyecto}/>
                            </Col>
                            <Col xs={24} md={12} lg={17}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={8}>
                                        <CardProyecto
                                            {...proyecto}
                                            nombre={`${proyecto?.cliente_id?.usuario?.nombre} ${proyecto?.cliente_id?.usuario?.apellido}`}
                                            empresa={proyecto?.cliente_id?.razon_social}
                                            telefono={proyecto?.cliente_id?.telefono}
                                            correo={proyecto?.cliente_id?.usuario?.email}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <CardTaskProgress
                                            started={proyecto.createdAt}
                                            proyecto={proyecto}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        {/* Vistas del Project Manager */}
                                        <Routes>
                                            <Route path="" element={<Cotizacion {...this.props} proyecto={proyecto} getProyecto={this.getProyecto}/>} />
                                            <Route path="/accounting" element={<Accounting {...this.props} proyecto={proyecto}/>} />
                                            <Route path="/produccion" element={<Produccion {...this.props} proyecto={proyecto}/>} />
                                            <Route path="/embarque" element={<Embarque {...this.props} proyecto={proyecto}/>} />
                                            <Route path="/entrega" element={<Entrega {...this.props} proyecto={proyecto}/>} />
                                        </Routes>
                                    </Col>
                                </Row>
                            </Col>
                            
                    	</Row>


                    </Content>
                </Spin>
			</>
		)
	}

}

export default function	Vista(props){
	return <Main  {...props} params={useParams()} navigate={useNavigate()}/>
}
import React, { useState, useEffect } from 'react';
import { Button, Row, Popconfirm, Spin, Layout, message, Space, Image, Typography, Select, Input, Form, InputNumber, Alert, Checkbox, Col } from 'antd'
import { IconDelete, } from '../../../Widgets/Iconos';
import { CheckOutlined, EditFilled} from '@ant-design/icons';
import { EditableProTable } from "@ant-design/pro-table";
import Decimal from 'decimal.js';

import '../../../../Styles/Modules/ProjectManager/MatrizCostos.scss'
import ModalAgregarProducto from '../../../Public/Cotizador/Modals/ModalAgregarProducto';

const { Text } = Typography;
const { Option } = Select;
const axios = require('axios')

export default function FOB(props) {

    const [form] = Form.useForm()

    let [editableKeys, setEditableKeys] = useState([]);
    let [dataSource, setDataSource] = useState([]);
    let [categorias, setCategorias] = useState([]);
    let [isFixed, setIsFixed] = useState(true);
    let [productId, setProductId] = useState(undefined);
    let [columnas, setColumnas] = useState([]);
    let [visibleModal, setVisibleModal] = useState(false);

    //componentDidMount
    useEffect(() => {
        getCategorias()
    }, []);

    useEffect(() => {
        if(props.productos){
            setDataSource([...props.productos])
            setEditableKeys(props.productos?.map((p) => p._id))

            let isComplete = (props.productos?.length > 0 && !(props.productos?.find(p => !p.proyecto_detalle?._id)))
            props.setChecked(isComplete)

            console.log(isComplete)
        }

    }, [props.productos]);

    useEffect(() => {

        let columnas = [
            {
                title: null,
                dataIndex: 'imagen',
                width: 50,
                fixed: isFixed ? 'left' : false,
                renderFormItem: (text, {record}) => (
                    <Row justify='center'>
                        {
                            record.fotografias && record.fotografias?.length > 0 && (
                                <Image
                                    width={35}
                                    src={`${axios.defaults.baseURL}/upload/${record.fotografias[0]}`}   
                                />
                            )
                        }
                    </Row>
    
                )
            },
            {
                title: <>
                    Modelo
                </>,
                dataIndex: 'modelo',
                width: 175,
                fixed: isFixed ? 'left' : false,
                renderFormItem: (text, { record }, extras) => {
                    return <Text className='center'>{record.modelo || '---'}</Text>
                }
    
            },
            {
                title: 'Descripción',
                dataIndex: 'descripcion',
                width: 175,
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: "Ingrese una descripción",
                        },
                    ],
                },
                renderFormItem: (text, record) => (
                    <Input placeholder='Descripcion' />
                )
            },
            {
                title: 'Categoría',
                dataIndex: ['proyecto_detalle', 'categoria_id'],
                width: 200,
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: "Seleccione la categoria",
                        },
                    ],
                },
                renderFormItem: (text, record) => (
                    <Select
                        className='categoria center'
                        showSearch
                        filterOption={false}
                        showArrow
                        onSearch={search => getCategorias(search)}
                        labelInValue
                    >
                        {categorias.length > 0 ? categorias.map(e => <Option value={e._id}>{e.nombre}</Option>) : null}
                    </Select>
                )
            },
            {
                title: 'Costo del Proveedor',
                dataIndex: ['proyecto_detalle', 'costo_proveedor'],
                width: 175,
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: "Ingrese el costo del proveedor",
                        },
                    ],
                },
                renderFormItem: (row, { record }, form) => (
                    <InputNumber
                        controls={false}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        className="width-100"
                    />
                ),
            },
            {
                title: <Alert message="Cantidad Mínima de la Orden (MOQ)" showIcon={false} style={{ borderRadius: 100, backgroundColor: "#F1F4FF" }} banner />,
                children: [
                    {
                        title: <div className='join-title'>Cantidad Mínima</div>,
                        dataIndex: ['proyecto_detalle', 'moq'],
                        width: 175,
                        formItemProps: {
                            rules: [
                                {
                                    required: true,
                                    message: "Ingrese la cantidad minima",
                                },
                            ],
                        },
                        renderFormItem: (text, record) => (
                            <InputNumber
                                controls={false}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                className="width-100"
                            />
                        )
                    },
                    {
                        title: <div className='join-title'>Cantidad en Contenedor</div>,
                        dataIndex: ['proyecto_detalle', 'cantidad_minima_contenedor'],
                        width: 175,
                        renderFormItem: (text, {record}) => (
                            <Text className="fob-center-text">{record.proyecto_detalle?.cantidad_minima_contenedor || "---"}</Text>
                        )
                    },
                    {
                        title: <div className='join-title'>Cantidad en Contenedores</div>,
                        dataIndex: ['proyecto_detalle', 'contenedores_moq'],
                        width: 175,
                        renderFormItem: (text, {record}) => {
    
                            const { cantidad_minima_contenedor, contenedores_moq, contenedor_20dc, contenedor_40dc, contenedor_40hq } = record.proyecto_detalle || {}
                            let cantidad
                            let tipo
    
                            if(cantidad_minima_contenedor !== undefined){
                                if(cantidad_minima_contenedor <= contenedor_20dc) 
                                    tipo = "20 DC"
                                else if(cantidad_minima_contenedor <= contenedor_40dc)
                                    tipo = "40 DC"
                                else if(cantidad_minima_contenedor <= contenedor_40hq)
                                    tipo = "40 HQ"
                                
                                if(tipo === undefined) tipo = (
                                    contenedor_40hq ? "40 HQ" :
                                    contenedor_40dc ? "40 DC" : "20 DC"
                                )
                            }
    
                            if(contenedores_moq !== undefined) cantidad = `${contenedores_moq} (${tipo})`
    
                            return (
                                <Text className="fob-center-text">{cantidad || "---"}</Text>
                            )
                        }
                    },
                ]
            },
            {
                title: 'Costo de la muestra',
                dataIndex: ['proyecto_detalle', 'costo_muestra'],
                width: 175,
                renderFormItem: (text, record) => (
                    <InputNumber
                        controls={false}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        className="width-100"
                    />
                )
            },
            {
                title: <Alert message="Contenedores" showIcon={false} style={{ borderRadius: 100, backgroundColor: "#F1F4FF" }} banner />,
                children: [
                    {
                        // title: '20 DC',
                        title: <div className='join-title'>20 DC</div>,
                        dataIndex: ['proyecto_detalle', 'contenedor_20dc'],
                        width: 175,
                        formItemProps: {
                            rules: [
                                ({ getFieldsValue }) => ({
                                    validator({field}, value) {
                                        let key = field.split('.')[0]
                                        let { proyecto_detalle } = getFieldsValue(key)[key]
                                        let { contenedor_40dc, contenedor_40hq } = proyecto_detalle
    
                                        if(contenedor_40dc && contenedor_40dc < value){
                                            return Promise.reject(new Error("La capacidad debe ser menor al 40 DC"))
                                        }
    
                                        if(contenedor_40hq && contenedor_40hq < value){
                                            return Promise.reject(new Error("La capacidad debe ser menor al 40 HQ"))
                                        }
    
                                        return Promise.resolve()
                                    },
                                })
                            ],
                        },
                        renderFormItem: (text, record) => (
                            <InputNumber
                                controls={false}
                                // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                className="width-100"
                            />
                        )
                    },
                    {
                        title: <div className='join-title'>40 DC</div>,
                        dataIndex: ['proyecto_detalle', 'contenedor_40dc'],
                        width: 175,
                        formItemProps: {
                            rules: [
                                ({ getFieldsValue }) => ({
                                    validator({field}, value) {
                                        let key = field.split('.')[0]
                                        let { proyecto_detalle } = getFieldsValue(key)[key]
                                        let { contenedor_20dc, contenedor_40hq } = proyecto_detalle
    
                                        if(contenedor_20dc && contenedor_20dc > value){
                                            return Promise.reject(new Error("La capacidad debe ser mayor al 20 DC"))
                                        }
    
                                        if(contenedor_40hq && contenedor_40hq < value){
                                            return Promise.reject(new Error("La capacidad debe ser menor al 40 HQ"))
                                        }
    
                                        return Promise.resolve()
                                    },
                                })
                            ],
                        },
                        renderFormItem: (text, record, form) => {
                            return (
                                <InputNumber
                                    controls={false}
                                    // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                />
                            )
                        }
                    },
                    {
                        title: <div className='join-title'>40 HQ</div>,
                        dataIndex: ['proyecto_detalle', 'contenedor_40hq'],
                        width: 175,
                        formItemProps: {
                            rules: [
                                ({ getFieldsValue }) => ({
                                    validator({field}, value)  {
                                        let key = field.split('.')[0]
                                        let { proyecto_detalle } = getFieldsValue(key)[key]
                                        let { contenedor_20dc, contenedor_40dc } = proyecto_detalle
    
                                        if(contenedor_20dc && contenedor_20dc > value){
                                            return Promise.reject(new Error("La capacidad debe ser mayor al 20 DC"))
                                        }
    
                                        if(contenedor_40dc && contenedor_40dc > value){
                                            return Promise.reject(new Error("La capacidad debe ser mayor al 40 DC"))
                                        }
    
                                        return Promise.resolve()
                                    },
                                })
                            ],
                        },
                        renderFormItem: (text, record) => (
                            <InputNumber
                                controls={false}
                                // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                className="width-100"
                            />
                        )
                    },
                ]
            },
            {
                title: 'IGI',
                dataIndex: ['proyecto_detalle', 'igi'],
                width: 150,
                renderFormItem: (text, record) => (
                    <InputNumber
                        controls={false}
                        // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        className="width-100"
                    />
                )
            },
            {
                title: 'Piezas por Pallet',
                dataIndex: ['proyecto_detalle', 'piezas_pallet'],
                width: 150,
                renderFormItem: (text, record) => (
                    <InputNumber
                        controls={false}
                        // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        className="width-100"
                    />
                )
            },
            {
                title: 'Piezas por Master',
                dataIndex: ['proyecto_detalle', 'piezas_caja_maestra'],
                width: 150,
                renderFormItem: (text, record) => (
                    <InputNumber
                        controls={false}
                        // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        className="width-100"
                    />
                )
            },
            {
                title: 'Acciones',
                dataIndex: '_id',
                width: 150,
                renderFormItem: (text, {record}, form) => {
    
                    return (
                        <Space direction="horizontal">
                            
                            <Button
                                type="primary"
                                className='ant-btn-primary-green btn-confirmar'
                                onClick = {() => {
                                    const { _id, proyecto_detalle, descripcion} = record
                                    const {moq, categoria_id, contenedor_20dc, contenedor_40dc, contenedor_40hq, igi } = proyecto_detalle || {}
    
                                    if(!contenedor_20dc && !contenedor_40dc && !contenedor_40hq){
                                        message.error("Es necesario ingresar los datos de un contenedor")
                                        return
                                    }
    
                                    if(!moq || !categoria_id || !descripcion || igi === null || igi === undefined){
                                        let lista = []
                                        if(!moq) lista.push("Cantidad Min.")
                                        if(!categoria_id) lista.push("Categoria")
                                        if(!descripcion) lista.push("Descripción")
                                        if(!igi && igi !== 0) lista.push("IGI")
                                        message.error("Faltan datos por ingresar: " + lista.join(", "))
                                        return
                                    }
                                    
                                    confirmDetalle(record)
                                }}
                                icon={<CheckOutlined style={{color: '#FFFFFF', width: 20, height: 20}}/>} title="Confirmar" />
                            <Button
                                type="primary"
                                className="ant-btn-primary-purple btn-editar"
                                onClick={() => {
                                    setProductId(record._id)
                                    setVisibleModal(true)
                                }}
                                icon={<EditFilled style={{ color: 'currentColor', width: 20, height: 20 }} />} title="Editar" />
                            <Popconfirm
                                placement="topRight"
                                title="¿Deseas eliminar este Producto?"
                                onConfirm={() => deleteDetalle(record)}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button
                                    type="primary"
                                    danger
                                    icon={<IconDelete  style={{width: 20, height: 20}}/>} 
                                    title="Eliminar" />
                            </Popconfirm>
                        </Space>
    
                    )
                }
            },
    
        ]

        setColumnas(columnas)

    }, [isFixed, categorias, props.productos])
    
   

    /**
     *
     *
     * @memberof FOB
     * @method onValuesChange
     * @description Al actualizar un valor, actualizamos toda la fila solamente en el estatdo. Hace falta confirmar cambios.
     */
    const onValuesChange = async (row, list) => {

        let { proyecto_detalle, _id } = row
        let { moq, contenedor_20dc, contenedor_40dc, contenedor_40hq, contenedores_moq, cantidad_minima_contenedor } = proyecto_detalle


        if(moq !== undefined && (contenedor_20dc || contenedor_40dc || contenedor_40hq)) {
            contenedores_moq =  moq / contenedor_20dc
            if(contenedores_moq >= 1 && contenedor_40dc) contenedores_moq = moq / contenedor_40dc
            if(contenedores_moq >= 1 && contenedor_40hq) contenedores_moq = moq / contenedor_40hq

            if(isNaN(contenedores_moq) || contenedores_moq < 1) contenedores_moq = 1

            cantidad_minima_contenedor = Decimal(moq / contenedores_moq).toDecimalPlaces(2).toNumber()
            contenedores_moq = Decimal(contenedores_moq).toDecimalPlaces(2).toNumber()

        }else if(contenedores_moq !== undefined || cantidad_minima_contenedor !== undefined){
            contenedores_moq = undefined
            cantidad_minima_contenedor = undefined
        }

        proyecto_detalle.contenedores_moq = contenedores_moq
        proyecto_detalle.cantidad_minima_contenedor = cantidad_minima_contenedor

        const detalles = [...dataSource.map(data => {
            if (data._id === _id) {
                return ({
                    ...row,
                    proyecto_detalle
                })
            }

            return data
        })]

        form.setFieldsValue({
            [_id]: detalles.find(d => d._id === _id)
        })
    }

    /**
    *
    *
    * @memberof FOB
    * @method updateDetalle
    * @description Confirma el producto y solicita su creacion o actualizacion.
    */
    const confirmDetalle = (row) => {

        if (row.proyecto_detalle?.categoria_id?.value)
            row.proyecto_detalle.categoria_id = row.proyecto_detalle.categoria_id.value 

        if (row.proyecto_detalle){
            let { proyecto_detalle } = props.productos.find(p => p._id === row._id) 
            row.proyecto_detalle = {
                ...proyecto_detalle,
                ...row.proyecto_detalle
            }
        }

        if (row.proyecto_detalle?._id) {
            updateDetalle(row)
        } else {
            saveDetalle(row)
        }
    }

    /**
    *
    *
    * @memberof FOB
    * @method updateDetalle
    * @description Actualiza el producto confirmado.
    */
    const updateDetalle = async (values) => {

        axios.put('/project-manager/listado-producto', {
            ...values
        }).then(response => {
            const rfq_detalle = response.data

            console.log({[values._id]: rfq_detalle})
            
            form.setFieldsValue({
                [values._id]: rfq_detalle
            })

            message.success('Producto Actualizado')
        }).catch(error => {
            console.log('error',error)
            message.error('Error al actualizar')
        })
    }

    /**
    *
    *
    * @memberof FOB
    * @method saveDetalle
    * @description Crea el producto confirmado.
    */
    const saveDetalle = async (values) => {

        axios.post('/project-manager/listado-producto', {
            ...values,
            proyecto_id: props.proyecto?._id,
            proyecto: true
        }).then(response => {
            const rfq_detalle = response.data

            const productos = [...props.productos.map(data => {
                console.log(rfq_detalle._id === data._id, rfq_detalle._id, data._id)
                if (rfq_detalle._id === data._id) return rfq_detalle

                return data
            })]
            
            props.setProductos(productos)
            
            message.success('Producto Confirmado')
        }).catch(error => {
            message.error('Error al crear')
        })
    }

    /**
    *
    *
    * @memberof FOB
    * @method deleteDetalle
    * @description Elimina el producto.
    */
    const deleteDetalle = async (values) => {
        axios.delete('/project-manager/listado-producto',{
            params: {
                ...values   
            }
        }).then(response => {

            console.log("data", dataSource)
        
            const productos = [...dataSource.filter(data => values._id !== data._id)]
            props.setProductos(productos)
            
            message.success('Producto Eliminado')
        }).catch(error => {
            message.error('Error al eliminar')
        })
    }

    /**
     * @methodOf  Areas
     * @method getCategorias
     *
     * @description Obtiene las categorias
     * */
    const getCategorias = (search) => {
        axios.get('/categorias', {
            params: {
                search
            }
        }).then(({ data }) => {
            setCategorias(data.data)

        }).catch(error => {
            message.error('Error al traer la información')
        })
    }

    const deleteImage = (image) => {
        axios
            .post("/upload/delete", {
                filename: image,
            })
            .then((res) => {
                console.log("imagen removida con éxito", res);
            })
            .catch((res) => {
                console.log("imagen no se puedo remover", res);
            });
    };


    return (
        <>
            <Spin spinning={false}>
                <Row style={{padding: "1rem"}}>
                    <Col xs={24}>
                    <Alert message="Para guardar los datos del producto, selecciona su boton &#9989;." type="info" showIcon />
                    </Col>
                </Row>
                <EditableProTable
                    className='fob-table mb-1 '
                    columns={columnas}
                    rowKey="_id"
                    value={dataSource}
                    recordCreatorProps={{
                        style: { display: "none" },
                    }}
                    title={() => <Checkbox onChange={() => setIsFixed(!isFixed)} checked={isFixed}><small>Mantener Visible los Productos</small></Checkbox>}
                    scroll={{ x: 1050 }}
                    editable={{
                        form,
                        //type: "multiple",
                        onValuesChange,
                        editableKeys,
                    }}
                />
            </Spin>
            <ModalAgregarProducto
                fob
                
                productId={productId}
                products={[...dataSource]}
                visible={visibleModal || props.visibleModal}

                rfqId={props.proyecto?.rfq_id?._id}

                onSuccess={(productos) => {
                    props.setProductos(productos)
                    setProductId(undefined)
                }}
                setVisible={(value) => {
                    setVisibleModal(value)
                    props.setVisibleModal(value)
                }}
                onCancel={(image) => {
                    deleteImage(Image)
                    setVisibleModal(false)
                    setProductId(undefined)
                    props.setVisibleModal(false)
                }}
            />
        </>
    )

}
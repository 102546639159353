import React, { Component, useState, useEffect } from "react";
import { Row, Col, Divider, Radio, Typography, Form, List, Collapse, Avatar, Button } from "antd";

import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

import Logged from "../../../../Hooks/Logged.js";

import "../../../../Styles/Modules/Public/marketplace.css";

const axios = require("axios").default;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

function EditInput(props) {
    const { onChange } = props;

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if (value !== props.value) setValue(props.value);
    });

    const triggerChange = (value) => {
        setValue(value);
        onChange(value);
    };

    return (
        <Paragraph
            editable={{
                icon: <EditOutlined style={{ opacity: 0.5 }} />,
                onChange: triggerChange,
            }}
        >
            {value}
        </Paragraph>
    );
}

/**
 *
 * @class StepReview
 * @extends {Component}
 * @description Step para mostrar el resumen de la cotizacion con la posibilidad de editarse
 */
export default class StepReview extends Component {
    state = {
        activeKeysProducts: {},
    };

    static contextType = Logged;

    componentDidMount() {

        console.log('this.props.contactData', this.props.contactData)
        console.log('this.props.rfqData', this.props.rfqData)
        console.log('this.context?.cliente_id', this.context)

        const cliente = {
            ...this.context?.cliente_id,
            nombre: this.context?.nombre,
            apellido: this.context?.apellido
        }

        this.formContacto.setFieldsValue({
            ...(this.props.contactData !== undefined
                ? this.props.contactData
                : cliente),
            ...this.props.rfqData,
        })
    }

    /**
     *
     * @method deleteProduct
     * @param {*} index Number que indica la posicion del producto en la lista de productos del estado padre
     * @memberof StepReview
     * @description Metodo encargado de eliminar el registro de un producto en una lista padre
     */
    deleteProduct = (index) => {
        const products = [];

        this.props.products.forEach((product, idx) => {
            if (idx !== index) {
                products.push(product);
                return
            }

            if (product.fotografia) this.props.deleteImage(product.fotografia)
            if (product.documentos) product.documentos.map(doc => this.props.deleteImage(doc))
            if (product.logo) this.props.deleteImage(product.logo)
        });

        this.props.setProducts(products);
    };

    /**
     * @param {*} _id
     * @description openProductCollapse
     */
    openProductCollapse = (_id) => {
        let { activeKeysProducts } = this.state;
        if (activeKeysProducts[_id]) delete activeKeysProducts[_id];
        else activeKeysProducts[_id] = true;

        this.setState({ activeKeysProducts });
    };


    deleteProduct = (index) => {
        const products = [];

        this.props.products.forEach((product, idx) => {
            if (idx !== index) {
                products.push(product);
            } else if (product.imagen) {
                this.props.deleteImage(product.imagen[0].filename);
            }
        });

        this.props.setProducts(products);
    }


    save = (values) => {
        values.detalles = this.props.products
        axios.post('/rfq/cotizacion', values)
    }


    formContacto;
    formInformacion;

    render() {
        const { review } = this.props;

        console.log("this.state.", this.state.activeKeysProducts);
        return (
            <Form
                ref={(ref) => (this.formContacto = ref)}
                layout="vertical"
                onFinish={this.props.saveCotizacion}
            >
                <Row gutter={[0, 40]} style={{ margin: "2rem 0" }}>
                    <Col xs={24}>
                        <Row>
                            <Col xs={24}>
                                <Title level={4}>Información de Contacto</Title>
                            </Col>
                            <Divider
                                style={{
                                    margin: "0 0 2rem 0",
                                    borderColor: "#fff",
                                }}
                            />
                        </Row>
                        <Row justify="space-between" gutter={[24, 16]}>
                            <Col xs={12} >
                                <Form.Item
                                    className="form-item-review"
                                    name="nombre"
                                    label="Nombre(s)"
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                            <Col xs={12} >
                                <Form.Item
                                    className="form-item-review"
                                    name="apellido"
                                    label="Apellidos"
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                            <Col xs={12} >
                                <Form.Item
                                    className="form-item-review"
                                    name="rfc"
                                    label="RFC"
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    className="form-item-review"
                                    name="razon_social"
                                    label="Empresa  / Razón Social"
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    className="form-item-review"
                                    name="email"
                                    label="Correo Electrónico"
                                >
                                    <Paragraph>{this.context?.email}</Paragraph>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    className="form-item-review"
                                    name="telefono"
                                    label="Teléfono"
                                    rules={[{
                                        pattern: /^(\+?[0-9-]{1,4})?-?[0-9-]{10}$/,
                                        message: "El teléfono solo puede tener números (0-9), guiones (-) y una lada (+52 o +1-584) "
                                    }]}
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    className="form-item-review"
                                    name="departamento"
                                    label="Departamento"
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    {/* Seccion 2 */}
                    <Col xs={24}>
                        <Row>
                            <Col xs={24}>
                                <Title level={4}>Lista de Productos</Title>
                            </Col>
                            <Divider
                                style={{
                                    margin: "0 0 2rem 0",
                                    borderColor: "#fff",
                                }}
                            />
                        </Row>
                        <Row className="width-100">
                            <Collapse
                                bordered={false}
                                className="products-collapse width-100"
                                activeKey={Object.keys(
                                    this.state.activeKeysProducts
                                )}
                            >
                                {this.props.products.map((product, index) => {
                                    return (
                                        <Panel
                                            header={
                                                <List.Item
                                                    className="width-100"
                                                    actions={[
                                                        <Button
                                                            className="step-review-button blue"
                                                            icon={<EditOutlined
                                                                className="step-review-icon"
                                                            />}
                                                            style={{
                                                                color: "#FFF"
                                                            }}
                                                            onClick={() => this.props.openModal(index)}
                                                        />,
                                                        <Button
                                                            className="step-review-button red"
                                                            icon={<DeleteOutlined
                                                                className="step-review-icon"
                                                            />}
                                                            style={{
                                                                color: "#FFF"
                                                            }}
                                                            onClick={() => this.deleteProduct(index)}
                                                        />,

                                                        <DownOutlined
                                                            className={
                                                                "step-review-icon " +
                                                                (this.state
                                                                    .activeKeysProducts[
                                                                    index
                                                                ]
                                                                    ? "rotate-icon"
                                                                    : "")
                                                            }
                                                            onClick={() =>
                                                                this.openProductCollapse(
                                                                    index
                                                                )
                                                            }
                                                        />,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        onClick={() =>
                                                            this.openProductCollapse(
                                                                index
                                                            )
                                                        }
                                                        avatar={
                                                            product.fotografias?.length > 0 ? (
                                                                <Avatar
                                                                    src={
                                                                        axios
                                                                            .defaults
                                                                            .baseURL +
                                                                        "/upload/" +
                                                                        product.fotografias[0]
                                                                    }
                                                                    shape="square"
                                                                    size={80}
                                                                />
                                                            ) : undefined
                                                        }
                                                        title={
                                                            <Title level={5}>
                                                                {product.modelo}
                                                            </Title>
                                                        }
                                                        description={
                                                            product.descripcion
                                                        }
                                                    />
                                                </List.Item>
                                            }
                                            key={product._id}
                                            showArrow={false}
                                        >
                                            <List className="width-100">
                                                {product.cantidad ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Cantidad
                                                            </Col>
                                                            <Col span={18}>
                                                                <Text>
                                                                    {
                                                                        product.cantidad
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}

                                                {product.capacidad ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Capacity
                                                            </Col>
                                                            <Col span={18}>
                                                                <Text>
                                                                    {
                                                                        product.capacidad
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}
                                                {product.medidas ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Measurements
                                                            </Col>
                                                            <Col span={18}>
                                                                <Text>
                                                                    {
                                                                        product.medidas
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}
                                                {product.material ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Material /
                                                                Composition
                                                            </Col>
                                                            <Col span={18}>
                                                                <Text>
                                                                    {
                                                                        product.material
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}
                                                {product.color ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Color
                                                            </Col>
                                                            <Col span={18}>
                                                                <Text>
                                                                    {
                                                                        product.color
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}
                                                {product.funcion ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Functionality
                                                            </Col>
                                                            <Col span={18}>
                                                                <Text>
                                                                    {
                                                                        product.funcion
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}
                                                {product.especificaciones_electricas ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Electrical
                                                                Specifications
                                                            </Col>
                                                            <Col span={18}>
                                                                <Text>
                                                                    {
                                                                        product.especificaciones_electricas
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}
                                                <List.Item className="width-100">
                                                    <Row className="width-100">
                                                        <Col span={6}>
                                                            Is the product requires too be the same or similar?                                                        </Col>
                                                        <Col span={18}>
                                                            <Text>
                                                                {
                                                                    product.mismo ? "Yes" : "No"
                                                                }
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                                <List.Item className="width-100">
                                                    <Row className="width-100">
                                                        <Col span={6}>
                                                            Requires Customization
                                                        </Col>
                                                        <Col span={18}>
                                                            <Text strong>
                                                                {product.personalizacion_si
                                                                    ? "Yes"
                                                                    : "No"}
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                </List.Item>


                                                {product.logo ? (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                            <Col span={6}>
                                                                Logo for
                                                                Customization
                                                            </Col>
                                                            <Col span={18}>
                                                                <Avatar
                                                                    src={
                                                                        axios
                                                                            .defaults
                                                                            .baseURL +
                                                                        "/upload/" +
                                                                        product.logo
                                                                    }
                                                                    shape="square"
                                                                    size={48}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ) : null}
                                            </List>
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        </Row>
                    </Col>
                    {/* Seccion 3 */}
                    <Col xs={24}>
                        <Row>
                            <Col xs={24}>
                                <Title level={4}>
                                    Aqui el titulo de la seccion
                                </Title>
                            </Col>
                            <Divider
                                style={{
                                    margin: "0 0 2rem 0",
                                    borderColor: "#fff",
                                }}
                            />
                        </Row>

                        <Row justify="space-between" gutter={[24, 16]}>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    name="almacenaje"
                                    label="Requiere Almacenaje"
                                    className="form-item-review"
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Sí</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    name="financiamiento"
                                    label="Requiere Financiamiento"
                                    className="form-item-review"
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Sí</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    name="plazo"
                                    label="Tiempo que Requerirá el Servicio"
                                    className="form-item-review"
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    name="entrega"
                                    label="Lugar de Entrega"
                                    className="form-item-review"
                                >
                                    <EditInput />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    }
}

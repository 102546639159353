import React, { Component } from 'react'
import { List, Card, Row, Col, Typography, Button, message, Popconfirm, Space } from 'antd'

//componentes
import { IconDetails, IconEdit, IconDelete } from '../../../../Widgets/Iconos';
import { renderMontoTransaccion } from "../../../../Utils";
import CustomAvatar from "../../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalTransacciones from '../../../Finanzas/Transacciones/ModalTransacciones'
import ModalTransaccionesDetalle from '../../../Finanzas/Transacciones/ModalTransaccionesDetalle'


const moment = require('moment');
const axios = require('axios');
const { Text } = Typography;


/**
 * @class EgresosList 
 * @descripcion Lista con las transacciones relacionadas al proyecto
 **/
class EgresosList extends Component {

	constructor(props){
		super(props)
		this.state = {
			loading: false,
			transacciones: {
                data: [],
                limit: 10,
                page: 1,
                total: 0
            },
		}
	}


	componentDidMount(){
		this.getTransacciones()
	}


	/**
     * @memberof EgresosList
     * @method getTransacciones
     * @description Obtiene las transacciones de el proyecto correspondiente
     */
    getTransacciones = ({
    	page = this.state.transacciones.page,
    	limit = this.state.transacciones.limit
    } = this.state.transacciones) => {

    	this.setState({loading: true})
        axios.post('/transacciones/list', {
            page,
            limit,
            proyecto_id: this.props.proyecto_id,
            tipo: 2 // Egreso,
        }).then(({ data }) => {
        	this.setState({transacciones: data.data})
                
        }).catch(err => {
        	console.log(err)
        	message.error('Error al obtener las transacciones de egreso')
        })
        .finally(()=>this.setState({loading: false}))
    }

	render(){
		return(
			<>
				<List
                    loading={this.state.loading}
                    className="component-list no-scroll"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Transacciones de egreso" }}
                    dataSource={this.state.transacciones.data}
                    pagination={{
                    	current: this.state.transacciones.page,
                        total: this.state.transacciones.total,
                        pageSize: this.state.transacciones.limit,
                        showSizeChanger: true,
                        onChange: (page, limit) => this.getTransacciones({ page, limit })
                    }}
                    header={<Row className="width-100">
	                    <Col span={2} className="center">
	                        <Text strong>Fecha</Text>
	                    </Col>
	                    <Col span={4} className="center">
	                        <Text strong>Concepto</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Área</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Rubro</Text>
	                    </Col>
	                    <Col span={4} className="center">
	                        <Text strong>Cliente</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Cuenta</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Monto</Text>
	                    </Col>
	                    <Col span={2} className="center">
	                        <Text strong>Acciones </Text>
	                    </Col>
	                </Row>}
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card 
                                className="card-list"
                            >
                                <Row>
                                	<Col xs={12} xl={2} className="center">
				                        <Text ellipsis>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
				                    </Col>
				                    <Col xs={12} xl={4} className="center">
				                        <Text ellipsis>{item.concepto}</Text>
				                    </Col>
				                    <Col xs={12} xl={3} className="center">
				                        <Text ellipsis>{item.area_id?.nombre ? item.area_id?.nombre : '-'}</Text>
				                    </Col>
				                    <Col xs={12} xl={3} className="center">
				                        <Text ellipsis>{item.rubro_id?.nombre ? item.rubro_id.nombre : '-'}</Text>
				                    </Col>
				                    <Col xs={12} xl={4} className="center">
				                        {item.cliente_id?.razon_social ? <CustomAvatar
	                                            size="small"
	                                            name={item.cliente_id?.razon_social}
	                                        /> : 'n/a'
				                        } <Text>{item.cliente_id?.razon_social}</Text>
				                    </Col>
				                    <Col xs={12} xl={3} className="center">
				                        <Text ellipsis>{item.cuenta_id.nombre}</Text>
				                    </Col>
				                    <Col xs={12} xl={3} className="center">
				                    	{renderMontoTransaccion({monto: item.monto, tipo: item.tipo})}
				                    </Col>
				                    <Col xs={12} xl={2} className="center">
				                    	<Space>
					                        <Button
	                                            icon={<IconDetails />}
	                                            type="primary"
	                                            title="Ver Detalle"
	                                            onClick={() => this.setState({ modal_visible_detalle: true, transaccion_id: item._id })}
	                                            size="small"
	                                            style={{paddingTop: '3px'}}
	                                        />
	                                        <Button
	                                            size="small"
	                                            icon={<IconEdit />}
	                                            type="primary"
	                                            title="Editar"
	                                            onClick={() => this.setState({ modal_visible: true, transaccion_id: item._id })}>
	                                        </Button>
	                                        <Popconfirm
	                                            placement="topRight"
	                                            title="¿Deseas eliminar esta Transacción?"
	                                            onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
	                                                message.success('Transaccion Eliminada')
	                                                this.getTransacciones()
	                                                this.props.getProyecto()
	                                            }).catch((error) => {
	                                                message.success('Transacción NO Eliminada')
	                                                this.getTransacciones();
	                                            })
	                                            }
	                                            okText="Si"
	                                            cancelText="No"
	                                        >
	                                            <Button 
	                                            	size="small" 
	                                            	type="primary" 
	                                            	danger 
	                                            	icon={<IconDelete />} 
	                                            	title="Eliminar" 
	                                            	style={{paddingTop: '3px'}}
	                                            />
	                                        </Popconfirm>
                                        </Space>
				                    </Col>
                   					
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton 
                	title="Nuevo registro" 
                	onClick={() => {
                		this.setState({ modal_visible: true })
                	}} 
                />
                <ModalTransacciones
                	visible={this.state.modal_visible}
                	onClose={(flag)=>{
                		this.setState({modal_visible: false, transaccion_id:  undefined})
                		if(flag) {
                			this.getTransacciones()
                			this.props.getProyecto()
                		}
                	}}
                	transaccion_id={this.state.transaccion_id}
                	//valores iniciales
                	cliente={{
                		value: this.props.proyecto?.cliente_id?._id,
                		key: this.props.proyecto?.cliente_id?._id,
                		label: this.props.proyecto?.cliente_id?.razon_social
                	}}
                	proyecto_id={this.props.proyecto?._id}
                	tipo={2} //egreso
                />

                <ModalTransaccionesDetalle
                	visible={this.state.modal_visible_detalle}
                	onClose={()=>{
                		this.setState({modal_visible_detalle: false, transaccion_id:  undefined})
                	}}
                	transaccion_id={this.state.transaccion_id}
                	
                />
			</>
		)
	}

}

export default function Vista(props) {
    return <EgresosList {...props}/>
}
import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const ProveedorSelect
 * @description Select para los proveedores registrados en el sistema
 */
const ProveedorSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el proveedor", onSelect = () => {}, disabled = false, className = "" } = props

    const [ proveedores, setProveedores ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    })


    /**
     * @const getProveedores
     * @description Obitiene los proveedores
     */
    const getProveedores = ({page, limit, search} = proveedores) => {

        axios.get('/proveedores/list', {
            params: { page, limit, search },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setProveedores({
                ...proveedores,
                data: response.data.data.itemsList,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            message('Error al obtener los proveedores')
        })
    }

    //componentDidMount
    useEffect(() => {
        getProveedores()
    }, [])

    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getProveedores({search})}
            onSelect={(proveedor)=> {
                onChange(proveedor.value)
                onSelect(proveedor.value)
            }}
            value={value}
        >
            {
                proveedores.data.map(proveedor => <Option value={proveedor._id}>{proveedor.alias}</Option>)
            }
        </Select>
    );
}



export default ProveedorSelect;
import React from 'react'
import { Route, Routes } from "react-router-dom";

import RouterPublic from "./Routers/RouterPublic";
import AdminRoutes from "./Routers/AdminRoutes";
import CustomerRoutes from "./Routers/CustomerRoutes";
import RequireAuth from './Middleware/PrivateRoute';





/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
 export default function rutas() {
    return (
        <Routes>
            <Route path="/admin/*" element={
                <RequireAuth>
                    <AdminRoutes />
                </RequireAuth>
            } />

            <Route path="/customer/*" element={
                <RequireAuth>
                    <CustomerRoutes />
                </RequireAuth>
            } />

            <Route path="/*" element={<RouterPublic />} />
        </Routes>
    )
}


import React, { Component } from 'react';
import { Layout, Spin, PageHeader, Button, Col, Row, Card, List, message } from 'antd'
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons"
import axios from 'axios';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { CardClientesAnuales, CardMontos, CardVentasUsuario } from '../../Widgets/Cards';

const { Content } = Layout
const moment = require('moment')
/**
 *
 *
 * @export
 * @class CRM
 * @extends {Component}
 * 
 * @description Vista del CRM
 */
export default class CRM extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            clientes: {
                data: [],
                loading: false,
            },
            ventas_totales: 0.00,
            total_pagado: 0.00,
            ventas_usuario: [],
            clientes_anuales: [{
                _id: moment(new Date()).format('YYYY'),
                total: 0
            }]
        }
    }


    componentDidMount() {
        this.getClientes();
        this.getClientesAnuales()
        this.getVentasUsuario();
        this.getMontos();
    }


    /**
    * @memberof CRM
    * 
    * @method getMontos
    * @description Obtiene la lista de clientes
    */
    getMontos = () => {

        axios.get('/crm/dashboard/montos')
            .then(res => {
                let data = res.data.data[0];
                console.log('res crm dashboard montos', data)
                this.setState({
                    ventas_totales: data.total,
                    total_pagado: data.ventas,
                })

            })
            .catch(error => {
                console.log('error crm dashboard', error)
            })

    }

    /**
    * @memberof CRM
    * 
    * @method getVentasUsuario
    * @description Obtiene la lista de clientes
    */
    getVentasUsuario = () => {
        axios.get('/crm/dashboard/ventas')
            .then(res => {
                let data = res.data.data;
                let data_ventas_usuario = []

                for(const user of data){
                    data_ventas_usuario.push({
                        value: user.ventas,
                        name: user.nombre,
                    }) 
                }

                this.setState({ ventas_usuario: data_ventas_usuario })

            })
            .catch(error => {
                console.log('error crm dashboard', error)
            })
    }

    /**
    * @memberof CRM
    * 
    * @method getClientesAnuales
    * @description Obtiene la lista de clientes
    */
    getClientesAnuales = () => {
        axios.get('/crm/dashboard/clientes')
            .then(res => {
                console.log('res crm dashboard clientes', res)
                this.setState({ clientes_anuales: res.data.data })
            })
            .catch(error => {
                console.log('error crm dashboard', error)
            })
    }

    /**
    * @memberof CRM
    * 
    * @method getClientes
    * @description Obtiene la lista de clientes
    * @param {Integer} page Pagina actual de la lista
    */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
    } = this.state.clientes,
        { clientes } = this.state) => {
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })
        axios.get('/clientes/list', {
            params: {
                page: page,
                limit: 10
            }
        }).then(({ data }) => {
            this.setState(state => {
                state.clientes.total = data.data.itemCount
                state.clientes.data = data.data.itemsList
                state.clientes.pages = data.data.pageCount
                state.clientes.loading = false
                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error(error?.response?.message)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        console.log('state', this.state)
        return (
            <Spin spinning={this.state.loading}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="CRM"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[25, 25]}>
                        <Col xxl={16} xl={16} md={24} sm={24} xs={24}>
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} sm={24} xs={24}>
                                    <CardMontos
                                        title="Ventas Totales"
                                        extra={<Button type="primary" icon={<ArrowRightOutlined style={{ color: 'currentColor' }} />}></Button>}
                                        prefix="$"
                                        suffix="mxn"
                                        value={this.state.ventas_totales}
                                    />

                                </Col>
                                <Col xl={12} md={12} sm={24} xs={24}>
                                    <CardMontos
                                        extra={<Button type="primary" icon={<ArrowRightOutlined style={{ color: 'currentColor' }} />}></Button>}
                                        title="Total Pagado"
                                        prefix="$"
                                        suffix="mxn"
                                        value={this.state.total_pagado}
                                    />
                                </Col>
                                <Col xl={24} md={24} sm={24} xs={24}>
                                    <CardClientesAnuales data={this.state.clientes_anuales} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={8} xl={8} md={24} sm={24} xs={24} className='width-100'>
                            <Row gutter={[0, 16]} className='width-100'>
                                <Col className='width-100'>
                                    <Col xl={24} md={24} sm={24} xs={24}>
                                        <CardVentasUsuario data={this.state.ventas_usuario} />
                                    </Col>
                                </Col>
                                <Col xl={24} md={24} sm={24} xs={24}>
                                    <Card
                                        className='card-title-gray card-head-borderless border-10 card-shadow'
                                        title="Clientes Nuevos"
                                        extra={
                                            <Link to={'/admin/crm/clientes/'}>
                                                <Button type="primary" icon={<ArrowRightOutlined style={{ color: 'currentColor' }} />} />
                                            </Link>}
                                    >
                                        <List
                                            loading={this.state.clientes.loading}
                                            itemLayout="horizontal"
                                            locale={{ emptyText: "Sin Clientes" }}
                                            dataSource={this.state.clientes.data}
                                            className="list-border-none list-crm-scroll"
                                            renderItem={item => (
                                                <List.Item >
                                                    <List.Item.Meta
                                                        avatar={<CustomAvatar size="large" color={item.usuario_id?.color} name={[item.usuario_id?.nombre, item.usuario_id?.apellido]} image={item.usuario_id?.avatar} />}
                                                        title={item.usuario_id?.nombre + ' ' + item.usuario_id?.apellido}
                                                        description={moment(item.createdAt).format('LLL')}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Spin>

        )
    }
}

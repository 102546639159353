import React, { Component } from 'react';
import { Button, Col, Row, Popconfirm, Spin, Table, PageHeader, Layout, message, Space, Tag, Collapse, List, Typography } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";

import { IconDelete, IconEdit, IconDetails, IconEye } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalProveedores from './ModalProveedores'
import { PlusOutlined } from "@ant-design/icons";
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import ModalProveedoresMultiple from './ModalProveedoresMultiple';
import '../../../Styles/Modules/Proveedores/proveedores.css'
import { DeleteOutlined, EditOutlined, EyeOutlined, FileSearchOutlined } from "@ant-design/icons"
import ModalProveedorCentroDistribucion from './ModalProveedorCentroDistribucion';

const { Content } = Layout;
const { Panel } = Collapse;
const { Text } = Typography

/**
 * @export
 * @class Proveedores
 * @extends {Component}
 * @description Vista de Proveedores
 */
export default class Proveedores extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalProveedores: false,

            proveedor: undefined,
            searching: true,
            filtroSearch: null,
            proveedores: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,


                filters: [],
                sort: {},

                loading: false
            },

        }
    }
    filterForms = React.createRef();
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProveedores();
    }

    /**
    * @memberof Proveedores
    * @method getProveedores
    * @description  Pagina los proveedores existentes en la base de datos
    */
    getProveedores = ({
        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
        filters = this.state.proveedores.filters,
        sort = this.state.proveedores.sort,
    } = this.state.proveedores) => {
        this.setState({ loading: true });
        let search = this.props.search;
        axios.post('/proveedores/list', {
            page, limit, filters, sort, search

        }).then(response => {
            console.log('response proveedores', response)
            this.setState({
                proveedores: {
                    ...this.state.proveedores,
                    data: response.data.data.itemsList,
                    total: response.data.data.paginator.itemCount,
                    pages: response.data.data.paginator.pageCount,
                    loading: false,
                    searching: true,
                    filtroSearch: search
                }
            });

        }).catch(error => {
            console.log(error)
            message.error(error?.response?.message)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }
    /**
         *
         *
         * @param {*} event
         * @param {*} filtro
         * @memberof Proveedores
         * @description Renderizamos los filtros que estén en el arreglo.
         */
    /* renderFiltros = ({ filters } = this.state.proveedores) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}

            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.nombre || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    } */
    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Proveedores
     * @description Para eliminar los filtros
     */
    /* deleteFilter = (event, filtro) => {
        event.preventDefault()
        const { objectName } = filtro
        try {
            let values = this?.drawerFiltros?.formFiltros.getFieldValue(objectName)
            let index = values.findIndex(e => e === filtro._id)
            values.splice(index, 1)

            let newValues = {}
            newValues[objectName] = values
            this?.drawerFiltros?.formFiltros?.setFieldsValue(newValues)
            this?.drawerFiltros?.formFiltros?.submit()
        } catch (error) {
            console.log('error', error)
            this.setState({ visibleFilters: true })
        }
    } */
    render() {

        const contentMenuPlus = (
            <div>
                <Row>
                    <Button htmlType="submit" type="primary" className="btn-azul" onClick={() => this.setState({ modalProveedores: true })}>
                        Agregar Proveedor
                    </Button>
                </Row>
                <Row className='mt-1'>
                    <Button htmlType="submit" type="primary" className="btn-azul" onClick={() => this.setState({ modalProveedoresMultiples: true })}>
                        Agregar múltiples
                    </Button>
                </Row>


            </div>
        );

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Proveedores
                                    </span>
                                </Col>

                            </Row>
                        }
                    /* extra={
                        <Button
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
                    } */
                    />

                    <Content className="admin-content content-bg pd-1">

                        <List
                            loading={this.state.loading}
                            className='component-list'
                            locale={{ emptyText: 'No hay proveedores' }}
                            dataSource={this.state.proveedores.data}
                            pagination={{
                                onChange: (page, limit) => this.setState(state => {
                                    state.proveedores.page = page;
                                    return state;
                                }, () => this.getProveedores({ page, limit })),
                                current: this.state.proveedores.page,
                                pageSize: this.state.proveedores.limit,
                                total: this.state.proveedores.total,
                                position: 'bottom',
                                hideOnSinglePage: true
                            }}

                            header={
                                <Row className="header-list " >
                                    <Col xs={4} lg={4} className="center">
                                        <Text strong>ALIAS</Text>
                                    </Col>
                                    <Col xs={4} lg={4} className="center">
                                        <Text strong>RAZÓN SOCIAL</Text>
                                    </Col>
                                    <Col xs={3} lg={3} className="center">
                                        <Text strong>RFC</Text>
                                    </Col>
                                    <Col xs={4} lg={4} className="center">
                                        <Text strong>NOMBRE COMPLETO</Text>
                                    </Col>
                                    <Col xs={3} lg={3} className="center">
                                        <Text strong>TELÉFONO</Text>
                                    </Col>
                                    <Col xs={3} lg={3} className="center">
                                        <Text strong>EMAIL</Text>
                                    </Col>
                                    <Col xs={3} lg={3} className="center">
                                        <Text strong>ACCIONES</Text>
                                    </Col>
                                </Row>
                            }

                            renderItem={item => (
                                <Collapse className="custom-collapse" >
                                    <Panel
                                        header={
                                            <Row className="width-100">
                                                <Col xs={4} lg={4} className="center">
                                                    <Text ellipsis>{item.alias}</Text>
                                                </Col>
                                                <Col xs={4} lg={4} className="center">
                                                    <Text ellipsis>{item.razon_social}</Text>
                                                </Col>
                                                <Col xs={3} lg={3} className="center">
                                                    <Text ellipsis>{item.rfc}</Text>
                                                </Col>
                                                <Col xs={4} lg={4} className="center">
                                                    <Text ellipsis>{item.persona}</Text>
                                                </Col>
                                                <Col xs={3} lg={3} className="center">
                                                    <Text ellipsis>{item.telefono ? item.telefono : '-'}</Text>
                                                </Col>
                                                <Col xs={3} lg={3} className="center">
                                                    <Text ellipsis>{item.email}</Text>
                                                </Col>
                                                <Col xs={3} lg={3} className="center">
                                                    <Space wrap>
                                                        <Button
                                                            onClick={event => {
                                                                event.stopPropagation()
                                                                this.setState({
                                                                    modalProveedorCentroDistribucion: true,
                                                                    proveedor_id: item._id
                                                                })
                                                            }}
                                                            size='small'
                                                            className='ant-btn-primary-lime'
                                                            type="primary"
                                                            icon={<PlusOutlined style={{ color: "currentcolor" }} />}
                                                            title="Agregar Centro de Distribución"
                                                        />
                                                        <Link to={`/admin/proveedores/detalle/${item._id}`}>
                                                            <Button

                                                                type="primary"
                                                                size='small'
                                                                icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
                                                                title="Ver Detalle"
                                                            />
                                                        </Link>

                                                        <Button
                                                            type="primary"
                                                            icon={<IconEdit />}
                                                            title="Editar"
                                                            size='small'
                                                            onClick={event => { event.stopPropagation(); this.setState({ modalProveedores: true, proveedor_id: item._id }) }}
                                                        />
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="¿Deseas eliminar este proveedor?"
                                                            onConfirm={
                                                                () => axios.post('/proveedores/delete',
                                                                    { id: item._id }).then(() => {
                                                                        this.getProveedores()
                                                                        message.success('Proveedor eliminado')
                                                                    }).catch(error => message.error(error?.response?.message))}
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                icon={<IconDelete />}
                                                                title="Eliminar"
                                                                size='small'
                                                                danger
                                                                onClick={event => { event.stopPropagation() }}
                                                            />
                                                        </Popconfirm>
                                                    </Space >
                                                </Col >
                                            </Row >
                                        }
                                        key="1"
                                    >

                                        <Row className="header-list width-100 mb-1" >
                                            <Col xs={12} lg={4} className="center">
                                                <Text strong>RAZÓN SOCIAL</Text>
                                            </Col>
                                            <Col xs={12} lg={4} className="center">
                                                <Text strong>RFC</Text>
                                            </Col>
                                            <Col xs={12} lg={7} className="center">
                                                <Text strong>DIRECCIÓN</Text>
                                            </Col>
                                            <Col xs={12} lg={3} className="center">
                                                <Text strong>ESTADO</Text>
                                            </Col>
                                            <Col xs={12} lg={3} className="center">
                                                <Text strong >PAÍS</Text>
                                            </Col>
                                        </Row>

                                        {item.proveedores_centros_distribuciones.map(centro => (

                                            <Row className="width-100 mb-1">
                                                <Col xs={12} lg={4} className="center">
                                                    <Text>{centro.razon_social}</Text>
                                                </Col>
                                                <Col xs={12} lg={4} className="center">
                                                    <Text>{centro.rfc}</Text>
                                                </Col>
                                                <Col xs={12} lg={7} className="center">
                                                    <Text>{centro.direccion}</Text>
                                                </Col>
                                                <Col xs={12} lg={3} className="center">
                                                    <Text>{centro.estado_id?.name}</Text>
                                                </Col>
                                                <Col xs={12} lg={3} className="center">
                                                    <Text>{centro.pais_id?.name}</Text>
                                                </Col>
                                                <Col xs={12} lg={3} className="center">
                                                    <Space wrap>
                                                        <Link to={`/admin/proveedores/detalle/${item._id}/centro-distribucion/${centro._id}`}>
                                                            <Button
                                                                size='small'
                                                                className='ant-btn-primary-green'
                                                                type="primary"
                                                                icon={<IconEye style={{ color: "currentcolor" }} />}
                                                                title="Ver detalle" />
                                                        </Link>
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="¿Deseas eliminar este centro de distribucion?"
                                                            onConfirm={
                                                                () => axios.delete('/proveedores/centros-distribucion/delete',
                                                                    {params:{ id: centro._id }}).then(() => {
                                                                        this.getProveedores()
                                                                        message.success('Centro de distribucion eliminado eliminado')
                                                                    }).catch(error => message.error(error?.response?.message))}
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                icon={<IconDelete style={{ color: "currentcolor" }} />}
                                                                title="Eliminar"
                                                                size='small'
                                                                onClick={event => { event.stopPropagation() }}
                                                                danger
                                                            />
                                                        </Popconfirm>
                                                    </Space>
                                                </Col>
                                            </Row>

                                        ))}

                                    </Panel>
                                </Collapse>
                            )}
                        />


                    </Content >

                    <FloatingButton title="Nuevo registro" content={contentMenuPlus} />


                    <ModalProveedores
                        visible={this.state.modalProveedores}
                        onClose={() => {
                            this.setState({ modalProveedores: false, proveedor_id: undefined })
                            this.getProveedores()
                        }}
                        proveedor_id={this.state.proveedor_id}
                    />

                    <ModalProveedoresMultiple
                        visible={this.state.modalProveedoresMultiples}
                        onClose={(flag) => {
                            this.setState({ modalProveedoresMultiples: false })
                            this.getProveedores(this.state.page)
                        }}
                    />

                    <ModalProveedorCentroDistribucion
                        visible={this.state.modalProveedorCentroDistribucion}
                        proveedor_id={this.state.proveedor_id}
                        centro_distribucion_id={this.state.centro_distribucion_id}
                        onClose={() => {
                            this.setState({ modalProveedorCentroDistribucion: false })
                            this.getProveedores(this.state.page)
                        }}
                    />

                    <DrawerFiltros
                        ref={ref => this.drawerFiltros = ref}
                        title="Filtrar Proveedores"
                        updateFilters={filters => this.getProveedores({
                            page: 1,
                            filters
                        })}
                        onClose={() => this.setState({ visibleFilters: false })}
                        visible={this.state.visibleFilters}
                        csv="proveedores/list"
                        clientes={false}
                        ordenes={false}
                        ordenes_compras={false}
                        area_rubro={false}
                        razones_sociales={false}
                        clasificadores={false}
                        negocios={false}

                    />

                </Spin >
            </>
        )
    }
}
import React, { Component } from "react";
import { Row, Col, Space, Button, List, Tag, Typography, Card, message, } from "antd";
import { CloseOutlined, WarningOutlined, CheckCircleOutlined, FilePdfOutlined } from "@ant-design/icons";
import { IconEdit, IconEye } from '../../../../Widgets/Iconos';

const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Facturas
 * @extends {Component}
 * @description Vista del listado de Facturas 
 */
class Facturas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            facturas: {

                data: [],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                //filtros
                cliente_id: this.props.cliente_id
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFacturas()
    }

    /**
    *
    *
    * @memberof Facturas
    * @description Obtiene todas todas las ListaCompras
    * @param values pagina
    */
    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            cliente_id = this.props.cliente_id,
            centro_distribucion_id = this.props.centro_id
        } = this.state.facturas,
        { facturas } = this.state

    ) => {

        facturas.page = page;
        facturas.limit = limit;
        this.setState({ loading: true, facturas });
        let search = this.props.search;
        axios.get('/facturas/list', {
            params: {
                search,
                page,
                cliente_id,
                centro_distribucion_id
            }
        })
            .then(response => {
                console.log("response", response);
                
                facturas.data = response.data.data.data
                facturas.total = response.data.data.total
                facturas.page = response.data.data.page
                this.setState({ facturas })
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }




    /**
     *
     *
     * @param {*} item
     * @memberof FacturasSC
     * 
     * @description Renderizamos el estatus de la factura.
     */
    renderEstatus = (item) => {

        switch (item.estatus) {
            case 0:
                return <Tag color="#87d068" icon={<CloseOutlined style={{ color: "currentcolor", fontSize: "14px" }} />}>Factura Cancelada</Tag>
            case 1:
                // Si la fecha de vencimiento 
                if (this.props.cliente?.premium && moment().isAfter(moment(item.fecha_vigencia)))
                    return <Tag color="#ff4d4f" icon={<CloseOutlined style={{ color: "currentcolor" }} />}>Vencida</Tag>
                else
                    return <Tag color="#faad14" icon={<WarningOutlined style={{ color: "currentcolor" }} />}>Pendiente</Tag>

            case 2:
                return <Tag color="#52c41a" icon={<CheckCircleOutlined style={{ color: "currentcolor" }} />}>Liquidada</Tag>
        }
    }

    /**
     *
     *
     * @param {*} item
     * @memberof Facturas
     * 
     * @description Renderizamos el estatus del tipo de factura.
     */
    renderTipo = (value) => {
        switch (value) {
            case 1:
                return <Tag color="#87d068">Ingreso</Tag>
            case 0:
                return "Sin cambios"
            case -1:
                return <Tag color="#f50">Ingreso</Tag>
        }
    }

    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Facturas " }}
                    dataSource={this.state.facturas.data}
                    pagination={{
                        current: this.state.page,
                        pageSize: 10,
                        total: this.state.total,
                        position: 'bottom',
                        className: "flex-left",
                        hideOnSinglePage: true,
                        onChange: (page) => this.getFacturas(page)
                    }}
                    header={<Row className="header-list width-100 pr-1 pl-1" >
                        <Col md={4} span={2} className="center">
                            <Text ellipsis strong>Tipo</Text>
                        </Col>
                        <Col md={6} span={4} className="center">
                            <Text ellipsis strong>Concepto</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Fecha</Text>
                        </Col>
                        <Col md={5} span={5} className="center">
                            <Text ellipsis strong>Estatus</Text>
                        </Col>

                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 ">
                                    <Col md={4} span={2} className="center">
                                        <Text ellipsis>{this.renderTipo(item.tipo)}</Text>
                                    </Col>
                                    <Col md={6} span={4} className="center">
                                        <p className="width-100 center">
                                            <strong>{item.concepto}</strong><br />
                                            <small>{item?.receptor?.rfc}</small>
                                        </p>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                    </Col>
                                    <Col md={5} span={5} className="center">
                                        {this.renderEstatus(item)}
                                    </Col>
                                    <Col  span={4} className="center">
                                        <Space>
                                            <Button
                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Editar"
                                                onClick={() => { this.setState({ modalFacturasVisible: true, factura_id: item._id }) }}
                                            />
                                            <Button
                                                type="primary"
                                                icon={<IconEye style={{ color: "currentColor" }} />}
                                                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                                                title="Ver XML"
                                                disabled={!item.factura_xml}
                                            >
                                            </Button>
                                            <Button
                                                type="primary"
                                                icon={<FilePdfOutlined style={{ color: "currentColor" }} />}
                                                onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}
                                                title="Ver PDF"
                                                disabled={!(item.tipo_facturacion === 1)}
                                            />
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                {/* <ModalFacturas
                    visible={this.state.modalFacturasVisible}
                    onClose={() => {
                        this.setState({ modalFacturasVisible: false, factura_id: undefined })
                        this.getFacturas()
                    }}
                    factura_id={this.state.factura_id}
                /> */}




            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {
    return (<Facturas {...props} />)
}
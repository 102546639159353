import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Checkbox, Button, Cascader, List, Form } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import FilterProyecto from "./FilterProyecto";
import FilterRubro from "./FilterRubro";
import FilterArea from "./FilterArea";
import FilterRazonSocial from "./FilterRazonSocial";
import FilterNegocio from "./FilterNegocio";
import FilterCliente from "./FilterCliente";
import FilterCuenta from "./FilterCuenta";
import PropTypes from 'prop-types';
import './filtros.css'


/**
 *
 *
 * @param {*} props
 * @returns Component
 */
function FilterSelect(props) {
    const { onChange, value } = props

    /** @type Array  @data */
    let [data, setData] = useState([
        { _id: 'Proyecto', nombre: 'Proyecto' },
        { _id: 'Area', nombre: 'Area' },
        { _id: 'Rubro', nombre: 'Rubro' },
        { _id: 'Cliente', nombre: 'Cliente' },
        { _id: 'Cuenta', nombre: 'Cuenta' },
        { _id: 'Razon Social', nombre: 'Razon Social' },
        { _id: 'Negocio', nombre: 'Negocio' },
    ]
    )
    const renderFiltro = (_id) => {

        switch (_id) {
            case 'Proyecto':
                return <Form.Item  name="proyecto_id"><FilterProyecto /></Form.Item>
                break;
            case 'Area':
                return <Form.Item  name="area_id"><FilterArea /></Form.Item>
                break;
            case 'Rubro':
                return <Form.Item  name="rubro_id"><FilterRubro /></Form.Item>
                break;
            case 'Cliente':
                return <Form.Item  name="cliente_id"><FilterCliente /></Form.Item>
                break;
            case 'Cuenta':
                return <Form.Item  name="cuenta_id"><FilterCuenta /></Form.Item>
                break;
            case 'Razon Social':
                return <Form.Item  name="razon_social_id"><FilterRazonSocial /></Form.Item>
                break;
            case 'Negocio':
                return <Form.Item  name="negocio_id"><FilterNegocio /></Form.Item>
                break;
            default:
                break;
        }

    }


    return (
        <List
            size="small"
            dataSource={data}
            style={{ backgroundColor: '#fff' }}
            renderItem={({ _id, nombre, }) => <List.Item >
                <>
                    <Dropdown className="filter-sub-menu" trigger="click" arrow={false} align={"bottomLeft"} overlay={renderFiltro(_id)}>
                        <a><LeftOutlined /> {nombre}</a>
                    </Dropdown>
                </>
            </List.Item>}
        />
    )

};
FilterSelect.propTypes = {
    onChange: PropTypes.func,
    onChange: PropTypes.func,
}
export default FilterSelect

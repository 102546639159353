

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, Upload } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";


const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;


/**
 *
 *
 * @class FormUsuarios
 * @extends {React.Component}
 * @description Formulario de usuarios
 */
class FormUsuarios extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    static contextType = Logged;
    formModaluser = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            id: null,
            image: null,
            negocios: [],
            showNegocios: false,

        }
    }


    /**:4
     * @methodOf ModalUsuarios
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getNegocios()
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.state.id = this.props.id
            this.getUsuario(this.props.id)
        }
    }

    /**
     * @methodOf ModalUsuarios
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un estado.
     *
     * */
    componentDidUpdate(prevProps, prevState, snapshot) {
        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.state.id = this.props.id
            this.getUsuario(this.props.id)
        }

        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModaluser.current.resetFields();
        }

    }


    /**
     *
     * @methodOf ModalUsuarios
     *
     * @method getUsuario
     * @description Obtiene el usuario
     * @param id ObjectId
     *
     * */
    getUsuario = (id) => axios.get("/usuarios/get", { params: { id } })
        .then(async ({ data }) => {

            const avatar = data.data.avatar;
            if (data.data.avatar !== undefined && data.data.avatar !== null && data.data.avatar !== "") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + avatar,
                        name: avatar
                    }
                })
                data.data.avatar = [{
                    uid: -1,
                    name: data.data.avatar,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.data.avatar,
                    response: { filename: data.data.avatar }
                }];

            } else data.data.avatar = []



            // data.data.proyectos_permisos = data.data.proyectos_permisos.map(p => p._id)

            let permisos = [];
            let roles = data.data.roles;


            if (roles?.finanzas) permisos.push("finanzas");
            if (roles?.crm) permisos.push("crm");
            if (roles?.p_manager) permisos.push("p_manager");
            if (roles?.legal) permisos.push("legal");
            if (roles?.cuentas) permisos.push("cuentas");
            if (roles?.areas) permisos.push("areas");
            if (roles?.categorias) permisos.push("categorias");
            if (roles?.rubros) permisos.push("rubros");
            if (roles?.negocios) permisos.push("negocios");
            if (roles?.usuarios) permisos.push("usuarios");

            if (roles?.bitacora_estatus_avance) permisos.push("bitacora_estatus_avance");
            if (roles?.estatus_avance) permisos.push("estatus_avance");
            if (roles?.razones_sociales) permisos.push("razones_sociales");

            data.data.permisos = permisos;
            this.setState({
                form: data.data,
                showNegocios: data.data?.tipo !== 1
            })
            await this.formModaluser.current.resetFields();
        })
        .catch(res => {
            message.error('No se encontro el usuario');
            console.log("Usuario no obtenido", res);
        })


    /**
     * @methodOf  Usuarios
     * @method saveUsuario
     *
     * @description Guardao actualiza el usuario, segun si elID está definido.
     *
     * */
    saveUsuario = values => {
        this.setState({
            loading: true
        })

        if (this.state.image)
            values.avatar = this.state.image.name;
        else
            values.avatar = null;
        if (values._id) {

            axios.put('/usuarios/update', { ...values, id: values._id })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el usuario!")
                    this.props.accept();
                })
                .catch((e) => {
                    message.error('No se pudo actualizar el usuario');
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                    image: undefined
                }))
        } else {
            axios.post('/usuarios/add', { ...values, id: values._id })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el usuario!")
                    this.props.accept();
                }).catch((e) => {
                    message.error('No se pudo crear el usuario')
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                    image: undefined
                }))
        }
    }


    /**
     *
     *
     * @memberof ModalUsuarios
     * @method getNegocios
     * @description Obtiene la información de los negocios registradas en la base de datos
     *
     */
    getNegocios = () => {
        axios.get('/negocios/list', {
            params: {
                pagination: false
            }
        }).then((negocios) => {
            console.log('negocios', negocios)
            this.setState({
                negocios: negocios.data.data.data
            })
        }).catch((error) => {
            console.log('error', error)
        })
    }




    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param image (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param e (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })
        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

            if (this.state.form._id)
                axios.put('/usuarios/update', { avatar: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        this.state.form.avatar = [];
        this.state.image = undefined;
        this.formModaluser.current.resetFields();
        if (this.state.form._id)
            axios.put('/usuarios/update', { avatar: 0, id: this.state.form._id })
                .finally(() => {
                    this.props.update();
                    this.setState({ loadingImage: false })
                })
        else {
            this.props.update();
            this.setState({ loadingImage: false })
        }
    };

    render() {
        const { normFile, formModaluser, saveUsuario, deleteImage } = this;
        const { form, image, loading, id, negocios, loadingImage } = this.state;
        const option = [
            {
                name: "Finanzas",
                value: "finanzas"
            },
            {
                name: "CRM",
                value: "crm"
            },
            {
                name: "Administrador de Proyectos",
                value: "p_manager"
            },
            {
                name: "Legal",
                value: "legal"
            },
            {
                name: "Cuentas",
                value: "cuentas"
            },
            {
                name: "Categorias",
                value: "categorias"
            },
            {
                name: "Areas",
                value: "areas"
            },
            {
                name: "Proyectos",
                value: "proyectos"
            },
            {
                name: "Usuarios",
                value: "usuarios"
            },
            {
                name: "Nóminas",
                value: "nominas"
            },
            {
                name: "Empleados",
                value: "empleados"
            },
            {
                name: "Finanzas Nominas",
                value: "finanzasnominas"
            },
            {
                name: "Manager",
                value: "manager"
            },
            {
                name: "Ventas",
                value: "ventas"
            },
            {
                name: "Compras",
                value: "compras"
            },

            {
                name: "Estatus Avance",
                value: "estatus_avance"
            },
            {
                name: "Proyecto Estatus Avance",
                value: "Proyecto"
            },
            {
                name: "Inventario",
                value: "inventario"
            },

        ];
        const user = this.context;
        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{id ? "Editar Usuario" : "Nuevo Usuario"}</Title>
                <Form
                    layout="vertical"
                    ref={formModaluser}
                    name="formulario-transacciones"
                    onFinish={saveUsuario}
                    initialValues={form}

                    onValuesChange={(changedValues, allValues) => {
                        if (changedValues.tipo) {
                            this.setState({
                                showNegocios: changedValues.tipo != 1
                            })

                        }
                    }}

                >
                    <Form.Item name="_id" noStyle >
                        <Input type="hidden" />
                    </Form.Item>
                    <Row justify="center" className="w-100" gutter={[16,0]}> 
                        <Form.Item name="avatar" getValueFromEvent={normFile} valuePropName="fileList"
                            help={image ? <Button className="btn-upload-delete" shape="circle" danger icon={<DeleteOutlined />} onClick={deleteImage} /> : null}
                        >
                            <Upload listType="picture-card" className="avatar-uploader" showUploadList={false} action={axios.defaults.baseURL + "/upload/add"} accept="image/*" >

                                {(loadingImage) ? <div>
                                    {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div>Upload</div>
                                </div> : ((image) ?
                                    <img src={image.url} alt="avatar" className="width-100" />
                                    :
                                    <div>
                                        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div>Upload</div>
                                    </div>)}
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row gutter={[16,0]}>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Nombre" name="nombre" rules={[{ required: true, message: "Por favor, ingrese el nombre" }]} >
                                <Input placeholder="Nombre"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item label="Apellido" name="apellido" rules={[{ required: true, message: "Por favor, ingrese apellido" }]} >
                                <Input placeholder="Apellido"></Input>
                            </Form.Item>
                        </Col>
                    
                        <Col xs={24} lg={12}>
                            <Form.Item label="Posición" name="posicion" rules={[{ required: true, message: "Por favor, ingrese posición" }]}>
                                <Input placeholder="Posición"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item label="Correo Electrónico" name="email" rules={[{ required: true, message: "Por favor, ingrese correo electrónico" }]} >
                                <Input placeholder="Correo Electrónico"></Input>
                            </Form.Item>
                        </Col>

                        {(user && user.tipo == 1) ? <Col xs={24} >
                            <Form.Item label="Tipo de Usuario" name="tipo" rules={[{ required: true, message: "Por favor, ingrese el tipo de Usuario" }]} >
                                <Select>
                                    <Option key="1" value={1}>Dueño</Option>
                                    <Option key="2" value={2}>Administrador</Option>
                                    <Option key="3" value={3}>Usuario</Option>
                                    <Option key="3" value={4}>Cliente</Option>
                                </Select>
                            </Form.Item>
                        </Col> : null}
                    </Row>
                    {this.state.showNegocios ? [
                        <Row>
                            <Col xs={24}>
                                <Form.Item label="Permisos" name="permisos">
                                    <Select mode="multiple" placeholder="Permisos ">
                                        {option.map((option, index) => (
                                            <Option key={index} value={option.value}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>,
                        <Row>
                            <Col xs={24}>
                                <Form.Item label="Negocios" name="negocios_permisos" >
                                    <Select mode="multiple" placeholder="Permisos Negocios">
                                        {negocios.map(function ({ _id, nombre, logo, color }, index) {
                                            return <Option value={_id} style={{ margin: '2px 0 2px 0' }}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    ] : null}

                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={loading || loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormUsuarios {...props} />
        </Modal>
    )
}
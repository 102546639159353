import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { DollarCircleOutlined, BankOutlined, ShopOutlined } from '@ant-design/icons';

//componentes
import { IconDashboard } from '../Widgets/Iconos.js'

//css
import '../../Styles/Global/sider.css';

const { SubMenu } = Menu;
const { Sider } = Layout;

/**
 * @class CustomerSidebar
 * @descrpcion Sidebar para el usuaario tipo cliente
 */
class CustomerSidebar extends Component {


    render() {

        return (
            <Sider
                style={{ zIndex: 2 }}
                theme="light"
                className="custom-sider sider-width"
                breakpoint="lg"
                collapsedWidth={0}
                width={240}
                collapsed={this.props.collapsed}

            >
                <Menu mode="inline" theme="light" style={{ height: '100%', borderRight: 0 }} >

                    <Menu.ItemGroup key="g1" title="NAVEGACIÓN">
                        <Menu.Item key="1" icon={<IconDashboard />}>
                            <Link to={'/customer/pedidos'}>Mis Pedidos</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </Menu>
            </Sider>
        )
    }
}

export default (props) => <CustomerSidebar navigate={useNavigate()} {...props} />

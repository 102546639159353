import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select ;
const axios = require("axios");

/**
 * @const RubroSelect
 * @description Select para los rubros del area que se seleccione, solo buscara los rubros del area definida
 * 
 * @param area_id ID id del area del cual se buscaran sus rubros
 */
const RubroSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el Rubro", onSelect = () => {}, area_id, disabled = false, className} = props

    const [ rubros, setRubros ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
        area_id: null
    })


    /**
     * @const getRubros
     * @description Obitiene los rubros del area seleccionado
     */
    const getRubros = ({
        page = rubros.page, 
        limit = rubros.limit, 
        search = rubros.search, 
        area_id = rubros.area_id
    } = rubros) => {

        axios.get('/rubros/list', {
            params: { 
                page, 
                limit, 
                search,
                area_id
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setRubros({
                ...rubros,
                area_id: area_id,
                data: response.data.data,
                total: response.data.total,
            })

        }).catch(error => {
            message('Error al obtener los clientes')
        })
    }
    
    //componentDidUpdate
    useEffect(() => {
        if(area_id)
            getRubros({area_id})
    }, [area_id])


    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getRubros({search})}
            onSelect={(rubro)=> {
                onChange(rubro.value)
                onSelect(rubro.value)
            }}
            value={value}
        >
            {
                rubros.data.map(rubro => <Option value={rubro._id} key={rubro._id}>{rubro.nombre}</Option>)
            }
        </Select>
    );
}



export default RubroSelect;
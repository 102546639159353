import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Space, Tabs, Typography } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";

import { CardAccountPurchases } from '../../Widgets/Cards';

//Componentes
import { IconDelete, IconEdit, IconDetails, IconPlus } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import IngresosList from './CuentasComprasSecciones/IngresosList';
import EgresosList from './CuentasComprasSecciones/EgresosList';
import FacturasList from './CuentasComprasSecciones/FacturasList';
import ComprasList from './CuentasComprasSecciones/ComprasList';


const { Content } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

/**
 * @export
 * @class Categorias
 * @extends {Component}
 * @description Vista de Categorias
 */
export default class CuentasCompras extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            monto_venta: 0,
            costos_proyectado: 0,
            costos_real: 0,
            ingreso_proyectado: 0,
            ingreso_real: 0,
            utilidad_proyectado: 0,
            utilidad_real: 0,
            facturas_emitidas: 0,
            facturas_recibidas: 0,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Cuentas y Compras"
                    />
                    <Content className="admin-content content-bg pd-1">


                        <Row className="width-100 ">
                            <CardAccountPurchases
                                monto_venta={this.state.monto_venta}
                                costos_proyectado={this.state.costos_proyectado}
                                costos_real={this.state.costos_real}
                                ingreso_proyectado={this.state.ingreso_proyectado}
                                ingreso_real={this.state.ingreso_real}
                                utilidad_proyectado={this.state.utilidad_proyectado}
                                utilidad_real={this.state.utilidad_real}
                                facturas_emitidas={this.state.facturas_emitidas}
                                facturas_recibidas={this.state.facturas_recibidas}
                            />
                        </Row>
                        <Row className='mt-1'>
                            <Tabs type="card" className="width-100">
                                <TabPane tab="Ingresos" key="ingresos" >
                                    <IngresosList
                                        
                                    />
                                </TabPane>
                                <TabPane tab="Egresos" key="egresos" >
                                    <EgresosList
                                        
                                    />
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas" >
                                    <FacturasList
                                        
                                    />
                                </TabPane>
                                <TabPane tab="Ordenes de compra" key="ordenes" >
                                    <ComprasList
                                        
                                    />
                                </TabPane>
                                {/* <TabPane tab="Transacciones" key="transacciones" >
                                    <Transacciones
                                        cliente_id={this.props.params.id}
                                        cliente={this.state?.cliente}
                                    />
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas">
                                    <Facturas
                                        cliente_id={this.props.params.id}
                                        cliente={this.state?.cliente}
                                    />
                                </TabPane> */}
                            </Tabs>

                        </Row>

                    </Content>
                </Spin>
            </>
        )
    }
}
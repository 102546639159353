import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const ClienteSelect
 * @description Select para los clientes registrados en el sistema
 */
const ClienteSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el cliente", onSelect = () => {}, disabled = false, className = "" } = props

    const [ clientes, setClientes ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getClientes
     * @description Obitiene los clientes
     */
    const getClientes = ({page, limit, search} = clientes) => {

        axios.get('/clientes/list', {
            params: { page, limit, search },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setClientes({
                ...clientes,
                data: response.data.data.itemsList,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            message('Error al obtener los clientes')
        })
    }

    //componentDidMount
    useEffect(() => {
        getClientes()
    }, [])

    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getClientes({search})}
            onSelect={(cliente)=> {
                onChange(cliente.value)
                onSelect(cliente.value)
            }}
            value={value}
        >
            {
                clientes.data.map(cliente => <Option value={cliente._id} key={cliente._id}>{cliente.razon_social}</Option>)
            }
        </Select>
    );
}



export default ClienteSelect;
import React, { Component } from 'react';
import { Spin, PageHeader, Layout, Collapse,  Space, Tabs, Col, Row, Typography } from 'antd'
import axios from 'axios';
import { Link, useParams ,useNavigate } from "react-router-dom";
import { SettingOutlined, FileTextFilled,DollarCircleFilled } from "@ant-design/icons";
import {FaFileInvoiceDollar} from "react-icons/fa"

//componentes
import FormOrden from './FormOrden';
import ReportesTab from './Tabs/ReportesTab';
import FacturasTab from './Tabs/FacturasTab';
import { IconArrowBack } from '../../Widgets/Iconos';
import TransaccionesTab from './Tabs/TransaccionesTab';
import ServiciosTableEditable from './MatrizCostos/Servicios';
import MaterialesTableEditable from './MatrizCostos/Materiales';
import RefaccionesTableEditable from './MatrizCostos/Refacciones';
import { CardResumenCostos, CardSolicitudOrdenMantenimiento } from '../../Widgets/Cards';

//scss
import '../../../Styles/Modules/Mantenimiento/mantenimiento.scss'

const { Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;
const { TabPane } = Tabs;
/**
 * @export
 * @class OrdenMantenimiento
 * @extends {Component}
 * @description Vista de Nueva  Orden de Mantenimiento 
 */
class OrdenMantenimientoMatriz extends Component {

    back = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            mantenimiento_id: this.props.params.mantenimiento_id,
            servicios:{
                total: 0,
                elementos: []
            },
            refacciones:{
                total: 0,
            },
            materiales:{
                total: 0,
            },

            tipo_solicitud: 0,
            solicitud: {
                usuario: {
                    nombre: 'Nombre',
                    rol: "rol_id",
                    email: "nombre@email.com"
                },
                tipo_aviso: "Tipo aviso",
                numero: 'numero',
                fecha: new Date()
            },
            

        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDetalles()
    }


    /**
     * @memberof CrearOrdenMantenimiento
     * @method getDetalles
     * @description obtiene los datos de los saldos de la orden
     */
    getDetalles = () => {
        axios.get('/mantenimiento/matriz',{
            params:{
                mantenimiento_id: this.state.mantenimiento_id,
            }
        }).then(response => {

            let { servicios, refacciones, materiales } = this.state

            let servicio_data = response.data.find(e => e._id === 0)
            let refacciones_data = response.data.find(e => e._id === 1)
            let materiales_data = response.data.find(e => e._id === 2)
            
            
            servicios = servicio_data ? servicio_data : { total: 0, total_t: 0, utilidad: 0, elementos: [] }
            refacciones = refacciones_data ? refacciones_data : { total: 0, total_t: 0, utilidad: 0, elementos: [] }
            materiales = materiales_data ? materiales_data : { total: 0, total_t: 0, utilidad: 0, elementos: [] }

                
            this.setState({servicios, refacciones, materiales})
            

        }).catch(error => {
            console.log("error", error);

        })
    }

    render() {


        return (
            <Spin spinning={this.state.loading}>
                <PageHeader
                    onBack={this.back}
                    backIcon={<IconArrowBack />}
                    className="site-page-header custom-page-header"
                    title="Orden de Mantenimiento"
                />
                <Content className="admin-content content-bg pd-1">

                    <Row gutter={[16, 16]}>
                        <Col xxl={{ span: 24, order: 1 }} xl={{ span: 24, order: 1 }} lg={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
                            <FormOrden
                                mantenimiento_id={this.state.mantenimiento_id}
                            />
                        </Col>
                        <Col xxl={{ span: 18, order: 2 }} xl={{ span: 16, order: 2 }} lg={{ span: 24, order: 3 }} md={{ span: 24, order: 3 }}>

                            <Tabs defaultActiveKey="1" >
                                <TabPane tab={<Space direction="horizontal"><SettingOutlined className='text-gray-dark'/><Text>Matriz Costos</Text> </Space>} key="1">
                                    <Collapse defaultActiveKey={['1']} className="collapse-blue ">
                                        <Panel
                                            header={
                                                <Row className="flex width-100" justify="space-around">
                                                    <Col span={12}>
                                                        <Text className="collapsable-title">
                                                            Servicios
                                                        </Text>
                                                    </Col>
                                                    <Col span={12} className="flex-right"><Text className="collapsable-title">$ {this.state.servicios?.total?.toMoney(true)} MXN</Text></Col>
                                                </Row>
                                            } key="1">
                                            <ServiciosTableEditable 
                                                mantenimiento_id={this.state.mantenimiento_id}
                                                updateTotal={()=>this.getDetalles()}
                                            />
                                        </Panel>
                                        <Panel
                                            header={
                                                <Row className="flex width-100" justify="space-around">
                                                    <Col span={12}>
                                                        <Text className="collapsable-title">
                                                            Refacciones
                                                        </Text>
                                                    </Col>
                                                    <Col span={12} className="flex-right"><Text className="collapsable-title">$ {this.state.refacciones?.total?.toMoney(true)} MXN</Text></Col>
                                                </Row>
                                            } key="2">
                                            <RefaccionesTableEditable 
                                                mantenimiento_id={this.state.mantenimiento_id}
                                                updateTotal={()=>this.getDetalles()}
                                            />
                                        </Panel>
                                        <Panel
                                            header={
                                                <Row className="flex width-100" justify="space-around">
                                                    <Col span={12}>
                                                        <Text className="collapsable-title">
                                                            Materiales
                                                        </Text>
                                                    </Col>
                                                    <Col span={12} className="flex-right"><Text className="collapsable-title">$ {this.state.materiales?.total?.toMoney(true)} MXN</Text></Col>
                                                </Row>
                                            } key="3">
                                            <MaterialesTableEditable 
                                                mantenimiento_id={this.state.mantenimiento_id}
                                                updateTotal={()=>this.getDetalles()}
                                            />
                                        </Panel>
                                    </Collapse>
                                </TabPane>
                                {/*<TabPane tab={<Space direction="horizontal"><FileTextFilled className='text-gray-dark'/><Text>Reportes </Text> </Space>}  key="2">
                                    <ReportesTab />
                                </TabPane>*/}
                                <TabPane tab={<Space direction="horizontal"><DollarCircleFilled className='text-gray-dark'/><Text>Transacciones </Text> </Space>} key="3">
                                    <TransaccionesTab 
                                        mantenimiento_id={this.state.mantenimiento_id}
                                    />
                                </TabPane>
                                <TabPane tab={<Space direction="horizontal"><FaFileInvoiceDollar className='text-gray-dark'/><Text>Facturas </Text> </Space>} key="4">
                                    <FacturasTab 
                                        mantenimiento_id={this.state.mantenimiento_id}
                                    />
                                </TabPane>
                            </Tabs>

                        </Col>
                        <Col xxl={{ span: 6, order: 3 }}  xl={{ span: 8, order: 3 }}  lg={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }}>
                            <CardResumenCostos servicios={this.state.servicios} refacciones={this.state.refacciones} materiales={this.state.materiales}/>
                        </Col>
                    </Row>
                </Content>

            </Spin >

        )
    }
}


export default function (props) {

    return <OrdenMantenimientoMatriz {...props} params={useParams()}  navigate={useNavigate()} />
}
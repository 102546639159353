import React, { Component } from 'react';
import { Spin, PageHeader, Layout, Card, Row, Radio, Col, Form, DatePicker, Select, Input, message } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { WarningOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';

//componentes
import FormOrden from './FormOrden';
import { IconArrowBack } from '../../Widgets/Iconos';
import { ProveedorSelect, ClienteSelect, ClienteCentrosSelect }  from "../../Widgets/Inputs/Selects";


//scss
import '../../../Styles/Modules/Mantenimiento/mantenimiento.scss'

const { Content } = Layout;
const { Option } = Select;

/**
 * @export
 * @class CrearOrdenMantenimiento
 * @extends {Component}
 * @description Vista de Nueva  Orden de Mantenimiento 
 */
export default class CrearOrdenMantenimiento extends Component {

    back = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            tipo_solicitud: 0,

            cliente_id: undefined,
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    /**
     *
     *
     * @memberof CrearOrdenMantenimiento
     * @method onChange_tipo_solicitud
     * @description Actualiza el valor de tipo_solicitud para ocultar o mostrar secciones del formulario
     */
    onChange_tipo_solicitud = (value) => {
        this.setState({ tipo_solicitud: value.target.value })
    }


    /**
     *
     *
     * @memberof CrearOrdenMantenimiento
     * @method addOrden
     * @description Crea una nueva orden de manatenimiento
     */
    addOrden = (values) => {
        this.setState({ loading: true })
        axios.post('/mantenimiento',{
            tipo: this.state.tipo_solicitud,
            ...values
        }).then(response => {
            console.log("response", response);
            message.success('Orden de Mantenimiento creada')
        }).catch(error => {
            console.log("error", error);
            message.error('Error al crear la orden')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     *
     *
     * @memberof CrearOrdenMantenimiento
     * @method onFinish
     * @description Metodo que se ejecuta al finalizar el formulario
     * @param values 
     */
    onFinish = (values) => {
        let form = this.formRef.current.getFieldsValue()

        this.addOrden(form)
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <PageHeader
                    onBack={this.back}
                    backIcon={<IconArrowBack />}
                    className="site-page-header custom-page-header"
                    title="Mantenimiento"

                />
                <Content className="admin-content content-bg pd-1">
                    <FormOrden/>
                </Content>

            </Spin>

        )
    }
}
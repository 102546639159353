import React, { Component } from "react";
import { Layout, Button, Col, Row, Typography, List, message, Card, Statistic} from "antd";

//componentes
import { IconEye } from '../../../Widgets/Iconos';
import { renderMontoFactura } from "../../../Utils";

const { Content } = Layout;
const {  Text } = Typography
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class FacturasList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class FacturasList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            facturas: {
                data:[],
                page: 1,
                limit: 10,
                total: 0,
            }
        }
    }


    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFacturas()
    }


    /**
     * @memberof FacturasList
     * @method getFacturas
     * @description Obtiene las facturas de el proyecto correspondiente
     */
    getFacturas = ({
        page = this.state.facturas.page,
        limit = this.state.facturas.limit
    } = this.state.facturas) => {
            console.log("this.props.proyecto_id,", this.props.proyecto_id,);

        this.setState({loading: true})
        axios.get('/customer/facturas', {
            params:{
                page,
                limit,
                proyecto_id: this.props.proyecto_id,
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({facturas: data})
        }).catch(err => {
            console.log(err)
            message.error('Error al obtener las facturas')
        })
        .finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000','#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className=""
                                itemLayout="horizontal"
                                dataSource={this.state.facturas.data}
                                loading={this.state.loading}
                                pagination={{
                                    current: this.state.facturas.page,
                                    pageSize: this.state.facturas.limit,
                                    total: this.state.facturas.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                }}
                                renderItem={ item => (
                                    <List.Item className="">
                                        <Card className="card-list width-100">
                                            <div className={`badge-card tipo-${item.tipo}`}>
                                            </div>
                                            <Row className="width-100">
                                            	<Col span={5} className="center">
                                                    <Text strong>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col span={10} className="center">
                                                    <Text strong>{item.concepto}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text>{renderMontoFactura({tipo: item.tipo, monto: item.monto})}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    {/*<Button
                                                        icon={<IconEye />}
                                                        type="primary"
                                                        title="Ver"
                                                        onClick={()=>this.setState({modalVisible: true, factura_id: item._id})}
                                                    >
                                                    </Button>*/}
                                                </Col>
                                            </Row>
                                        </Card>
                                </List.Item>
                                )}
                            />
                           
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}
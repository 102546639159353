import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Col, Row, Badge, Layout, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { BsBellFill } from "react-icons/bs";

import PropTypes from 'prop-types';
import CustomAvatar from "../Widgets/Avatar/Avatar";
import { Link, useNavigate } from "react-router-dom";
import '../../Styles/Global/header.scss';

import { User, SetUser } from "../../Hooks/Logged";


const { Header } = Layout;
const { Search } = Input;
let myRef = React.createRef();




/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} { setUser, user, showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = ({ showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal, admin }) => {

    const user = React.useContext(User)
    const setUser = React.useContext(SetUser)

    const navigate = useNavigate()



    /**
     * @const cerrarSesion
     * @description Cierra la sesion del dashboard
     */
    const cerrarSesion = () => {
        setUser(undefined);
        sessionStorage.clear();
        navigate("/login");

    };

    let userAvatar;
    let nombre;

    if (typeof user == "object") {
        let { avatar, nombre, apellido } = user;
        nombre = `${user.nombre} ${user.apellidos ? user.apellidos : ''}`;

        userAvatar = <div><CustomAvatar name={[nombre, apellido]} image={avatar} /><span style={{ marginLeft: '10px' }}>{nombre}</span></div>
    }


    /**
    * @const NavMenu
    * @description Muestra el Menu dependiendo si hay sesion iniciada o no
    */
    const NavMenu = () => {
        if (user == "object") {
            return [
                <Link to="/admin/dashboard">Dashboard</Link>,
                showResponsiveSidebar && <a>Cerrar Sesión</a>
            ]
        } else {
            return (
                <Link to="/login" className="link-login">
                    Iniciar Sesión
                </Link>
            )
        }
    }

    /**
    * @const UserMenu
    * @description Muestra el Menu del dashboard
    */
    const UserMenu = () => {
        return (
            <Col flex="auto" className="header-content" >
                <Row justify="space-around">
                    <Col span={20} xxl={20} xl={20} lg={17} md={18} sm={16} >
                        <div className="header-search">
                            {(showSearch) ?
                                <Search
                                    placeholder="Buscar"
                                    onSearch={(e) => { setFilterSearch(e) }}
                                    //  suffix={<SearchOutlined/>}
                                    id="inputFilterSearch"
                                    enterButton
                                    ref={myRef}
                                    className="input-global-search"
                                />
                                :
                                ''
                            }
                        </div>
                    </Col>
                    <Col span={4} xxl={4} xl={4} lg={7} md={6} sm={8}>
                        <Row type="flex" justify="center" align="middle" className=" header-options">
                            <Col flex={2} className="option-notificacion">
                                {/* <Link to="#" > */}
                                <Badge count={0}>
                                    <BsBellFill className="icon-bell" />
                                </Badge>
                                {/* </Link> */}
                            </Col>
                            <Col flex={3} className="option-menu">
                                <Dropdown
                                    arrow={true}
                                    overlay={
                                        <Menu>
                                            <Menu.Item key="0" onClick={cerrarSesion}>
                                                Cerrar Sesión
                                            </Menu.Item>
                                        </Menu>
                                    } trigger={['click']}>
                                    <div >
                                        <a>
                                            {userAvatar}
                                        </a>
                                        <a onClick={e => e.preventDefault()}
                                            style={{ marginLeft: '2rem' }}>
                                            {nombre} <DownOutlined />
                                        </a>
                                    </div>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </Col>
        )
    }

    /**
    * @const renderDesktopNavbar
    * @description Muestra el Menu del tamaño full cover para pantallas grandes
    */
    const renderDesktopNavbar = () => {
        return admin == true ? UserMenu() : NavMenu()

    }

    /**
    * @const renderMobileNavbar
    * @description Muestra el Menu responsivo
    */
    const renderMobileNavbar = () => {
        return showResponsiveSidebar ? '' : NavMenu();
    }

    return (
        <Header className="header" >
            <Row type="flex" className="row-header-content" >
                <Col className="header-logo" sm={24} xs={24}>
                    <div className="logo">
                        <Link to="/">
                            <img src={'/images/logo.png'} alt="logo" />
                        </Link>
                    </div>
                </Col>
                {renderDesktopNavbar()}
            </Row>
        </Header>
    );

}
Navbar.propTypes = {
    title: PropTypes.string,
    back: PropTypes.bool,
    onBack: PropTypes.func
};

Navbar.defaultProps = {
    back: false,
    title: 'Quantum 360',
};





export default Navbar;

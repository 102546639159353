import React, { Component } from "react";
import { Row, Col, Button, List, Tag, Typography, Card, message, Modal, Space } from "antd";
import { DeleteOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";

//componentes
import { IconDelete, IconEdit, IconEye } from "../../../Widgets/Iconos";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { renderMontoFactura, tipos_facturas, estatus_facturas }  from "../../../Utils"
//Modal
import ModalXML from "../../Syncfy/ModalXML";
import ModalFacturas from "../../Contabilidad/Facturas/ModalFacturas";


const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Facturas
 * @extends {Component}
 * @description Vista del listado de Facturas
 */
class Facturas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            facturas: {

                data: [],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                //filtros

            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFacturas()

    }

    /**
    * @memberof Facturas
    * @method getFacturas
    * @description  Lista las facturas de un proveedor
    **/
    getFacturas = ({
        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
    } = this.state.facturas) => {
        this.setState({ loading: true })
        axios.get('/facturas/list', {
            params: {
                proveedor_id: this.props.id
            }
        })
            .then(response => {
                this.setState({
                    facturas: response.data.data
                })
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof Facturas
     * @descripcion elimina una factura
     * @param {ObjectId} id id de la factura
     */
    deleteFactura = (_id) => {
        axios.delete('/facturas/delete', {
            params: {
                id: _id
            }
        })
            .then(e => {
                message.success("Se ha eliminado la factura")
                this.getFacturas();
            })
            .catch(error => {
                message.success("Error al eliminar la factura")
            })
    }

    renderTipo = (value) => {
        switch (value) {
            case 1:
                return "Ingreso"
            case 0:
                return "Sin cambios"
            case -1:
                return "Egreso"
        }
    }

    renderEstatus = (value) => {
        switch (value) {
            case 1:
                return "Pendiente"
            case 0:
                return "Cancelada"
            case 2:
                return "Liquidada"
        }
    }


    render() {

        // console.log('state', this.state)
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Facturas" }}
                    dataSource={this.state.facturas.data}
                    pagination={{
                        current: this.state.facturas.page,
                        pageSize: 10,
                        total: this.state.facturas.total,
                        position: 'bottom',
                        className: "flex-left",
                        onChange: (page) => this.getFacturas({page}) 
                    }}
                    header={<Row className="header-list width-100 pr-1 pl-1" >
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Fecha</Text>
                        </Col>
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Factura</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Concepto</Text>
                        </Col>
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Estatus</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Tipo</Text>
                        </Col>
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Monto</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Acciones</Text>
                        </Col>
                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 ">
                                    <Col md={3} span={3} className="center">
                                        <Text ellipsis strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                    </Col>
                                    <Col md={3} span={3} className="center">
                                        <Text ellipsis strong>{(item.folio != null && item.serie != null) ? `${item.folio}-${item.serie}` : 'N /A '}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{item.concepto}</Text>
                                    </Col>
                                    <Col md={3} span={3} className="center">
                                        <span className="spn-estatus">
                                            <Text ellipsis className="text-gray-dark">{estatus_facturas(item?.estatus)}</Text>
                                        </span>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <span className="spn-tipo">
                                           <Text ellipsis className="text-gray-dark">{tipos_facturas(item?.tipo)}</Text>
                                        </span>
                                    </Col>
                                    <Col md={3} span={3} className="center">
                                        {renderMontoFactura({monto: item.monto, tipo: item.tipo})}
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Space>
                                            <Button
                                                type="primary"
                                                className="ant-btn-primary-green"
                                                icon={<IconEye style={{ color: "currentColor" }} />}
                                                disabled={!item.factura_xml}
                                                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                                                title="Ver XML"
                                            />
                                            <Button
                                                type="primary"
                                                icon={<IconEdit style={{ color: "currentColor" }} />}
                                                onClick={(e) => this.setState({ modalFacturasVisible: true, factura_id: item._id })}
                                                title="Ver XML"
                                            />
                                            <Button
                                                type="primary"
                                                danger
                                                icon={<IconDelete style={{ color: "currentColor" }} />}
                                                onClick={(e) => Modal.confirm({
                                                    title: 'Eliminar Factura',
                                                    description: '¿Está seguro de eliminar la factura?',
                                                    onOk: () => this.deleteFactura(item._id)
                                                })}
                                                title="Eliminar "
                                            />
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton
                    onClick={() => this.setState({ modalFacturasVisible: true })}
                    title="Nuevo registro 2"
                />
                <ModalFacturas
                    visible={this.state.modalFacturasVisible}
                    onClose={() => {
                        this.setState({ modalFacturasVisible: false, factura_id: undefined })
                        this.getFacturas()
                    }}
                    factura_id={this.state.factura_id}
                />
                <ModalXML
                    visible={this.state.ModalXMLVisible}
                    onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                    factura_xml_storage={this.state.factura_xml_storage}
                />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Facturas {...props} />)
}
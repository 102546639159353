import React, { Component } from "react";
import { Layout, Row, Col, Divider, PageHeader, message, Modal, Pagination, Tabs, Button, Space } from "antd";
import { CardMantenimientoTask, CardTaskStep } from '../../Widgets/Cards';
import { useNavigate, Link } from "react-router-dom";
import { ExclamationCircleFilled, ExclamationCircleOutlined, FilterOutlined, WarningOutlined } from '@ant-design/icons'

import axios from 'axios'

import User from '../../../Hooks/Logged'

import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import "../../../Styles/Modules/ProjectManager/ProjectManager.scss";
import "../../../Styles/Modules/Mantenimiento/dashboard.scss";

const { Content } = Layout;
const { TabPane } = Tabs


/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista de Project Manager
 */
class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible_filters: false,
            tipo: 0,

            curso:{
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                estatus: 0,
                name: 'curso',
            },

            ordenes: {
                curso: [],
                aceptada: [],
                tecnico: [],
                tienda: [],
                pagada: [],
                page: 1,
                total: 0,
                filters: [],
            },
            redirect: false,
            filters: [],
        }
    }


    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getOrdenes({page: 1, name: 'curso'})
    }

    /**
     * @method getOrdenes 
     * @descipcion obtiene de forma independiente las ordenes dependiendo del estatus del que se quiere obtener las ordenes
     * @parmas OBJ { page, limit, estatus }
     * 
     **/
    getOrdenes = (parametros) => {
        let { page, limit, estatus } = this.state[parametros.name]
        axios.post('/mantenimiento-ordenes',{
            
                page,
                limit,
                tipo: this.state.tipo,
                estatus: estatus,
                filters: this.state.filters
            
        }).then(response => {
            this.setState({
                [parametros.name]:{
                    ...this.state[parametros.name],
                    data: response.data.data,
                    page: response.data.page,
                    total: response.data.total, 
                }
            })

        }).catch(error => {
            message.error('Erro al obtener las ordenes')
        })
    }

    /**
    * @memberof Dashboard
    * @method asignarResponsable
    * @description asignar responsable 
    * 
    */
    asignarResponsable = (mantenimiento_id, tipo_orden) => {

        Modal.confirm({
            title: '¿Quieres ser el Responsable de esta Orden?',
            icon: <ExclamationCircleOutlined />,
            //content: 'Some descriptions',
            onOk: () => {
                axios.put('/mantenimiento/reponsable', {
                    mantenimiento_id: mantenimiento_id,
                }).then(response => {
                    message.success('Responsable Asignado')
                    this.getOrdenes({name: tipo_orden})
                }).catch(error => {
                    console.log(error)
                    message.error('Error al asignar al Responsable')
                })
            },
        });
    }

    /**
    * @memberof Dashboard
    * @method redirect
    * @description Redirige a la vista para visualizar los datos de la orden
    * 
    */
    redirect = (orden_id) => {
        this.props.navigate(`/admin/mantenimiento/orden/${orden_id}`);
    }

    /**
    * @memberof Dashboard
    * @method onChangeTipo
    * @description Obtiene las ordenes al cambiar de tipo
    */
    onChangeTipo = (key) => {
        this.setState({tipo: key},() => {
            this.getOrdenes({page: 1, name: 'curso'})
        })
    }


    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={"Mantenimiento - Project Manager"}
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[8, 8]} className="width-100" align="start">
                        <Col flex="auto">
                        <Tabs defaultActiveKey={this.state.tipo} onChange={this.onChangeTipo} className="dashboard-mantenimiento-tabs">
                            <TabPane
                                tab={
                                    <span>
                                    <ExclamationCircleFilled />
                                    Correctivas
                                    </span>
                                }
                                key="0"
                            />
                            <TabPane
                                tab={
                                    <span>
                                    <WarningOutlined />
                                    Preventivas
                                    </span>
                                }
                                key="1"
                            />
                        </Tabs>
                        </Col>
                        <Col flex="none">
                            <Button
                                icon={<FilterOutlined style={{color: "#FFF"}} />}
                                type="primary"
                                title="Filtrar Ordenes"
                                onClick={() => this.setState({visible_filters: true})}>
                            </Button>
                        </Col>
                    </Row>

                    <Row gutter={[8, 8]} className="width-100 dashboard-mantenimiento-tasks" align="start">
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="dashboard-mantenimiento-col">
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep 
                                    color={'#0089ED'} 
                                    title={"En Curso"}
                                    simple={true}
                                />
                                <Divider className="border-task" />
                            </div>

                            <div className="col-list-task-margin col-list-task-bg">

                                {this.state.curso.data.map(orden => {

                                    return <CardMantenimientoTask 
                                        key={orden._id}
                                        color={'#0089ED'} 
                                        orden={orden} 
                                        onClick={() => {
                                            if (orden.responsable_id?.nombre || this.props.user?.tipo === 1){
                                                this.redirect(orden._id)
                                                return
                                            }

                                            this.asignarResponsable(orden._id)
                                        }}
                                    />
                                })}
                                  
                            </div>
                            
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="dashboard-mantenimiento-col">
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep 
                                    color={'#DAC401'} 
                                    title={"Aceptada"}
                                    simple={true}
                                />
                                <Divider className="border-task" />
                            </div>

                            <div className="col-list-task-margin col-list-task-bg">

                                {this.state.ordenes.aceptada.map(orden => {

                                    return <CardMantenimientoTask 
                                        key={orden._id}
                                        color={'#0089ED'} 
                                        orden={orden} 
                                        onClick={() => {
                                            if (orden.responsable_id?.nombre || this.props.user?.tipo === 1){
                                                this.redirect(orden._id)
                                                return
                                            }

                                            this.asignarResponsable(orden._id)
                                        }}
                                    />
                                })}
                                  
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="dashboard-mantenimiento-col">
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep 
                                    color={'#5C54E0'} 
                                    title={"Con Cierre Técnico"}
                                    simple={true}
                                />
                                <Divider className="border-task" />
                            </div>

                            <div className="col-list-task-margin col-list-task-bg">

                                {this.state.ordenes.tecnico.map(orden => {

                                    return <CardMantenimientoTask 
                                        key={orden._id}
                                        color={'#0089ED'} 
                                        orden={orden} 
                                        onClick={() => {
                                            if (orden.responsable_id?.nombre || this.props.user?.tipo === 1){
                                                this.redirect(orden._id)
                                                return
                                            }

                                            this.asignarResponsable(orden._id)
                                        }}
                                    />
                                })}
                                  
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="dashboard-mantenimiento-col">
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep 
                                    color={'#D6009A'} 
                                    title={"Con Cierre de Tienda"}
                                    simple={true}
                                />
                                <Divider className="border-task" />
                            </div>

                            <div className="col-list-task-margin col-list-task-bg">

                                {this.state.ordenes.tienda.map(orden => {

                                    return <CardMantenimientoTask 
                                        key={orden._id}
                                        color={'#0089ED'} 
                                        orden={orden} 
                                        onClick={() => {
                                            if (orden.responsable_id?.nombre || this.props.user?.tipo === 1){
                                                this.redirect(orden._id)
                                                return
                                            }

                                            this.asignarResponsable(orden._id)
                                        }}
                                    />
                                })}
                                  
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="dashboard-mantenimiento-col">
                            <div className="col-list-task-margin col-list-task-bg">
                                <CardTaskStep 
                                    color={'#0CC440'} 
                                    title={"Pagada"}
                                    simple={true}
                                />
                                <Divider className="border-task" />
                            </div>

                            <div className="col-list-task-margin col-list-task-bg">

                                {this.state.ordenes.pagada.map(orden => {

                                    return <CardMantenimientoTask 
                                        key={orden._id}
                                        color={'#0089ED'} 
                                        orden={orden} 
                                        onClick={() => {
                                            if (orden.responsable_id?.nombre || this.props.user?.tipo === 1){
                                                this.redirect(orden._id)
                                                return
                                            }

                                            this.asignarResponsable(orden._id)
                                        }}
                                    />
                                })}
                                  
                            </div>
                        </Col>
                    </Row>

                    
                    <Row  gutter={[8, 8]} className="width-100" >
                        <Col>
                            <Pagination
                                className
                                current={this.state.ordenes.page}
                                total={this.state.ordenes.total}
                                onChange={(page)=>{
                                    let ordenes = this.state.ordenes
                                    ordenes.page = page
                                    this.setState({ordenes}, ()=> {})
                                }}
                            />
                        </Col>
                    </Row>

                </Content>

                <Link to="orden/nueva">
                    <FloatingButton title="Confirmar"/>
                </Link>

                <DrawerFiltros
                    //ref={ref => this.drawerFiltros = ref}
                    title="Filtrar Ordenes"
                    updateFilters={filters => { 
                        this.setState({filters}, () => {
                            this.getOrdenes({page: 1, name: 'curso'})
                        }) 
                    }}
                    onClose={() => this.setState({ visible_filters: false })}
                    visible={this.state.visible_filters}

                    cuentas={false}
                    ordenes={false}
                    orden_compra={false}
                    area_rubro={false}
                    proveedores={false}
                    negocios = {false}
                    clasificadores = {false}
                    razones_sociales = {false}
                    
                    clientes={true}
                    // estatus={{
                    //     type: 'tree',
                    //     data: [{
                    //         value: 1,
                    //         title: "En Curso",
                    //         children: [{
                    //             value: 2,
                    //             title: "Autorizado",
                    //         }]
                    //     }]
                    // }}
                />
            </>
        )
    }
}


export default function (props){

    let navigate = useNavigate();
    const user = React.useContext(User);

    return <Dashboard {...props} navigate={navigate} user={user}/>
}

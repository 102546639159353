import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';

//componentes
import { ServiciosSelect, RefaccionSelect, MaterialSelect }  from "../../../Widgets/Inputs/Selects";

const { Title } = Typography;
const axios = require('axios').default;



/**
 * @class ModalMatriz
 * @descripcion Modal que permite añadir elementos a la matriz de costos del modulo de mantenimiento, 
 *      depende del tipo que se le envia ('Servicio', 'Refacción', 'Material')
 * */
class ModalMatriz  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     * @memberof ModalMatriz
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        this.addRegistro(values)
    }


    /**
     * @memberof ModalMatriz
     * @method addRegistro
     * @description Añade un nuevo registro a la matriz de la orden de mantenimiento
     */
    addRegistro = (values) => {
        this.setState({loading: true})
        axios.post('/mantenimiento/add/registro', {
            ...values,
            mantenimiento_id: this.props.mantenimiento_id
        }).then(response => {
            console.log("response", response);
            this.props.onClose({
                new_element: response.data.new_element,
                total: response.data.total,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al crear el registro.')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <Form
                layout="vertical"
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        {this.props.tipo === 'Servicio' ? <Col xs={24} lg={20} >
                            <Form.Item
                                label="Servicio"
                                name="servicio_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el servicio"
                                }]}
                            >
                                <ServiciosSelect/>
                            </Form.Item>
                        </Col> : null }

                        {this.props.tipo === 'Refacción' ? <Col xs={24} lg={20} >
                            <Form.Item
                                label="Refacción"
                                name="refaccion_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la refacción"
                                }]}
                            >
                                <RefaccionSelect/>
                            </Form.Item>
                        </Col> : null }

                        {this.props.tipo === 'Material' ? <Col xs={24} lg={20} >
                            <Form.Item
                                label="Material"
                                name="material_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Material"
                                }]}
                            >
                                <MaterialSelect/>
                            </Form.Item>
                        </Col> : null }
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function vista(props) {
    const { visible = false, onClose = () => { }, tipo } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>Añadir {tipo}</Title>
        </div>
        <ModalMatriz {...props} />
    </Modal>

}
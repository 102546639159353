import React, { Component } from 'react';
import { Button, Spin, PageHeader, Layout, message, Tabs, Row, Col, Card, Typography, } from 'antd'

import axios from 'axios';

import { IconArrowBack, } from '../../Widgets/Iconos';
import Proyectos from './Secciones/Proyectos'
import Facturas from './Secciones/Facturas';
import Transacciones from './Secciones/Transacciones';

import '../../../Styles/Modules/ProveedoresDashboard/dashboard.scss'
import { useParams,useNavigate } from 'react-router-dom';
import Centros from './Secciones/Centros';


const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Content } = Layout;

/**
 * @export
 * @class ProveedorDetalle
 * @extends {Component}
 * @description Vista de detalles de un proveedor
 */
class ProveedorDetalle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            proveedor: undefined,
            proveedores: [],

            page: 1,
            total: 0
        }
    }

    volver = () => {
        this.props.navigate(-1);
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.setState({ proveedor_id: this.props.params.proveedor_id })
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProveedor();
    }

    /**
    * @memberof ProveedorDetalle
    * @method getProveedor
    * @description  Obtiene la informacion del proveedor seleccionado
    **/
    getProveedor = () => {
        axios.get('/proveedores/get', {
            params: {
                id: this.props.params.proveedor_id
            }
        }).then(response => {
            this.setState({
                proveedor: response.data.data
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {

        console.log('v', this.props.params)

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <div>
                                <Button ghost onClick={this.volver} style={{ border: "none" }}>
                                    <IconArrowBack />
                                </Button>

                                <Text level={4} className="text-white ant-page-header-heading-title ml-1" > Información de {this.state.proveedor?.alias} </Text>
                            </div>
                        }
                    />

                    <Content className="admin-content content-bg pd-1">

                        <Row>
                            <Col span={24}>
                                <Card bordered >
                                    <Row className='mb-2'>
                                        <Title level={4}>Información del Proveedor </Title>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Alias</label>
                                            <p className="pInfo"><strong>
                                                {this.state.proveedor?.alias}
                                            </strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Razón Social</label>
                                            <p className="pInfo"><strong>
                                                {this.state.proveedor?.razon_social
                                                }
                                            </strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Linea de crédito</label>
                                            <p className="pInfo"><strong>
                                                {`$ ${(this.state.proveedor?.linea_credito) ? this.state.proveedor?.linea_credito?.toMoney(true) : 0.00} MXN`}
                                            </strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Plazo de crédito</label>
                                            <p className="pInfo"><strong>
                                                {`${(this.state.proveedor?.plazo) ? this.state.proveedor?.plazo: 'Sin'} días`}
                                            </strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Saldo sin vencer</label>
                                            <p className="pInfo"><strong>
                                                {`$ ${this.state.proveedor?.saldo_pendiente.toMoney(true)} MXN `}
                                            </strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Monto actual pagado</label>
                                            <p className="pInfo"><strong>
                                                {`$ ${this.state.proveedor?.total_pagado.toMoney(true)} MXN `}
                                            </strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Saldo Vencido</label>
                                            <p className="pInfo"><strong>
                                                {`$ ${this.state.proveedor?.saldo_pendiente.toMoney(true)} MXN `}
                                            </strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="mb-1 lblTitulo">Saldo Total</label>
                                            <p className="pInfo"><strong>
                                                {`$ ${this.state.proveedor?.total_vendido.toMoney(true)} MXN `}
                                            </strong> </p>
                                        </Col>

                                    </Row>

                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Tabs type="card" className="width-100">
                                <TabPane tab="Centros Distribución" key="centros">
                                    <Centros id={this.props.params.proveedor_id}/>
                                </TabPane>
                                <TabPane tab="Transacciones" key="transacciones" >
                                    <Transacciones id={this.props.params.proveedor_id} />
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas">
                                    <Facturas id={this.props.params.proveedor_id} />
                                </TabPane>
                            </Tabs>

                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}
export default function (props){

    return <ProveedorDetalle {...props} params={useParams()} navigate={useNavigate()}/>
}
import React from 'react'
import { Route, Routes } from "react-router-dom";

import CRM from "../components/Admin/CRM/CRM"
import Clientes from '../components/Admin/CRM/Clientes/Clientes'
import DetalleClientes from '../components/Admin/CRM/Clientes/DetalleClientes'
import CentroDistribucion from '../components/Admin/CentrosDistribucion/CentroDistribucion';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCRM(props) {

    return (
        <Routes>
            <Route path="" element={<CRM {...props} />} />
            <Route path="clientes" element={<Clientes {...props} />} />
            <Route path="cliente/:id" element={<DetalleClientes {...props} />}  />
            <Route path="cliente/:cliente_id/centro-distribucion/:centro_id" element={<CentroDistribucion {...props}  title="Cliente"/>}  />

        </Routes>
    )
}

export default RouterCRM;

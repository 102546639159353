import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import '../../Styles/Global/sider.css';
import { Link } from "react-router-dom"

import { useNavigate } from "react-router-dom";

import {

    IconCRM,
    IconCuentas,
    IconFinanzas,
    IconDashboard,


    IconUsuarios,
    IconRubros,
    IconProyectos,
    IconPM,
    IconMenu,

    IconProveedores,
    IconDetails,
    IconContabilidad,
    IconLegal,

} from '../Widgets/Iconos.js'

import {
    ToolOutlined
} from '@ant-design/icons';
import { DollarCircleOutlined, BankOutlined, ShopOutlined } from '@ant-design/icons'

import { GiMechanicalArm } from "react-icons/gi"
import { BsBoxSeam } from "react-icons/bs"

const { SubMenu } = Menu;
const { Sider } = Layout;
let roles = {
    finanzas: false,
    crm: false,
    p_manager: false,
    legal: false,
    cuentas: false,
    categorias: false,
    rubros: false,
    proyectos: false,
    usuarios: false,

    manager: false,

    estatus_avance: false,
    bitacora_estatus_avance: false,
};



/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class AdminSidebar extends Component {



    render() {
        const { user } = this.props;
        let owner = false;
        if (typeof user == "object") {
            if (user.roles !== undefined)
                roles = user.roles;

            if (user.tipo == 1)
                owner = true;
        }
        return (
            <Sider
                style={{ zIndex: 2 }}
                theme="light"
                // className="custom-sider sider-width"
                className={(this.props.collapsed) ? "btn-display custom-sider sider-width  " : "btn-no-display custom-sider sider-width"}
                breakpoint="lg"
                collapsedWidth={0}
                width={240}
                collapsible={true}
                onCollapse={this.props.setCollapse}
                collapsed={this.props.collapsed}

            >
                <Menu mode="inline" theme="light" style={{ height: '100%', borderRight: 0 }} >
                    <Button onClick={this.props.setCollapse} className={(this.props.collapsed) ? "btn-display no-border  " : "btn-no-display no-border"}  >
                        {this.props.collapsed ? <IconMenu /> : <IconMenu />}
                    </Button>
                    <Menu.ItemGroup key="g1" title="NAVEGACIÓN">

                        <Menu.Item key="1" icon={<IconDashboard />}>
                            <Link to={'/admin/dashboard'}>Dashboard</Link>
                        </Menu.Item>

                        {(owner || roles.finanzas) ?
                            <SubMenu
                                key="finanzas"
                                title={<Link to={'/admin/finanzas'}>Finanzas</Link>}
                                icon={<IconFinanzas />}
                            >
                                <Menu.Item key="transacciones" icon={<DollarCircleOutlined />} onClick={() => this.props.navigate('/admin/finanzas/transacciones', { replace: true })}>
                                    Transacciones
                                </Menu.Item>
                            </SubMenu>
                            : null}

                        {(owner || roles.contabilidad) ?
                            <SubMenu
                                key="contabilidad"
                                title={<Link to={'/admin/contabilidad'}>Contabilidad</Link>}
                                icon={<IconContabilidad />}
                            >
                                <Menu.Item key="facturas" icon={<IconDetails />} onClick={() => this.props.navigate('/admin/contabilidad/facturas', { replace: true })}>
                                    {/* <Link to={'/admin/contabilidad/facturas'} replace ={true} > */}
                                    Facturas
                                    {/* </Link> */}
                                </Menu.Item>
                            </SubMenu>

                            : null}
                        {(owner || roles.crm) ?
                            <SubMenu
                                key="crm"
                                title={<Link to={'/admin/crm'}>CRM</Link>}
                                icon={<IconCRM style={{ margin: 0 }} />}
                            >
                                <Menu.Item key="clientes" icon={<IconUsuarios />} onClick={() => this.props.navigate('/admin/crm/clientes', { replace: true })}>Clientes</Menu.Item>
                            </SubMenu>
                            : null}

                        {(owner || roles.proveedores) ? <SubMenu
                            key="proveedores-dashboard"
                            title={<Link to={'/admin/proveedores/dashboard'}>Proveedores</Link>}
                            icon={<IconProveedores style={{ margin: 0 }} />}
                        >
                            <Menu.Item key="proveedores" icon={<IconDetails />} onClick={() => this.props.navigate('/admin/proveedores', { replace: true })}>
                                Proveedores
                                {/* <Link to={'/admin/proveedores'}></Link> */}
                            </Menu.Item>
                        </SubMenu>
                            : null}

                        {(owner || roles.p_manager) ?
                            <Menu.Item key="project-manager" icon={<IconPM />} onClick={() => this.props.navigate('/admin/project-manager/dashboard', { replace: true })}>
                                Project Manager
                            </Menu.Item>
                            : null}



                        <SubMenu
                            key="mantenimiento"
                            title={<Link to={'/admin/mantenimiento'}>Mantenimiento</Link>}
                            icon={<IconRubros />}
                        >
                            <Menu.Item key="servicios" icon={<ToolOutlined />}>
                                <Link to={'/admin/servicios'} replace>Servicios</Link>
                            </Menu.Item>

                            <Menu.Item key="refacciones" icon={<GiMechanicalArm />}>
                                <Link to={'/admin/refacciones'} replace>Refacciones</Link>
                            </Menu.Item>

                            <Menu.Item key="materiales" icon={<BsBoxSeam />}>
                                <Link to={'/admin/materiales'} replace>Materiales</Link>
                            </Menu.Item>
                        </SubMenu>






                    </Menu.ItemGroup>
                    <Menu.Divider className="group-sider-divider " />
                    <Menu.ItemGroup key="usuarios" title="AJUSTES">
                        {(owner || roles.usuarios) ?
                            <Menu.Item key="8" icon={<IconUsuarios />}>
                                <Link to={'/admin/usuarios'} replace>Usuarios</Link>
                            </Menu.Item> : null}

                        {(owner || roles.negocios) ?
                            <Menu.Item key="negocios" icon={<IconProyectos />}>
                                <Link to={'/admin/negocios'} replace>Negocios</Link>
                            </Menu.Item> : null}

                        {(owner || roles.razon_social) ?
                            <Menu.Item key="razones-sociales" icon={<BankOutlined />} >
                                <Link to={'/admin/razones-sociales'} replace>Razones Sociales</Link>
                            </Menu.Item> : null}
                        {(owner || roles.cuentas) ?
                            <Menu.Item key="cuentas" icon={<IconCuentas />}>
                                <Link to={'/admin/cuentas'} replace>Cuentas</Link>
                            </Menu.Item> : null}

                        <Menu.Item key="areas" icon={<IconRubros />}>
                            <Link to={'/admin/areas'} replace>Áreas</Link>
                        </Menu.Item>

                        <Menu.Item key="categorias" icon={<IconRubros />}>
                            <Link to={'/admin/categorias'} replace>Categorias</Link>
                        </Menu.Item>




                    </Menu.ItemGroup>
                </Menu>

            </Sider>
        )
    }
}

export default (props) => <AdminSidebar navigate={useNavigate()} {...props} />

import React, { Component } from 'react';
import { Spin, PageHeader, Layout, message, Row, Col, Card, Typography, List, Tag } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom"

//componentes
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconError, IconWarning, IconSuccess } from '../../Widgets/Iconos';

//modales
import ModalProveedores from "./ModalProveedores"

//css
import '../../../Styles/Modules/ProveedoresDashboard/dashboard.scss'

const { Text, Title } = Typography;
const { Content } = Layout;

/**
 * @export
 * @class ProveedoresDashboard
 * @extends {Component}
 * @description Vista de Proveedores
 */
export default class ProveedoresDashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            proveedor: undefined,
            proveedores: {
                data: [],

                page: 1,
                limit: 8,

                total: 0,
                pages: 0,
                loading: false
            },
            facturas: {
                data: [],

                page: 1,
                limit: 5,

                total: 0,
                pages: 0,


                filters: [],
                sort: {},

                loading: false
            },

        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDashboard();
        this.getProveedores();
        this.getFacturas();
    }

    /**
    * @memberof ProveedoresDashboard
    * @method getProveedores
    * @description  Pagina los proveedores existentes en la base de datos
    */
    getProveedores = ({
        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
    } = this.state.proveedores) => {
        this.setState({ loading: true });
        axios.post('/proveedores/list', {
            page, limit, dashboard: true

        }).then(response => {
            this.setState({
                proveedores: {
                    ...this.state.proveedores,
                    data: response.data.data.itemsList,
                    total: response.data.data.paginator.itemCount,
                    pages: response.data.data.paginator.pageCount,
                }
            });

        }).catch(error => {
            console.log(error)
            message.error(error?.response?.message)
        }).finally(()=>this.setState({ loading: false }))
    }

    /**
* @memberof ProveedoresDashboard

* @description  Lista las facturas de una cuenta, en un rango de fechas
**/
    getFacturas = ({
        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
    } = this.state.facturas) => {
        
        axios.post('/facturas/list', {
            page, limit, dashboard: true
        })
            .then(response => {
                console.log('facturas', response.data.data);
                this.setState({
                    facturas: {
                        ...this.state.facturas,
                        data: response.data.data.itemsList,
                        total: response.data.data.paginator.itemCount,
                        pages: response.data.data.paginator.pageCount,
                        loading: false
                    }
                });
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof ProveedoresDashboard
    * @method getDashboard
    * @description  Lista las facturas de una proveedor, se le puede poner un limite al query
    de facturas vigentes a renderizar

    **/
    getDashboard = (pageProv, pageFact) => {
        if (pageProv == null) pageProv = this.state.pageProv
        if (pageFact == null) pageFact = this.state.pageFact
        this.setState({ loading: true })
        axios.get('/proveedores/dashboard', {
            params: {
                pageProv,
                pageFact,
                limit: undefined,
            }
        })
            .then(response => {
                console.log('proveedores', response.data.data)
                this.setState({
                    vencidas: response.data.data.vencidas[0]?.vencidas,
                    vigentes: response.data.data.vigentes[0]?.vigentes,
                })
            })
            .catch(error => {
                message.error('Error al Cargar los proveedores')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))

    }

    renderEstatus = (value) => {

        let estatus = {
            1: "Pendiente",
            0: "Cancelada",
            2: "Liquidada", 
        }

        return estatus[value] ?? '-'
    }

    renderEstatusProveedor = (value) => {
        let vencidas = value.facturas_vencidas[0] ? value.facturas_vencidas[0]?.cantidad : null
        let porVencer = value.facturas_por_vencer[0] ? value.facturas_por_vencer[0]?.cantidad : null

        if (vencidas) {
            return <IconError/>
        } else if (porVencer) {
            return <IconWarning/>
        } else {
            return <IconSuccess/>
        }
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Proveedores Dashboard"
                    />
                    <Content className="admin-content dashboard content-bg pd-1">
                        <Row gutter={[16, 16]} className="width-100" align="start" justify="space-between">
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                <Card className="vencidas">
                                    <Row>
                                        <Text className='card-title'>Facturas Vencidas</Text>
                                    </Row>
                                    <Row className='mt-2' align='center'>
                                        <Text className='card-title'>{this.state.vencidas ? this.state.vencidas : 0}</Text>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                <Card className="por-vencer">
                                    <Row>
                                        <Text className='card-title'>Facturas por vencer</Text>
                                    </Row>
                                    <Row className='mt-2' align='center'>
                                        <Text className='card-title'>{this.state.aVencer ? this.state.aVencer : 0}</Text>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                                <Card className="a-tiempo">
                                    <Row>
                                        <Text className='card-title'>Facturas a tiempo</Text>
                                    </Row>
                                    <Row className='mt-2' align='center'>
                                        <Text className='card-title'>{this.state.vigentes ? this.state.vigentes : 0}</Text>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        
                        <Row gutter={[16, 8]} className="width-100" align="start" justify="space-between">
                            <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                                <Row>
                                    <List
                                        loading = {this.state.loading}
                                        className = 'width-100 list-no-border'
                                        grid={{
                                            gutter: [16,16],
                                            xs: 1,
                                            sm: 1,
                                            md: 2,
                                            lg: 2,
                                            xl: 2,
                                            xxl: 2,
                                          }}
                                        locale={{emptyText: 'No hay proveedores'}}
                                        dataSource={this.state.proveedores.data}
                                        pagination={{
                                            current: this.state.proveedores.page,
                                            pageSize: this.state.proveedores.limit,
                                            total: this.state.proveedores.total,
                                            position: 'bottom',
                                            className: "flex-left",
                                            hideOnSinglePage: true,
                                            onChange: (page, limit) => this.setState(state => {
                                                state.proveedores.page = page;
                                                return state;
                                            }, () => this.getProveedores({ page, limit })),
                                        }}

                                        header={<Row className="">
                                            <Title level={4} className="m-0 text-gray">Proveedores</Title>
                                        </Row>}

                                        renderItem={item => (
                                            <Col>
                                                <Link to={`/admin/proveedores/detalle/${item._id}`} replace>
                                                    <Card className='card-shadow card-list'>
                                                        <Row>
                                                            <Text>PROVEEDOR</Text>
                                                        </Row>
                                                        <Row className='mt-1 mb-1' justify='space-between' align='middle'>
                                                            <Col span={21}>
                                                                <Text className='nombre-proveedor'>{item.alias}</Text>
                                                            </Col>
                                                            <Col span={3}>
                                                            {this.renderEstatusProveedor(item)}
                                                            </Col>
                                                        </Row>
                                                        <Row className='mt-1' justify='space-around' align='middle'>
                                                            <Col>
                                                                <Row className='center'>
                                                                    <Text className='tag-title'>Vencidas</Text>
                                                                </Row>
                                                                <Row className='center'>
                                                                    <Text className='tag-info'>{item.facturas_vencidas[0] ? item.facturas_vencidas[0]?.cantidad : 0}</Text>
                                                                </Row>
                                                            </Col>
                                                            <Col>
                                                                <Row className='center'>
                                                                    <Text className='tag-title'>Por Vencer</Text>
                                                                </Row>
                                                                <Row className='center'>
                                                                    <Text className='tag-info'>{item.facturas_por_vencer[0] ? item.facturas_por_vencer[0]?.cantidad : 0}</Text>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Link>
                                            </Col>

                                        )}
                                    />
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                                <List
                                    loading = {this.state.loading}
                                    className = "width-100 list-no-border"
                                    itemLayout='vertical'
                                    locale={{emptyText: 'No hay facturas'}}
                                    dataSource={this.state.facturas.data}
                                    header={<Row className="">
                                        <Title level={4} className="m-0 text-gray">Facturas</Title>
                                    </Row>}

                                    renderItem = {item => (                                        
                                        <Card className='mb-1 card-shadow card-list' size="small" >
                                            <Row>
                                                <Tag className='tag-estatus'><Text className='tag-estatus-txt'>{this.renderEstatus(item.estatus)}</Text></Tag>
                                            </Row>
                                            <Row justify='space-between' align='middle'>
                                                <Col span={18}>
                                                    <Text ellipsis className='concepto'>{item.concepto}</Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Text>$ {item.monto_pesos ? item.monto_pesos.toMoney(true) : 0} MXN</Text>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Text>{item.proveedor_id.alias}</Text>
                                            </Row>
                                        </Card>
                                        )}
                                    />
                                
                            </Col>
                        </Row>
                    </Content>
                </Spin>
                
                <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

                <ModalProveedores
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({
                            modal_visible: false
                        })
                        if(flag) this.getProveedores()
                    }}
                />
            </>
        )
    }
}
import React, { Component } from "react";
import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Spin, Typography, Upload, } from 'antd';


import PropTypes from "prop-types";
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { UsuarioSelect } from "../../../Widgets/Inputs/Selects";

import {User} from "../../../../Hooks/Logged";
const axios = require('axios').default;
const { Title, } = Typography;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class FormReporte
 * @extends {Component}
 */
class FormReporte extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };
  

    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            form: {},
            loadingFile: false,

            responsables: {
                data: [{}],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                loading: false
            }
        }
    }
    static contextType = User;
    formModalReporte = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.state.id = this.props.id
            this.getReporte(this.props.id)
        }
    }

    /**
     * @methodOf FormReporte
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un
     *
     * */
    componentDidUpdate() {
        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.id !== this.state.id && this.props.id !== undefined && this.props.id !== null) {
            this.state.id = this.props.id
            this.getReporte(this.props.id)
        }

        //Si la propiedad es diferente de la actual y el estado es diferente de nada y la propiedad es nada, reiniciamos el formulario.
        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.file = undefined;
            this.formModalReporte.current.resetFields();
        }
    }

    /**
     * @methodOf FormReporte
     * @function getReporte
     *
     * @description Obtiene el proyecto
     *
     * */
    getReporte = (id) => axios.get("", {
        params: { id }
    })
        .then(async ({ data }) => {
           
            await this.formModalReporte.current.resetFields();
        })
        .catch(res => {
            message.error('No se pudo cargar el Reporte')
            console.log("no obtenida", res);
        })


    /**
     * @methodOf FormReporte
     * @function saveReporte
     *
     * @description Guarda el proyecto
     *
     * */
    saveReporte = values => {
        
        // if (values._id) {

        //     axios.put('', { ...values, id: values._id })
        //         .then(() => {
        //             message.success("¡Se ha guardado correctamente el Reporte!")
        //             this.props.accept();
        //         })
        //         .catch((e) => {
        //             message.error('No se pudo guardar el Reporte')
        //             console.log('e', e)
        //         })
        //         .finally(() => this.setState({
        //             loading: false,
        //             loadingFile: false,
        //             image: undefined
        //         }))
        // } else {
        //     axios.post('/negocios/add', { ...values, id: values._id })
        //         .then(() => {
        //             message.success("¡Se ha guardado correctamente el Reporte!")
        //             this.props.accept();
        //         })
        //         .catch((e) => {
        //             message.error('No se pudo guardar el Reporte')
        //             console.log('e', e)
        //         })
        //         .finally(() => this.setState({
        //             loading: false,
        //             loadingFile: false,
        //             image: undefined
        //         }))
        // }
    }



    /**
     *
     * @memberof ModalReporte
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param {string} file Recibe el nombre del archivo.
     */
    removeFile = (file) => {
        axios.post("/upload/delete", {
            filename: file
        })
            .then(res => {
                console.log("Archivo removido con éxito", res);
            })
            .catch(res => {
                console.log("Archivo no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalReporte
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingFile: true })


        if (file.status === "done") {
            if (this.state.file)
                this.removeFile(this.state.file.name)

            this.setState({
                file: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingFile: false
            })
            if (this.state.form._id)
                axios.put('/negocios/update', { documento: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };


    /**
     *
     * @memberof FormReporte
     *
     * @method deleteFile
     * @description Elimina un archivo del WebService.
     *
     */
    deleteFile = () => {
        this.setState({ loadingFile: true })
        const { file } = this.state;
        this.removeFile(file.name);
        this.state.form.avatar = [];
        this.state.file = undefined;
        this.formModalReporte.current.resetFields();
        if (this.state.form._id)
            axios.put('', { documento: 0, id: this.state.form._id })
                .finally(() => {
                    this.props.update()
                    this.setState({ loadingFile: false })
                })
        else {
            this.props.update()
            this.setState({ loadingFile: false })
        }
    };

    render() {
        const { normFile, formModalReporte, saveReporte, deleteFile } = this;
        const { form, file, loading, id, loadingFile } = this.state;
        const user = this.context;
        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{id ? "Editar Reporte" : "Nuevo Reporte"}</Title>
                <Form layout="vertical" ref={formModalReporte} initialValues={form} onFinish={saveReporte} >
                    <Form.Item name="_id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>

                    <Row className="" align="center" gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Responsable"
                                name="responsable_id"
                                required
                            >
                               <UsuarioSelect/>
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                label="Responsable"
                                name="responsable_id"
                                required
                            >
                               <UsuarioSelect/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                required
                            >
                                <Select
                                    className="width-100"
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => {}}
                                >
                                    <Option value={1} >{"normal"}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={16}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="reporte" align="center" getValueFromEvent={normFile} valuePropName="fileList"
                                help={file ? <Button className="btn-upload-delete" shape="circle" danger onClick={deleteFile}><DeleteOutlined /></Button> : null} >
                                <Upload
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={axios.defaults.baseURL + "/upload/add"}
                                >
                                    {(loadingFile) ? <div>
                                        {(this.state.loading || loadingFile) ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div className="ant-upload-text">Subir Reporte</div>
                                    </div> : ((file) ?
                                        <img src={file.url} alt="avatar" className="width-100" />
                                        :
                                        <div>
                                            {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                            <div className="ant-upload-text">Upload</div>
                                        </div>)}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalReportes
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormReporte {...props} />
        </Modal>
    )
}

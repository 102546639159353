import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Select, InputNumber } from 'antd';

const { Title } = Typography;
const {Option} = Select;

const axios = require('axios').default;


/**
 *
 *
 * @class ModalServicios
 * @extends {Component}
 * @description Modal con formulario para agregar/editar Servicios
 */
class ModalServicios  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedores: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
        }
    }

    ModalServicios = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProveedores()
        if (this.props.servicio_id !== undefined) {
            this.getServicio()
            
        }
    }


    /**
     * @memberof ModalServicios
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.servicio_id) {
            this.updateServicio(values)
        } else {
            this.addServicio(values)
        }
    }


    /**
     * @memberof ModalServicios
     * @method addRubro
     * @description Añade un rubro a la BD
     */
    addServicio = (values) => {
        axios.post('/servicios/add', {
            ...values
        }).then(response => {
        
            if (response.data.success) {
                message.success('Servicio creado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al crear servicio, verifique los datos ingresados.')
            }
        }).catch(error => {
            console.log(error)
            message.error('Error al crear servicio.')
        })
    }


    /**
     * @memberof ModalServicios
     * @method getRubro
     * @description Obtiene un rubro de la DB
     */
    getServicio = () => {

        this.setState({ loading: true })

        axios.get('/servicios/get', {
            params: {
                id: this.props.servicio_id
            }
        }).then(response => {

            let servicio = response.data.data
            this.setState({
                servicio: response.data.data,
            })

            this.ModalServicios.current.setFieldsValue({
                nombre: servicio.nombre,
                codigo_servicio: servicio.codigo_servicio,
                proveedor_id: servicio.proveedor_id,
                precio: servicio.precio,
                descripcion: servicio.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer el servicio')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalServicios
     * @method updateRubro
     * @description Actualiza la información de un rubro
     */
    updateServicio = (values) => {
        this.setState({ loading: true })
        axios.put('/servicios/update', {
            ...values,
            id: this.props.servicio_id,
        }).then(response => {

            if (response.data.success) {
                message.success('Servicio Actualizado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar servicio, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar servicio')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
     getProveedores = ({

        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
        search = this.state.proveedores.search,

    } = this.state.proveedores) => {

        this.setState({ proveedores: { ...this.state.proveedores, loading: true, page, limit, search } })

        axios.get('/proveedores/list', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {

                this.setState(state => {
                    state.proveedores.data = data.data.itemsList;
                    state.proveedores.total = data.data.paginator.itemCount;
                    state.proveedores.pages = data.data.paginator.pageCount;

                    return state;
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la información')
            })
    }

    render() {
        return (
            <Form
                layout="vertical"
                ref={this.ModalServicios}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={12} className="ant-row-center">
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Código Servicio"
                                name="codigo_servicio"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el código del servicio"
                                }]}
                            >
                                <Input placeholder="Código" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el proveedor"
                                }]}
                            >
                                <Select
                                    showArrow
                                    className="width-100"
                                    
                                >
                                    {this.state.proveedores.data.map((proveedor) => {
                                        return <Option value={proveedor._id} >{proveedor.alias}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Precio"
                                name="precio"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el precio del servicio"
                                }]}
                            >
                                <InputNumber placeholder="0" className="width-100" controls={false}/>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                           
                            >
                                <Input placeholder="Descripción" ></Input>

                            </Form.Item>
                        </Col>

                    </Row>

                    <Row >
                        <Col span={24}>
                            <Form.Item className="text-center center">
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>Servicio</Title>
        </div>
        <ModalServicios {...props} />
    </Modal>

}
import React, { Component } from "react";
import { message, Row, Col, Spin, Empty } from "antd";
import { CardCentroDistribucion } from "../../../Widgets/Cards";

const axios = require('axios')



/**
 *
 *
 * @export
 * @class Centros
 * @extends {Component}
 * @description Vista del listado de Centros
 */
class Centros extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            centros: {

                data: [],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                //filtros
                proveedor_id: this.props.id
            },
            modal_visible: false,
            centro_id: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCentros()
    }

    /**
    *
    *
    * @memberof Centros
    * @description Obtiene los centros de distribucion del proveedor
    */
    getCentros = (
        {
            page = this.state.centros.page,
            limit = this.state.centros.limit,
            proveedor_id = this.props.id
        } = this.state.centros,
        { centros } = this.state

    ) => {
        console.log('proveedor', this.props.id)
        centros.page = page;
        centros.limit = limit;
        this.setState({ loading: true, centros });
        let search = this.props.search;
        axios.get('/proveedores/centros-distribucion/list', {
            params: {
                search,
                page,
                proveedor_id
            }
        })
            .then(response => {
                console.log('response.data.data', response.data.data)
                centros.data = response.data.data.data
                centros.total = response.data.data.total
                centros.pages = response.data.data.pages
                this.setState({ centros })
            })
            .catch(error => {
                message.error('Error al Cargar las Centros')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
     *
     *
     * @memberof Centros
     * @param id ObjectId del centro de distribucion del proveedor
     */
    deleteCentro = (id) => {
        axios.delete('/proveedores/centros-distribucion/delete', {params:{ id: id }})
            .then((response) => {
                console.log("delete ?",response)
                message.success('Centro de Distribución Eliminado')
            }).catch((error) => {
                message.error('Centro de Distribución NO Eliminado')
            })
            .finally(() => this.getCentros())
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row className="width-100"  gutter={[16, 16]}>
                    {
                        this.state.centros.data.length > 0 ?
                            (this.state.centros.data?.map(item => <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                                <CardCentroDistribucion
                                    item={item}
                                    cliente_id={this.props.proveedor_id}
                                    onview={() => this.setState({ modal_visible: true, centro_id: item._id })}
                                    ondelete={() => this.deleteCentro(item._id)}
                                    urlRedirect={`/admin/proveedores/detalle/${this.props.id}/centro-distribucion/${item._id}`} />
                            </Col>
                            ))
                            : <Empty
                                className="text-center center width-100"
                                description={<span>Sin Centros de Distribución</span>}
                            />
                    }
                    
                </Row>

            </Spin>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {
    return (<Centros {...props} />)
}

import React, { Component } from "react";
import { Button, Col, Space, List, Row, Typography, Pagination, message } from "antd";

import { CardEmbarque, CardEntrega, CardLegalInfo, CardProjectInfo, CardUtilidad } from "../../../Widgets/Cards";
import ModalEmbarque from "./ModalEmbarque";
import { useNavigate, useParams } from "react-router-dom";
import {Proyecto} from "../../../../Hooks/Proyecto";

const { Title } = Typography

const axios = require('axios')
/**
 *
 *
 * @export
 * @class OrdenEmbarque
 * @extends {Component}
 */
class OrdenEmbarque extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            orden_compra_id: undefined,
            ordenes: {
                data: [{
                    _id: 1,
                    fecha: new Date(),
                    folio: '0001',
                    order: 10012,
                    firma: true,
                    recibido: 'Juan Perez'
    
                },
                {
                    _id: 2,
                    fecha: new Date(),
                    folio: '0002',
                    order: 10012,
                    firma: false,
                    recibido: 'Juan Perez'
    
                }],
                loading: false,
                page: 1,
                limit: 10,
                total: 10
            }

        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenesCompra();
    }

    getOrdenesCompra = ({
        page = this.state.ordenes.page,
        limit = this.state.ordenes.limit
    } = this.state.ordenes) => {
        return axios.get('/orden-compra/list', {
            params: {
                proyecto_id: this.props.proyecto_id,
                page,
                limit
            }
        })
            .then(({ data }) => this.setState(state => {
                state.ordenes = {
                    ...state.ordenes,
                    ...data
                }
                return state
            }))
            .catch(err => {
                message.error("Error al obtener las ordenes de compra")
            })
    }

    updateProyectoEstatus = () => {
        axios.put('/proyectos', {
            id:  this.props.proyecto_id,
            status_logistica: 2
        }).then(({ data }) => {
            message.success("Proyecto actualizado")
            this.props.navigate(`/admin/project-manager/manager/transportes/${this.props.proyecto_id}`)
        }).catch(err => {
            message.error("Error al actualizar el proyecto")
        })
    }

    render() {
        return (
            <>
                <Col flex="auto" >
                    <Row gutter={[16, 16]}>
                        <Col span={8} lg={8} md={24} >
                            <CardProjectInfo 
                                quote_day={this.props.proyecto?.createdAt}
                                nombre={`${this.props.proyecto?.cliente_id?.usuario?.nombre} ${this.props.proyecto?.cliente_id?.usuario?.apellido}`}
                                empresa={this.props.proyecto?.cliente_id?.razon_social}
                                telefono={this.props.proyecto?.cliente_id?.telefono}
                                correo={this.props.proyecto?.cliente_id?.usuario?.email}
                                avatar_props={{
                                    image: this.props.proyecto?.agente_compras_id?.avatar,
                                    name: `${this.props.proyecto?.agente_compras_id?.nombre} ${this.props.proyecto?.agente_compras_id?.apellido}`,
                                }}
                                rfq={this.props.proyecto.rfq_id}
                            />
                        </Col>
                        <Col span={8} lg={8} md={12}>
                            <Row gutter={[0, 16]}>
                                <Col span={24}>
                                    <CardUtilidad margen={this.props.proyecto?.margen}/>
                                </Col>
                                <Col span={24}>
                                    <CardLegalInfo proyecto_id={this.props.proyecto_id} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} lg={8} md={12}>
                            <CardEntrega />
                        </Col>
                    </Row>
                    
                    <Row gutter={[16, 16]}>
                        
                        <Row justify="space-between " className="width-100 mt-1">
                            <Col><Title level={3}>Embarcación de Mercancia</Title></Col>
                            <Col>
                                <Space size={10}>
                                    <Button
                                        type="primary"
                                        disabled={this.props.proyecto?.status_logistica >=2}
                                        style={{backgroundColor: "#D6009A", borderColor: "#D6009A"}}
                                        size="large"
                                        onClick={() => this.updateProyectoEstatus()}
                                    >
                                        <span style={{ fontWeight: 400 }}>Embarcación Completo</span>
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        <Col span={24}>
                            <List
                                className="component-list medium-list"
                                itemLayout="horizontal"
                                dataSource={this.state.ordenes.data}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <CardEmbarque
                                            orden = {item}
                                           onEdit = {() => this.setState({modalVisible: true, orden_compra_id: item._id})}
                                           onDelete = {() => this.deleteEmbarque(item._id)}
                                        />
                                    </List.Item>
                                )}

                            />
                                
                            <Pagination
                                    hideOnSinglePage={true}
                                    defaultCurrent={this.state.ordenes.page}
                                    current={this.state.ordenes.page}
                                    defaultPageSize={this.state.ordenes.limit}
                                    onChange={(page) => this.getOrdenesCompra({page})}
                                    total={this.state.ordenes.limit}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} registros`}
                                />
                        </Col>
                    </Row>
                </Col>
                <ModalEmbarque
                    visible={this.state.modalVisible}
                    orden_compra_id={this.state.orden_compra_id}
                    onClose={() => {
                        this.setState({ modalVisible: false, orden_compra_id: undefined })
                        this.getOrdenesCompra()
                    }}
                    //orden={this.state.orden}
                />
            </>
        )
    }
}

export default props => {

    const { proyecto_id } = useParams()
    const navigate = useNavigate()

    let {margen, ...proyecto} = React.useContext(Proyecto)

    let {ingresos, costos} = margen || {}
    margen = ingresos !== undefined && costos !== undefined ? ((ingresos - costos) / ingresos) * 100 : 0

    return <OrdenEmbarque {...props} proyecto_id={proyecto_id} navigate={navigate} proyecto={{margen, ...proyecto}}/>
}
import React from 'react'
import { Route, Routes } from "react-router-dom";
import Negocios from '../components/Admin/Negocios/Negocios';


/**
 *
 *
 * @export
 * @function RouterNegocios
 */
function RouterNegocios( props) {
    return (
        <Routes>
            <Route path="" element={<Negocios {...props} />} />
        </Routes>
    )
}

export default RouterNegocios;
import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const AreaSelect
 * @description Select para las areas registrados en el sistema
 */
const AreaSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el Area", onSelect = () => {}, disabled = false, className = "" } = props

    const [ areas, setAreas ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getAreas
     * @description Obitiene las areas
     */
    const getAreas = ({
        page = areas.page, 
        limit = areas.limit, 
        search = areas.search,
    } = areas) => {

        axios.get('/areas/list', {
            params: { 
                page, 
                limit, 
                search 
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setAreas({
                ...areas,
                data: response.data.data,
                total: response.data.total,
            })

        }).catch(error => {
            message('Error al obtener las areas')
        })
    }

    //componentDidMount
    useEffect(() => {
        getAreas()
    }, [])

    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getAreas({search})}
            onSelect={(area)=> {
                onChange(area.value)
                onSelect(area.value)
            }}
            value={value}
        >
            {
                areas.data.map(area => <Option value={area._id} key={area._id}>{area.nombre}</Option>)
            }
        </Select>
    );
}



export default AreaSelect;
import React, { Component, useState, createContext } from "react";
import { Button, Row, Radio, Space, Col, Modal, Typography, Form, Input, Image, Upload, InputNumber, message } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";

import "../../../../Styles/Modules/Public/marketplace.css";
import "../../../../Styles/Modules/Public/modal-agregar-producto.css";

const { Title } = Typography;

class AgregarProducto extends Component {
    formRef = createContext();

    constructor(props) {
        super(props);
        this.state = {
            product: {},
            form_disabled: {
                capacidad_si: true,
                material_si: true,
                color_si: true,
                especificaciones_electricas_si: true,
                personalizacion_si: true

            },
            fotografias: {
                loading: false,
                hasChanged: false
            },
            logo: {
                loading: false,
                hasChanged: false
            },
            documentos: {
                loading: false,
                hasChanged: false
            }
        };
    }

    componentDidMount() {
        this.loadForm();
    }

    /**
     *
     *
     * @memberof AgregarProducto
     * @description En caso de editar un producto, esta funcion carga al formulario con los datos disponibles
     */
    loadForm = () => {
        if (this.props.productId === null) return;

        let id = this.props.productId;
        let product;

        if (this.props.marketplace) product = { ...this.props.products[id] }
        if (this.props.fob) product = { ...this.props.products.find(p => p._id === id) }

        if (product.fotografias) product.fotografias = product.fotografias = product.fotografias.map((foto, index) => ({
            uid: index + 1,
            url: axios.defaults.baseURL + '/upload/' + foto,
            response: {
                filename: foto,
            },
            name: foto,
            file: foto,
            status: "done"
        }))

        if (product.logo) product.logo = [{
            uid: 1,
            url: axios.defaults.baseURL + '/upload/' + product.logo,
            response: {
                filename: product.logo
            },
            name: product.logo,
            file: product.logo,
            status: "done"
        }]

        if (product.documentos) product.documentos = product.documentos.map((doc, index) => ({
            uid: index + 1,
            url: axios.defaults.baseURL + '/upload/' + doc,
            response: {
                filename: doc
            },
            name: doc,
            file: doc,
            status: "done"
        }))

        let radios = {}

        if(product.capacity !== undefined) radios.capacidad_si = true
        if(product.material_composition !== undefined) radios.material_si = true
        if(product.color !== undefined) radios.color_si = true
        if(product.electrical_especifications !== undefined) radios.especificaciones_electricas_si = true

        console.log(product)
        
        this.formRef.current?.setFieldsValue({ ...product, ...radios })
        this.setState({form_disabled: {...this.state.form_disabled, ...radios}})
    };

    /**
     *
     *
     * @param {object} values Valores actualizados
     * @param {object} allValues Todos los valores
     * @memberof AgregarProducto
     * @description Maneja los campos opcionales y actualiza el state en base al formulario
     */
    onFormChange = (values, allValues) => {
        const {
            capacidad_si,
            material_si,
            color_si,
            especificaciones_electricas_si,
            personalizacion_si
        } = allValues;

        this.setState({
            form_disabled: {
                capacidad_si,
                material_si,
                color_si,
                especificaciones_electricas_si,
                personalizacion_si
            },
        });

        let uncheckedValues = {}

        if(!capacidad_si) uncheckedValues.capacity = undefined
        if(!material_si) uncheckedValues.material_composition = undefined
        if(!color_si) uncheckedValues.color = undefined
        if(!especificaciones_electricas_si) uncheckedValues.electrical_especifications = undefined

        this.formRef.current?.setFieldsValue({
            ...allValues,
            ...uncheckedValues
        })
    };

    /**
     *
     *
     * @param {object} producto
     * @memberof AgregarProducto
     * @description Crea un nuevo producto para cotizar
     */
    saveProduct = (producto) => {

        axios.post('/project-manager/listado-producto', {
            ...producto,
            rfq_id: this.props.rfqId,
            rfq: true
        }).then(response => {
            const productos = [...this.props.products]
            const data = response.data

            productos.push(data)
            this.props.onSuccess(productos)

            message.success('Producto Creado')

            this.props.setVisible(false)
        }).catch(error => {
            console.log(error)
            message.error('Error al crear')
        })
    }

    /**
    *
    *
    * @param {object} producto
    * @memberof AgregarProducto
    * @description Actualiza los datos del producto a cotizar
    */
    updateProduct = (producto) => {

        axios.put('/project-manager/listado-producto', {
            ...producto,
            rfq: true
        }).then(response => {
            let productos = [...this.props.products]
            const data = response.data

            productos = productos.map(p => {
                if (p._id === data._id) return data
                return p
            })

            this.props.onSuccess(productos)

            message.success('Producto Actualizado')

            this.props.setVisible(false)
        }).catch(error => {
            console.log(error)
            message.error('Error al crear')
        })
    }

    /**
    *
    *
    * @param {object} values
    * @memberof AgregarProducto
    * @description Procesa los datos del formulario al emitir el submit
    */
    onSave = (values) => {
        const { productId, setProducts, setVisible } = { ...this.props };
        const products = [...this.props.products];

        values.fotografias = 
            Array.isArray(values.fotografias) && values.fotografias?.length > 0
                ? values.fotografias.map(file => file.file)
                : [];
        values.logo =
            Array.isArray(values.logo) && values.logo?.length > 0
                ? values.logo[0].file
                : undefined;
        values.documentos =
            Array.isArray(values.documentos) && values.documentos?.length > 0
                ? values.documentos.map(file => file.file)
                : [];

        console.log("VALUES", values)

        
        if (this.props.fob && productId !== undefined) {
            let product = products.find(p => p._id === productId)
            this.updateProduct({
                ...product,
                ...values
            })
            setVisible(false);
            return
        } else if (this.props.fob) {
            this.saveProduct(values)
            setVisible(false);
            return
        }
        
        if (productId !== undefined) {
            products[productId] = values;
        } else {
            products.push(values);
        }
        
        if (this.props.marketplace) {
            setProducts(products);
            setVisible(false);
        }

    };

    /**
     *
     * @memberof ModalAgregarProducto
     *
     * @method normFile
     * @description Realiza acciones durante el cambio de estatus de un archivo.
     *
     * @param {object} e Objecto con los archivos del evento
     * @param {string} key Propiedad del objecto en el state que maneja los datos del input
     */
    normFile = (e, key) => {

        const { file, fileList } = e;

        if (file.status === "uploading"){
            this.setState(state => {
                state[key].loading = true
                return state
            })
        }

        if (file.status === "error") {
            message.error("No se pudo subir el archivo")
            this.setState(state => {
                state[key].loading = false
                return state
            })
        }
        

        if (file.status === "done") {
            for (let x = 0; x < fileList.length; x++) {
                if (fileList[x].response) {
                    //e.fileList[x].name = fileList[x].response.filename
                    e.fileList[x].file = fileList[x].response.filename
                    e.fileList[x].name = fileList[x].originFileObj?.name || fileList[x].response.filename
                }
            }

            console.log(e, e.fileList)

            this.setState({
                [key]: {
                    hasChanged: true,
                    loading: false
                }
            })
        }
        
        return e && e.fileList;
    };

    /**
     *
     * @memberof ModalAgregarProducto
     *
     * @method removeFile
     * @description Elimina un archivo del servidor
     *
     * @param {object} file Recibe el objecto del archivo.
     */
    removeFile = ({name}) => {
        axios.post("/upload/delete", {
            filename: name
        })
            .then(res => {
                console.log("Archivo removido con éxito");
            })
            .catch(res => {
                console.log("No se pudo remover el archivo");
            })
    };

    render() {

        return (
            <Form
                layout="vertical"
                onValuesChange={this.onFormChange}
                onFinish={this.onSave}
                initialValues={{
                    prioridad: 1,
                    ...this.state.form_disabled
                }}
                ref={this.formRef}
            >
                <Row
                    justify="center"
                    gutter={[24, 24]}
                    style={{ padding: "3em 219 2em 2em" }}
                >
                    <Col span={12}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Name Product & Model"
                            name="modelo"

                            rules={[
                                {
                                    required: true,
                                    message: "Debe ingresar el nombre del producto, y el modelo."
                                }
                            ]}
                        >
                            <Input placeholder="Kia Piston 2BX15PO" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Imagenes del Producto"
                            name="fotografias"
                            getValueFromEvent={(e) => this.normFile(e, "fotografias")}
                            valuePropName="fileList"

                        >
                            <Upload
                                className="width-100"
                                action={axios.defaults.baseURL + "/upload/add"}
                                multiple={true}
                                onRemove={(e) => this.removeFile(e)}
                            >
                                <Button
                                    block
                                    loading={this.state.fotografias.loading}
                                    icon={<UploadOutlined />}
                                    {...(this.props.fob ? {} : {
                                        type: "primary",
                                        style: {
                                            backgroundColor: "rgb(6, 144, 251)",
                                            textAlign: "start"
                                        }
                                    })}
                                >
                                    Subir Imagenes
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Brief Description"
                            name="descripcion"
                            rules={[
                                {
                                    required: true,
                                    message: "Debe de ingresar la descripción."
                                }
                            ]}
                        >
                            <Input.TextArea placeholder="Ingresa la descripcion" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Quantity"
                            name="cantidad"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingrese la cantidad."
                                }
                            ]}

                        >
                            <InputNumber placeholder={15} controls={false} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ficha Técnica"
                            name="documentos"
                            getValueFromEvent={(e) => this.normFile(e, "documentos")}
                            valuePropName="fileList"
                            rules={[{
                                required: true,
                                message: "Suba los documentos"
                            }]}
                        >
                            <Upload
                                className="width-100"
                                action={axios.defaults.baseURL + "/upload/add"}
                                multiple={true}
                                onRemove={(e) => this.removeFile(e)}
                            >
                                <Button
                                    block
                                    loading={this.state.documentos.loading}
                                    icon={<UploadOutlined />}
                                    {...(this.props.fob ? {} : {
                                        type: "primary",
                                        style: {
                                            backgroundColor: "rgb(6, 144, 251)",
                                            textAlign: "start"
                                        }
                                    })}
                                >
                                    Subir Documentos
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[12, 12]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Width"
                                    className="agregar-producto-col-input"
                                    name={["measurements", "width"]}
                                    extra={"(e.g. mm, cm, etc.) "}
                                    rules={[{
                                        required: true,
                                        message: "Ingrese el ancho"
                                    }]}
                                >
                                    <Input
                                        placeholder="Width"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Length"
                                    className="agregar-producto-col-input"
                                    name={["measurements", "length"]}
                                    extra={"(e.g. mm, cm, etc.) "}
                                    rules={[{
                                        required: true,
                                        message: "Ingrese el largo"
                                    }]}
                                >
                                    <Input
                                        placeholder="Length"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Height"
                                    className="agregar-producto-col-input"
                                    name={["measurements", "height"]}
                                    extra={"(e.g. mm, cm, etc.) "}
                                    rules={[{
                                        required: true,
                                        message: "Ingrese el alto"
                                    }]}
                                >
                                    <Input
                                        placeholder="Height"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={14}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    label="Capacity"
                                    name="capacidad_si"
                                >
                                    <Radio.Group>
                                        <Space>
                                            <Radio value={true}>Sí</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    name="capacity"
                                    extra={"(e.g. liters, ounces. etc.) "}
                                >
                                    <Input
                                        disabled={!this.state.form_disabled?.capacidad_si}
                                        placeholder="Capacidad"
                                        style={{ marginTop: "15px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={14}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    label="Material / Composition"
                                    name="material_si"
                                >
                                    <Radio.Group>
                                        <Space>
                                            <Radio value={true}>Si</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    name="material_composition"
                                    extra={"(e.g. steal, polymer, etc) "}
                                >
                                    <Input
                                        disabled={!this.state.form_disabled?.material_si}
                                        placeholder="Material / Composition"
                                        style={{ marginTop: "15px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={14}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    label="Color"
                                    name="color_si"
                                >
                                    <Radio.Group>
                                        <Space>
                                            <Radio value={true}>Sí</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    name="color"
                                    extra={"(e.g. red, white, etc.) "}
                                >
                                    <Input
                                        disabled={!this.state.form_disabled?.color_si}
                                        placeholder="Color"
                                        style={{ marginTop: "15px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Use or Function"
                            name="use_funcion"
                        >
                            <Input.TextArea placeholder="Ingresa la función del dispositivo" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Row>
                            <Col span={14}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    label="Electrical Specifications"
                                    name="especificaciones_electricas_si"
                                >
                                    <Radio.Group>
                                        <Space>
                                            <Radio value={true}>Sí</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    name="electrical_especifications"
                                    extra={"(V, HZ, A, W)"}
                                >
                                    <Input
                                        disabled={!this.state.form_disabled?.especificaciones_electricas_si}
                                        placeholder="12 v left 15 v rigth 25 v powersource"
                                        style={{ marginTop: "15px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Is the product requires too be the same or similar?"
                            name="same_similar"
                        >
                            <Radio.Group>
                                <Space>
                                    <Radio value={true}>Sí</Radio>
                                    <Radio value={false}>No</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className="agregar-producto-col-input"
                                    label="Customization"
                                    name="customatization"
                                >
                                    <Input.TextArea
                                        placeholder="Left male entry point, white conector PJ45"
                                        style={{ marginTop: "15px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Row>
                            <Col span={18}>
                                <Row className="width-100">
                                    <Col span={12}>
                                        <Form.Item
                                            className="agregar-producto-col-input"
                                            label="Requires Customization"
                                            name="personalizacion_si"
                                        >
                                            <Radio.Group>
                                                <Space>
                                                    <Radio value={true}>
                                                        Sí
                                                    </Radio>
                                                    <Radio value={false}>
                                                        No
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label="Imagen para personalizar"
                                            name="logo"
                                            getValueFromEvent={(e) => this.normFile(e, "logo")}
                                            valuePropName="fileList"
                                        >
                                            <Upload
                                                className="width-100"
                                                action={axios.defaults.baseURL + "/upload/add"}
                                                multiple={true}
                                                maxCount={1}
                                                onRemove={(e) => this.removeFile(e)}
                                            >
                                                <Button
                                                    block
                                                    loading={this.state.logo.loading}
                                                    icon={<UploadOutlined />}
                                                    {...(this.props.fob ? {} : {
                                                        type: "primary",
                                                        style: {
                                                            backgroundColor: "rgb(6, 144, 251)",
                                                            textAlign: "start"
                                                        }
                                                    })}
                                                >
                                                    Subir Imagen
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="width-100"></Row>
                            </Col>
                            <Col span={6}>
                                <Image />
                            </Col>
                        </Row>
                    </Col>

                   
                </Row>
                <Row justify="center">
                    <Col
                        xs={{ span: 22 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "2rem",
                        }}
                    >
                        <Form.Item>
                            <Button
                                {...(this.props.fob ? {
                                    type: "primary"
                                } : {
                                    className: "agregar-producto-button"
                                })}
                                htmlType="submit"
                            >
                                GUARDAR
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default function ModalAgregarProducto(props) {
    let [image, setImage] = useState(undefined);

    return (
        <Modal
            title={null}
            footer={null}
            closable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={700}
            visible={props.visible}
            onCancel={() => props.onCancel(ImageData)}
            className={props.fob ? '' : "agregar-producto"}
        >
            <Row className="mb-2">
                <Col xs={2}>
                    {
                        !props.fob && <CloseOutlined
                            onClick={() => props.onCancel(image)}
                            style={{ fontSize: "2.5rem" }}
                        />
                    }
                </Col>
                <Col xs={20} style={{ textAlign: "center" }}>
                    <Title level={2}>Producto a Cotizar</Title>
                </Col>
            </Row>
            <AgregarProducto {...props} setImage={setImage} />
        </Modal>
    );
}
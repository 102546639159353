import React, { useState, useEffect } from 'react';
import { Input } from 'antd'


const PhoneInput = (props) => {

    const { value, onChange } = props

    const [inputValue, setInputValue] = useState(value)

    const handleInput = (e) => {
        const phone = formatNumber(e.target.value)
        setInputValue(phone)
        onChange(phone)
    }

    useEffect(() => {
        setInputValue(value)
    }, [value])

    return (
        <Input onChange={(e=>handleInput(e))} value={inputValue}/>
    );
}

function formatNumber(value){
	if(!value) return value

	const phoneNumber = value.replace(/[^\d]/g,'');
    const phoneNumberLength = phoneNumber.length;

    if(phoneNumberLength < 4) return phoneNumber;
    if(phoneNumberLength < 7){
        return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6,10)}`
}


export default PhoneInput;
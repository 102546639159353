import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Categorias from '../components/Admin/Categorias/Categorias'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCategorias(props) {

    return (
        <Routes>
            <Route path="" element={<Categorias {...props} />} />
        </Routes>
    )
}

export default RouterCategorias;

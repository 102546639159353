import React from 'react'
import { Row, Col, Space, Button, List, Typography, Card, Popconfirm } from "antd";

//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconDelete, IconEdit, IconPDF } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//Modal
import ModalReporte from '../Modals/ModalReporte';

const {Text} = Typography

export default class ReportesTab extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            reporte_id: undefined,
            reportes: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,
                loading: false
            }
        }
    }

    render(){
        return(
            <>
                <List
                    loading={this.state.reportes.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    dataSource={this.state.reportes.data}
                    pagination={{
                        current: this.state.reportes.page,
                        total: this.state.reportes.total,
                        pageSize: this.state.reportes.limit,
                        showSizeChanger: true,
                        onChange: (page, limit) => {}
                    }}

                    header={<Row className="header-list width-100 pl-1 pr-1">
                        <Col xs={24} md={5}>
                            <Text strong >Responsable</Text>
                        </Col>
                        <Col xs={24} md={12}>
                            <Text strong >Descripción</Text>
                        </Col>
                        <Col xs={12} md={4}>
                            <Text strong >Tipo</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                        </Col>
                    </Row>
                    }

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100" align='middle'>
                                    <Col xs={24} md={5} >
                                        <Space spacing={16}>
                                            <CustomAvatar
                                                size="default"
                                                name={["N", "A"]}
                                            />
                                            <Text strong>{"{{ Nombre }}"}</Text>
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={12} >
                                        <Text strong>{"{{ Descripción }}"}</Text>
                                    </Col>
                                    <Col xs={12} md={4}>
                                    <Text strong>{"{{ Tipo }}"}</Text>
                                    </Col>
                                    <Col xs={12} md={3} className="center">
                                        <Space>
                                            <Button
                                                icon={<IconPDF />}
                                                type="primary"
                                                title="Ver Detalle"
                                                style={{backgroundColor: "#05CC19", borderColor: "#05CC19"}}
                                                onClick={() => {}}>
                                            </Button>
                                            <Button
                                                icon={<IconEdit />}
                                                type="primary"
                                                title="Editar"
                                                style={{backgroundColor: "#0022A9", borderColor: "#0022A9"}}
                                                onClick={() => this.setState({ visible_modal: true, reporte_id: item._id })}>
                                            </Button>
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar este Reporte?"
                                                onConfirm={() => {}}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button type="primary" style={{backgroundColor: "#FF547D", borderColor: "#FF547D"}} icon={<IconDelete />} title="Eliminar" />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}

                />

                <FloatingButton title="Nuevo Reporte" onClick={() => this.setState({ visible_modal: true })}/>

                <ModalReporte
                    id = {this.state.reporte_id}
                    visible = {this.state.visible_modal}
                    hideModal = {() => {
                        this.setState({visible_modal: false})
                    }}
                    accept = {() => {
                        this.setState({visible_modal: false})
                    }}
                    update = {() => {}}
                />
            </>
        )
    }
}
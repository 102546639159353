import React, { Component } from 'react';
import { Button,  Row, Col, Typography, Space } from 'antd';

import {  PlusOutlined } from '@ant-design/icons';

import { CardProducto } from '../../../Widgets/Cards';
import '../../../../Styles/Modules/Public/marketplace.css';
const { Title, Paragraph } = Typography;

export default class StepCotizador extends Component {
    

    deleteProduct = (index) => {
        const products = [];

        this.props.products.forEach((product, idx) => {
            if(idx !== index){
                products.push(product);
            } else if (product.imagen){
                this.props.deleteImage(product.imagen);
            }
        });

        this.props.setProducts(products);
    }

    render() {

        const { review } = this.props

        return (
            <>
                <Title level={3} style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                    Información de cotización
                </Title>
                <Row justify="space-between" style={{ alignItems: "center" }}>
                    <Col>
                        <Title level={4} style={{ fontSize: "1.25rem", fontWeight: 400, marginBottom: 0 }}>
                            {
                                !review ? "Lista de Productos" : "Los productos estan en orden?"
                            }
                        </Title>
                    </Col>
                    {
                        !review ? (
                        <Col>
                            <Button className="cotizador-button-add" onClick={() => this.props.openModal()}>
                                Agregar Producto <PlusOutlined />
                            </Button>
                        </Col>
                        ) : null
                    }
                </Row>
                <Row justify="center">
                    
                    {
                        this.props.products.length < 1 && !review ? (
                        <Col style={{textAlign: "center", margin: "4.5rem 0"}}>
                            <Paragraph style={{fontSize: "1.125rem", marginBottom: 0}}>
                                No hay ningun producto a cotizar
                            </Paragraph>
                            <Button 
                                className="cotizador-text-add" 
                                style={{fontSize: "1.125rem"}}
                                onClick={() => this.props.openModal()}
                            >
                                ¡Agregar Uno!
                            </Button>
                        </Col>
                        ) : (
                        <Col span={24} style={{ margin: "2.5rem 0" }}>
                            <Space direction="vertical" style={{width:"100%"}} size={16}>
                                {
                                    this.props.products.map((product, index) => (
                                        <CardProducto
                                            producto={product}
                                            onEditClick={() => this.props.openModal(index)}
                                            onDeleteClick={() => this.deleteProduct(index)}
                                        />
                                    ))
                                }
                            </Space>
                        </Col>
                        )
                    }
                </Row>
            </>
        )
    }
}
import React, { Children, Component } from "react";
import { Button, Col, Row, Popconfirm, Spin, Table, PageHeader, Layout, message, Space, Tag, 
	Image, Typography, Select, Collapse, Form, List, InputNumber, Input, Checkbox, Radio, } from "antd";
import {
	IconCheck,
	IconCheckGreen,
	IconDelete,
	IconEdit,
} from "../../../Widgets/Iconos";
import { CheckOutlined } from "@ant-design/icons";
import FormTable from "./SeccionesBase/FormTable";
import FormTableDescuentos from "./SeccionesBase/FormTableDescuentos";

import BaseList from "./Base.json";
import BaseCampo from "./BaseCampo";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const axios = require('axios')

export default class Base extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ocultos: false,
			allPanels: ["0", "1", "2", "3", "4", "5", "6", "7"],
			panels: BaseList,
			proyecto: {},
			hiddenPanels: {} 
		};
	}

	componentDidMount() {
		this.setState({ valoresIniciales: this.props.valoresIniciales },  () => this.loadPanels());
		this.getProyecto()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps != this.props) {
			this.setState({ valoresIniciales: this.props.valoresIniciales }, () => this.loadPanels());
		}

		if (prevState.ocultos != this.state.ocultos){
			this.loadPanels()
		}
	}

	/**
	 * @memberof Base
	 *
	 * @method getProyecto
	 * @description obetenmos la informacion del proyecto
	 */
	getProyecto = () => {
		this.setState({ loading: true });
		axios.get("/project-manager/proyecto", {
			params: {
				id: this.props.proyecto_id,
				detalles:true,
				proveedor:false
			},
		})
		.then((response) => {
			let proyecto = response.data
			this.setState({
				proyecto: proyecto,
			});
		})
		.catch((error) => {
			console.log(error);
			message.error("Error al cargar Proyecto");
		})
		.finally(() => this.setState({ loading: false }));
	}


	loadPanels = () => {
		// let panels = []

		// if(this.state.ocultos) 
		// 	panels = BaseList
		// else
		// 	panels = BaseList.filter(({fields, section}) => {
		// 		let checks = []
		// 		for(let {requiere, opcional} of fields){
		// 			let { required, optional } = this.checkRequerimientos(requiere, opcional)
		// 			checks.push(required || optional) 
		// 		}

		// 		return checks.find(check => check === true)
		// 	})

		// this.setState({panels})
	}

	/**
	 * @memberof Base
	 *
	 * @method updateInfo
	 * @description actualiza la informacion del proyecto
	 */
	updateInfo = ({sectionName, name, newData}) => {
		let proyecto = this.state.proyecto
		let section = this.state.proyecto[sectionName] ? this.state.proyecto[sectionName] : {}
		section[name] = newData[sectionName][name]
		proyecto[sectionName] = section
		this.state.proyecto = proyecto
	}

	/**
	 * @memberOf Base
	 * @descripcion Oculta todos los componentes BaseCampos que no esten activos
	 * 
	 * */
	toggleHidden = (e) => {
		this.setState({ ocultos: e.target.checked });
	};


	checkRequerimientos = (requerimientos = {}, opcionales = {}) => {
		const { valoresIniciales } = this.state;

		let checks = []
		let flags = {}

		for(let key in requerimientos){ 
			checks.push(requerimientos[key].includes(valoresIniciales[key]))
		}

		flags.required = checks.every((check) => check === true)

		if(Object.keys(opcionales)?.length > 0){
			checks = []
			
			for(let key in opcionales){ 
				checks.push(opcionales[key].includes(valoresIniciales[key]))
			}

			flags.optional = checks.every((check) => check === true)
		}

		return flags
	}


	render() {
		//Aqui se renderizan los componentes del formulario
		//Se debe de pasar el contenido de cada seccion, el concepto y el id de cada item de la forma
		//El componente debe de recibir un array de objetos
		//El objeto que el componente recibe es el siguiente:


		return (
			<Spin spinning={false}>
				<Content style={{ margin: "2em", paddingBottom: '1rem' }}  align="end">
					<Row >
						<Space className=" mb-1" style={{ float: "left" }} size={30}>
							<Radio.Group size="small" options={[{ label: "Expandir Todo", value: 1 }, { label: "Contraer Todo", value: 2 }]} optionType="button" buttonStyle="solid" onChange={(va) => this.setState({ activos: ((va.target.value === 1) ? this.state.allPanels : []) })} />
							<Checkbox defaultChecked={false} onChange={this.toggleHidden}>
								¿ Desea mostrar todos los campos ocultos ?
							</Checkbox>
							<Select size="small" defaultValue={1}>
								<Option value={1}>Contenedor 20DC</Option>
								<Option value={2}>Contenedor 40DC</Option>
								<Option value={3}>Contenedor 40HQ</Option>
							</Select>
						</Space>
					</Row>
					<Row>
						<Col span={24} >
							{/*<Form
								name="datos-base"
								size="small"
								className="datos-base "
								onValuesChange={(valoresCambiados, valoresActuales) => {
									console.log("A", valoresActuales);
								}}
							>*/}
								<Collapse
									className="base-collapse"
									bordered={false}
									destroyInactivePanel={true}
									expandIconPosition={"end"}
									activeKey={this.state.activos}
									onChange={(e) => this.setState({ activos: e })}
								>
									{this.state.panels.map((field, indexField) => (
										<Panel
											key={indexField}
											header={
												<Space size={10}>
													<Text className="collapsable-title">
														{field.section} 
													</Text>
													{
														field.abbreviation && (
															<Text className="collapsable-abbreviation">
																({field.abbreviation}) 
															</Text>
														)
													}
												</Space>
											}
										>
											<List
												className="component-list width-100 form-list"
												locale={{ emptyText: "No hay proveedores" }}
												dataSource={field.fields}
												header={
													<Row className="header-list width-100 mt-2 mb-1">
														<Col span={1}>
															
														</Col>
														<Col span={4} className="flex-left">
															<Text strong>Concepto</Text>
														</Col>
														<Col span={3} className="center ">
															<Text strong>Tarifa Base</Text>
														</Col>
														<Col span={3} className="center ">
															<Text strong>Proporción</Text>
														</Col>
														<Col span={3} className="center ">
															<Text strong>Tarifa</Text>
														</Col>
														<Col span={2} className="center ">
															<Text strong>Tipo</Text>
														</Col>
														<Col span={3} className="center ">
															<Text strong>Base Prorrateo</Text>
														</Col>
														<Col span={4} className="center ">
															<Text strong>Especificaciones</Text>
														</Col>
													</Row>
												}
												renderItem={(
													{ name, fieldname, tipo, disabled, requiere, opcional , moneda},
													index
												) => {

													//const {required, optional} = this.checkRequerimientos(requiere, opcional)

													return (
														<>
															
																<BaseCampo
																	section={field.section}
																	sectionName={field.name}
																	//required={required}
																	moneda={moneda}
																	proyecto_id={this.props.proyecto_id}
																	proyecto={this.state.proyecto}
																	name={name}
																	fieldname={fieldname}
																	tipo={tipo}
																	show={this.state.ocultos}
																	disabled={disabled}
																	updateInfo={this.updateInfo}
																	
																/>
														
														
															
														</>
	
													)
												}}
											/>
										</Panel>
									))}
								</Collapse>

							{/*</Form>*/}
						</Col>
					</Row>
				</Content>
			</Spin>
		);
	}
}

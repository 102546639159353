import React, { useState } from 'react'
import { Route, Routes,  } from "react-router-dom";

import { Modal } from "antd";
import Contabilidad from '../components/Admin/Contabilidad/Contabilidad'
import RazonSocial from '../components/Admin/Contabilidad/RazonSocial'
import Facturas from '../components/Admin/Contabilidad/Facturas/Facturas'
import FacturasSyncfy from '../components/Admin/Contabilidad/Facturas/FacturasSyncfy'

const axios = require('axios');


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterContabilidad(props) {

    const [syncfyUsuarioId, setSyncfyUsuarioId] = useState(null);

    // setUserId
    /**
     *
     *
     * @param {*} userId
     * @description setUserId
     */
    const setUserId = async (userId) => {
        if (syncfyUsuarioId !== userId && userId !== undefined) {
            setSyncfyUsuarioId(userId)
            await startSession(userId)
        }
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method deleteSession
     * @description Eliminamos una sesión.
     */
    const deleteSession = () => {
        axios.defaults.headers.common['syncfy'] = sessionStorage.getItem('syncfy')
        return axios.delete('/syncfy/session', {

        })
            .then(({ data }) => {
                sessionStorage.removeItem('syncfy')
            })
            .catch(error => {
                console.log('Error al eliminar', error.response)
                if (error.response?.status === 403) {
                    Modal.warning({
                        title: error.response?.data?.data?.nombre,
                        content: error.response?.data?.data?.description,
                    })
                }
            })
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method startSession
     * @description Creamos una sesión para poder obtener las credenciales. Declaramos el token de synfy en la cabecera de axios, para que se envie siempre durante la solicitudes
     */
    const startSession = async (id = syncfyUsuarioId) => {
        if (sessionStorage.getItem('syncfy')) {
            try {
                await deleteSession()
            } catch (error) {
                console.log('error', error)
            }
        }

        return axios.get('/syncfy/session', {
            params: { id_user_syncfy: id }
        })
            .then(({ data }) => {
                sessionStorage.setItem('syncfy', data.token)
                axios.defaults.headers.common['syncfy'] = data.token
            })
            .catch((response) => {
                console.log('error', response)
            })
            .finally(() => { })
    }



    return (
        <Routes>
            {/* <Route exact path="/admin/contabilidad" render={props => <Contabilidad {...props} />} />
            <Route exact path="/admin/contabilidad/razon-social/sin-clasificar/:razon_social_id" render={(props) => <FacturasSyncfy key="route-sin-clasificar" setUserId={setUserId} {...props} />} />
            <Route exact path="/admin/contabilidad/facturas" render={() => <Facturas  {...props}/>} />
            <Route exact path="/admin/contabilidad/razon-social/:razon_social_id" render={props => <RazonSocial  {...props} />} /> */}

            <Route path="" element={<Contabilidad {...props} />} />
            <Route path="facturas" element={<Facturas  {...props}/>} />
            <Route path="razon-social/sin-clasificar/:razon_social_id" element={<FacturasSyncfy key="route-sin-clasificar" setUserId={setUserId} {...props} /> } />
            <Route path="razon-social/:razon_social_id" element={<RazonSocial  {...props} />} />
        </Routes>
    )
}

export default RouterContabilidad;

import React, { Component, createRef } from 'react';
import { Button, Layout, Image, Row, Col, Modal, Typography, Form, Input, Upload, InputNumber, Steps, Space, Radio } from 'antd';

import { Link } from "react-router-dom";

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import '../../../../Styles/Modules/Public/marketplace.css';

const { Title, Paragraph } = Typography;

export default class StepRFQ extends Component {

    formRef = createRef();

    constructor(props) { 
        super(props)
        this.state = {
            hasAccount: true
        }
    }

    componentDidMount = () => {
        this.formRef.current?.setFieldsValue(this.props.rfqData)
    }

    onFormChange = values => {
        this.props.setRFQData(values)
    }

    render() {
        return (
            <>
                <Title level={3} style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                    Información del Servicio
                </Title>
                <Form
                    layout="vertical"
                    onValuesChange={(_, values) => this.onFormChange(values)}
                    ref={this.formRef}
                >
                    <Row justify="space-between" style={{ margin: "3rem 0 2rem" }}>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="almacenaje"
                                label="Requiere Almacenaje"
                            >
                                <Radio.Group>
                                    <Radio value={true}>Si</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="financiamiento"
                                label="Requiere Financiamiento"
                            >
                                <Radio.Group>
                                    <Radio value={true}>Si</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="plazo"
                                label="Tiempo que Requerirá el Servicio (días)"
                                
                            >
                                <InputNumber controls={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="entrega"
                                label="Lugar de Entrega"
                                
                            >
                                <Input type="text" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}
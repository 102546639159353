import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Radio } from 'antd'
import { Link } from 'react-router-dom';

//componentes
import { CardInfoCustomerHeader } from '../../Widgets/Cards'
import { IconPdtCot, IconPdtAcp, IconPrdAcp, IconPrdPrc } from '../../Widgets/Iconos'
import { renderEstatusCotizacion } from '../../Utils'
//css
import '../../../Styles/Modules/Customers/pedidos.css';

const { Content } = Layout;
const { Text, Title } = Typography 
const moment = require('moment')
const axios = require('axios')

class Dashboard extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            proyectos: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                status_cliente: null
            },
            data:{
                aceptadas: 0,
                en_proceso: 0,
                pagado: 0,
                por_aceptar: 0,
                por_cotizar: 0,
                por_pagar: 0,
            }
        }
    }

    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProyectos()
    }


    /**
     * @methodOf  Areas
     * @method getProyectos
     *
     * @description Obtiene las areas
     * */
    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        status_cliente = this.state.proyectos.status_cliente,
    } = this.state.proyectos) => {
        this.setState({loading: true})
        axios.get('/customer/pedidos',{
            params:{
                page,
                limit,
                status_cliente,
            }
        }).then(response => {
            console.clear() 
            console.log("response", response.data);
            this.setState({
                proyectos: {...response.data.proyectos, status_cliente},
                data: response.data.data ? response.data.data : this.state.data
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))

    }

	render(){

        const { data } = this.state

		return (
			<>
				<Spin spinning={false}>

                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Mis Pedidos"
                    />
                    <Content className="admin-content content-bg pd-1">

                    	<Row gutter={[16, 16]}>
                    		<Col xs={24} md={6}>
                    			<CardInfoCustomerHeader
                                    bg_color={"linear-gradient(89.69deg, #F39034 2.03%, #FF2727 99.73%)"}
                                    titulo={"Pendientes a Cotizar"}
                                    texto={"Cotizaciones"}
                                    valor={data.por_cotizar}
                                    icon={<IconPdtCot/>}
                                />
                    		</Col>
                    		<Col xs={24} md={6}>
                                <CardInfoCustomerHeader
                                    bg_color={"linear-gradient(270deg, #003AD2 0%, #0097EC 100%)"}
                                    titulo={"Pendientes a Aceptar"}
                                    texto={"Cotizaciones"}
                                    valor={data.por_aceptar}
                                    icon={<IconPdtAcp/>}
                                />
                    		</Col>
                    		<Col xs={24} md={6}>
                                <CardInfoCustomerHeader
                                    bg_color={"linear-gradient(270.23deg, #00A843 0.19%, #1FD071 99.8%)"}
                                    titulo={"Aceptadas"}
                                    texto={"Cotizaciones"}
                                    valor={data.aceptadas}
                                    icon={<IconPrdAcp/>}
                                />
                    		</Col>
                    		<Col xs={24} md={6}>
                                <CardInfoCustomerHeader
                                    bg_color={"linear-gradient(269.97deg, #5900C9 0.02%, #9852F0 99.98%)"}
                                    titulo={"En Proceso"}
                                    texto={"Cotizaciones"}
                                    valor={data.en_proceso}
                                    icon={<IconPrdPrc/>}
                                />
                    		</Col>

                            <Col xs={24} md={18}>
                                <Card>
                                    <Row className='mb-1'>
                                        <Col xs={24} lg={6} className="flex-left">
                                            <Title level={5} className="m-0"> Cotizaciones Spot</Title>
                                        </Col>

                                        <Col xs={24} lg={18}>
                                            <Radio.Group
                                                className="float-right radio-cotizaciones"
                                                defaultValue={null}
                                                buttonStyle="solid"
                                                onChange={e => this.getProyectos({
                                                    status_cliente: e.target.value
                                                })}
                                            >
                                                <Radio.Button value={null}>Todas</Radio.Button>
                                                <Radio.Button value={1}>Por Cotizar</Radio.Button>
                                                <Radio.Button value={2}>Por Aceptar</Radio.Button>
                                                <Radio.Button value={3}>En Proceso</Radio.Button>
                                                <Radio.Button value={6}>Completadas</Radio.Button>
                                            </Radio.Group>
                                        </Col>

                                    </Row>

                                    <List
                                        loading={this.state.loading}
                                        itemLayout="horizontal"
                                        locale={{ emptyText: "Sin información para mostrar" }}
                                        dataSource={this.state.proyectos.data}
                                        pagination={{
                                            current: this.state.proyectos.page,
                                            pageSize: this.state.proyectos.limit,
                                            total: this.state.proyectos.total,
                                            position: 'bottom',
                                            className: "flex-left",
                                            size: 'small',
                                            onChange: (page, limit) => {
                                                this.getData({ page, limit })
                                            }
                                        }}

                                        header={<Row className="header-list width-100" >

                                            <Col span={6} className="center">
                                                <Text strong>Folio</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text strong>Fecha</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text strong>Estatus</Text>
                                            </Col>
                                            <Col span={6} className="center">
                                                <Text strong>Monto</Text>
                                            </Col>
                                        </Row>}

                                        renderItem={item => (
                                            <List.Item className="hover-effect">
                                                <Link className="width-100" to={`/customer/pedidos/pm/${item._id}`}>
                                                    <Row className="width-100 font-12 hover">
                                                        <Col span={6} className="center">
                                                            <Text className='item-orden-id'>{item.folio}</Text>
                                                        </Col>
                                                        <Col span={6} className="center">
                                                            <Text className='text-blue' >{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                                        </Col>
                                                        <Col span={6} className="center">
                                                            {renderEstatusCotizacion(item.status_cliente)}
                                                        </Col>
                                                        <Col span={6} className="center">
                                                            <Text className='title-blue' >$ {item.total ? item.total.toMoney(true) : 0} MXN</Text>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={6}>
                                <Card >

                                    <Row style={{ borderBottom: "solid 1px #CCC" }}>
                                        <Title level={5} className='title-blue'> Cotizaciones Autorizadas</Title>
                                        <Col span={12} className='mb-1'>
                                            <Text className='text-blue'>Monto a Pagar </Text>
                                        </Col>
                                         <Col span={12} className='mb-1'>
                                            <Text className='text-gray flex-right'>$ {data.por_pagar.toMoney(true)}</Text>
                                        </Col>
                                        <Col span={12} className='mb-1'>
                                            <Text className='text-blue'>Pagado </Text>
                                        </Col>
                                        <Col span={12} className='mb-1'>
                                            <Text className='flex-right text-gray'>$ {data.pagado.toMoney(true)}</Text>
                                        </Col>
                                        <Col span={12} className='mb-1'>
                                            <Text className='text-blue'>Saldo </Text>
                                        </Col>
                                        <Col span={12} className='mb-1'>
                                            <Text level={5} strong style={{ borderTop: "solid 0.5px #CCC" }} className="flex-right text-blue">$ {(data.por_pagar - data.pagado).toMoney(true)} MXN</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                    	</Row>


                    </Content>
                </Spin>
			</>
		)
	}

}

export default function	Vista(props){
	return <Dashboard/>
}
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";

// componentes
import Landing from "../components/Public/index";
import ThemeEdit from "../components/ThemeEdit/index";
import Login from "../components/Auth/Login";
import UpdatePassword from "../components/Auth/UpdatePassword";
import Marketplace from "../components/Public/Cotizador/Marketplace";


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function PublicRouter({ setUser }) {
    return (
        <Layout>
            <Routes>
                <Route path="" element={<Landing />} />
                <Route path="marketplace" element={<Marketplace />} />
                <Route path="login" element={<Login />} />
                <Route path="recovery/email-:email/token-:token" element={<UpdatePassword />} />
                <Route path="register/email-:email/token-:token" element={<UpdatePassword />} />
                <Route path="theme" element={<ThemeEdit />} />
            </Routes>
        </Layout>
    );
}

export default PublicRouter;

import React, { Component } from "react";
import { Row, Col, Button, Modal, List, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin,  AutoComplete } from 'antd';

import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { ClienteSelect, ClienteCentrosSelect, ProveedorSelect, ProveedorCentrosSelect, 
    AreaSelect, RubroSelect, NegocioSelect, RazonSocialSelect } from "../../../Widgets/Inputs/Selects";

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

const Decimal = require('decimal.js');

class ModalTransacciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            cuentas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },


            facturas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null

            },

            filtros: {

            },
            tipo_cambio: 0,
            cuentaSeleccionada: '',
            disabled: false,
            transaccion: {}

        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCuentas();

        if (this.props.transaccion_id) {
            this.getTransaccion();
        } else {
            this.getTipoCambio();
        }

        if(this.props.cliente){
            this.setState({ cliente_id: this.props.cliente.value })
        }
    }

    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if(typeof values.cliente_id === 'object'){ values.cliente_id = values.cliente_id.value }

        if (this.props.transaccion_id) {
            this.updateTransaccion(values)
        } else {
            this.addTransaccion(values)
        }
    }

    /**
     * @memberof ModalTransacciones
     * @method getTransaccion
     * @description Obtiene la informacion de la transaccion, llena el formulario
     * 
     */
    getTransaccion = () => {
        this.setState({ loading: true })

        axios.post('/transacciones/get', {
            id: this.props.transaccion_id,
        }).then(async response => {

            let transaccion = response.data.data;
            console.log("transaccion", transaccion);

            this.setState({ 
                transaccion,
                cliente_id: transaccion?.cliente_id?._id,
                proveedor_id: transaccion?.proveedor_id?._id,
                area_id: transaccion?.area_id?._id 
            })

            this.modalRef.current?.setFieldsValue({
                concepto: transaccion.concepto,
                fecha: moment(transaccion.fecha),
                descripcion: transaccion.descripcion,
                cuenta_id: transaccion.cuenta_id._id,
                cuenta_destino_id: transaccion.cuenta_destino_id?._id,
                tipo: transaccion.tipo,
                tipo_cambio: transaccion.tipo_cambio,
                monto_pesos: transaccion.monto_pesos,
                monto_dolar: transaccion.monto_dolar,
                uuid: transaccion.uuid,
                area_id: (transaccion.area_id?._id) ? {
                    value: transaccion.area_id._id,
                    key: transaccion.area_id._id,
                    label: transaccion.area_id.nombre
                } : undefined,
                rubro_id: (transaccion.rubro_id?._id) ? {
                    value: transaccion.rubro_id._id,
                    key: transaccion.rubro_id._id,
                    label: transaccion.rubro_id.nombre
                } : undefined,
                cliente_id: (transaccion.cliente_id?._id) ? {
                    value: transaccion.cliente_id._id,
                    label: transaccion.cliente_id.razon_social,
                    key: transaccion.cliente_id._id 
                }: undefined,
                cliente_centro_distribucion_id: transaccion.cliente_centro_distribucion_id ? {
                    value: transaccion.cliente_centro_distribucion_id._id,
                    key: transaccion.cliente_centro_distribucion_id._id,
                    label: transaccion.cliente_centro_distribucion_id.identificador
                } : undefined,
                proveedor_id: (transaccion.proveedor_id?._id) ? {
                    value: transaccion.proveedor_id._id,
                    label: transaccion.proveedor_id.alias,
                    key: transaccion.proveedor_id._id 
                }: undefined,
                proveedor_centro_distribucion_id: transaccion.proveedor_centro_distribucion_id ? {
                    value: transaccion.proveedor_centro_distribucion_id._id,
                    key: transaccion.proveedor_centro_distribucion_id._id,
                    label: transaccion.proveedor_centro_distribucion_id.identificador
                } : undefined,
                negocio_id: (transaccion.negocio_id?._id) ? {
                    value: transaccion.negocio_id._id,
                    label: transaccion.negocio_id.nombre,
                    key: transaccion.negocio_id._id 
                }: undefined,
                razon_social_id: (transaccion.razon_social_id?._id) ? {
                    value: transaccion.razon_social_id._id,
                    label: transaccion.razon_social_id.nombre,
                    key: transaccion.razon_social_id._id 
                }: undefined,
            })


            if (transaccion.tipo === 3) {
                this.setState({
                    cuentaSeleccionada: transaccion.cuenta_id._id,
                    tipoTransaccion: transaccion.tipo,
                })
            }


        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }


    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las facturas 
    * 
    */
    getFacturas = ({

        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
        search = this.state.facturas.search,

    } = this.state.facturas) => {
        this.setState({ facturas: { ...this.state.facturas, loading: true, page, limit, search } })
        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.facturas.data = data.data.itemsList;
                    state.facturas.total = data.data.paginator.itemCount;
                    state.facturas.pages = data.data.paginator.pageCount;

                    return state;
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la información')
            })
    }

    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = ({

        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,
        search = this.state.cuentas.search,

    } = this.state.cuentas) => {
        this.setState({ cuentas: { ...this.state.cuentas, loading: true, page, limit, search } })
        axios.get('/cuentas/list', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.cuentas.data = data.data.itemsList;
                    state.cuentas.total = data.data.paginator.itemCount;
                    state.cuentas.pages = data.data.paginator.pageCount;

                    return state;
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la información')
            })
    }

    /**
    * @memberof ModalTransacciones
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        axios.get('/tipo-cambio/get').then(response => {
            this.modalRef.current?.setFieldsValue({
                tipo_cambio: response.data.data[0].valor.toMoney()
            })
            this.setState({
                tipo_cambio: response.data.data[0].valor.toMoney()
            })

        }).catch(error => {
            console.log(error);
            message.error('Error al traer tipo de cambio')
        })
    }


    /**
     * @memberof ModalTransacciones
     * @method addTransaccion
     * @description Trae las cuentas 
     * 
     */
    addTransaccion = (values) => {
        this.setState({ loading: true })
        axios.post('/transacciones/add', {
            ...values,
            monto_pesos: values.monto_pesos.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
            proyecto_id: this.props.proyecto_id, //opcional para abonar al proyecto
            mantenimiento_id: this.props.mantenimiento_id, //opcional para relacionar a una orden de mantenimineto
        }).then(response => {
            message.success('Transacción creada')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            if(error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method updateTransaccion
    * @description Trae las cuentas 
    * 
    */
    updateTransaccion = (values) => {
        this.setState({ loading: true })
        axios.post('/transacciones/update', {
            ...values,
            monto_pesos: values.monto_pesos.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
            id: this.props.transaccion_id
        }).then(response => {
            message.success('Transacción Actualizada')
            this.props.onClose(true)
        }).catch(error => {
            if(error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        let tipo_cambio = this.modalRef.current?.getFieldValue('tipo_cambio')
        this.modalRef.current?.setFieldsValue({
            monto_dolar: parseFloat(value / tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        let tipo_cambio = this.modalRef.current?.getFieldValue('tipo_cambio')
        this.modalRef.current?.setFieldsValue({
            monto_pesos: parseFloat(value * tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let monto_pesos = this.modalRef.current?.getFieldValue('monto_pesos')
        this.modalRef.current?.setFieldsValue({
            monto_dolar: parseFloat(monto_pesos / value).toMoney()
        })
        this.setState({ tipo_cambio: value })
    }

    /**
     * @memberof ModalTransacciones
     * @method clasificarTransacciones
     * @description Envia las transacciones a clasificar. Las clasifica. 
     * 
     */
    clasificarTransacciones = (values) => {
        values.proveedor_id = (values?.proveedor_id?.value) ? values.proveedor_id.value : values.proveedor_id

        this.setState({ loading: true })
        axios.post('/syncfy/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            transacciones: Object.values(this.props.seleccionadas),
            tipo_cambio: this.state.tipo_cambio,
        }).then(response => {
            message.success('Transacciones clasificadas')
            this.props.onClose()
            this.props.clearSeleccionadas()
        }).catch(error => {
            console.log(error)
            message.error('Error al clasificar transacciones')
        }).finally(() => this.setState({ loading: false }))
    }



    render() {
        const { disabled } = this.state;

        const { clasificacion, seleccionadas } = this.props;

        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.modalRef}
                onFinish={clasificacion ? this.clasificarTransacciones : this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio,
                    cliente_id: this.props.cliente,
                    tipo: this.props.tipo
                }}

                onValuesChange={value => {
                    if (value.tipo_cambio) this.onChangeTipo(value.tipo_cambio)
                    if (value.monto_pesos) this.onChangePesos(value.monto_pesos)
                    if (value.monto_dolar) this.onChangeDolar(value.monto_dolar)
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[16, 0]}>
                        {!clasificacion ? [<Col xs={24} lg={12}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>, <Col xs={24} lg={12}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>] : null}
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Area"
                                name="area_id"
                                required
                            >
                                <AreaSelect
                                    className="width-100"
                                    disabled={disabled}
                                    onSelect={(area_id)=>{
                                        this.setState({area_id})
                                        this.modalRef.current.setFieldsValue({rubro_id: null})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Rubro"
                                name="rubro_id"
                                required
                            >
                                <RubroSelect
                                    className="width-100"
                                    disabled={disabled}
                                    area_id={this.state.area_id}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <ClienteSelect
                                    className="width-100"
                                    disabled={disabled || this.props.cliente}
                                    onSelect={cliente_id => {
                                        this.setState({ cliente_id: cliente_id })
                                        this.modalRef.current.setFieldsValue({cliente_centro_distribucion_id: null})
                                        
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={<Text>Centros de Distribución <small>(Cliente)</small></Text>}
                                name="cliente_centro_distribucion_id"
                            >
                                <ClienteCentrosSelect
                                    className="width-100"
                                    disabled={disabled}
                                    cliente_id={this.state.cliente_id}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                            >
                                <ProveedorSelect
                                    className="width-100"
                                    disabled={disabled}
                                    onSelect={proveedor_id => {
                                        this.setState({ proveedor_id: proveedor_id })
                                        this.modalRef.current.setFieldsValue({proveedor_centro_distribucion_id: null}) 
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={<Text>Centros de Distribución <small>(Proveedor)</small></Text>}
                                name="proveedor_centro_distribucion_id"
                            >
                                <ProveedorCentrosSelect
                                    className="width-100"
                                    disabled={disabled}
                                    proveedor_id={this.state.proveedor_id}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Facturas (UUID)"
                                name="uuid"
                            >
                                <AutoComplete
                                    className="width-100"
                                    options={this.state.facturas.data.map(item => {
                                        const { uuid, serie, folio, cliente, proveedor, monto, monto_restante } = item
                                        return (
                                            {
                                                option: item,
                                                value: uuid,
                                                label: <List.Item className="width-100" style={{ borderBottom: "1px solid lightgray" }}>
                                                    <List.Item.Meta
                                                        title={<>
                                                            {serie}-{folio}
                                                            <small>{uuid}</small>
                                                            <small>{(cliente || proveedor) ? (cliente ? cliente.razon_social : proveedor.nombre_comercial) : null}</small>
                                                        </>}
                                                        description={<>
                                                            {monto.toLocaleString('en-US', { style: 'currency', currency: "USD" })}<br />
                                                            <small><strong>{monto_restante.toLocaleString('en-US', { style: 'currency', currency: "USD" })}</strong></small>
                                                        </>}
                                                    />
                                                </List.Item>,
                                                title: uuid
                                            }
                                        )
                                    })}
                                    onSearch={search => this.getFacturas({ search })}
                                    onSelect={(value, option) => {

                                        if (this.ModalTransacciones.current.getFieldValue("monto_pesos") > option.option.monto_restante) {
                                            this.ModalTransacciones.current.setFieldsValue({
                                                uuid: null
                                            })
                                            return message.error("El monto es mayor al monto restante")
                                        }



                                        this.ModalTransacciones.current.setFieldsValue({
                                            uuid: value
                                        })

                                    }}
                                >
                                    <Input placeholder="UUID" />
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        {!clasificacion ? [
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Cuenta"
                                    name="cuenta_id"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione la cuenta"
                                    }]}
                                >
                                    <Select
                                        onSelect={value => {
                                            this.setState({ cuentaSeleccionada: value })
                                            let cuenta_detino = this.modalRef.current?.getFieldValue('cuenta_destino_id')
                                            if (cuenta_detino === value)
                                                this.modalRef.current?.setFieldsValue({
                                                    cuenta_destino_id: null
                                                })
                                        }}
                                        placeholder="Seleccione cuenta"
                                    >
                                        {this.state.cuentas.data.map(function ({ _id, nombre, logo, color }, index) {
                                            return <Option style={{ margin: '2px 0 2px 0' }} value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>,
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tipo"
                                    name="tipo"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el tipo"
                                    }]}
                                >
                                    <Select
                                        onSelect={value => {
                                            this.setState({ tipoTransaccion: value })
                                        }}
                                        disabled={this.props.tipo}
                                        placeholder="Seleccione tipo">
                                        <Option value={1}>Ingreso</Option>
                                        <Option value={2}>Egreso</Option>
                                        <Option value={3}>Traspaso</Option>
                                        <Option value={4}>Nulo <Text type="secondary" size="small">(No afecta saldos)</Text></Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        ] : null}
                        <Col xs={24} lg={clasificacion ? 24 : 6} >
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={1} defaultValue={0} className="width-100" disabled={disabled}

                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        {!clasificacion ? [
                            <Col xs={24} lg={9} >
                                <Form.Item
                                    label="Monto (Pesos)"
                                    name="monto_pesos"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber min={0.01} defaultValue={0} className="width-100"
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    />
                                </Form.Item>
                            </Col>,
                            <Col xs={24} lg={9} >
                                <Form.Item
                                    label="Monto (Dolar)"
                                    name="monto_dolar"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber

                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}


                                        min={0.01} defaultValue={0} className="width-100" />
                                </Form.Item>
                            </Col>
                        ] : null}


                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Negocio"
                                name="negocio_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Negocio"
                                }]}
                            >
                                <NegocioSelect
                                    allowClear={true}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Razón Social"
                                name="razon_social_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la Razón Social"
                                }]}
                            >
                                <RazonSocialSelect
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Comentarios Adicionales"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción"></Input.TextArea>
                            </Form.Item>
                        </Col>
                    </Row>

                    {this.state.tipoTransaccion === 3 ? <Row>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta_destino_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta">
                                    {this.state.cuentas.data.map(({ _id, nombre, logo, color }, index) => {

                                        if (this.state.cuentaSeleccionada.toString() === _id.toString())
                                            return null
                                        else
                                            return <Option style={{ margin: '2px 0 2px 0' }} value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> : null}

                    {clasificacion ? <Row>

                        <List
                            header={<Title level={5}>Transacciones a clasificar</Title>}
                            className="width-100"
                            itemLayout="horizontal"
                            dataSource={Object.values(seleccionadas)}
                            renderItem={item => (
                                <List.Item className="width-100">
                                    <List.Item.Meta
                                        title={<a href="https://ant.design">{item.description}</a>}
                                        description={<>
                                            {item.reference ?? <p>{item.reference} </p>} <br />
                                            <strong>{moment.unix(item.dt_transaction).format("LLLL")}</strong>
                                        </>}
                                    />
                                    <div style={{ textAlign: "right" }}>{(
                                        item.currency == "MXN"
                                    ) ? <>
                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></p>
                                        <p>{Decimal(item.amount).div(this.state.tipo_cambio).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></p>
                                    </> : <>
                                        <p>{Decimal(item.amount).mul(this.state.tipo_cambio).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></p>
                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></p>
                                    </>
                                    }</div>
                                </List.Item>
                            )}
                        />
                    </Row> : null}


                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        style={{ minWidth: props.clasificacion ? 600 : 600 }}
    >
        <div className="center">
            <Title level={3}>
                {clasificacion ? <>
                    Clasificando Transacciones
                </> : <>
                    {(transaccion_id != null) ? "Editar " : "Nueva"} Transacción
                </>}
            </Title>
        </div>
        <ModalTransacciones {...props} />
    </Modal>

}
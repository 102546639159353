import React, { Component } from "react";
import {  Modal, Typography, Empty, Spin } from 'antd';

const { Text, Title } = Typography;
const axios = require('axios').default;

class ModalXML extends Component {

    modalFacturas = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            factura_xml: {}
        }
    }

    /**
    * @memberof ModalXML
    *
    * @method   componentDidMount
    * @description  
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.factura_xml_storage){
            console.log(this.props.factura_xml_storage)
            return this.renderXMLStorage()
        }

        if (this.props.xml)
            return this.renderXML()

        this.getAttachment()
    }
    

    /**
     *
     *
     * @param {*} [factura_xml=this.props.factura_xml]
     * @memberof ModalXML
     * 
     * @description Renderiza un XML directo
     */
    renderXML = (xml = this.props.xml) => this.setState({ xml, loading: true }, () => {
        axios.post('/syncfy/xml-json', {
            xml
        })
            .then(({ data }) => {
                this.setState({ xml_json: data })
            })
            .finally(() => this.setState({ loading: false }))

    })

    /**
     *
     *
     * @param {*} [factura_xml=this.props.factura_xml]
     * @memberof ModalXML
     * 
     * @description Renderiza un XML directo
     */
    renderXMLStorage = (xml = this.props.factura_xml_storage) => this.setState({ xml, loading: true }, () => {
        axios.get('/facturas/xml-json', {
            params:{
                filename: xml
            }
        })
            .then(({ data }) => {
                console.log(data)
                this.setState({ xml_json: data })
            }).catch(error => {
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))

    })

    /**
     *
     *
     * @param {*} [factura_xml=this.props.factura_xml]
     * @memberof ModalXML
     * 
     * @description Renderiza un archivo de syncfy
     */
    getAttachment = (factura_xml = this.props.factura_xml) => this.setState({ factura_xml, loading: true }, () => {
        axios.get('/syncfy/attachment', {
            params: {
                id_attachment: factura_xml?.id_attachment
            }
        })
            .then(({ data }) => {
                this.setState({ xml_json: data.data })
            })
            .finally(() => this.setState({ loading: false }))

    })


    /**
     *
     *
     * @memberof ModalXML
     * @function renderObj
     *
     * @description Obtiene un objeto y despliga el contenido en forma de tabla, los va seprando conforme el tipo de valor
     */

    renderObj = (element) => {
        if (Array.isArray(element)) {
            let columnas = []

            element.forEach(elem => {
                let addColumns = Object.keys(elem);

                addColumns.forEach(col => {
                    let index = columnas.indexOf(col)
                    if (index === -1) {
                        columnas.push(col)
                    }
                })
            })
            return <div className="table-container">
                <table className="factura-xml-table">
                    <thead>
                        <tr>
                            {columnas.map(col => <th>{col}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {element.map(elem => {
                            return <tr>
                                {columnas.map(col => <td>{elem[col] ? typeof elem[col] === 'string' ? <Text>{elem[col]}</Text> : this.renderObj(elem[col]) : '-'}</td>)}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>




        } else if (typeof element === 'object') {
            return <div>
                {
                    Object.keys(element).map((key, i) => {
                        if (typeof element[key] === 'object') {
                            return <div >
                                <div className="div-title">{key}</div>
                                <div className="div-obj">
                                    {this.renderObj(element[key])}
                                </div>
                            </div>
                        } else if (typeof element[key] === 'string') {
                            return <div className="div-row">
                                <div className="div-key">{key}</div>
                                <div className="div-string">{element[key]}</div>
                            </div>
                        }
                    })
                }
            </div>
        }
    }

    /**
     *
     *
     * @memberof ModalXML
     * @function renderText
     *
     * @description renderiza un campo y su valor en forma de tabla
     */

    renderText = (obj) => {

        return (
            <div>
                {
                    Object.keys(obj).map((key, i) => (
                        <div className="div-row">
                            <div className="div-key">{key} :</div>
                            {typeof obj[key] === 'string' ? <div className="div-string">{obj[key]}</div> : <div>{typeof obj[key]}</div>}
                        </div>
                    ))
                }
            </div>
        )
    }

    
    render() {

        // return <Spin spining={true} size="large" />
        return this.state.loading ? <Spin spining={true} style={{
            display: 'block',
            marginBottom: '10em',
            marginTop: '10em'
         }} /> : this.state.xml_json ? this.renderObj(this.state.xml_json) : <Empty description="No hay ningún archivo XML" />  
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={"Visualización de la factura"}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={1000}
    >
        <ModalXML {...props} />
    </Modal>

}

import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout, Button } from "antd";

//COMPONENTES
import Navbar from '../components/Header/Header';
import { User, SetUser } from '../Hooks/Logged';
import AdminSidebar from "../components/Sidebar/Sidebar";


//ROUTERS
import RouterDashboard from "./RouterDashboard";
import RouterFinanzas from "./RouterFinanzas";
import RouterProjectManager from "./RouterProjectManager";
import RouterCuentas from "./RouterCuentas";
import RouterUsuarios from "./RouterUsuarios";

import RouterAreas from "./RouterAreas";
import RouterNegocios from "./RouterNegocios";
import RouterRazonesSociales from "./RouterRazonesSociales";

import RouterProveedores from "./RouterProveedores";
import RouterContabilidad from "./RouterContabilidad";
import RouterCRM from './RouterCRM';


import RouterMantenimiento from "./RouterMantenimiento";
import RouterCategorias from "./RouterCategorias";
import RouterCuentasCompras from "./RouterCuentasCompras"

import RouterServicios from "./RouterServicios";
import RouterRefacciones from "./RouterRefacciones";
import RouterMateriales from "./RouterMateriales";

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
//css
import '../Styles/Global/admin.css';


const { Content } = Layout;

class AdminRoutes extends Component {

    static contextType = User

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false,
            collapsed: false
        };
    }

    setFilterSearch(e) {
        this.setState({ filterSearch: e })
    }

    updateFilterSearch = (ShowFilter) => {
        if (ShowFilter === undefined) { ShowFilter = false }
        this.setState({
            showSearch: ShowFilter,
        })
    };

    setCollapse = () => {
        let collapsed = this.state.collapsed;
        this.setState({ collapsed: !collapsed })
    }


    render() {
        const { back } = this.state;
        const { updateFilterSearch } = this;
        return (
            <Layout className="layout"  >
                <User.Consumer>
                    {value => { this.context = value }}
                </User.Consumer>
                <Navbar
                    user={this.context}
                    back={back}
                    onBack={this.onBack}
                    setUser={this.setUser}
                    showResponsiveSidebar={() => this.setState({ responsiveSidebar: !this.state.responsiveSidebar })}
                    showSearch={this.state.showSearch}
                    setFilterSearch={(e) => this.setFilterSearch(e)}
                    filterSearchVal={this.state.filterSearch}
                    admin={true}
                />
                <Layout className="layout-content ">
                    <AdminSidebar
                        collapsed={this.state.collapsed}
                        setCollapse={this.setCollapse}
                        user={this.context}
                        back={back}
                        setUser={this.setUser}
                    />

                    <Content>

                        <Routes>
                            <Route
                                path="dashboard/*"
                                element={<RouterDashboard updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="finanzas/*"
                                element={<RouterFinanzas updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="project-manager/*"
                                element={<RouterProjectManager updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route
                                path="mantenimiento/*"
                                element={<RouterMantenimiento updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="contabilidad/*"
                                element={<RouterContabilidad updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="crm/*"
                                element={<RouterCRM updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route
                                path="usuarios/*"
                                element={<RouterUsuarios updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="cuentas/*"
                                element={<RouterCuentas updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="categorias/*"
                                element={<RouterCategorias updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="servicios/*"
                                element={<RouterServicios updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route
                                path="refacciones/*"
                                element={<RouterRefacciones updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route
                                path="materiales/*"
                                element={<RouterMateriales updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route
                                path="negocios/*"
                                element={<RouterNegocios updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="razones-sociales/*"
                                element={<RouterRazonesSociales updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="areas/*"
                                element={<RouterAreas updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="proveedores/*"
                                element={<RouterProveedores updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route
                                path="cuentas-compras/*"
                                element={<RouterCuentasCompras updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                        </Routes>
                    </Content>
                </Layout>

            </Layout >
        )
    }
}


export default function Vista(props) {
    return <AdminRoutes {...props} setUser={React.useContext(SetUser)} />
}
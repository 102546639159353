import React, { Component } from "react";
import { Button, Layout, Image } from 'antd';

import '../../Styles/Global/landing.css';
import Navbar from "../Header/Header";
const { Content } = Layout;



/**
 *
 *
 * @export
 * @class Landing
 * @extends {Component}
 * @description Pagina principal del sistema
 */
export default class Landing extends Component {

    render() {
        return (
            <Layout>
                <Navbar admin={false}/>
                <Content className="landing-content" style={{ background: "url('/images/bg-3.jpg')", backgroundSize: 'cover' }} >
                    <div className="center-landing text-center" style={{padding: '12% 0px'}}>
                        <Button type="button" className="landing-button">enterprise resource planning</Button>
                        <Image className="pd-1" preview={false} src={"images/logo.png"}  style={{
                            maxWidth: 500,
                            display: "block",
                            margin: "0 auto"
                        }}/>
                    </div>
                </Content>
            </Layout>
        )
    }

}
import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Cuentas from '../components/Admin/Cuentas/Cuentas'
/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCuentas(props) {
    return (
        <Routes>
            <Route path="" element={<Cuentas {...props} />} />
        </Routes>
    )
}

export default RouterCuentas;

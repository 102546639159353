import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


function convertToMoney(string = false) {
  try {
    if (this) {
      var r = this.toString().replace(',', '').match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return (string === true) ? parseFloat(r).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(r)
    }
    else
      return 0.00
  } catch (error) {
    return 0.00
  }
}

Number.prototype.toMoney = convertToMoney

String.prototype.toMoney = convertToMoney


function RenderBanner() {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef()
  


  let onScroll = () => {

    let root = document.querySelector("#root")
    if ((window.innerHeight + window.scrollY) >= root.offsetHeight ) 
      return setIsSticky(true)
    else 
      return setIsSticky(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, [
    () => window.removeEventListener('scroll', onScroll)
  ])

  return <div className={'test-banner ' + (isSticky ? " sticky" : "")} ref={ref} >
      <p className='test-banner-text'>
        Esta software es solo una versión de prueba, las actividades y modificaciones que se realicen en esta versión no afectarán el entorno real.
      </p>
    </div>
}

let banner = (process.env.REACT_APP_ENVIROMENT === "staging" || process.env.NODE_ENV === "development") ? <RenderBanner /> : null

ReactDOM.render(
  <React.StrictMode>
    <App />
    {banner}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from "react";
import { Layout, Col, Row, Typography, List, message, Card, Statistic } from "antd";

//componentes
import { renderMontoTransaccion } from "../../../Utils";



const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class Pagos
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class Pagos extends Component {


    constructor(props) {
        super(props)
        this.state = {
            transacciones:{
                data: [],
                page: 1,
                limit: 10,
                total: 0
            }
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getTransacciones()
    }


    /**
    * @memberof Transacciones
    * @method getTransacciones
    * @description Obtiene todas todas las transacciones, del proyecto
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.props.search
    } = this.state.transacciones) => {

        axios.post('/transacciones/list', {
            page, 
            limit, 
            search,
            tipo: 1, //ingreso
            proyecto_id: this.props.proyecto_id
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({
                    transacciones: data.data
                })
            })
            .catch(error => {
                console.log('error', error)
                message.error('No se pudieron cargar las transacciones')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className=""
                                itemLayout="horizontal"
                                dataSource={this.state.transacciones.data}
                                pagination={{
                                    current: this.state.transacciones.page,
                                    pageSize: this.state.transacciones.limit,
                                    total: this.state.transacciones.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                }}
                                renderItem={item => (
                                    <List.Item className="">
                                        <Card className="card-list width-100">
                                            <div className={`badge-card tipo-1`}></div>
                                            <Row className="width-100">
                                                <Col span={10} className="pl-1">
                                                    <Text strong>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col span={10} className="pl-1">
                                                    <Text strong>{item.concepto}</Text>
                                                </Col>
                                                <Col span={4} className="flex-right">
                                                    <Text>{renderMontoTransaccion({tipo: item.tipo, monto: item.monto})}</Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}
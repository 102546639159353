import React, { Component } from "react";
import { Row, Col, Button, Modal, List, Typography, Checkbox, Form, DatePicker, Input, InputNumber, Select, message, Spin, Cascader, Tag, AutoComplete, Divider } from 'antd';

import CustomAvatar from "../../../Widgets/Avatar/Avatar";

import { ClienteSelect, ClienteCentrosSelect, ProveedorSelect, ProveedorCentrosSelect, 
    AreaSelect, RubroSelect, NegocioSelect, RazonSocialSelect } from "../../../Widgets/Inputs/Selects";

import ModalXML from "../../Syncfy/ModalXML";


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

const Decimal = require('decimal.js');


class ModalFacturas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalXMLVisible: false,

            cuentas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },

            filtros: {

            },
            tipo_cambio: 0,
            cuentaSeleccionada: '',
            disabled: false,
            transaccion: {}
        }
    }

    ModalTransacciones = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


        this.getCuentas();

        if (this.props.factura_id !== undefined || this.props.factura_id != null) {
            this.getFactura();
        } else {
            this.getTipoCambio();
        }

    }


    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        values.orden_id = (typeof values?.orden_id?.value === "string") ? values?.orden_id?.value : values?.orden_id
        values.orden_compra_id = (typeof values?.orden_compra_id?.value === "string") ? values?.orden_compra_id?.value : values?.orden_compra_id
        if(typeof values.cliente_id === 'object'){ values.cliente_id = values.cliente_id.value }
        console.log("values", values)

        if (this.props.factura_id) {
            this.updateFactura(values)
        } else {
            this.addFactura(values)
        }
    }

    /**
     * @memberof ModalTransacciones
     * @method getTransaccion
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    getFactura = () => {
        this.setState({ loading: true })


        console.log("this.props.transaccion_id", this.props.factura_id)
        axios.get('/facturas/get', {
            params: {
                id: this.props.factura_id,
            }
        }).then(async ({ data }) => {
            data = data.data

            // console.log("                fecha: moment(data.fecha),",                 moment(data.fecha),)
            this.ModalTransacciones.current.setFieldsValue({
                ...data,

                cliente_id: data?.cliente_id ? {
                    label: data?.cliente_id?.nombre,
                    key: data?.cliente_id?._id,
                    value: data?.cliente_id?._id,
                } : null,
                cliente_centro_distribucion_id: data?.cliente_centro_distribucion_id ? {
                    label: data?.cliente_centro_distribucion_id?.identificador,
                    key: data?.cliente_centro_distribucion_id?._id,
                    value: data?.cliente_centro_distribucion_id?._id,
                } : null,
                proveedor_centro_distribucion_id: data?.proveedor_centro_distribucion_id ? {
                    label: data?.proveedor_centro_distribucion_id?.identificador,
                    key: data?.proveedor_centro_distribucion_id?._id,
                    value: data?.proveedor_centro_distribucion_id?._id,
                } : null,

                proveedor_id: data?.proveedor_id ? {
                    label: data?.proveedor_id?.alias,
                    key: data?.proveedor_id?._id,
                    value: data?.proveedor_id?._id,
                } : null,
                negocio_id: data?.negocio_id ? {
                    label: data?.negocio_id?.nombre,
                    key: data?.negocio_id?._id,
                    value: data?.negocio_id?._id,
                } : null,

                razon_social_id: data?.razon_social_id ? {
                    label: data?.razon_social_id?.nombre,
                    key: data?.razon_social_id?._id,
                    value: data?.razon_social_id?._id,
                } : null,
                fecha: moment(data.fecha),
                fecha_vigencia: moment(data.fecha_vigencia),
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method addTransaccion
     * @description Trae las cuentas 
     * 
     */
    addFactura = (values) => {
        this.setState({ loading: true })
        axios.post('/facturas/add', {
            ...values,
            monto_pesos: values.monto_pesos.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
            proyecto_id: this.props.proyecto_id, //opcional
            mantenimiento_id: this.props.mantenimiento_id //opcional
        }).then(response => {
            message.success('Factura creada')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method updateTransaccion
    * @description Trae las cuentas 
    * 
    */
    updateFactura = (values) => {
        this.setState({ loading: true })
        axios.post('/facturas/update', {
            ...values,
            monto_pesos: values.monto_pesos,
            monto_dolar: values.monto_dolar,
            tipo_cambio: values.tipo_cambio,
            id: this.props.factura_id
        }).then(response => {
            message.success('Transacción Actualizada')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = ({

        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,
        search = this.state.cuentas.search,

    } = this.state.cuentas) => {
        this.setState({ cuentas: { ...this.state.cuentas, loading: true, page, limit, search } })
        axios.get('/cuentas/list', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.cuentas.data = data.data.itemsList;
                    state.cuentas.total = data.data.paginator.itemCount;
                    state.cuentas.pages = data.data.paginator.pageCount;

                    return state;
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la información')
            })
    }

    /**
    * @memberof ModalTransacciones
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        axios.get('/tipo-cambio/get').then(response => {
            this.ModalTransacciones.current?.setFieldsValue({
                tipo_cambio: response.data.data[0].valor.toMoney()
            })
            this.setState({
                tipo_cambio: response.data.data[0].valor.toMoney()
            })

        }).catch(error => {
            console.log(error);
            message.error('Error al traer tipo de cambio')
        })
    }




    /**
     * @memberof ModalTransacciones
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        let tipo_cambio = this.ModalTransacciones.current?.getFieldValue('tipo_cambio')
        this.ModalTransacciones.current?.setFieldsValue({
            monto_dolar: parseFloat(value / tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        let tipo_cambio = this.ModalTransacciones.current?.getFieldValue('tipo_cambio')
        this.ModalTransacciones.current?.setFieldsValue({
            monto_pesos: parseFloat(value * tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let monto_pesos = this.ModalTransacciones.current?.getFieldValue('monto_pesos')
        this.ModalTransacciones.current?.setFieldsValue({
            monto_dolar: parseFloat(monto_pesos / value).toMoney()
        })
        this.setState({ tipo_cambio: value })
    }

    /**
     * @memberof ModalTransacciones
     * @method clasificarTransacciones
     * @description Envia las transacciones a clasificar. Las clasifica. 
     * 
     */
    clasificarFacturas = (values) => {
        this.setState({ loading: true })

        values.orden_id = (typeof values?.orden_id?.value === "string") ? values?.orden_id?.value : values?.orden_id
        values.orden_compra_id = (typeof values?.orden_compra_id?.value === "string") ? values?.orden_compra_id?.value : values?.orden_compra_id
        
        axios.post('/syncfy/facturas/add', {
            ...values,
            razon_social_id: this.props.razon_social_id,
            facturas: Object.values(this.props.seleccionadas),
            tipo_cambio: this.state.tipo_cambio,
        }).then(response => {
            message.success('Transacciones clasificadas')
            this.props.onClear()
            this.props.onClose()

        }).catch(error => {
            console.log(error)
            message.error('Error al clasificar transacciones')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransacciones
     * @method tagRender
     * @description renderiza los tags del select con su respectivo color
     * 
     */
    tagRender = (props, other) => {

        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };

        let obj = JSON.parse(value)

        return (
            <Tag
                color={obj.color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );

    }


    render() {
        const { disabled } = this.state;
        const { clasificacion, seleccionadas } = this.props


        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalTransacciones}
                onFinish={clasificacion ? this.clasificarFacturas : this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio,
                    cliente_id: this.props.cliente,
                }}

                onValuesChange={value => {
                    if (typeof value.credito === "boolean") this.setState({ uso_credito: value.credito })
                    if (value.tipo_cambio) this.onChangeTipo(value.tipo_cambio)
                    if (value.monto_pesos) this.onChangePesos(value.monto_pesos)
                    if (value.monto_dolar) this.onChangeDolar(value.monto_dolar)
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[16, 0]}>
                        {!clasificacion ? [<Col xs={24} lg={12}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>, <Col xs={24} lg={12}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>] : null}
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <ClienteSelect
                                    className="width-100"
                                    disabled={disabled || this.props.cliente}
                                    onSelect={cliente_id => {
                                        this.setState({ cliente_id: cliente_id })
                                        this.ModalTransacciones.current.setFieldsValue({cliente_centro_distribucion_id: null})
                                        
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={<Text>Centros de Distribución <small>(Cliente)</small></Text>}
                                name="cliente_centro_distribucion_id"
                            >
                                <ClienteCentrosSelect
                                    className="width-100"
                                    disabled={disabled}
                                    cliente_id={this.state.cliente_id}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                            >
                                <ProveedorSelect
                                    className="width-100"
                                    disabled={disabled}
                                    onSelect={proveedor_id => {
                                        this.setState({ proveedor_id: proveedor_id })
                                        this.ModalTransacciones.current.setFieldsValue({proveedor_centro_distribucion_id: null}) 
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={<Text>Centros de Distribución <small>(Proveedor)</small></Text>}
                                name="proveedor_centro_distribucion_id"
                            >
                                <ProveedorCentrosSelect
                                    className="width-100"
                                    disabled={disabled}
                                    proveedor_id={this.state.proveedor_id}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Row className="w-100" gutter={[16,0]}>
                                <Col xs={24} lg={clasificacion ? 24 : 6} >
                                    <Form.Item
                                        label="Tipo Cambio"
                                        name="tipo_cambio"

                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese monto"
                                        }]}
                                    >
                                        <InputNumber min={1} defaultValue={0} className="width-100" disabled={disabled}

                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                        />
                                    </Form.Item>
                                </Col>
                                {!clasificacion ? [
                                    <Col xs={24} lg={9} >
                                        <Form.Item
                                            label="Monto (Pesos)"
                                            name="monto_pesos"

                                            rules={[{
                                                required: true,
                                                message: "Por favor, ingrese monto"
                                            }]}
                                        >
                                            <InputNumber min={0.01} defaultValue={0} className="width-100"
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                            />
                                        </Form.Item>
                                    </Col>,
                                    <Col xs={24} lg={9} >
                                        <Form.Item
                                            label="Monto (Dolar)"
                                            name="monto_dolar"

                                            rules={[{
                                                required: true,
                                                message: "Por favor, ingrese monto"
                                            }]}
                                        >
                                            <InputNumber

                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}


                                                min={0.01} defaultValue={0} className="width-100" />
                                        </Form.Item>
                                    </Col>
                                ] : null}


                                <Col  xs={24} lg={clasificacion ? 24 : 24}>
                                    <Form.Item
                                        label="Negocio"
                                        name="negocio_id"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el Negocio"
                                        }]}
                                    >
                                        <NegocioSelect
                                            allowClear={true}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {!clasificacion ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Razón Social"
                                name="razon_social_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la Razón Social"
                                }]}
                                labelInValue

                            >
                                <RazonSocialSelect
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col> : null}

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo de Factura"
                                name="tipo">
                                <Select
                                    onSelect={value => {
                                        this.setState({ tipoFactura: value })
                                    }}
                                    placeholder="Seleccione tipo">
                                    <Option value={0}>Sin Cambio</Option>
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={-1}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                valuePropName="checked"
                                label="Linea de Crédito"
                                name="credito"
                            >
                                <Checkbox ></Checkbox>
                            </Form.Item>
                        </Col>
                        {!this.state.uso_credito ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Fecha de Vencimiento"
                                name="fecha_vigencia" //<--------------------------------------- fecha_vigencia
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha de monto"
                                }]}
                                className="width-100"
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col> : null}
                    </Row>

                    {this.state.tipoTransaccion === 3 ? <Row>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta_destino_id"
                                labelInValue
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta">
                                    {this.state.cuentas.data.map(({ _id, nombre, logo, color }, index) => {

                                        if (this.state.cuentaSeleccionada.toString() === _id.toString())
                                            return null
                                        else
                                            return <Option style={{ margin: '2px 0 2px 0' }} value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> : null}

                    {clasificacion ? <Row>

                        <List
                            header={<Title level={5}>Transacciones a clasificar</Title>}
                            className="width-100"
                            itemLayout="horizontal"
                            dataSource={Object.values(seleccionadas)}
                            renderItem={item => (
                                <List.Item className="width-100">
                                    <List.Item.Meta
                                        title={item.xml ? <Button onClick={() => this.setState({ xml: item.xml, modalXMLVisible: true })}>{item.description}</Button> : item.description}
                                        description={<>
                                            {item.reference ?? <p>{item.reference} </p>} <br />
                                            <strong>{moment.unix(item.dt_transaction).format("LLLL")}</strong>
                                        </>}
                                    />
                                    <div style={{ textAlign: "right" }}>{(
                                        item.currency == "MXN"
                                    ) ? <>
                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></p>
                                        <p>{Decimal(item.amount).div(this.state.tipo_cambio).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></p>
                                    </> : <>
                                        <p>{Decimal(item.amount).mul(this.state.tipo_cambio).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></p>
                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></p>
                                    </>
                                    }</div>
                                </List.Item>
                            )}
                        />
                    </Row> : null}


                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
                <ModalXML
                    visible={this.state.modalXMLVisible}
                    onCancel={() => this.setState({ modalXMLVisible: false, factura_xml: undefined, xml: undefined })}
                    factura_xml={this.state.factura_xml}
                    xml={this.state.xml}
                />
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        style={{ minWidth: props.clasificacion ? 600 : 600 }}
    >
        <div className="center">
            <Title level={3} className="text-center">
                {clasificacion ? <>
                    Clasificando Facturas
                </> : <>
                    {(factura_id != null) ? "Editar " : "Nueva"} Factura
                </>}

            </Title>
        </div>
        <ModalFacturas {...props} />
    </Modal>

}
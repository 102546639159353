import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

import {
	EllipsisOutlined,
	ArrowUpOutlined,
	FlagFilled,
	ArrowDownOutlined,
	DeleteFilled,
	EditFilled,
	FormOutlined,
	WarningOutlined,
	PaperClipOutlined,
} from "@ant-design/icons";
import {
	Card,
	Tag,
	List,
	Checkbox,
	Typography,
	Statistic,
	Row,
	Col,
	Image,
	Progress,
	Avatar,
	Button,
	Space,
	Popconfirm,
	Tooltip,
	Divider,
	Form,
	Select,
	InputNumber,
	DatePicker,
	Dropdown,
	Menu
} from "antd";


import CustomAvatar from "./Avatar/Avatar";
import { LightenDarkenColor } from "lighten-darken-color";
import {
	IconVisa,
	IconMaster,
	IconMoneyCircle,
	IconCheckMedal,
	IconProfit,
	IconLoss,
	IconToolOperation,
	IconMenuDots,
	IconEdit,
	IconDelete,
	IconDetails,
	IconFacturaMoney,
	IconAceptarOrden,
	IconPDF,
	IconSuccess,
	IconError,
	IconWarning,
	IconEye,
	IconBtnOpcExclamacion,
	IconBtnOpcCancelar,
	IconBtnOpcAceptar,
	IconCheck,
} from "./Iconos";

import "../../Styles/Global/cards.css";
import ModalEditarVigencia from "../../components/Admin/ProjectManager/Matriz/ModalEditarVigencia";
import { Link } from "react-router-dom";
import { EditableProTable } from "@ant-design/pro-table";

const { Option } = Select;
const { Text, Title } = Typography;
const moment = require("moment");
const axios = require("axios");
const echarts = require("echarts");

// Configuracion de moment para Zona horaria Ciudad de Mexico
moment.locale('es');

/**
 * @const CardTaskStep
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la informacion del step
 * @param {String} color Color del step
 * @param {String} title Titulo del card
 * @param {Boolean} simple Define si el card tiene titulo o no
 */
const CardTaskStep = ({ title = "", position = "", simple = false, color = "#bababa" }) => {

	if (simple) return (
		<Card
			className="card-task-name card-task-simple"
			cover={
				<span className="card-task-color card-task-simple" style={{ backgroundColor: color }}>
					{title}
				</span>
			}
		>
		</Card>
	)

	return (
		<Card
			className="card-task-name"
			cover={
				<span className="card-task-color" style={{ backgroundColor: color }} />
			}
		>
			<Card.Meta
				className="card-task-name-meta"
				title={title || "Quotation"}
				description={`${position || "First"} `}
			/>
		</Card>
	);
};

/**
 * @const CardTaskClient
 * @param {Event} onClick Accion a ejecutar al seleccionar card
 * @param {Object} proyecto Proyecto a renderizar
 * @param {Array} task Lista
 *
 */
const CardTaskClient = ({
	task,
	proyecto = {},
	onClick = () => { },
}) => {
	let fecha_hoy = moment();
	let fecha_proyecto_creacion = moment(proyecto.fecha)
	let dias = fecha_hoy.diff(fecha_proyecto_creacion, 'days');


	console.log('proyecto fecha', moment(proyecto.fecha).fromNow())

	const isChecked = (item) => {
		if (Array.isArray(item.name)) {
			return (proyecto[item.section][item.name])?.includes(true)
		}

		if (item.name !== null || item.name !== undefined || item.name !== 'undefined') {
			return proyecto[item.section] && proyecto[item.section][item.name]
		}
		return false
	}


	return (
		<Card
			title={<a># {proyecto.folio}</a>}
			hoverable
			extra={
				<Tag
					className="card-task-tag"
					color={
						(dias >= 5 && dias < 10) ? '#ffea00' :
							(dias >= 10) ? '#ff0015' : '#0089ED'}
				>
					{(dias >= 5) ? <><WarningOutlined className="text-white" />&nbsp;</> : null} {moment(proyecto.fecha).fromNow()}
				</Tag>
			}
			bordered={false}
			className="card-task"
			onClick={onClick}
		>
			<p className="card-task-description">
				There are many variations of passages of Lorem Ipsum available, but the
				majority have suffered alteration in some form, by injected humour,
			</p>
			<List
				itemLayout="horizontal"
				dataSource={task}
				bordered={false}
				className="list-border-none"
				renderItem={(item) => (
					<List.Item>
						<List.Item.Meta
							className="card-task-list-meta"
							avatar={
								<Checkbox
									className="custom-checkbox-circle"
									checked={isChecked(item)}
								/>
							}
							title={item.title}
						/>
					</List.Item>
				)}
			/>
			<Space direction="vertical">
				{proyecto.cliente_id?.nombre ?
					<Tag color="#7B61FF">{`${proyecto.cliente_id?.nombre} ${proyecto.cliente_id?.apellido}`}
					</Tag>
					: null}
				{proyecto.empresa_id?.nombre ?
					<Tag color="#ACFE79">{`${proyecto.empresa_id?.nombre} ${proyecto.empresa_id?.apellido}`}
					</Tag>
					: null}
			</Space>
			<p>{moment(proyecto.updatedAt).format("LL")}</p>
			<p>
				{proyecto.agente_compras_id ? (
					<>
						<CustomAvatar
							image={proyecto.agente_compras_id.avatar}
							name={[
								proyecto.agente_compras_id?.nombre,
								proyecto.agente_compras_id?.apellido,
							]}
						/>
						<span className="ml-1">
							{`${proyecto.agente_compras_id?.nombre} ${proyecto.agente_compras_id?.apellido}`}
						</span>
					</>
				) : (
					<span> Sin asesor asignado </span>
				)}
			</p>
		</Card>
	);
};

/**
 * @const CardMantenimientoTask
 * @description Card del dashboard de mantenimiento
 * @param cliente_id cliente de la orden
 * @param color Color de la orden
 * @param orden Objecto con la informacion de la orden
 *
 */
const CardMantenimientoTask = (props) => {

	const { orden, color, onClick } = props
	const { cliente_id } = orden
	let ago = moment(orden.createdAt).fromNow()
	ago = ago.charAt(0).toUpperCase() + ago.slice(1)
	return (
		<Card
			title={<>
				<Title className="card-mantenimiento-task-title"># {orden.folio}</Title>
				<Text className="card-mantenimiento-task-subtitle">$ {orden?.total?.toMoney(true)} MXN</Text>
			</>}
			hoverable
			extra={
				<Tag className="card-mantenimiento-task-tag" color={color}>
					{ago}
				</Tag>
			}
			bordered={false}
			className="card-mantenimiento-task"
			onClick={onClick}
		>
			<Row gutter={[24, 24]}>
				<Col xs={24}>
					<Text className="card-mantenimiento-task-location">Localización</Text><br />
					<Text className="card-mantenimiento-task-address">Dirección</Text>
				</Col>
				<Col xs={24}>
					<Text className="card-mantenimiento-task-description">
						{orden.descripcion}
					</Text>
				</Col>
				<Col xs={24}>
					<Tag className="card-mantenimiento-task-client" color={color} >
						{cliente_id?.nombre ? cliente_id.nombre + " " + cliente_id.apellido : 'Sin Cliente'}
					</Tag>
				</Col>
				<Col xs={24} className="card-mantenimiento-task-update">
					<Text>Ultima actualización hace {moment(orden.updatedAt).fromNow()}</Text>
				</Col>
				<Col xs={24}>
					<p>
						{orden.responsable_id ? (
							<>
								<CustomAvatar
									image={orden.responsable_id.avatar}
									name={[
										orden.responsable_id?.nombre,
										orden.responsable_id?.apellido,
									]}
								/>
								<span className="ml-1 card-mantenimiento-task-name">
									{" "}
									{orden.responsable_id?.nombre}{" "}
									{orden.responsable_id?.apellido}
								</span>
							</>
						) : (
							<>
								<span className="card-mantenimiento-task-name"> Sin asesor asignado </span>
							</>
						)}
					</p>
				</Col>
			</Row>
		</Card>
	);
};

/**
 * @const CardSaldo
 * @description Card que muetra el total de Saldo diponible entre todas las cuentas
 * @param {Number} saldo suma de los saldos disponibles
 * @param {Number} cuentas cantidad de cuentas registradas
 */
const CardSaldo = ({ saldo = 0, cuentas = 0 }) => {
	return (
		<Card className="card-saldo">
			<Card.Meta
				className="card-saldo-main-meta"
				title="Saldo al Momento"
				description={
					<>
						<Text style={{ marginRight: "0.5rem" }}>
							$ {saldo.toMoney(true)}
						</Text>
						<small>MXN</small>
					</>
				}
			/>
			<Card.Meta
				className="card-saldo-sub-meta"
				title="Cuentas Incluidas"
				description={<>{cuentas}</>}
			/>
		</Card>
	);
};

/**
 * @const CardCuenta
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardCuenta = ({ cuenta = { saldo: 0 } }) => {
	let logos = {
		1: <IconMaster />,
		2: <IconVisa />,
		3: <img src={"/images/AE.png"} alt="AE" height="30px" />,
	};

	return (
		<Card
			bordered={false}
			className="card-cuenta"
			style={{
				background: `linear-gradient(244.64deg, 
								${LightenDarkenColor(
					cuenta.color ? cuenta.color : "#0022A9",
					-20
				)} 10%, 
								${LightenDarkenColor(
					cuenta.color ? cuenta.color : "#0022A9",
					20
				)} 49.92%, 
								${LightenDarkenColor(
					cuenta.color ? cuenta.color : "#0022A9",
					-10
				)}  85.62%)`,
			}}
		>
			<Row justify="end" align="top">
				<Col span={12} className="center">
					{cuenta.syncfy_credenciales_id && cuenta.syncfy_cuenta_bancaria_id ? (
						<Link
							to={`/admin/finanzas/transacciones/sin-clasificar/${cuenta._id}`}
							style={{ position: "absolute", right: 0, top: -8 }}
						>
							<Tooltip title="Transacciones Bancarias">
								<Button
									type="primary"
									icon={
										<IconMoneyCircle
											width={25}
											height={25}
											style={{ position: "relative", left: 1 }}
										/>
									}
									style={{ fontWeight: 300 }}
								></Button>
							</Tooltip>
						</Link>
					) : null}
				</Col>
			</Row>
			<Row>
				<Col span={4} className="center">
					<CustomAvatar
						name={[cuenta.nombre, cuenta.apellido]}
						image={cuenta.logo}
						color={cuenta.color}
					/>
				</Col>
				<Col span={14} className="flex-left ">
					<Text className="cuenta-nombre">
						{cuenta.nombre} <IconCheckMedal />
					</Text>
				</Col>
			</Row>
			<Card.Meta
				className="card-saldo-sub-meta"
				title={
					<Row justify="space-between">
						<Col>
							<Text style={{ marginRight: "0.5rem" }} className="text-white">
								$ {cuenta.saldo?.toMoney(true)}
							</Text>
							<small>MXN</small>
						</Col>
						<Col>
							{" "}
							<Image preview={false} src={"/images/Chip.png"} />
						</Col>
					</Row>
				}
				description={
					cuenta.syncfy_saldo ? (
						<>
							<Text style={{ marginRight: "0.5rem" }} className="text-white">
								$ {cuenta.syncfy_saldo?.toMoney()}
							</Text>
							<small>MXN</small>
						</>
					) : (
						<>
							<Text
								style={{ marginRight: "0.5rem" }}
								className="text-white"
							></Text>
						</>
					)
				}
			/>
			<Row>
				<Col span={8} className="flex-left">
					<small className="text-white">ACCOUNT NUMBER</small>
				</Col>
				<Col span={12} className="flex-left">
					<Text strong style={{ paddingLeft: "7px" }} className="text-white">
						{cuenta.cuenta}
					</Text>
				</Col>
				<Col span={4} className="center">
					{logos[cuenta.marca]}
				</Col>
			</Row>
		</Card>
	);
};

/**
 * @const CardProfit
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardGains = ({ title = "Profit", status = "success" }) => {
	return (
		<Card className="card-gains card-border-16">
			<Row>
				<Col span={3}>
					<div className={`${title === "Profit" ? "green-box" : "red-box"}`}>
						{title === "Profit" ? <IconProfit /> : <IconLoss />}
					</div>
				</Col>
				<Col span={17} className="flex-left pl-1">
					<Text className="card-title">{title}</Text>
				</Col>
				<Col span={4} className="flex-right dots">
					<Button ghost icon={<IconMenuDots />}></Button>
				</Col>
				<Col span={24} className="center">
					<Text>{moment().format("DD/MM/YYYY")}</Text>
				</Col>
				<Col span={12} className="flex-left">
					<Text className="text-paid">Monto Pagado</Text>
				</Col>
				<Col span={12} className="flex-right">
					<Text className="text-paid">Total</Text>
				</Col>
				<Col span={24} className="center mb-2">
					<Progress percent={90} status={status} showInfo={false} />
				</Col>
				<Col span={24} className="center">
					<Text>{moment().format("DD/MM/YYYY")}</Text>
				</Col>
				<Col span={12} className="flex-left">
					<Text className="text-paid">Monto Pagado</Text>
				</Col>
				<Col span={12} className="flex-right">
					<Text className="text-paid">Total</Text>
				</Col>
				<Col span={24} className="center">
					<Progress percent={100} status={status} showInfo={false} />
				</Col>
			</Row>
		</Card>
	);
};

/**
 * @const CardSaldosGraph
 * @description Card de saldos de las cuentas
 * @param Array data Lista de cuentas con saldos
 * @param String title Nombre de la grafica
 */
const CardSaldosGraph = ({ title = "Saldos", data = [] }) => {
	let dataSaldos = [
		{ value: 1000, name: "cuenta 1" },
		{ value: 0, name: "cuenta 2" },
		{ value: 20, name: "cuenta 3" },
		{ value: 100, name: "cuenta 4" },
	];
	let series = [];
	dataSaldos.map((item) => {
		series.push({
			name: item.name,
			type: "bar",
			stack: "1",
			label: {
				show: false,
			},
			barWidth: 15,
			data: [item.value],
		});
	});

	return (
		<Card
			className=" card-saldos-dashboard"
			bordered={false}
			extra={<EllipsisOutlined style={{ fontSize: 25 }} />}
			title={title}
		>
			<ReactEcharts
				style={{ height: "260px", width: "500px" }}
				theme="light"
				className="pd-1 width-100"
				option={{
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
					},
					title: {
						subtext: new Intl.NumberFormat().format(4000000),
						subtextStyle: {
							align: "center",
							fontSize: 30,
							color: "#000",
							fontWeight: "bold",
						},
					},
					legend: {
						data: dataSaldos,
						left: "left",
						bottom: 0,
						itemWidth: 10,
						itemHeight: 10,
						orient: "vertical",
						textStyle: {
							align: "right",
							fontSize: 14,
							fontWeight: "normal",
							lineHeight: 21,
							color: "#858997",
						},
						lineStyle: {
							cap: "round",
						},
						formatter: function (name) {
							let info = "";
							for (let index = 0; index < dataSaldos.length; index++) {
								const element = dataSaldos[index];
								if (element.name == name) {
									const val = new Intl.NumberFormat().format(element.value);
									info = name + " $ " + val;
									break;
								}
							}
							return info;
						},
					},
					grid: {
						left: "2%",
						right: "2%",
						bottom: "0px",
						top: "80px",
						height: "20px",
						innerWidth: "100px",
						containLabel: false,
					},
					xAxis: {
						type: "value",
						show: false,
						splitLine: {
							show: false,
						},
					},
					yAxis: {
						type: "category",
						show: false,
						splitLine: {
							show: false,
						},
					},
					series: series,
				}}
			/>
		</Card>
	);
};

/**
 * @const CardOperations
 * @description Card de saldos de las cuentas
 * @param String title Nombre del card
 * @param color Color del ProgressBar y el Icono,
 * @param String icon Si el icono es flecha arriba o flecha abajo
 */
const CardOperations = ({
	title = "",
	color = "#006BFF",
	icon = "down",
}) => {
	return (
		<Card
			bordered={false}
			className="card-operations-dashboard pl-1 pr-1"
			title={title}
		>
			<Card.Meta
				title={
					<>
						{" "}
						$32,134{" "}
						<span
							className={icon == "up" ? "operations-up" : "operations-down"}
						>
							{icon == "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %
						</span>
					</>
				}
				description="Compared to $21,340 last month"
			/>
			<Card
				extra={<EllipsisOutlined style={{ fontSize: 25, color: "#425466" }} />}
				size="small"
				className="card-operations-dashboard-inner pd-1"
			>
				<Row className="width-100">
					<Col span={24}>
						<div
							className="box-icon-rounded"
							style={{ backgroundColor: "rgba(${color},0.3)" }}
						>
							<IconToolOperation
								color={color}
								style={{ display: "block", margin: "auto" }}
							/>
						</div>
					</Col>
					<Col span={24}>
						<Row className="width-100">
							<Col span={24}>
								<p className="card-operations-dashboard-inner-title  ">
									Operations Costs
								</p>
							</Col>
							<Col span={24}>
								<Row
									align="middle"
									justify="space-between"
									className="width-100"
								>
									<Col span={20}>
										<p className="card-operations-dashboard-progress-text">
											Amounts
										</p>
									</Col>
									<Col span={4}>
										<p className="card-operations-dashboard-progress-text">
											Total
										</p>
									</Col>
								</Row>
								<Progress percent={30} showInfo={false} strokeColor={color} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
		</Card>
	);
};

/**
 * @const CardOperationsList
 * @description Card de saldos de las cuentas
 * @param String title Nombre del card
 * @param String icon Si el icono es flecha arriba o flecha abajo
 */
const CardOperationsList = ({
	title = "",
	icon = "down",
}) => {
	let data = [
		{ color: "#4807EA", nombre: "Rubro", monto: 0 },
		{ color: "#FF9C54", nombre: "Rubro", monto: 0 },
		{ color: "#00FF47", nombre: "Rubro", monto: 0 },
		{ color: "#00FF47", nombre: "Rubro", monto: 0 },
		{ color: "#00FF47", nombre: "Rubro", monto: 0 },
	];
	return (
		<Card
			bordered={false}
			className="card-operations-dashboard pl-1 pr-1"
			title={title}
		>
			<Card.Meta
				title={
					<>
						{" "}
						$32,134{" "}
						<span
							className={icon == "up" ? "operations-up" : "operations-down"}
						>
							{icon == "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %
						</span>
					</>
				}
				description="Compared to $21,340 last month"
			/>
			<List
				size="small"
				className="list-border-none list-top-dashboard"
				header={<div className="top-list-header ">Top 5 Headings</div>}
				bordered={false}
				dataSource={data}
				renderItem={(item) => (
					<List.Item>
						<List.Item.Meta
							avatar={
								<Avatar
									shape="square"
									size="small"
									style={{ backgroundColor: item.color }}
								/>
							}
							title={item.nombre}
						/>

						<div className="list-top-dashboard-monto">${item.monto}</div>
					</List.Item>
				)}
			/>
		</Card>
	);
};

/**
 *
 * @const CardProducto
 * @param {*} {producto, onEditClick, onDeleteClick} objeto y funciones para manejar la informacion del producto
 * @description Card del producto con la informacion para cotizar en marketplace
 */
const CardProducto = ({ producto, review, onEditClick, onDeleteClick }) => {
	return (
		<Card className="card-producto" style={{ width: "100%" }}>
			<Row style={{ flexWrap: "nowrap", width: "100%" }}>
				{producto.fotografias?.length > 0 && (
					<Col style={{ marginRight: "1rem" }}>
						<img
							src={`${axios.defaults.baseURL}/upload/${producto.fotografias[0]}`}
							alt=""
							className="card-producto-thumb"
						/>
					</Col>
				)}
				<Col style={{ flexGrow: 1 }}>
					<Row justify="space-between">
						<Col>
							<Space size={16}>
								<Text>{producto.modelo}</Text>
							</Space>
						</Col>

						{!review ? (
							<Col>
								<Button className="card-producto-button" onClick={onEditClick}>
									<EditFilled
										style={{
											fontSize: "1rem",
										}}
									/>
								</Button>
								<Button
									className="card-producto-button"
									onClick={onDeleteClick}
								>
									<DeleteFilled
										style={{
											fontSize: "1rem",
										}}
									/>
								</Button>
							</Col>
						) : null}
					</Row>
					<Row
						justify="space-between"
						style={{ flexWrap: "nowrap", marginTop: "0.25rem" }}
					>
						<Col style={{ flexGrow: 1, paddingRight: "2rem" }}>
							<Text
								style={{ fontSize: "0.75rem", maxWidth: 600, display: "block" }}
							>
								{producto.descripcion
									? producto.descripcion.slice(0, 200) + "..."
									: ""}
							</Text>
							{/* <Text style={{ fontSize: "1rem", marginTop: "0.5rem", display: "block" }}>
																{`${producto.prioridad === 1 ? '💰' : '⌚'}`}
														</Text> */}
						</Col>
						<Col className="card-producto-cantidad" style={{ minWidth: 50 }}>
							<Text style={{ fontSize: "0.75rem", display: "block" }}>
								{producto.capacidad_si ? producto.capacidad : null}
							</Text>
							{/* <Text style={{ fontSize: "0.75rem", display: "block" }}>
																{
																		producto.precio_ref ?
																				`$${new Intl.NumberFormat().format(producto.precio_ref)}`
																				: ""
																}
														</Text> */}
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
};

/**
 * @const CardEntrega
 * @description Card con los datos de Entrega del ProjectManager
 * @param {String} direccion  direccion de entrega del producto
 */
const CardEntrega = ({ entrega = "", almacenaje = false, financiamiento = false, plazo = 0 }) => {
	return (
		<Card
			className="card-address card-shadow card-medium-height heigth-100"
			extra={<EllipsisOutlined style={{ fontSize: 25, color: "#425466" }} />}
			title={<label className="tag-card-blue">Lugar de Entrega</label>}
		>
			<p className="mt-1">{entrega}</p>
			<Card.Meta
				title={<label className="tag-card-blue">Condiciones Comerciales</label>}
			/>
			<Row className="mt-1" justify="space-between">
				<Text>Requiere Financiamiento</Text>
				<Text className="answer">{financiamiento ? "Sí" : "No"}</Text>
			</Row>
			<Row className="mt-1" justify="space-between">
				<Text>Requiere Almacenaje</Text>
				<Text className="answer">{almacenaje ? "Sí" : "No"}</Text>
			</Row>
			<Row className="mt-1" justify="space-between">
				<Text>Tiempo del servicio</Text>
				<Text className="answer">{`${plazo} ${plazo > 1 ? "días" : "día"}`} </Text>
			</Row>
		</Card>
	);
};

/**
 * @const CardProjectInfo
 * @description Card de saldos de las cuentas
 * @param users Array de Usuarios
 * @param project Object con la informacion del projecto
 * @param color Color del ProgressBar
 */
const CardProjectInfo = (props) => {
	const {
		color = "#46BD84",
		quote_day,

		nombre = "-",
		empresa = "-",
		telefono = "-",
		correo = "-",
		progress = 0,
		avatar_props = {},
		rfq = {}
	} = props;

	return (
		<Card
			// extra={<EllipsisOutlined style={{ fontSize: 25, color }} />}
			title="Proyecto"
			className="card-project heigth-100 card-shadow card-medium-height"
		>
			<Card.Meta
				description={
					<p>
						<FlagFilled /> <span>{moment(quote_day).fromNow(true)}</span>&nbsp;
						<small>({moment(quote_day).format("LLL")})</small>
					</p>
				}
			/>
			<Row className="pb-1 ">
				<Col span={24} className="card-project-info">
					{nombre}
				</Col>
				<Col span={24} className="card-project-info">
					{empresa}
				</Col>
				<Col span={24} className="card-project-info">
					{telefono} /<span>{correo}</span>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Row align="middle" justify="space-between" className="width-100">
						<Col span={20}>
							<p className="card-operations-dashboard-progress-text">
								Quotation Progress
							</p>
						</Col>
						<Col span={4}>
							<p className="card-operations-dashboard-progress-text">
								{progress.toMoney()} %
							</p>
						</Col>
					</Row>
					<Progress percent={progress} showInfo={false} strokeColor={color} />
				</Col>
			</Row>
			<Row>
				<Col span={24} className="flex-left mt-05 mb-05">
					{avatar_props._id ? <CustomAvatar
						{...avatar_props}
						style={{ backgroundColor: "#1890ff", marginRight: '4px' }}
					/> : ''}
					{avatar_props._id ? avatar_props.name : 'Sin Asesor'}
				</Col>
				<Col span={24} className="flex-between mb-05 ">
					<Text strong className="text-blue">¿Requiere Almacenaje?</Text>
					<Text className="text-gray">{rfq.almacenaje ? 'Si' : 'No'}</Text>
				</Col>
				<Col span={24} className="flex-between mb-05 ">
					<Text strong className="text-blue">¿Requiere Financiamiento?</Text>
					<Text className="text-gray">{rfq.finaciamiento ? 'Si' : 'No'}</Text>
				</Col>
				<Col span={24} className="flex-between mb-05 ">
					<Text strong className="text-blue">Tiempo que Requerirá el Servicio (días)</Text>
					<Text className="text-gray">{rfq.plazo ? rfq.plazo : 0}</Text>
				</Col>
				<Col span={24} className="flex-between mb-05 ">
					<Text strong className="text-blue">Lugar de Entrega</Text>
					<Tooltip placement="topRight" title={rfq.entrega ? rfq.entrega : '-'}>
						<Text
							ellipsis
							style={{ maxWidth: '240px' }}
							className="text-gray">{rfq.entrega ? rfq.entrega : '-'}</Text>
					</Tooltip>
				</Col>

			</Row>
		</Card>
	);
};

/**
 * @const CardTaskProgress
 * @description Card de saldos de las cuentas
 * @param color String con el color del progress bar
 * @param task Object con la informacion del card
 */
const CardTaskProgress = ({
	color = "#46BD84",
	percent = 22,
	started = null,
	proyecto = {}
} = {}) => {

	if (proyecto.status_cliente <= 2) {
		percent = 16.66
	}

	if (proyecto.status_cliente > 2) {
		percent = 33.33
	}

	return (
		<Card
			//extra={<EllipsisOutlined style={{ fontSize: 25, color: "#425466" }} />}
			className="card-task-progress card-shadow card-medium-height heigth-100"
			title="Progreso del Proyecto"
		>
			<Card.Meta
				description={
					started !== null ? `Inicio ${moment(started).fromNow()}` : null
				}
			/>
			<Row align="middle" justify="center">
				<Col className="block-center mt-1">
					<Progress
						type="circle"
						percent={percent.toMoney()}
						width={150}
						strokeColor={color}
					/>
				</Col>
			</Row>
		</Card>
	);
};
const CardFactura = ({
	item,
	deleteItem,
	editItem,
	seeItemDetails,
}) => {
	return (
		<Card className="card-list">
			<Row className="width-100">
				<Col span={2} className="center">
					<Text strong>{moment(item.fecha_vigencia).format("DD/MM/YYYY")}</Text>
				</Col>
				<Col xs={4} className="center">
					<Text className="text-gray-dark">{item.concepto}</Text>
				</Col>
				<Col xs={3} className="center">
					<Text className="text-gray-dark">
						{item.estatus === 0
							? "Rechazada"
							: item.estatus === 1
								? "Cotización"
								: item.estatus === 2
									? "Factura"
									: item.estatus === 3
										? "Transacción"
										: "Cancelada"}
					</Text>
				</Col>
				<Col xs={3} className="center">
					<Text className="text-gray-dark">
						{item.estatus === -1
							? "Egreso"
							: item.estatus === 0
								? "Sin Cambios"
								: "Ingreso"}
					</Text>
				</Col>
				<Col xs={3} className="center">
					<Text className="text-gray-dark">{item.emisor?.nombre}</Text>
				</Col>
				<Col xs={3} className=" center">
					<Text className="text-gray-dark">{item.receptor?.nombre}</Text>
				</Col>
				<Col xs={2} className=" center">
					<Text className="text-gray-dark">
						{item.monto ? "$" + Intl.NumberFormat().format(item.monto) : ""}
					</Text>
				</Col>
				<Col xs={4} className="center">
					<Space>
						<Button
							icon={<IconDetails />}
							type="primary"
							title="Ver Detalles"
							onClick={seeItemDetails}
							style={{
								backgroundColor: "#ADFF45",
								borderColor: "#ADFF45",
							}}
						></Button>
						<Button
							icon={<IconEdit />}
							type="primary"
							title="Editar"
							onClick={editItem}
							style={{
								backgroundColor: "#456EFF",
								borderColor: "#456EFF",
							}}
						></Button>
						<Popconfirm
							placement="topRight"
							title="¿Deseas eliminar este registro?"
							okText="Si"
							cancelText="No"
							onConfirm={() => deleteItem(item._id)}
						>
							<Button
								type="primary"
								danger
								icon={<IconDelete />}
								title="Eliminar"
							/>
						</Popconfirm>
					</Space>
				</Col>
			</Row>
		</Card>
	);
};
/**
 * @const CardUtilidad
 * @description  Card con la informacion de la utilidad
 * @param utility Integer con la utilidad en porcentaje
 */
const CardUtilidad = ({ color = "#D459E8", margen = 5.5 }) => {
	return (
		<Card
			title="Margen de Utilidad"
			className="card-utilidad card-shadow card-small-height"
		>
			<Row className="pb-1 ">
				<Col span={24}>
					<Row
						align="middle"
						justify="space-between"
						className="width-100 flex"
					>
						<Col span={20}>
							<p className="card-operations-dashboard-progress-text">
								Utilidad
							</p>
						</Col>
						<Col span={4}>
							<p className="card-operations-dashboard-progress-text flex-right">
								{margen}%
							</p>
						</Col>
					</Row>
					<Progress percent={margen} showInfo={false} strokeColor={color} />
				</Col>
			</Row>
		</Card>
	);
};
/**
 * @const CardLegalInfo
 * @description  Card con la informacion legal
 */
function CardLegalInfo(props) {
	const { proyecto_id } = props;

	const [proyecto, setProyecto] = useState({});

	const [modalVigenciaVisible, setModalVigenciaVisible] = useState(false);
	const [tipo, setTipo] = useState(1);

	useEffect(() => {
		getProyecto();
	}, []);

	const getProyecto = () => {
		axios
			.get("/proyectos/id", {
				params: {
					id: proyecto_id,
				},
				headers: { Authorization: sessionStorage.getItem("token") },
			})
			.then(({ data }) => {

				setProyecto(data.data);
			})
			.catch((error) => {
				console.log("error", error);
			});
	};

	return (
		<Card className="card-shadow card-small-height padding-sm">
			<Row justify="space-between" align="bottom" className="mb-05">
				<Col span={24}>
					<Row className="mb-05">
						<Col span={23}>
							<label className="tag-card-blue">Términos y Condiciones</label>
						</Col>
					</Row>
					<Row>
						<Col span={23}>{proyecto?.cotizacion_id?.terms?.length} términos</Col>
						<Col span={1}>
							<FormOutlined
								onClick={() => {
									setModalVigenciaVisible(true);
									setTipo(1);
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row justify="space-between" align="bottom" className="mb-05">
				<Col span={24}>
					<Row className="mb-05">
						<Col span={23}>
							<label className="tag-card-blue">Vigencia</label>
						</Col>
					</Row>
					<Row>
						{/* modalVigenciaVisible, setModalVigenciaVisible */}
						<Col span={23}>{proyecto?.cotizacion_id?.vigencia} Dias</Col>
						<Col span={1}>
							<FormOutlined
								onClick={() => {
									setModalVigenciaVisible(true);
									setTipo(2);
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			{/* modalVigenciaVisible, setModalVigenciaVisible */}
			<ModalEditarVigencia
				proyecto_id={proyecto_id}
				tipo={tipo}
				visible={modalVigenciaVisible}
				onClose={() => {
					setModalVigenciaVisible(false);
					getProyecto();
				}}
			/>
		</Card>
	);
}

const CardIncomeExpense = ({ item, deleteItem, editItem, seeItemDetails }) => {
	return (
		<Card className="card-list">
			<div className={`badge-card tipo-${item.tipo}`}> </div>
			<Row className="width-100">
				<Col xs={4} xxl={2} className="center mb-1">
					<Text strong>{moment(item.fecha).format("DD/MM/YYYY")}</Text>
				</Col>
				<Col xs={5} xxl={3} className="center mb-1">
					<Text className="text-gray-dark">{item.concepto}</Text>
				</Col>
				<Col xs={5} xxl={3} className="center mb-1">
					<Text className="text-gray-dark">{item.area_id?.nombre}</Text>
				</Col>
				<Col xs={5} xxl={3} className="center mb-1">
					<Text className="text-gray-dark">{item.rubro_id?.nombre}</Text>
				</Col>
				<Col xs={5} xxl={3} className="center mb-1">
					<Text className="text-gray-dark">{item.orden_id?.folio}</Text>
				</Col>
				<Col xs={6} xxl={1} className="center mb-1">
					<CustomAvatar
						name={[item.cliente_id?.nombre, item.cliente_id?.apellido]}
					/>
				</Col>
				<Col xs={6} xxl={3} className="center mb-1">
					<Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
				</Col>
				<Col xs={6} xxl={3} className="center mb-1">
					<span
						style={{
							color: item.tipo === 1 ? "#10A902" : "#FF0000",
							fontWeight: "700",
							fontSize: "16px",
							fontFamily: "Poppins",
						}}
					>
						{" "}
						$ {Intl.NumberFormat().format(item.monto)}{" "}
					</span>
				</Col>
				<Col xs={5} xxl={2} className="center mb-1">
					<Space>
						<Button
							icon={<IconDetails />}
							type="primary"
							title="Ver Detalles"
							onClick={seeItemDetails}
							style={{
								backgroundColor: "#ADFF45",
								borderColor: "#ADFF45",
							}}
						></Button>
						<Button
							icon={<IconEdit />}
							type="primary"
							title="Editar"
							onClick={editItem}
							style={{
								backgroundColor: "#456EFF",
								borderColor: "#456EFF",
							}}
						></Button>
						<Popconfirm
							placement="topRight"
							title="¿Deseas eliminar este registro?"
							okText="Si"
							cancelText="No"
							onConfirm={() => deleteItem(item._id)}
						>
							<Button
								type="primary"
								danger
								icon={<IconDelete />}
								title="Eliminar"
							/>
						</Popconfirm>
					</Space>
				</Col>
			</Row>
		</Card>
	);
};

const CardOrdenCompra = ({
	item,
	aceptarOrden,
	pdfOrden,
}) => {

	let estatus = "";
	switch (item.estatus) {
		case 0:
			estatus = "Creada"
			break;
		case 1:
			estatus = "Aceptada"
			break;
		case 2:
			estatus = "Confirmada"
			break;
		case 3:
			estatus = "Incompleta"
			break;
		case 4:
			estatus = "Completa"
			break;

		default:
			estatus = "Cancelada"
			break;
	}


	return (
		<Card className="card-list">
			<Row className="width-100">
				{/* Fecha */}
				<Col xs={6} xxl={2} className="center mb-1">
					<Text strong>{moment(item.fecha_vigencia).format("DD/MM/YYYY")}</Text>
				</Col>

				{/* Proveedor */}
				<Col xs={6} xxl={5} className="center mb-1">
					<Text className="text-gray-dark">{item.proveedor?.alias}</Text>
				</Col>

				{/* Estatus */}
				<Col xs={6} xxl={3} className="center mb-1">
					<Text
						style={{
							minWidth: "80px",
							textAlign: "center",
							background: "#C4C4C4",
							color: "#222222",
							padding: "5px  15px",
							borderRadius: "20px",
						}}
					>
						{estatus}
					</Text>
				</Col>

				{/* Destinatario */}
				<Col xs={6} xxl={4} className="center mb-1">
					<Text
						style={{
							minWidth: "140px",
							textAlign: "center",
							background: "#deddf9",
							color: "#5C54E0",
							padding: "5px  15px",
							borderRadius: "20px",
						}}
					>
						{"Proveedor"}
					</Text>
				</Col>

				{/* Monto total */}
				<Col xs={8} xxl={3} className="center ">
					<Text className="text-gray-dark">
						{item.monto_total ? "$" + item.monto_total.toMoney() : "0"}
					</Text>
				</Col>

				{/* Monto pagado */}
				<Col xs={8} xxl={3} className="center ">
					<Text className="text-gray-dark">
						{item.monto_pagado ? "$" + item.monto_pagado.toMoney() : "0"}
					</Text>
				</Col>

				{/* Acciones */}
				<Col xs={8} xxl={3} className="center ">
					<Space>
						{item.estatus === 0 ? (
							<Button
								icon={<IconAceptarOrden />}
								type="primary"
								title="Aceptar"
								onClick={aceptarOrden}
								style={{
									backgroundColor: "#FFCB45",
									borderColor: "#FFCB45",
								}}
							></Button>
						) : null}

						<Button
							icon={<IconPDF />}
							type="primary"
							title="PDF"
							onClick={pdfOrden}
							style={{
								backgroundColor: "#54FFD6",
								borderColor: "#54FFD6",
							}}
						></Button>
					</Space>
				</Col>
			</Row>
		</Card>
	);
};


const CardAccountPurchases = ({
	monto_venta,
	costos_real,
	ingreso_real,
	facturas_emitidas,
	facturas_recibidas,
}) => {
	return (
		<Card
			className="card-shadow card-account-purchases width-100"
		>
			<Row>
				<Col xs={24} sm={12} xl={4}>
					<Row justify="center">
						<Col span={24}>
							<Title className="card-title-purple" level={5}>
								Monto de Venta
							</Title>
						</Col>
						<Col span={24} className="center">
							<Row align="middle">
								<Text className="card-monto-gray">
									$ {monto_venta?.toMoney(true)} MXN
								</Text>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={12} xl={4}>
					<Row justify="center">
						<Col span={24}>
							<Title className="card-title-purple" level={5}>
								Costo
							</Title>
						</Col>
						<Col span={24} className="center">
							<Row align="middle">
								<Col span={24}>
									<Text className="card-monto-gray">
										$ {costos_real?.toMoney(true)} MXN
									</Text>
								</Col>
							</Row>

						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={12} xl={4}>
					<Row justify="center">
						<Col span={24}>
							<Title className="card-title-purple" level={5}>
								Ingresos
							</Title>
						</Col>
						<Col span={24} className="center">
							<Row align="middle">
								<Col span={24}>
									<Text className="card-monto-gray">
										$ {ingreso_real?.toMoney(true)} MXN
									</Text>
								</Col>

							</Row>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={12} xl={4}>
					<Row justify="center">
						<Col span={24}>
							<Title className="card-title-purple" level={5}>
								Utilidad
							</Title>
						</Col>
						<Col span={24} className="center">
							<Row align="middle">
								<Col span={24}>
									<Text className="card-monto-gray">
										$ {(ingreso_real - costos_real)?.toMoney(true)} MXN
									</Text>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={12} xl={4}>
					<Row justify="center">
						<Col span={24}>
							<Title className="card-title-purple" level={5}>
								Facturas Emitidas
							</Title>
						</Col>
						<Col span={24} className="center">
							<Row align="middle">
								<Text className="card-monto-gray">
									$ {facturas_emitidas ? facturas_emitidas.toMoney(true) : 0} MXN
								</Text>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={12} xl={4}>
					<Row justify="center">
						<Col span={24}>
							<Title className="card-title-purple" level={5}>
								Facturas Recibidas
							</Title>
						</Col>
						<Col span={24} className="center">
							<Row align="middle">
								<Text className="card-monto-gray">
									$ {facturas_recibidas ? facturas_recibidas.toMoney(true) : 0} MXN
								</Text>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
};

/**
 * @const CardRazonSocial
 * @description Card con los datos de razon social para contabilidad
 */
const CardRazonSocial = ({
	razon_social = {},
	transacciones = [],
	facturas = [],
	onClick = () => { },
}) => {
	// * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
	let trans_ingresos = transacciones.find((trans) => trans._id == 1);
	let trans_egresos = transacciones.find((trans) => trans._id == 2);

	//0 - Sin Cambios ,1 - Ingreso ,-1 - Egreso
	let fact_ingresos = facturas.find((fact) => fact._id == 1);
	let fact_egresos = facturas.find((fact) => fact._id == -1);

	return (
		<Card
			onClick={onClick}
			className="card-project card-shadow card-medium-small"
			title={
				<Row className="width-100">
					<Col span={4}>
						<Link to={"/admin/contabilidad/razon-social/" + razon_social._id}>
							<Tooltip title="Ver Detalles">
								<CustomAvatar
									color={razon_social.color}
									size="large"
									image={razon_social.logo}
									name={razon_social.razon_social}
									className=" mr-1 "
								/>
							</Tooltip>
						</Link>
					</Col>
					<Col span={8} className="text-wrap">
						<Link to={"/admin/contabilidad/razon-social/" + razon_social._id}>
							<Tooltip title="Ver Detalles">
								{razon_social.razon_social}
							</Tooltip>
						</Link>
					</Col>

					<Link
						to={
							"/admin/contabilidad/razon-social/sin-clasificar/" +
							razon_social._id
						}
					>
						<Tooltip title="Acceder a las Facturas del SAT">
							<Button
								style={{ position: "absolute", right: 10, top: 10 }}
								type="primary"
								icon={<IconFacturaMoney width={27} height={27} />}
								danger
							/>
						</Tooltip>
					</Link>
				</Row>
			}
		>
			<Row align="middle" justify="start" style={{ marginTop: "24px" }}>
				<Col span={24} className="m-1 flex-left">
					<Text ellipsis className="text-gray">
						{" "}
						<FlagFilled className="mr-1" />
						<span className="mt-1">Alta &nbsp;</span>
						<span>{moment(razon_social.createdAt).format("L")}</span>
					</Text>
				</Col>
			</Row>

			{/* Bancos */}
			<Row align="middle" justify="start" className="mt-1">
				<Col span={24}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-blue">
							Bancos
						</Col>
					</Row>
				</Col>
				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Ingresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							${trans_ingresos?.monto
								? trans_ingresos?.monto.toMoney(true)
								: 0}{" "}
							MXN
						</Col>
					</Row>
				</Col>

				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Egresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							${trans_egresos?.monto
								? trans_egresos?.monto.toMoney(true)
								: 0}{" "}
							MXN
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Facturación */}
			<Row align="middle" justify="start" className="mt-1">
				<Col span={24}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-blue">
							Facturación
						</Col>
					</Row>
				</Col>

				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Ingresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							${(fact_ingresos?.monto ? fact_ingresos?.monto : 0).toMoney(
								true
							)}{" "}
							MXN
						</Col>
					</Row>
				</Col>
				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Egresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							${(fact_egresos?.monto ? fact_egresos?.monto : 0).toMoney(
								true
							)}{" "}
							MXN
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
};
/**
* @const CardEstatusContabilidad

* @description Card con los datos de razon social
*/
const CardEstatusContabilidad = ({
	SinSoporte = 0,
	Incompleto = 0,
	Completo = 0,
	onClick = () => { },
}) => {
	return (
		<Card
			onClick={onClick}
			hoverable={true}
			className="card-project card-shadow card-medium-small"
			title={
				<Row className="mb-1 p-1">
					<Text strong className="pt-5px">
						Estatus
					</Text>
				</Row>
			}
		>
			<Row align="middle" justify="start" style={{ marginTop: "10px" }}>
				<Col span={24}>
					<Row align="middle" justify="start" className="row-sinSoporte">
						<Col span={20}>
							<IconError />
							<span className="spn-titulo">Sin Soporte</span>
						</Col>
						<Col span={4}>
							<Text strong>{SinSoporte}</Text>
						</Col>
					</Row>
					<Row align="middle" justify="start" className="row-incompleto">
						<Col span={20}>
							<IconWarning />
							<span className="spn-titulo">Incompleto</span>
						</Col>
						<Col span={4}>
							<Text strong>{Incompleto}</Text>
						</Col>
					</Row>

					<Row align="middle" justify="start" className="row-completo">
						<Col span={20}>
							<IconSuccess />
							<span className="spn-titulo">Completo</span>
						</Col>
						<Col span={4}>
							<Text strong>{Completo}</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
};

/**
 * @const MontosFaltantes
 * @description
 */
const MontosFaltantes = ({
	transacciones = [],
	facturas = [],
	onClick = () => { },
}) => {
	// * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
	let trans_ingresos = transacciones.find((trans) => trans._id == 1);
	let trans_egresos = transacciones.find((trans) => trans._id == 2);

	//0 - Sin Cambios ,1 - Ingreso ,-1 - Egreso
	let fact_ingresos = facturas.find((fact) => fact._id == 1);
	let fact_egresos = facturas.find((fact) => fact._id == -1);

	return (
		<Card
			onClick={onClick}
			hoverable={true}
			className="card-project card-shadow card-medium-small"
			title={
				<Row className="mb-1 p-1" align="start">
					<Text strong className="pt-5px">
						Montos Faltantes
					</Text>
				</Row>
			}
		>
			<Row align="middle" justify="start" style={{ marginTop: "10px" }}>
				<Col span={24}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-blue">
							Bancos
						</Col>
					</Row>
				</Col>

				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Ingresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							$
							{(trans_ingresos?.orden_total -
								trans_ingresos?.monto_transacciones >
								0
								? trans_ingresos?.orden_total -
								trans_ingresos?.monto_transacciones
								: 0
							).toMoney()}{" "}
							MXN
						</Col>
					</Row>
				</Col>
				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Egresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							$
							{(trans_egresos?.orden_total -
								trans_egresos?.monto_transacciones >
								0
								? trans_egresos?.orden_total -
								trans_egresos?.monto_transacciones
								: 0
							).toMoney()}{" "}
							MXN
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Facturación */}
			<Row align="middle" justify="start" style={{ marginTop: "10px" }}>
				<Col span={24}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-blue">
							Facturación
						</Col>
					</Row>
				</Col>

				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Ingresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							$
							{(fact_ingresos?.orden_total - fact_ingresos?.monto_factura
								? fact_ingresos?.orden_total - fact_ingresos?.monto_factura
								: 0
							).toMoney()}{" "}
							MXN
						</Col>
					</Row>
				</Col>
				<Col xs={24} lg={12}>
					<Row align="middle" justify="start">
						<Col span={24} className="card-rs-text">
							Egresos
						</Col>
						<Col span={24} className="card-rs-cantidad">
							{" "}
							$
							{(fact_egresos?.orden_total - fact_egresos?.monto_factura
								? fact_egresos?.orden_total - fact_egresos?.monto_factura
								: 0
							).toMoney()}{" "}
							MXN
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
};

/**
 * @const CardMontos
 * @description Card generica para mostrar Valores numericos
 * @param {String} title  Titulo del Card
 * @param {Number} value monto a desplegar,
 * @param {Component} extra  Componente para mostrar en el extra,
 * @param {String || Component} suffix  Texto o Componente a mostrar en el suffix del Stadistics,
 * @param {String || Component} prefix Texto o Componente a mostrar en el prefix del Stadistics
 */
const CardMontos = ({
	title = "Titulo",
	value = 0,
	extra = null,
	suffix = null,
	prefix = null,
	classNameStadistic = "card-count",
}) => {
	return (
		<Card
			extra={extra}
			className="card-title-gray card-head-borderless border-10 card-shadow"
			title={title}
		>
			<Statistic
				prefix={prefix}
				suffix={suffix}
				className={classNameStadistic}
				value={value}
			/>
		</Card>
	);
};
/**
 * @const CardMontos
 * @description Card generica para mostrar Grafica de Barras
 * @param {String} title  Titulo del Card
 * @param {Array[{_id,total}]} data Datos para mostrar en la grafica
 */
const CardClientesAnuales = ({ title = "Clientes por Año", data = [] }) => {
	return (
		<Card title={title} className="card-head-borderless card-shadow">
			<ReactEcharts
				option={{
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
					},
					yAxis: {
						type: "value",
					},
					xAxis: {
						type: "category",
						data: data?.map(({ _id }) => _id),
						axisLabel: {
							inside: true,
							textStyle: {
								color: "#fff",
							},
						},
						axisTick: {
							show: false,
						},
						axisLine: {
							show: false,
						},
						z: 10,
					},
					yAxis: {
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: "#999",
							},
						},
					},
					dataZoom: [
						{
							type: "inside",
						},
					],
					series: [
						{
							type: "bar",
							showBackground: true,
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: "#83bff6" },
									{ offset: 0.5, color: "#188df0" },
									{ offset: 1, color: "#188df0" },
								]),
							},
							emphasis: {
								itemStyle: {
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: "#2378f7" },
										{ offset: 0.7, color: "#2378f7" },
										{ offset: 1, color: "#83bff6" },
									]),
								},
							},
							data: data.map(({ total }) => total),
						},
					],
				}}
				notMerge={true}
				lazyUpdate={true}
				style={{ minHeight: 500, maxWidth: 1000 }}
			/>
		</Card>
	);
};
/**
 * @const CardMontos
 * @description Card generica para mostrar Valores numericos
 * @param {String} title  Titulo del Card
 * @param {Array[{value,name}]} data Datos para mostrar en la grafica
 */
const CardVentasUsuario = ({ title = "Ventas por Usuario", data = [] }) => {
	return (
		<Card
			title={title}
			className="card-title-gray card-head-borderless border-10 card-shadow"
		>
			<ReactEcharts
				style={{ height: "200px" }}
				option={{
					tooltip: {
						position: [10, 10],
					},

					series: [
						{
							type: "pie",
							label: {
								show: true,
							},
							legendHoverLink: true,
							data: data,
						},
					],
				}}
			/>
		</Card>
	);
};

/**
 * @const CardMontos
 * @description Card generica para mostrar Valores numericos
 * @param {Object} item  Objeto que contiene la informacion del centro de distribucion
 * @param {String} urlRedirect Url a la cual se va a redireccionar al hacer click al ojo
 * @param {Function} onview Metodo que se ejecuta al hacer click al boton editar
 * @param {Function} ondelete Metodo que se ejecuta al hacer click al boton de eliminar
 */
const CardCentroDistribucion = ({
	item = {},
	urlRedirect = "#",
	onview = () => { },
	ondelete = () => { },
}) => {
	return (
		<Card
			bordered
			className="card-centros card-shadow border-10 "
			title={
				<Row justify="center" className="width-100 m-1">
					<Col span={24} className="center">
						<Text ellipsis className="text-gray  card-centro-title text-center">
							{item?.razon_social}
						</Text>
					</Col>
					<Col span={24} className="center">
						<Text strong className="pd-1">
							{item?.rfc}
						</Text>
					</Col>
					<Col span={24} className="center">
						<Text className="text-center text-gray card-centro-text-tiny">
							{item?.direccion}
						</Text>
					</Col>
				</Row>
			}
		>
			<Card.Grid hoverable={false} className="card-grid-md">
				<div className="grid-element">
					<Text ellipsis>Monto Venta</Text>
					<Text ellipsis strong>
						${" "}
						{item?.saldo_pendiente ? item?.saldo_pendiente.toMoney(true) : 0.0}{" "}
						mxn
					</Text>
				</div>
			</Card.Grid>
			<Card.Grid hoverable={false} className="card-grid-md">
				<div className="grid-element">
					<Text ellipsis>Monto Pagado</Text>
					<Text ellipsis strong>
						$ {item?.total_pagado ? item?.total_pagado.toMoney(true) : 0.0} mxn
					</Text>
				</div>
			</Card.Grid>
			<Card.Grid hoverable={false} className="card-grid-md">
				<div className="grid-element">
					<Text ellipsis>Saldo por Pagar</Text>
					<Text ellipsis strong>
						${" "}
						{item?.saldo_pendiente ? item?.saldo_pendiente.toMoney(true) : 0.0}{" "}
						mxn
					</Text>
				</div>
			</Card.Grid>
			<Card.Grid hoverable={false} className="card-grid-md">
				<div className="grid-element">
					<Text ellipsis>Total Facturado </Text>
					<Text ellipsis strong>
						${" "}
						{item?.total_facturado ? item?.total_facturado.toMoney(true) : 0.0}{" "}
						mxn
					</Text>
				</div>
			</Card.Grid>
			<Card.Grid hoverable={false} className="card-grid-lg card-grid-bottom">
				<Row
					justify="center"
					align="bottom"
					className="width-100 mt-1 mb-1"
				>
					<Col span={24} className="center">
						<Space>
							<Link to={urlRedirect}>
								<Button
									type="primary"
									icon={<IconEye style={{ color: "currentColor" }} />}
									title="Ver"
									className="ant-btn-primary-green"
								/>
							</Link>
							<Button
								type="primary"
								icon={<FormOutlined style={{ color: "currentColor" }} />}
								title="Editar"
								className="btn-primary"
								onClick={onview}
							/>
							<Popconfirm
								placement="topRight"
								title="¿Deseas eliminar este Centro de Distribución?"
								onConfirm={ondelete}
								okText="Si"
								cancelText="No"
							>
								<Button
									type="primary"
									danger
									icon={
										<IconDelete
											style={{ color: "currentColor" }}
											title="Eliminar"
										/>
									}
								/>
							</Popconfirm>
						</Space>
					</Col>

				</Row>
			</Card.Grid>
		</Card>
	);
};
/**
 * @const CardProductoMatriz
 * @description Card para mostrar la informacion de un producto
 * @param {String} title  Titulo del Card
 * @param {Array[{value,name}]} data Datos para mostrar en la grafica
 */
const CardProductoMatriz = (props) => {

	let { categoria_id, proyecto_detalle_id, rfq_detalle_id, cantidad, modelo, descripcion, proyecto_id } = props.row

	let image = '/images/nofile.png'

	if (rfq_detalle_id?.fotografia)
		image = `${axios.defaults.baseURL}/upload/${rfq_detalle_id.fotografia}`
	else if (rfq_detalle_id?.similares?.length > 0)
		image = `${axios.defaults.baseURL}/upload/${rfq_detalle_id?.similares[0]}`

	let incoterms = {
		0: "FOB",
		1: "CIF",
		2: "DDP",
	}

	let servicios = {
		0: "FULL SERVICE",
		1: "MIXTO",
		2: "SOURCING",
		3: "AUDITOTIA",
		4: "PRE",
		5: "INSPECCION",
		6: "FLETE INTERNACIONAL",
	}


	return (
		<Card className="card-producto-matriz">
			<Row>
				<Col span={6} className center>
					<Image
						width={75}
						preview={false}
						src={image}
					/>
				</Col>
				<Col span={10}>
					<Row>
						<Text strong>{rfq_detalle_id?.modelo ?? '-'}</Text>
					</Row>
					<Row>
						<Text className="desc">{rfq_detalle_id?.cantidad ?? 0}</Text>
					</Row>
					<Row>
						<Text className="desc">{rfq_detalle_id?.descripcion ?? '-'}</Text>
					</Row>
				</Col>
				<Col span={8}>
					<Tag className="categoria-tab">
						<Text>{categoria_id ? categoria_id.nombre : '-'}</Text>
					</Tag>
				</Col>
			</Row>
			<Row className="mt-1">
				<Col span={10}>Tipo de servicio:</Col>
				<Col span={14}>
					<Text strong className="blue-text">{servicios[proyecto_id.tipo_servicio]}</Text>
				</Col>
				<Col span={10}>INCOTERM:</Col>
				<Col span={14}>
					<Text strong className="blue-text">{incoterms[proyecto_id.incoterm]}</Text>
				</Col>
				<Col span={10}>CU FOB Fabrica China:</Col>
				<Col span={14}>
					<Text strong>$ {proyecto_detalle_id?.costo_proveedor?.toMoney(true)} MXN</Text>
				</Col>
			</Row>
		</Card>
	);
};

const CardSolicitudOrdenMantenimiento = ({ solicitud = {} }) => {
	return (
		<Card bordered={false} className="card-shadow">
			<Card
				bordered={false}
				className="borderless card-inner card-inner-title "
				size="small"
				title="Datos de la Solicitud"
			>
				<Row gutter={[16, 16]}>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Nombre</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.nombre}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Rol / Tipo de Usuario</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.rol}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Correo Electrónico</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.email}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Tipo de Aviso</label>
						<p className="card-text">
							<strong>{solicitud.tipo_aviso}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={18}>
						<label className="card-subtitle">Número</label>
						<p className="card-text">
							<strong>{solicitud.numero}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Fecha de Solicitud</label>
						<p className="card-text">
							<strong>{moment(solicitud.fecha).format("LLL")}</strong>{" "}
						</p>
					</Col>
				</Row>
			</Card>

			<Card
				bordered={false}
				className="borderless card-inner card-inner-title"
				size="small"
				title="Datos de la Orden de Trabajo"
			>
				<Row gutter={[16, 16]}>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">No. Orden</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.nombre}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Cliente</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.rol}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Centro de Distribución</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.email}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">ESM Estado</label>
						<p className="card-text">
							<strong>{solicitud.tipo_aviso}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Proveedor Asignado</label>
						<p className="card-text">
							<strong>{moment(solicitud.fecha).format("LLL")}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Número</label>
						<p className="card-text">
							<strong>{moment(solicitud.fecha).format("LLL")}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Tipo de Aviso</label>
						<p className="card-text">
							<strong>{moment(solicitud.fecha).format("LLL")}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Tipo de Incidencia</label>
						<p className="card-text">
							<strong>{moment(solicitud.fecha).format("LLL")}</strong>{" "}
						</p>
					</Col>

					<Col xs={24} lg={12} xl={12}>
						<label className="card-subtitle">Observaciones</label>
						<p className="card-text">
							<strong>{moment(solicitud.fecha).format("LLL")}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={12}>
						<label className="card-subtitle">Descripción Diagnóstico</label>
						<p className="card-text">
							<strong>{moment(solicitud.fecha).format("LLL")}</strong>{" "}
						</p>
					</Col>
				</Row>
			</Card>

			<Card
				bordered={false}
				className="borderless card-inner card-inner-title"
				size="small"
				title="Información de los Costos"
			>
				<Row gutter={[16, 16]}>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Pedido</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.nombre}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Recibo</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.rol}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle">Serie / Folio</label>
						<p className="card-text">
							<strong>{solicitud.usuario?.email}</strong>{" "}
						</p>
					</Col>
					<Col xs={24} lg={12} xl={6}>
						<label className="card-subtitle card-subtitle-upper">
							Costo Proveedor
						</label>
						<p className="card-text">
							<strong>{solicitud.tipo_aviso}</strong>{" "}
						</p>
					</Col>
				</Row>
			</Card>
		</Card>
	);
};

const CardResumenCostos = ({
	servicios = {
		total: 0,
		utilidad: 0,
		total_t: 5278,
	},
	refacciones = {
		total: 0,
		utilidad: 0,
		total_t: 14000,
	},
	materiales = {
		total: 0,
		utilidad: 0,
		total_t: 14000,
	},
}) => {
	return (
		<Card
			title="Resumen de Costos"
			className="card-costos-mantenimiento card-shadow sticky"
		>
			<Row justify="space-around card-costos-mantenimiento-head">
				<Col span={8}>
					<Text strong className="center bold500">
						Costo
					</Text>
				</Col>
				<Col span={8}>
					<Text strong className="center bold500">
						Margen / Utilidad
					</Text>
				</Col>
				<Col span={8}>
					<Text strong className="center bold500">
						Total
					</Text>
				</Col>
			</Row>
			<Divider className="mb-1 mt-1" />
			<Row className="mt-05" justify="space-around">
				<Col span={24}>
					<Tag className="tag-blue">Servicios</Tag>
				</Col>
				<Col span={8}>
					<Text className="center bold500">
						$ {servicios?.total ? servicios?.total.toMoney(true) : 0.0}
					</Text>
				</Col>
				<Col span={8}>
					<Space className="center" align="center" direction="vertical">
						<Text className="center bold500">
							$ {servicios?.utilidad ? servicios?.utilidad.toMoney(true) : 0.0}
						</Text>
						{/*<Text className="center bold500 amount-red">-30%</Text>*/}
					</Space>
				</Col>
				<Col span={8}>
					<Text className="center bold500">
						$ {servicios?.total ? servicios?.total_t.toMoney(true) : 0.0}
					</Text>
				</Col>
			</Row>
			<Divider className="mb-1 mt-1" />

			<Row className="mt-05">
				<Col span={24}>
					<Tag className="tag-blue">Refacciones</Tag>
				</Col>
				<Col span={8}>
					<Text className="center bold500 m-0">
						$ {refacciones?.total ? refacciones?.total.toMoney(true) : 0.0}
					</Text>
				</Col>
				<Col span={8}>
					<Space
						className="center"
						size={2}
						align="center"
						direction="vertical"
					>
						<Text className="center bold500 m-0">
							${" "}
							{refacciones?.utilidad
								? refacciones?.utilidad.toMoney(true)
								: 0.0}
						</Text>
						{/*<Text className="center bold500 m-0 amount-green ">40%</Text>*/}
					</Space>
				</Col>
				<Col span={8}>
					<Text className="center bold500 m-0">
						$ {refacciones?.total ? refacciones?.total_t.toMoney(true) : 0.0}
					</Text>
				</Col>
			</Row>
			<Divider className="mb-1 mt-1" />

			<Row className="mt-05">
				<Col span={24}>
					<Tag className="tag-blue">Materiales</Tag>
				</Col>
				<Col span={8}>
					<Text className="center bold500 m-0">
						$ {materiales?.total ? materiales?.total.toMoney(true) : 0.0}
					</Text>
				</Col>
				<Col span={8}>
					<Space
						className="center"
						size={2}
						align="center"
						direction="vertical"
					>
						<Text className="center bold500 m-0">
							${" "}
							{materiales?.utilidad ? materiales?.utilidad.toMoney(true) : 0.0}
						</Text>
						{/*<Text className="center amount-red">-30%</Text>*/}
					</Space>
				</Col>
				<Col span={8}>
					<Text className="center bold500 m-0 ">
						$ {materiales?.total ? materiales?.total_t.toMoney(true) : 0.0}
					</Text>
				</Col>
			</Row>

			<Divider />
			<Row>
				<Col span={8}>
					<Text strong className="amount-bold center">
						$ {(servicios.total + refacciones.total + materiales.total).toMoney(true)} MXN
					</Text>
				</Col>
				<Col span={8}>
					<Text strong className="amount-bold center">
						$ {(servicios.utilidad + refacciones.utilidad + materiales.utilidad).toMoney(true)} MXN
					</Text>
				</Col>
				<Col span={8}>
					<Text strong className="amount-green amount-bold center">
						$ {(servicios.total_t + refacciones.total_t + materiales.total_t).toMoney(true)} MXN
					</Text>
				</Col>
			</Row>
		</Card>
	);
};

function CardEspecificaciones(props) {

	let { proyecto, onChange } = props

	let [proveedores, setProveedores] = useState([])

	let formRef = React.createRef()

	//componentdidMount
	useEffect(() => {
		getProveedores()
	}, [])

	const getProveedores = (search) => {
		axios.get('/proveedores/list', {
			params: {
				search,
			}
		}).then(response => {
			setProveedores(response.data.data.itemsList)
		}).catch(error => {
			console.log("error", error);

		})
	}

	useEffect(() => {
		formRef.current.setFieldsValue({
			fecha_entrega: moment(proyecto.fecha_entrega),
			proveedor_id: proyecto.proveedor_id?._id ? {
				value: proyecto.proveedor_id?._id,
				key: proyecto.proveedor_id?._id,
				label: proyecto.proveedor_id?.alias
			} : null
		})
	}, [proyecto])

	return (
		<Card className="card-shadow  padding-sm card-form card-especificaciones">
			<Row className="mb-1">
				<Col span={23}>
					<Tag className="tag-blue">Lista de Especificaciones</Tag>
				</Col>
			</Row>
			<Form
				ref={formRef}
				onValuesChange={(values) => {
					if (values.proveedor_id) values.proveedor_id = values.proveedor_id.value
					props.onChange(values)
				}}
			>
				<Row align="middle" gutter={[12, 12]}>
					<Col span={14}>
						<Text>Fecha Aproximada de Entrega</Text>
					</Col>
					<Col span={10}>
						<Form.Item name="fecha_entrega">
							<DatePicker
								size="small"
								className="width-100"
							/>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Space direction="vertical" className="width-100" size={6}>
							<Text>Proveedor</Text>
							<Form.Item name="proveedor_id">
								<Select
									className="width-100"
									size="small"
									showSearch
									filterOption={false}
									showArrow
									onSearch={search => this.getProveedores(search)}
									labelInValue={true}
								>
									{
										proveedores.map(proveedor => <Option value={proveedor._id}>{proveedor.alias}</Option>)
									}
								</Select>
							</Form.Item>
						</Space>
					</Col>
				</Row>
			</Form>
		</Card>
	);
}

function CardCostos(props) {

	let { proyecto } = props

	let formRef = React.createRef()

	useEffect(() => {
		formRef.current.setFieldsValue({
			...proyecto
		})
	}, [proyecto])

	return (
		<Card className="card-shadow card-small-height padding-sm card-form">
			<Form
				onValuesChange={props.onChange}
				ref={formRef}
			>
				<Row align="middle">
					<Col span={18}>
						<Text>Costo de la Norma</Text>
					</Col>
					<Col span={6}>
						<Form.Item name="costo_nom" valuePropName="checked">
							<Checkbox />
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={18}>
						<Text>Costo de la Muestra</Text>
					</Col>
					<Col span={6}>
						<Form.Item name="costo_muestra">
							<InputNumber
								placeholder="0"
								min={0}
								size="small"
								className="width-100"
								formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								parser={value => value.replace(/\$\s?|(,*)/g, '')}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Card>
	);
}

function CardOpcionesMatriz(props) {

	let { proyecto } = props

	const [incoterm, setIncoterm] = useState(proyecto?.incoterm)

	let formRef = React.createRef()

	let services = []

	switch (incoterm) {
		//FOB
		case 0:
			services = [
				{ label: 'FULL SERVICE', value: 0 },
				{ label: 'SOURCING', value: 2 },
				{ label: 'AUDITORIA', value: 3 },
				{ label: 'PRE', value: 4 },
				{ label: 'INSPECCIÓN', value: 5 },
				{ label: 'MIXTO', value: 1 },
			]
			break;
		//Ciff
		case 1:
			services = [
				{ label: 'FULL SERVICE', value: 0 },
				{ label: 'FLETE', value: 6 },
				{ label: 'MIXTO', value: 1 },
			]

			break;

		//DDP
		case 2:
			services = [
				{ label: 'FULL SERVICE', value: 0 },
				{ label: 'MIXTO', value: 1 },
			]
			break;
	}
	useEffect(() => {
		formRef.current.setFieldsValue({
			...proyecto
		})
		setIncoterm(proyecto.incoterm)
	}, [proyecto])

	return (
		<Card className="card-shadow card-small-height padding-sm card-form">
			<Form
				/* onValuesChange={(e,x) => {

					console.log(e,x)
				}} */
				onValuesChange={(e, x) => props.onChange(e, x)}
				ref={formRef}
			>
				<Row align="middle">
					<Col span={15}>
						<Text strong>Tipo de cambio</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="tipo_cambio">
							<InputNumber
								min={0}
								size="small"
								className="width-100"
								formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								parser={value => value.replace(/\$\s?|(,*)/g, '')}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={15}>
						<Text strong>INCOTERM</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="incoterm">
							<Select
								onSelect={(value) => {
									formRef.current.setFieldsValue({
										'tipo_servicio': null
									})
									setIncoterm(value)
								}}
							>
								<Option value={0}>FOB</Option>
								<Option value={1}>CIF</Option>
								<Option value={2}>DDP</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={15}>
						<Text strong>Tipo de servicio</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="tipo_servicio">
							<Select
								options={services}
							>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={15}>
						<Text strong>Margen Minimo</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="margen_minimo">
							<InputNumber
								placeholder="0"
								min={0}
								max={100}
								size="small"
								className="width-100"
								onChange={value => {
									let cobertura = formRef.current.getFieldValue('cobertura') ? formRef.current.getFieldValue('cobertura') : 0
									formRef.current.setFieldsValue({
										margen_bruto: value + cobertura
									})
								}}
								formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								parser={value => value.replace(/\$\s?|(,*)/g, '')}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={15}>
						<Text strong>Cobertura</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="cobertura">
							<InputNumber
								placeholder="0"
								min={0}
								max={100}
								size="small"
								className="width-100"
								onChange={value => {
									let margen_minimo = formRef.current.getFieldValue('margen_minimo') ? formRef.current.getFieldValue('margen_minimo') : 0
									formRef.current.setFieldsValue({
										margen_bruto: value + margen_minimo
									})
								}}
								formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								parser={value => value.replace(/\$\s?|(,*)/g, '')}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={15}>
						<Text strong>Margen Bruto</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="margen_bruto">
							<InputNumber
								placeholder="0"
								min={0}
								size="small"
								className="width-100"
								formatter={value => `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								parser={value => value.replace(/\$\s?|(,*)/g, '')}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={15}>
						<Text strong>Regimen</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="regimen">
							<Select>
								<Option value={0}>A1</Option>
								<Option value={1}>A4</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={15}>
						<Text strong>CROSS DOCK</Text>
					</Col>
					<Col span={9}>
						<Form.Item name="cross_dock">
							<Select>
								<Option value={false}>Cliente</Option>
								<Option value={true}>Almacén</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Card>
	);
}

/**
 * @const CardInfoCustomerHeader
 * @description  Card con la informacion de cotizaciones por estatus, en el cliente
 */
function CardInfoCustomerHeader(props) {

	return <Card style={{ borderRadius: "20px", background: props.bg_color, border: 'none' }}>
		<Row>
			<Col span={22}>
				<Title style={{ margin: "0px" }} level={5} className='text-white'>{props.titulo} </Title>
			</Col>
			<Col span={2}>
				{props.icon}
			</Col>
		</Row>
		<Title style={{ margin: "0px" }} className='text-white' >{props.valor}</Title>
		<Text className='text-white '  >{props.texto}</Text>
	</Card>
}

/**
 * @const CardProyecto
 * @description Card de con la informacion basica del proyecto
 */
const CardProyecto = (props) => {
	const {
		folio,
		createdAt,
		nombre = "-",
		empresa = "-",
		telefono = "-",
		correo = "-",
	} = props;

	return (
		<Card
			title={`Proyecto ${folio}`}
			className="card-project heigth-100 card-shadow card-medium-height"
		>
			<Card.Meta
				description={
					<p className="mt-1">
						<FlagFilled /> <span>{moment(createdAt).fromNow(true)}</span>
						<small>({moment(createdAt).format("LLL")})</small>
					</p>
				}
			/>
			<Row className="pb-1 mt-1">
				<Col span={24} className="card-project-info">
					{nombre}
				</Col>
				<Col span={24} className="card-project-info">
					{empresa}
				</Col>
				<Col span={24} className="card-project-info">
					{telefono} /<span>{correo}</span>
				</Col>
			</Row>
		</Card>
	);
};

const CardCustomerRowCotizacion = (props) => {

	let { proyecto } = props

	const renderTag = (status) => {

		let tags = {
			0: <Tag color="blue">Creada</Tag>,
			2: <Tag color="purple">Enviada</Tag>,
			3: <Tag color="green">Aceptada</Tag>,
			4: <Tag color="red">Rechazada</Tag>,
			5: <Tag color="purple">Recotizando</Tag>,

		}
		return status ? tags[status] : <Tag>N/A</Tag>
	}

	return <>
		<Card
			className=" width-100 card-cotizacion"
		>
			<Row className='mb-1'>
				<Col span={10} >
					<Tag className="tag-card-cotizacion">Cotizacion {proyecto.folio ?? ''}</Tag>
				</Col>
				<Col span={10} className="flex-right" >
					{renderTag(proyecto.status_cliente)}
				</Col>
			</Row>

			<Row className="mb-1">
				<Col xs={24} md={6} className="flex-left">
					<Text>{proyecto.cliente_id?.usuario ? proyecto.cliente_id?.usuario.nombre : ''} {proyecto.cliente_id?.usuario ? proyecto.cliente_id?.usuario.apellido : ''}</Text>
				</Col>
				<Col xs={24} md={6} className="flex-column">
					<Text className="text-gray">Monto Total</Text>
					<Text>$ {proyecto?.total?.toMoney(true)} MXN </Text>
				</Col>
				<Col xs={24} md={6} className="center text-center">
					{proyecto.envio_cotizacion ? <a href={props.getPDF()} download target="_blank">Descargar Cotización</a> : 'Cotización aun no disponible'}
				</Col>
				<Col xs={24} md={6} className=" center" >
					{proyecto.envio_cotizacion ? <Space>
						<Button
							className='btn-opc-circle aceptar'
							title="Aceptar Cotizacion"
							onClick={() => props.aceptar()}
							icon={<IconBtnOpcAceptar />}
							disabled={proyecto.status_cliente > 2}
						/>

						<Button
							className='btn-opc-circle recotizar'
							title="Recotizar"
							onClick={() => props.openModal()}
							icon={<IconBtnOpcExclamacion />}
							disabled={proyecto.status_cliente > 2}
						/>

						<Button
							className='btn-opc-circle rechazar'
							title="Rechazar Cotizacion"
							onClick={(e) => { props.openModalRechazo() }}
							icon={<IconBtnOpcCancelar />}
							disabled={proyecto.status_cliente > 2}
						/>
					</Space> : ''}
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					{/*<FlagFilled className="mr-1" /> {orden?.cotizacion_id?.fecha ? moment(orden?.cotizacion_id?.fecha).format("LLL") : 'Cotización aun no disponible'}*/}
				</Col>
			</Row>
		</Card>

	</>
}

const CardCustomerRowEntrega = (props) => {

	let { proyecto } = props
	return <>
		<Card
			className=" width-100 card-cotizacion"
		>
			<Row>
				<Col xs={24} md={12} >
					<Row>
						<Col span={24}>
							<Tag className="tag-card-cotizacion mb-1">Cotizacion {proyecto.folio ?? ''}</Tag>
						</Col>
						<Col span={24} className="mb-1">
							<Text >[Descripcion]</Text>
						</Col>
						<Col span={24}>
							<FlagFilled className="mr-1" /> {moment().format("LLL")}
						</Col>
					</Row>
				</Col>
				<Col xs={24} md={12}>
					<Row>
						<Col xs={24} md={12}>
							<Row>
								<Col span={24} className="center">
									<Text className="text-gray mb-1">Entregado</Text>
								</Col>
								<Col span={24} className="center">
									<IconCheck />
								</Col>
								<Col span={24} className="center">
									<Text className="mt-1">{moment().format('DD-MM-YYYY')}</Text>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={12}>
							<Row>
								<Col span={24} className="center">
									<Text className="text-gray mb-1">Proof of Delivery</Text>
								</Col>
								<Col span={24} className="center">
									<Text>arhicvo.png</Text>
								</Col>
								<Col span={24} className="center">

								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	</>
}

const CardReservacion = ({ onEdit, orden }) => {

	return (
		<Card className="width-100 card-logistica card-shadow" bordered={false}>
			<Row className="width-100" align="middle" justify="start">
				<Col span={24}>
					<Row className="width-100" align="middle" justify="start" >
						<Col md={{ span: 22, order: 1 }} xl={{ span: 22, order: 1 }} xxl={{ span: 23, order: 1 }}  >
							<Text className="tag-card"> {orden.reservacion_flete?.nombre ? orden.reservacion_flete?.nombre : 'Flete sin asignar'} </Text>
						</Col>
						<Col md={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }} xxl={{ span: 8, order: 3 }} >
							<Text ellipsis> {orden.reservacion_flete?.descripcion} </Text>
						</Col>
						<Col md={{ span: 16, order: 4 }} xl={{ span: 16, order: 4 }} xxl={{ span: 16, order: 4 }} >
							<Space wrap>
								{
									orden.reservacion_flete?.comprobantes?.map((filename) => (
										<Button type="link" key={filename} target="_blank" href={`${axios.defaults.baseURL}/voucher/${filename}`} icon={<PaperClipOutlined />} > {filename}  </Button>
									))
								}
							</Space>
						</Col>
						<Col md={{ span: 2, order: 2 }} xl={{ span: 2, order: 2 }} xxl={{ span: 1, order: 2 }} style={{ textAlign: 'right' }} >
							<Dropdown
								placement="bottomRight"
								overlay={<Menu>
									<Menu.Item onClick={() => onEdit()}>
										Editar
									</Menu.Item>
								</Menu>}
								trigger={['click']}
							>
								<Button
									icon={<EllipsisOutlined />}
									type="ghost"
									className="no-border"
								/>
							</Dropdown>
						</Col>
					</Row>
				</Col>
				<Col span={24} className="mt-1">
					<Row align="middle" justify="start" gutter={[28, 28]}>
						<Col>
							<Text ellipsis className="text-gray"><FlagFilled /> <span>{moment(orden.createdAt).format('L')}</span></Text>
						</Col>
						<Col >
							<Space size={12}>
								<Text ellipsis className="text-gray">{moment(orden.createdAt).toNow()}</Text>
								<CustomAvatar />
							</Space>
						</Col>
						<Col >
							<Text ellipsis className="text-gray">Folio: {orden.folio}</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	)
}

const CardEmbarque = ({ onEdit, orden }) => {

	return (
		<Card className="width-100 card-logistica card-shadow" bordered={false}>
			<Row className="width-100" align="middle" justify="start">
				<Col span={24}>
					<Row className="width-100" align="middle" justify="start" >
						<Col md={14} xl={14} xxl={14}  >
							<Text className="tag-card">{orden.nombre ? orden.nombre : 'Sin embarque asignado'} </Text>
						</Col>
						<Col md={8} xl={8} xxl={8}  >
							{/* <Text className="card-logistica-section">Embarcación de Mercancia</Text> */}
						</Col>
						<Col md={2} xl={2} xxl={2} style={{ textAlign: 'right' }} >
							<Dropdown
								placement="bottomRight"
								overlay={<Menu>
									<Menu.Item onClick={() => onEdit()}>
										Editar
									</Menu.Item>
								</Menu>}
								trigger={['click']}
							>
								<Button
									icon={<EllipsisOutlined />}
									type="ghost"
									className="no-border"
								/>
							</Dropdown>
						</Col>

						<Col md={7} xl={7} xxl={7} >
							<Text ellipsis>{orden.descripcion}</Text>
						</Col>
						<Col md={7} xl={7} xxl={7} >
							<Space wrap>
								{
									orden.archivos?.map((filename) => (
										<Button type="link" key={filename} target="_blank" href={`${axios.defaults.baseURL}/voucher/${filename}`} icon={<PaperClipOutlined />} > {filename}  </Button>
									))
								}
							</Space>
						</Col>

						<Col md={5} xl={5} xxl={5} >
							<Text ellipsis>{orden.embarque?.comentarios}</Text>
						</Col>
						<Col md={5} xl={5} xxl={5} >
							<Space wrap>
								{
									orden.embarque?.comprobantes?.map((filename) => (
										<Button type="link" key={filename} target="_blank" href={`${axios.defaults.baseURL}/voucher/${filename}`} icon={<PaperClipOutlined />} > {filename}  </Button>
									))
								}
							</Space>
						</Col>
					</Row>
				</Col>
				<Col span={24} className="mt-1">
					<Row align="middle" justify="start" gutter={[28, 28]}>
						<Col>
							<Text ellipsis className="text-gray"><FlagFilled /> <span>{moment(orden.createdAt).format('L')}</span></Text>
						</Col>
						<Col >
							<Space size={12}>
								<Text ellipsis className="text-gray">{moment(orden.createdAt).toNow()}</Text>
								<CustomAvatar />
							</Space>
						</Col>
						<Col >
							<Text ellipsis className="text-gray">Folio: {orden.folio}</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	)
}

const CardTransporte = ({ onEdit, orden }) => {

	return (
		<Card className="width-100 card-logistica card-shadow" bordered={false}>
			<Row className="width-100" align="middle" justify="start">
				<Col span={24}>
					<Row className="width-100" align="middle" justify="start" >
						<Col md={{ span: 22, order: 1 }} xl={{ span: 22, order: 1 }} xxl={{ span: 23, order: 1 }}  >
							<Text className="tag-card"> {orden.nombre ? orden.nombre : 'Sin Transporte Asignado'} </Text>
						</Col>
						<Col md={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }} xxl={{ span: 8, order: 3 }} >
							<Text ellipsis>{orden.transporte_mercancia?.comentarios}</Text>
						</Col>
						<Col md={{ span: 16, order: 4 }} xl={{ span: 16, order: 4 }} xxl={{ span: 16, order: 4 }} >
							<Space wrap>
								{
									orden.transporte_mercancia?.comprobantes?.map((filename) => (
										<Button type="link" key={filename} target="_blank" href={`${axios.defaults.baseURL}/voucher/${filename}`} icon={<PaperClipOutlined />} > {filename}  </Button>
									))
								}
							</Space>
						</Col>
						<Col md={{ span: 2, order: 2 }} xl={{ span: 2, order: 2 }} xxl={{ span: 1, order: 2 }} style={{ textAlign: 'right' }} >
							<Dropdown
								placement="bottomRight"
								overlay={<Menu>
									<Menu.Item onClick={() => onEdit()}>
										Editar
									</Menu.Item>

								</Menu>}
								trigger={['click']}
							>
								<Button
									icon={<EllipsisOutlined />}
									type="ghost"
									className="no-border"
								/>
							</Dropdown>
						</Col>
					</Row>
				</Col>
				<Col span={24} className="mt-1">
					<Row align="middle" justify="start" gutter={[28, 28]}>
						<Col>
							<Text ellipsis className="text-gray"><FlagFilled /> <span>{moment(orden.createdAt).format('L')}</span></Text>
						</Col>
						<Col >
							<Space size={12}>
								<Text ellipsis className="text-gray">{moment(orden.createdAt).toNow()}</Text>
								<CustomAvatar />
							</Space>
						</Col>
						<Col >
							<Text ellipsis className="text-gray">Folio: {orden.folio}</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	)
}

export {
	CardTaskClient,
	CardMantenimientoTask,
	CardTaskStep,
	CardSaldosGraph,
	CardOperations,
	CardOperationsList,
	CardSaldo,
	CardCuenta,
	CardGains,
	CardUtilidad,
	CardLegalInfo,
	CardFactura,
	CardAccountPurchases,
	CardProducto,
	CardEntrega,
	CardProjectInfo,
	CardTaskProgress,
	CardIncomeExpense,
	CardOrdenCompra,
	CardEstatusContabilidad,
	CardRazonSocial,
	MontosFaltantes,
	CardClientesAnuales,
	CardVentasUsuario,
	CardMontos,
	CardCentroDistribucion,
	CardProductoMatriz,
	CardSolicitudOrdenMantenimiento,
	CardResumenCostos,
	CardEspecificaciones,
	CardCostos,
	CardOpcionesMatriz,
	CardReservacion,
	CardTransporte,
	CardEmbarque,

	//cards clientes
	CardInfoCustomerHeader,
	CardProyecto,
	CardCustomerRowCotizacion,
	CardCustomerRowEntrega,
};

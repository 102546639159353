import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Upload, InputNumber } from 'antd';
import { PlusOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import PhoneInput from "../../Widgets/Inputs/PhoneInput"
const { Title } = Typography;

const axios = require('axios').default;



/**
 *
 *
 * @class ModalProveedores
 * @extends {Component}
 */
class ModalProveedores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 1,
            proveedor:{},
            getPais: []
        }
    }

    ModalProveedores = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.proveedor_id !== undefined) {
            this.getProveedor()
        }
    }


    /**
     * @memberof ModalProveedores
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.proveedor_id) {
            this.updateProveedor(values)
        } else {
            this.addProveedor(values)
        }
    }


    /**
     * @memberof ModalProveedores
     * @method addProveedor
     * @description Añade un proveedor a la BD
     */
    addProveedor = (values) => {

        let archivos = []

        if (values.documentos && values.documentos.length > 0) {
            archivos = values.documentos.map(img => img.name)
        }

        axios.post('/proveedores/add', {
            ...values,
            documentos: archivos,

        }).then(response => {
            message.success('Proveedor creado')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al crear proveedor.')
        })
    }


    /**
     * @memberof ModalProveedores
     * @method getProveedor
     * @description Obtiene un proveedor de la DB
     */
    getProveedor = () => {

        this.setState({ loading: true })

        axios.get('/proveedores/get', {
            params: {
                id: this.props.proveedor_id
            }
        }).then(response => {

            let proveedor = response.data.data
            this.setState({
                proveedor: response.data.data,
            })



            let archivos = []
            if (proveedor.documentos && proveedor.documentos.length > 0) {
                archivos = proveedor.documentos.map((img, index) => {
                    return {
                        uid: index,
                        response: {
                            filename: img,
                            message: "File upload"
                        },
                        name: img,
                        url: axios.defaults.baseURL + "/upload/" + img,
                        status: 'done',
                    }
                })
            }

            //Actualiza los valores del form dentro del modal.
            this.ModalProveedores.current.setFieldsValue({

                nombre: proveedor.nombre,
                linea_credito: proveedor.linea_credito,
                alias: proveedor.alias,
                rfc: proveedor.rfc,
                plazo: proveedor.plazo,
                email: proveedor.email,
                telefono: proveedor.telefono,
                pais: proveedor.pais,
                direccion: proveedor.direccion,
                persona: proveedor.persona,
                documentos: archivos,

            })

        }).catch(error => {
            message.error(error?.response?.message)
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalProveedores
     * @method updateProveedor
     * @description Actualiza la información de un proveedor
     */
    updateProveedor = (values) => {

        this.setState({ loading: true })

        let archivos = []
        if (values.documentos && values.documentos.length > 0) {
            archivos = values.documentos.map(img => img.name)
        }
        axios.post('/proveedores/update', {
            id: this.props.proveedor_id,
            nombre: values.nombre,
            linea_credito: values.linea_credito,
            alias: values.alias,
            rfc: values.rfc,
            plazo: values.plazo,
            email: values.email,
            telefono: values.telefono,
            pais: values.pais,
            direccion: values.direccion,
            persona: values.persona,
            documentos: archivos,
        }).then(response => {

            if (response.data.success) {
                message.success('Proveedor Actualizado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar proveedor, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar proveedor')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
  *
  * @memberof ModalProveedores
  *
  * @method removeFile
  * @description Elimina un archivo del WebService.
  *
  * @param images (string)
  * Recibe el nombre de la imagen.
  */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);

        this.setState({ loadingImage: false })


    };


    /**
     *
     * @memberof ModalProveedores
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;
        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !== undefined) ? file.response.filename : file.name);
        return e && e.fileList;

    };

    /**
     *
     * @memberof ModalProveedores
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito");
                this.setState({ loadingImage: false,/* image: null */ })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };

    render() {

        const uploadButton = (
            <div>
                {this.state.loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Subir Imagen</div>
            </div>
        );

        return (
            <Form
                layout="vertical"
                name="form-proveedores"
                ref={this.ModalProveedores}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Alias"
                                name="alias"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el Alias"
                                }]}
                            >
                                <Input placeholder="Alias" />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Razón social"
                                name="razon_social"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la nombre comercial"
                                }]}
                            >
                                <Input placeholder="Nombre Comercial" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el RFC"
                                },
                                ]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el email"
                                },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el teléfono"
                                },
                                ]}
                            >
                                <PhoneInput placeholder="Teléfono" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="País"
                                name="pais"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el país"
                                },
                                ]}
                            >
                                <Input placeholder="País" />
                            </Form.Item>
                        </Col>


                        <Col xs={10} lg={8} >
                            <Form.Item
                                label="Línea de Crédito"
                                name="linea_credito"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la línea de crédito"
                                }]}
                            >
                                <InputNumber placeholder="Linea de Crédito" min={0} defaultValue={0} className="width-100" />
                            </Form.Item>
                        </Col>


                        <Col xs={4} lg={4} >
                        </Col>

                        <Col xs={10} lg={8} >
                            <Form.Item
                                label="Días del crédito"
                                name="plazo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el plazo de crédito"
                                }]}
                            >
                                <InputNumber placeholder="Plazo de Crédito" min={0} defaultValue={0} className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la dirección"
                                },
                                ]}
                            >
                                <Input placeholder="Dirección" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Persona"
                                name="persona"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre del representante"
                                },
                                ]}
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={true}
                                //maxCount = {2}
                                >
                                    <Button>
                                        <UploadOutlined /> Agregar archivos
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24} >
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Proveedor </Title>
        </div>
        <ModalProveedores {...props} />
    </Modal>

}
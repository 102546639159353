import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Select, Divider } from 'antd';
import PhoneInput from "../../../Widgets/Inputs/PhoneInput";
const { Title } = Typography;
const { Option } = Select;

const axios = require('axios').default;


const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

/**
 *
 *
 * @class ModalClientes
 * @extends {Component}
 */
class ModalClientes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            usuarios: [],
            cliente: undefined,
        }
    }

    ModalClientes = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getUsuarios()
        if (this.props.cliente_id !== undefined) {
            this.getCliente()
        }
    }



    /**
     * @memberof ModalClientes
     * @method getUsuarios
     * @description Obtiene lista de usuarios
     */
    getUsuarios = (search) => {
        this.setState({ loading: true })
        axios.get('/usuarios', {
            params: {
                responsables: true,
                search
            }
        }).then(response => {
            console.log('responsables', response.data.data)
            this.setState({
                usuarios: response.data.data.itemsList,
            })


        }).catch(error => {
            message.error('Error al traer usuarios')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalClientes
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => (this.props.cliente_id) ? this.updateCliente(values) : this.addCliente(values)

    /**
     * @memberof ModalClientes
     * @method addCliente
     * @description Añade un cliente a la BD
     */
    addCliente = (values) => {

        if (this.state.loading) return

        this.setState({ loading: true }, () => {

            axios.post('/clientes/add', {
                ...values
            }).then(response => {

                if (response.data.success) {
                    message.success('Cliente creado')
                    this.props.onClose()
                }
                else if (response.data.message !== undefined) {
                    message.error(response.data.message.title + "  " + response.data.message.content)
                }
                else {
                    message.error('Error al crear cliente, verifique los datos ingresados.')
                }
            }).catch(error => {
                console.log(error.response)
                message.error('Error al crear cliente.')
            }).finally(() => this.setState({ loading: false }))
        })

    }
    /**
     * @memberof ModalClientes
     * @method getCliente
     * @description Obtiene un cliente de la DB
     */
    getCliente = () => {

        this.setState({ loading: true })

        axios.get('/clientes/get', {
            params: {
                id: this.props.cliente_id
            }
        }).then(response => {

            if (response.data.success) {
                let usuario = response.data.data;

                this.setState({
                    cliente: response.data.data,
                })

                this.ModalClientes.current.setFieldsValue({
                    nombre          : usuario.nombre,
                    apellido        : usuario.apellido,
                    email           : usuario.email,
                    razon_social    : usuario.cliente_id.razon_social,
                    telefono        : usuario.cliente_id.telefono,
                    rfc             : usuario.cliente_id.rfc,
                    direccion       : usuario.cliente_id.direccion,
                    responsable_id  : usuario.cliente_id?.responsable_id?._id,
                    regimen_fiscal  : usuario.cliente_id?.regimen_fiscal,
                })
            }
            else {
                message.error('Error al traer cliente')
            }

        }).catch(error => {
            message.error('Error al traer cliente')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalClientes
     * @method updateCliente
     * @description Actualiza la información de un cliente
     */
    updateCliente = (values) => {

        this.setState({ loading: true })
        axios.post('/clientes/update', {
            id: this.props.cliente_id,
            ...values
        }).then(response => {

            if (response.data.success) {
                message.success('Cliente Actualizado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar cliente, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error(error.data.message)
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.ModalClientes}
                onFinish={this.onFinish}
                initialValues={{ regimen_fiscal: "621" }}
            >
                <Spin spinning={this.state.loading}>
                    <Divider>Datos Personales</Divider>
                    <Row gutter={[8,8]}>
                        <Col span={12}>
                        <Form.Item
                            label="Nombre (s)"
                            name="nombre"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese nombre"
                            }]}
                        >
                            <Input placeholder="Nombre" />
                        </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item
                            label="Apellido (s)"
                            name="apellido"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese apellido"
                            }]}
                        >
                            <Input placeholder="Apellido" />
                        </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese email"
                        }]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        label="Teléfono"
                        name="telefono"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese teléfono"
                        }]}
                    >
                        <PhoneInput />
                    </Form.Item>

                    <Divider>Datos Fiscales</Divider>

                    <Form.Item
                        label="Razón Social"
                        name="razon_social"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese su razon social"
                        }]}
                    >
                        <Input placeholder="Razón Social" />
                    </Form.Item>


                    <Form.Item
                        label="RFC"
                        name="rfc"
                        rules={[{
                            //required: true,
                            message: "Por favor, ingrese el RFC"
                        },
                        {
                            pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                            message: 'RFC invalido'
                        }
                        ]}
                    >
                        <Input placeholder="RFC" />
                    </Form.Item>
                    <Form.Item
                        label="Dirección"
                        name="direccion"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese Dirección"
                        }]}
                    >
                        <Input placeholder="Dirección" className="width-100" />
                    </Form.Item>
                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading}>
                            Guardar
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Cliente </Title>
        </div>
        <ModalClientes {...props} />
    </Modal>

}
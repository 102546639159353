import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';

const { Title } = Typography;
const axios = require('axios').default;



/**
 *
 *
 * @class ModalRubros
 * @extends {Component}
 * @description Modal que carga el formulario para agregar/actualizar rubro
 */
class ModalRubros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            rubro: {}
        }
    }

    ModalRubros = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.rubro_id !== undefined) {
            this.getRubro()
        }
    }


    /**
     * @memberof ModalRubros
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.rubro_id) {
            this.updateRubro(values)
        } else {
            this.addRubro(values)
        }
    }


    /**
     * @memberof ModalRubros
     * @method addRubro
     * @description Añade un rubro a la BD
     */
    addRubro = (values) => {
        axios.post('/rubros/add', {
            area_id: this.props.area_id,
            ...values
        }).then(response => {

            if (response.data.success) {
                message.success('Rubro creado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al crear rubro, verifique los datos ingresados.')
            }
        }).catch(error => {
            console.log(error)
            message.error('Error al crear rubro.')
        })
    }


    /**
     * @memberof ModalRubros
     * @method getRubro
     * @description Obtiene un rubro de la DB
     */
    getRubro = () => {

        this.setState({ loading: true })

        axios.get('/rubros/get', {
            params: {
                id: this.props.rubro_id
            }
        }).then(response => {

            let rubro = response.data.data
            this.setState({
                rubro: response.data.data,
            })

            this.ModalRubros.current.setFieldsValue({
                nombre: rubro.nombre,
                descripcion: rubro.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer rubro')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalRubros
     * @method updateRubro
     * @description Actualiza la información de un rubro
     */
    updateRubro = (values) => {
        this.setState({ loading: true })
        axios.post('/rubros/update', {
            ...values,
            id: this.props.rubro_id,
        }).then(response => {

            if (response.data.success) {
                message.success('Rubro Actualizado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar rubro, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar rubro')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.ModalRubros}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>


                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>

                    <Form.Item
                        label="Descripción"
                        name="descripcion"

                    >
                        <Input placeholder="Descripción" ></Input>
                    </Form.Item>
                    <Form.Item className="text-center center">
                        <Button htmlType="submit" type="primary">
                            Guardar
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>Rubro</Title>
        </div>
        <ModalRubros {...props} />
    </Modal>

}
import React from 'react'

let Proyecto = React.createContext({});
let SetProyecto = React.createContext(() => {});

export {
    Proyecto,
    SetProyecto
}

export default Proyecto;

import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, Upload, List } from 'antd';
import { PlusOutlined, UploadOutlined, LoadingOutlined, } from '@ant-design/icons';

import { IconTemplate } from '../../Widgets/Iconos';


const { Title } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalProveedoresMultiple
 * @extends {Component}
 */
class ModalProveedoresMultiple extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalProveedoresMultiple = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     * @memberof ModalProveedoresMultiple
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

    }

    normFile = (e) => {
    	this.setState({loading:true})
        const { file, fileList } = e;
        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

            console.log("file.status", file.status);

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
        	this.setState({loading:false})
            this.props.onClose()
            const blob = new Blob([e.file.response], { type: 'text/csv; charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            let filename = e.file.originFileObj?.name?.split('.')[0]

            link.setAttribute('href', url);
            link.setAttribute('download', `${filename}-Resultados.csv`);
            link.click();
            message.info(`Se ha descargado el informe de resultados de la carga.`)
        }

        if (file.status === "error") {
        	this.setState({loading:false})

            message.error(`Transacciones no fueron creadas`)
            this.props.onClose(true)
        }

        return e && e.fileList;

    };


    render() {

       
        return (
            <Form
                layout="vertical"
                ref={this.ModalProveedoresMultiple}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <List
                            style={{ justifyContent: "center" }}
                            align="center"
                            size="small"
                            header={<div>Toma en cuenta las siguientes consideraciones.</div>}
                            footer={<div>Descarga el template csv para subir múltiples Proveedores.</div>}
                            dataSource={[
                                <div style={{ justifyContent: 'center', width: '100%' }}>- No cambies el nombre de las columnas</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Solamente se guardara la información de las columnas predefinidas </div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Asegurarse que el dato esté escrito correctamente  igual que en el sistema (incluyendo mayúsculas y acentos)</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Asegúrese de no incluir acentos en los números</div>,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />

                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <a href={`${axios.defaults.baseURL}/files/templateProveedores.csv`} download>
                            <IconTemplate />
                        </a>
                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <label> Una vez que hayas guardado tu archivo .csv con los proveedores, súbalo aquí. </label>
                    </Row>

                    <Row className="ant-row-center">

                        <Col xs={24} lg={20} className="mt-3 mb-3" >
                            <Form.Item
                                label="Archivo (.csv)"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    accept={'csv'}
                                    headers={{ Authorization: axios.defaults.headers.common['Authorization'] }}
                                    action={axios.defaults.baseURL + "/voucher/add/csv/proveedores"}
                                    max={1}
                                    className="width-100"
                                >
                                    <Button className="width-100">
                                        <UploadOutlined /> Agregar archivos
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}> Proveedores Múltiples</Title>
        </div>
        <ModalProveedoresMultiple {...props} />
    </Modal>

}
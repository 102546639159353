import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, } from 'antd';


const { Title, Text } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalRechazo
 * @extends {Component}
 */
class ModalRechazo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            premium: false
        }
    }

    ModalRechazo = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    render() {


        return (
            <Row className="mt-1 " justify='center'>
	            <Col xs={22} >
	                <Text>
	                    Al rechazar, la cotización quedará cancelada. <br />
	                    Puede recotizar agregando las observaciones o mejoras para tomarlas en cuenta.
	                </Text>
	            </Col>
	            <Col span={22} className="mt-1 center">
	                <Button htmlType="submit" type="primary" className="mr-05" danger onClick={() => {
	                    this.props.updateProyecto({ status_cliente: 4 });
	                    this.props.onClose()
	                }}>
	                    Rechazar
	                </Button>
	                <Button htmlType="submit" style={{ backgroundColor: "#e4e837" }} className="mr-05" onClick={() => {
	                    this.props.openRecotizar()
	                }}>
	                    Re - Cotizar
	                </Button>
	                <Button htmlType="submit" type="ghost" onClick={() => {
	                    this.props.onClose()
	                }}>
	                    Cancelar
	                </Button>
	            </Col>
	        </Row>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Rechazar Cotización </Title>
        </div>
        <ModalRechazo {...props} />
    </Modal>

}
import React, { Component } from "react";
import { Layout, Row, Col, PageHeader, Space, Button, List, Tag, Typography, Card, message, Spin, Popconfirm, Statistic } from "antd";
import { PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, FileOutlined, EditOutlined, DeleteOutlined, } from "@ant-design/icons";

//componentes
import Sort from '../../../Widgets/Sort'
import { renderMontoTransaccion } from "../../../Utils";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import DrawerFiltros from '../../../Widgets/DrawerFiltros/DrawerFiltros';
import { IconEdit, IconDelete, IconDetails } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalTransacciones from './ModalTransacciones'
import ModalTransaccionesDetalle from './ModalTransaccionesDetalle'
import ModalTransaccionMultiple from "./ModalTransaccionMultiple";


const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')




/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de transacciones
 */
export default class Transacciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transaccion_id: undefined,


            transacciones: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,


                filters: [],
                sort: {},

                loading: false,
                filtroSearch: '',
                searching: true,
            }


        }
    }

    filterForms = React.createRef();
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getTransacciones(1);
    }

    /**
    *
    *
    * @memberof Transacciones
    * 
    * @method componentDidUpdate
    * @description Actualiza la vista si se buscan datos
    */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.getTransacciones();
            this.setState({ searching: false })
        }
    }

    /**
    *
    *
    * @memberof Transacciones
    * @method getTransacciones
    * @description Obtiene todas todas las transacciones
    * @param page página actual de la lista
    * @param limit cantidad de elementos por página
    * @param filters Filtros a aplicar a la página
    * @param sort Ordenar elementos
    * 
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        filters = this.state.transacciones.filters,
        sort = this.state.transacciones.sort,
        search = this.props.search
    } = this.state.transacciones) => {
        this.setState({ transacciones: { ...this.state.transacciones, page, limit, filters, sort, loading: true } });
        axios.post('/transacciones/list', {
            page, limit, filters, sort, search
        })
            .then(({ data }) => {
                data = data.data;

                this.setState({
                    transacciones: {
                        ...this.state.transacciones,
                        data: data.data,
                        total: data.total,
                        pages: data.pages,
                        loading: false
                    },
                    searching: true,
                    filtroSearch: search
                });
            })
            .catch(error => {
                console.log('error', error)
                message.error('No se pudieron cargar las transacciones')
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
     *
     *
     * @param {*} key
     * @memberof Transacciones
     */
    setSort = (key) => {
        let value;
        switch (this.state.transacciones.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getTransacciones({
            sort: {
                ...this.state.transacciones.sort,
                [key]: value
            }
        })
    }


    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters } = this.state.transacciones) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}

            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {
                    filtro?.filter?.nombre || 
                    filtro?.filter?.razon_social || 
                    filtro?.filter?.email || 
                    filtro?.filter?.folio ||
                    (Array.isArray(filtro?.filter?.range) && `${filtro.filter.range[0]?.format("DD/MM/YYYY")} - ${filtro.filter.range[1]?.format("DD/MM/YYYY")}`)
                }
            </Tag>
        });
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        const { objectName } = filtro
        try {
            let values = this?.drawerFiltros?.formFiltros.getFieldValue(objectName)
            
            if(filtro._id !== undefined) {
                let index = values.findIndex(e => e === filtro._id)
                values.splice(index, 1)
            }else{
                values = undefined
            }

            let newValues = {}
            newValues[objectName] = values
            this?.drawerFiltros?.formFiltros?.setFieldsValue(newValues)
            this?.drawerFiltros?.formFiltros?.submit()
        } catch (error) {
            console.log('error', error)
            this.setState({ visibleFilters: true })
        }
    }


    render() {

        const contentMenuPlus = (
            <div>
                <Row>
                    <Button htmlType="submit" type="primary" className="btn-azul" onClick={() => this.setState({ modalTransacVisible: true })}>
                        Agregar Transacciones
                    </Button>
                </Row>
                <Row className='mt-1'>
                    <Button htmlType="submit" type="primary" className="btn-azul" onClick={() => this.setState({ modalTransaccionesMultiples: true })}>
                        Agregar multiples
                    </Button>
                </Row>


            </div>
        );

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Transacciones
                                    </span>
                                </Col>
                                <Col span={24}>
                                    {this.renderFiltros()}
                                </Col>
                            </Row>
                        }
                        extra={
                            <Button
                                onClick={() => this.setState({ visibleFilters: true })}
                                className="button-plus"
                                icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.transacciones.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones.data}
                            pagination={{
                                current: this.state.transacciones.page,
                                total: this.state.transacciones.total,
                                pageSize: this.state.transacciones.limit,
                                showSizeChanger: true,
                                onChange: (page, limit) => this.getTransacciones({ page, limit })
                            }}

                            header={<Row className="header-list width-100 pl-1 pr-1">
                                <Col span={2} className=" center">
                                    <Sort
                                        sort={this.state.transacciones.sort["fecha"]} onClick={() => this.setSort("fecha")} >
                                        <Text strong >FECHA</Text>
                                    </Sort>
                                </Col>
                                <Col xs={5} className="center">
                                    <Sort
                                        sort={this.state.transacciones.sort["concepto"]} onClick={() => this.setSort("concepto")} >
                                        <Text strong >CONCEPTO</Text>
                                    </Sort>
                                </Col>
                                <Col xs={3} className="center">
                                    <Sort
                                        sort={this.state.transacciones.sort["area_id.nombre"]} onClick={() => this.setSort("area_id.nombre")} >
                                        <Text strong >ÁREA</Text>
                                    </Sort>
                                </Col>
                                <Col xs={3} className="center">
                                    <Sort
                                        sort={this.state.transacciones.sort["proyecto_id.folio"]}
                                        onClick={() => this.setSort("proyecto_id.folio")} >
                                        <Text strong >RUBRO</Text>
                                    </Sort>
                                </Col>
                                <Col xs={3} className="text-center">
                                    <Text strong >CLIENTE / PROVEEDOR</Text>
                                </Col>
                                <Col xs={3} className=" center">
                                    <Sort
                                        sort={this.state.transacciones.sort["cuenta_id.nombre"]}
                                        onClick={() => this.setSort("cuenta_id.nombre")} >
                                        <Text strong >CUENTA</Text>
                                    </Sort>
                                </Col>
                                <Col xs={3} className="center">
                                    <Sort
                                        sort={this.state.transacciones.sort["monto"]}
                                        onClick={() => this.setSort("monto")} >
                                        <Text strong >MONTO</Text>
                                    </Sort>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <div className={`badge-card tipo-${item.tipo}`}>
                                        </div>
                                        <Row className="width-100">
                                            <Col span={2} className="flex-left">
                                                <Text strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col xs={5} className="center">
                                                <Text className="text-gray-dark">{item.concepto}</Text>
                                            </Col>
                                            <Col xs={3} className="center">
                                                <Text className="text-gray-dark">{item.area_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={3} className="center">
                                                <Text className="text-gray-dark">{item.rubro_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={3} className="center">
                                                {item.proveedor_id?.alias ? <>
                                                    {item.proveedor_id?.alias}

                                                </> : item.cliente_id?.usuario ? <>
                                                    <CustomAvatar
                                                        size="small"
                                                        name={[item.cliente_id?.usuario?.nombre,
                                                        item.cliente_id?.usuario?.apellido]}
                                                        style={{ minWidth: '25px' }}
                                                    />
                                                    <Text ellipsis>{item.cliente_id?.usuario?.nombre} {item.cliente_id?.usuario?.apellido}</Text>
                                                </> : 'N/A'
                                                }
                                            </Col>
                                            <Col xs={3} className=" center">
                                                <Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={3} className="center">
                                                {renderMontoTransaccion({ tipo: item.tipo, monto: item.monto })}
                                            </Col>
                                            <Col xs={2} className="center">
                                                <Space>
                                                    <Button
                                                        // size="small"
                                                        icon={<FileOutlined style={{ color: "currentcolor" }}  />}
                                                        type="primary"
                                                        title="Ver Detalle"
                                                        onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
                                                    </Button>
                                                    <Button
                                                        // size="small"
                                                        icon={<EditOutlined style={{ color: "currentcolor" }}  />}
                                                        type="primary"
                                                        title="Editar"
                                                        onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}>
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta Transacción?"
                                                        onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                            message.success('Transaccion Eliminada')
                                                            this.getTransacciones()
                                                        }).catch((error) => {
                                                            message.success('Transacción NO Eliminada')
                                                            this.getTransacciones();
                                                        })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            // size="small"
                                                            type="primary"
                                                            danger
                                                            icon={<DeleteOutlined style={{ color: "currentcolor" }} />} title="Eliminar" />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                        />
                        <FloatingButton title="Nuevo registro" content={contentMenuPlus} />
                    </Content>
                </Spin>

                <ModalTransacciones
                    visible={this.state.modalTransacVisible}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones()
                    }}
                />

                <ModalTransaccionMultiple
                    visible={this.state.modalTransaccionesMultiples}
                    onClose={(flag) => {
                        this.setState({ modalTransaccionesMultiples: false })
                        this.getTransacciones(this.state.page)
                    }}
                />

                <ModalTransaccionesDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => this.setState({
                        modalTransacDetalleVisible: false,
                        transaccion_id: null
                    })}
                    transaccion_id={this.state.transaccion_id}
                />
                <DrawerFiltros
                    title="Filtrar Transacciones"
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filters => this.getTransacciones({
                        page: 1,
                        filters
                    })}
                    
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    negocios={false}
                />
            </>
        )
    }
}
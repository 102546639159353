import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const ServiciosSelect
 * @description Select para los servicios registrados en el sistema
 */
const ServiciosSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el servicio" } = props

    const [ servicios, setServicios ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    })


    /**
     * @const getServicios
     * @description Obitiene los servicios
     */
    const getServicios = ({page, limit, search} = servicios) => {

        axios.get('/servicios/list', {
            params: { page, limit, search },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setServicios({
                ...servicios,
                data: response.data.data.data,
                total: response.data.data.total,
            })

        }).catch(error => {
            message('Error al obtener los servicios')
        })
    }

    //componentDidMount
    useEffect(() => {
        getServicios()
    }, [])

    return (
        <Select
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getServicios({search})}
            onSelect={(servicio)=> {
                onChange(servicio.value)
            }}
            value={value}
        >
            {
                servicios.data.map(servicio => <Option value={servicio._id} key={servicio._id}>{servicio.nombre}</Option>)
            }
        </Select>
    );
}



export default ServiciosSelect;
import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select ;
const axios = require("axios");

/**
 * @const ClienteCentrosSelect
 * @description Select para los centros de distribucion del cliente que se seleccione, solo buscara los centros si el cliente_id esta definido
 * 
 * @param cliente_id ID id del cliente del cual se buscaran sus centros de distribucion
 */
const ClienteCentrosSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el Centro de Distribución", onSelect = () => {}, cliente_id, disabled = false, className} = props

    const [ centros, setCentros ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
        cliente_id: null
    })


    /**
     * @const getCentros
     * @description Obitiene los centros del cliente
     */
    const getCentros = ({
        page, 
        limit, 
        search, 
        cliente_id = centros.cliente_id
    } = centros) => {

        axios.get('/clientes/centros-distribucion/list', {
            params: { 
                page, 
                limit, 
                search,
                cliente_id
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setCentros({
                ...centros,
                cliente_id: cliente_id,
                data: response.data.data.data,
                total: response.data.data.total,
            })

        }).catch(error => {
            message('Error al obtener los clientes')
        })
    }
    
    //componentDidUpdate
    useEffect(() => {
        if(cliente_id)
            getCentros({cliente_id})
    }, [cliente_id])


    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getCentros({search})}
            onSelect={(centro)=> {
                onChange(centro.value)
                onSelect(centro.value)
            }}
            value={value}
        >
            {
                centros.data.map(centro => <Option value={centro._id} key={centro._id}>
                    <div className="flex-left-column">
                        <Text>{centro.identificador}</Text>
                        <small>{centro.razon_social}</small>
                    </div>
                </Option>)
            }
        </Select>
    );
}



export default ClienteCentrosSelect;
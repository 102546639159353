import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Steps, Card, List, Tabs, message } from "antd";
import { FaCheckCircle, FaTimesCircle, FaClock, FaRegCircle } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";

//componentes


const { Step } = Steps;
const { TabPane } = Tabs

/**
 * @const StepTracker
 * @extends {Component}
 * @description StepTracker del Cliente
 */
class StepTracker extends Component{

    constructor(props){
        super(props)
        this.state = {
            loading: false
        }
    }
    
     /**
     * @method renderAvatar
     * @description Renderiza los cheks de cada step, depende del estatus para mostrar el adecuado
     */
    renderAvatar = (key = 1) => {

        const style = {
            fontSize: 25,
            position: 'relative',
            bottom: 3
        }

        if (typeof key === "boolean")
            return (key) ? <FaCheckCircle style={style} className="step-icon" /> : <FaRegCircle style={{ ...style, color: "#2C3E50" }} />//circulo sin relleno 

        switch (key) {
            case 0:
                //Non started
                return <FaRegCircle style={{ ...style, color: "#2C3E50" }} />//circulo sin relleno

            case 1:
                //Success
                return <FaCheckCircle style={style} className="step-icon" />//check

            case 2:
                //In progress
                return <FaClock style={{ ...style, color: "#FFC107" }} />//reloj
            case 3:
                //Error
                return <FaTimesCircle style={{ ...style, color: "#DC3545" }} />//X

            case 4:
                //LOADING
                return <LoadingOutlined style={style} spin />

        }
    }


    /**
     * @method renderItem
     * @description Renderiza los steps de cada step padre
     */
    renderItem = (list = []) => <List
        itemLayout="horizontal"
        dataSource={list}
        className="width-100 card-task-list list-border-none"
        locale={{ emptyText: ' ' }}
        renderItem={({ check, task }) => (
            <List.Item >
                <List.Item.Meta
                    className="card-task-list-meta"
                    avatar={this.renderAvatar((this.state.loading === true) ? 4 : check())}
                    title={task}
                />
            </List.Item>
        )}
    />

    render(){

        let steps = [
            {
                title: "Cotización",
                steps: [
                    {

                        check: () => true,
                        task: 'Formulario de Cliente',
                    },
                    {
                        check: () => this.props.proyecto.status_cliente > 2,
                        task: <Link to={`/customer/pedidos/pm/${this.props.proyecto_id}`} >Aprobación o Rechazo</Link>,
                    },
                ],
                className: "step-color-blue",
            },
            {

                title: "Accounting",
                steps: [
                    {
                        check: e => false,
                        task: <Link to={`/customer/pedidos/pm/${this.props.proyecto_id}/accounting`} >Orden de Compra</Link>,
                    },

                ],
                className: "step-color-yellow",
            },
            {
                title: "Logística",
                steps: [
                    {
                        check: e => false,
                        task: <Link to={`/customer/pedidos/pm/${this.props.proyecto_id}/produccion`} >Producción</Link>,
                    },
                    {
                        check: e => false,
                        task: <Link to={`/customer/pedidos/pm/${this.props.proyecto_id}/embarque`} >Orden de Embarque</Link>,
                    },
                    {
                        check: e => false,
                        task: <Link to={`/customer/pedidos/pm/${this.props.proyecto_id}/entrega`} >Entrega</Link>,
                    },
                ],
                className: "step-color-pink",
            },
            {
                title: "Orden Completa",
                className: "step-color-green",
            },
        ]

        return(
            <Card className="card-shadow step-card-customer">

                <Steps direction="vertical" className="step-tracker">
                    {steps.map(({ title, steps, className }, index) => <Step
                        key={'step-' + index}
                        title={title}
                        description={this.renderItem(steps)}
                        className={className}
                    />)}
                </Steps>

            </Card>
        )
    }
}


export default StepTracker
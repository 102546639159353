import React, { Component } from 'react'
import { Row, Col, Form, Input, Button, Modal, Spin, Image } from 'antd';
import { Navigate, Link, useParams } from "react-router-dom";

import '../../Styles/Global/auth.css';

const axios = require("axios").default;


/**
 *
 *
 * @class UpdatePassword
 * @extends {Component}
 */
class UpdatePassword extends Component {



    /**
     *Creates an instance of UpdatePassword.
     * @param {*} props
     * @memberof UpdatePassword
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,

            isRecovery: false,
            isRecoveryLoading: false,
            Navigate: false,
            form: {}
        }
    }

    recoveryPassword = React.createRef();

    NavigateTo = "/login"


    /**
     *
     *
     * @memberof UpdatePassword
     */
    async componentDidMount(): void {
        const { token, email } = this.props.params;
        if (token === undefined || email === undefined) {
            this.NavigateTo = "/login";
            this.setState({ Navigate: true })
        } else {
            await this.setState({
                form: { email, token }
            })
            this.recoveryPassword.current.resetFields();
        }
    }



    /**
     *
     *
     * @memberof UpdatePassword
     */
    renderNavigate = () => {
        if (this.state.Navigate) {
            return <Navigate to={this.NavigateTo} />;
        }
    };


    /**
     *
     *
     * @memberof UpdatePassword
     */
    onFinish = async values => {
        axios.put('/password/recovery/update', values)
            .then(() => {
                this.to = '/login';
                this.setState({
                    Navigate: true
                })

            })
            .catch(() => {
                Modal.error({
                    title: "No fue posible actualizar.",
                    content: "El token es invalido o el correo no existe."
                })
            })
    };
    render() {
        return (
            <>
                {this.renderNavigate()}
                <Row style={{ height: '100vh' }}>
                    <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 10, order: 1 }} xl={{ span: 10, order: 1 }} xxl={{ span: 10, order: 1 }} >
                        <Spin spinning={false}>
                            <Row justify="center">
                                <Col span={24} >
                                    <Row justify="center">
                                        <Col xs={{ span: 20, offset: 2, pull: 1 }} sm={{ span: 24, offset: 0, pull: 0 }} md={{ span: 20, offset: 0 }} lg={{ span: 20, offset: 1, pull: 1 }} xl={{ span: 20, offset: 0, pull: 0 }} xxl={{ span: 12, offset: 0, pull: 0 }} >
                                            <Image src={'/images/logo-blue.png'} wrapperClassName="center" preview={false} className="mt-3 mb-3" />
                                            <h3 className="h3-title mt-2">{(this.props.welcome === true)? "Bienvenido": "Nueva Contraseña"}</h3>
                                            <Form name="basic" layout={"vertical"} onFinish={this.onFinish} initialValues={this.state.form} ref={this.recoveryPassword} className="mt-3">
                                                <Form.Item name="token" noStyle >
                                                    <Input type="hidden" />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Correo Electrónico"
                                                    name="email"
                                                    rules={[{
                                                        required: true,
                                                        message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                                                    }]}
                                                >
                                                    <Input className="input-login" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="password"
                                                    label="Password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Por favor, ingrese la contraseña.',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input.Password className="input-login" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="confirm"
                                                    label="Confirm Password"
                                                    dependencies={['password']}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Por favor, confirme su contraseña.!',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (!value || getFieldValue('password') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject('Las contraseñas no coinciden.');
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password className="input-login" />
                                                </Form.Item>

                                                <Form.Item >
                                                    <Button type="primary" htmlType="submit" block size="large" className="button-login">
                                                        Cambiar Contraseña
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Spin>
                    </Col>
                    <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 14, order: 2 }} xl={{ span: 14, order: 2 }} xxl={{ span: 14, order: 2 }} style={{ backgroundImage: "url('/images/bg-2.jpg')", backgroundSize: 'cover' }} >
                    </Col>
                </Row >
            </>
        )
    }
}




export default function (props) {


    return <UpdatePassword {...props} params={useParams()} />
};
import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Statistic, Tag, message, Spin, Divider, Space } from 'antd';
import { PaperClipOutlined } from "@ant-design/icons"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import Item from "antd/lib/list/Item";

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalTransaccionesDetalle
 * @extends {Component}
 */
class ModalTransaccionesDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transaccion: {
                _id: 0,
                concepto: '',
                proyecto_id: {
                    nombre: ''
                },
                cuenta_id: {
                    nombre: ''
                },
                cuenta_destino_id: {
                    nombre: ''
                },
                monto: 0,
                fecha: new Date(),

                tipo: 0,

                usuario_id: {
                    nombre: ''
                },
                area_id: {
                    nombre: ''
                },
                rubro_id: {
                    nombre: ''
                },
                descripcion: '',
                comprobantes: [],
                actualiza_saldo: false,
                tipo_cliente: 'transaccion-especie',
                descripcion: '',

                proveedor_id: {
                    nombre: ''
                },
                facturas: [{
                    factura_id: {

                    },
                    monto_abonado: 0
                }],

                inversion_id: {
                },
            },
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.transaccion_id) {
            this.getTransaccion()
        }
    }

    /**
    * @memberof ModalTransaccionesDetalle
    * @method getTransaccion
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    getTransaccion = () => {
        this.setState({ loading: true })
        axios.post('/transacciones/get', {
            id: this.props.transaccion_id,
        }).then((transaccion) => {

            console.log('get transaccion', transaccion)
            const data = transaccion.data.data;
            this.setState({ transaccion: data });
        })
            .catch(error => {
                console.log('error', error.response)
            })
            .finally(() => {
                this.setState({ loading: false })
            })

    }

    render() {
        const { transaccion } = this.state;
        return (
            <Form layout="vertical"   >
                <Title level={3} className="text-center">Detalle Transacción </Title>
                <Spin spinning={false}>
                    <Row justify="center">
                        <Col xs={24} lg={11}>
                            <Form.Item label="Concepto" >
                                <Text type="secondary" >{transaccion.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(transaccion.fecha).format('LL')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Área" >
                                <Text type="secondary" >{transaccion.area_id?.nombre}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Rubro" >
                                <Text type="secondary" >{transaccion.rubro_id?.nombre}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Cuenta" >
                                <Row className="mb-1">
                                    {(transaccion.actualiza_saldo === true) ? <Tag color="#87d068">Actualiza Saldo</Tag> : null}
                                </Row>

                                <Text type="secondary" > <CustomAvatar name={transaccion.cuenta_id.nombre} /> {transaccion.cuenta_id.nombre} &nbsp;</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Tipo" >
                                <Text type="secondary" >{
                                    (transaccion.tipo != 3) ? ((transaccion.tipo == 1) ? 'Ingreso' : 'Egreso') : 'Traspaso'}
                                </Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Monto" >
                                {(transaccion.tipo == 2) ?
                                    <Statistic
                                        value={transaccion.monto}
                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
                                        prefix={'$'}
                                        suffix={'MXN'}
                                    /> :
                                    <Statistic
                                        value={transaccion.monto}
                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }}
                                        prefix={'$'}
                                        suffix={'MXN'}
                                    />
                                }
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Cliente" >
                                <div>
                                    <CustomAvatar name={transaccion.cliente_id?.usuario?.nombre} size="small" style={{ marginRight: '5px' }} />
                                    {transaccion.cliente_id?.razon_social}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11} >
                            <Form.Item label="Negocio" >
                                <div>
                                    <CustomAvatar name={transaccion.negocio_id?.nombre} size="small" style={{ marginRight: '5px' }} />
                                    {transaccion.negocio_id?.nombre}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11} >

                        </Col>
                        <Col xs={24} lg={22}>
                            <Form.Item label="Comprobantes" >
                                {transaccion.comprobantes?.map((imagen, index) => {
                                    <Button type="link" icon={<PaperClipOutlined />} to={axios.defaults.baseURL + '/voucher/' + imagen}>{imagen}</Button>
                                })}

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22} >
                            <Form.Item label="Clasificadores" >
                                {transaccion.clasificadores?.map(function ({ _id, nombre, color }, index) {
                                    return <Tag style={{
                                        border: '1px solid ' + color,
                                        borderRadius: 0,
                                        background: `rgba(${color},0.5)`,
                                        color: color
                                    }}>{nombre}</Tag>
                                })}


                            </Form.Item>
                        </Col>

                        <Divider />
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item label="Autor" name="usuario_id">
                                <div>
                                    <CustomAvatar name={`${transaccion.usuario_id?.nombre} ${transaccion.usuario_id?.apellido}`} size="small" style={{ marginRight: '5px' }} />
                                    {`${transaccion.usuario_id?.nombre} ${transaccion.usuario_id?.apellido}`}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Editores" name="editado_por">
                                {transaccion.editado_por?.map(function ({ _id, nombre, apellido }, index) {
                                    return <div>
                                        <CustomAvatar name={`${nombre} ${apellido}`} size="small" style={{ marginRight: '5px' }} />
                                        {`${nombre} ${apellido}`}
                                    </div>
                                })}
                            </Form.Item>
                        </Col>


                    </Row>


                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Cerrar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalTransaccionesDetalle {...props} />
    </Modal>

}
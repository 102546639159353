import React, { useState, useEffect } from "react";
import { Card, Dropdown, Form, Input, List, message, Tooltip } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import Avatar from "../Widgets/Avatar/Avatar";
import axios from "axios";


/**
 *
 *
 * @param {*} props
 * @returns <List/>
 */
function ListUsers(props) {

    const [usuarios, setUsuarios] = useState({
        data: [],

        page: 1,
        limit: 5,

        total: 0,
        pages: 0,

        search: null,
    })

    /**
     * 
     * @param {*} param
     * @method getUsuarios
     * 
     * @description Obtener usuarios
     */
    const [search, setSearch] = useState(null);


    /**
     * 
     * @param {*} param
     * @method getUsuarios
     * 
     * @description Obtener usuarios
     */
    const getUsuarios = ({
        page = usuarios.page,
        limit = usuarios.limit,
        search = usuarios.search,
    } = usuarios) => {
        setUsuarios({
            ...usuarios,
            page,
            limit,
            search,
        })
        axios.get("/usuarios", { params: { page, limit, search, vendedores: 1 } })
            .then((response) => {
                if (page === 1) usuarios.data = response.data.data.itemsList;
                else
                    usuarios.data = [
                        ...usuarios.data,
                        ...response.data.data.itemsList,
                    ];
                usuarios.total = response.data.data.itemCount;
                usuarios.pages = response.data.data.pageCount;
                setUsuarios({ ...usuarios });
            })
            .catch((error) => {
                console.error(error);
                message.error("Error al traer la información");
            });
    }

    useEffect(() => {
        getUsuarios();
    }, []);

    useEffect(() => {
        if (props.search !== search) {
            setSearch(props.search);
            getUsuarios({ search: props.search });
        }
    });

    return (
        <List
            bordered={false}
            size="small"
            itemLayout="horizontal"
            dataSource={usuarios.data}
            renderItem={(item) => (
                <List.Item onClick={() => props.onSelect(item._id)}>
                    <List.Item.Meta
                        size="small"
                        avatar={
                            <Avatar
                                size="small"
                                name={[item.nombre, item.apellido]}
                                image={item.avatar}
                                color={item.color}
                            />
                        }
                        title={
                            <div>
                                {item.nombre} {item.apellido}
                            </div>
                        }
                    />
                </List.Item>
            )}
        />
    );
}

export default function UserSelector(props) {
    const { name, proyecto_id } = props;

    const [form] = Form.useForm();

    /** @type {*} SI está visible o no el dropdown */
    let [visible, setVisible] = useState(null);

    /** @type {*} SI está visible o no el dropdown */
    let [search, setSearch] = useState(null);

    /** @type {*} SI está visible o no el dropdown */
    let [user, setUser] = useState(null);

    /** @type {*} SI está visible o no el dropdown */
    let [getted, setGetted] = useState(false);

    useEffect(() => {
        if (props.user?._id !== user?._id && getted === false) {
            console.log("seteando usuario", props.user);
            setUser(props.user);
        }
    });

    const onSelect = (selected) => {
        axios.post("/proyectos/permisos", {
            proyecto_id,
            user_id: selected,
            name,
        })
            .then((response) => {
                setGetted(true);
                setUser(response?.data);
            })
            .catch((error) => {
                message.error("No fue posible guardar el permiso.");
            });

        setVisible(false);
    };

    return (
        <Dropdown
            overlayClassName="selector-user"
            onVisibleChange={setVisible}
            visible={visible}
            trigger={["click"]}
            overlay={
                <Card style={{ width: 250 }}>
                    <Input
                        value={search}
                        onChangeCapture={(event) =>
                            setSearch(event.target.value)
                        }
                        autoFocus
                        bordered={false}
                        placeholder="Buscar ..."
                    />
                    <ListUsers search={search} onSelect={onSelect} />
                </Card>
            }
        >
            <Tooltip placement="left" title={user ? `${user?.nombre} ${user?.apellido}` : ''}>
                <Avatar
                    src={!user ? <UserAddOutlined /> : undefined}
                    name={user ? [user.nombre, user.apellido] : undefined}
                    image={user?.avatar}
                    color={null}
                    style={{
                        position: "relative",
                        bottom: 6,
                    }}
                    size="small"
                />
            </Tooltip>
        </Dropdown>
    );
}

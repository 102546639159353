import React, { Component } from "react";
import { Row, Col, Button, List, Tag, Typography, Card, message, Popconfirm,Space } from "antd";

//componentes
import { IconDelete, IconEdit, IconEye } from "../../../Widgets/Iconos";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { renderMontoTransaccion }  from "../../../Utils"

//Modales
import ModalTransacciones from "../../Finanzas/Transacciones/ModalTransacciones";
import ModalTransaccionesDetalle from "../../Finanzas/Transacciones/ModalTransaccionesDetalle"

const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de Transacciones
 */
class Transacciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transacciones: {

                data: [],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                //filtros

            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTransacciones();
    }

    /**
    *
    *
    * @memberof Transacciones
    * @description Obtiene todas todas las transacciones
    * @param page pagina actual
    * @param limit limite de objetos de una pagina
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,

    } = this.state.transacciones) => {
        this.setState({ transacciones: { ...this.state.transacciones, page, limit, loading: true } });
        axios.post('/transacciones/list', {
            page, limit,
            proveedor_id: this.props.id,

        })
            .then(({ data }) => {
                data = data.data;
                console.log("transacciones", data)
                this.setState({
                    transacciones: {
                        ...this.state.transacciones,
                        data: data.data,
                        total: data.total,
                        pages: data.pages,
                        loading: false
                    }
                });
            })
            .catch(res => {
                message.error('No se pudieron cargar las transacciones')
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {

        // console.log('state', this.state)
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Proyectos" }}
                    dataSource={this.state.transacciones?.data}
                    pagination={{
                        current: this.state.page,
                        pageSize: 10,
                        total: this.state.total,
                        position: 'bottom',
                        className: "flex-left",
                        onChange: () => this.getFacturas()
                    }}
                    header={<Row className="header-list width-100 pr-1 pl-1" >
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Fecha</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Concepto</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Area</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Rubro</Text>
                        </Col>
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Cuenta</Text>
                        </Col>
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Monto</Text>
                        </Col>
                        <Col md={3} span={3} className="center">
                            <Text ellipsis strong>Acciones</Text>
                        </Col>
                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 ">
                                    <Col md={3} span={3} className="center">
                                        <Text ellipsis strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{item.concepto}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{item?.area_id?.nombre}</Text>
                                    </Col>
                                    <Col md={4} span={4} className="center">
                                        <Text ellipsis strong>{item?.rubro_id?.nombre}</Text>
                                    </Col>
                                    <Col md={3} span={3} className="center">
                                        <Text ellipsis strong>{item.cuenta_id.nombre}</Text>
                                    </Col>
                                    <Col md={3} span={3} className="center">
                                        {renderMontoTransaccion({monto: item.monto, tipo: item.tipo})}
                                    </Col>
                                    <Col md={3} span={3} className="center">
                                        <Space>
                                            <Button
                                                size="small"
                                                type="primary"
                                                className="ant-btn-primary-green"
                                                icon={<IconEye />}
                                                title="Detalle"
                                                onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
                                            />
                                            <Button
                                                size="small"
                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Factura"
                                                onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}
                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar esta Transacción?"
                                                onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                    message.success('Transacción Eliminada')
                                                    this.getTransacciones()
                                                }).catch((error) => {
                                                    message.success('Transacción NO Eliminada')
                                                    this.getTransacciones();
                                                })
                                                }
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button size="small" type="primary" danger icon={<IconDelete />} title="Eliminar" />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalTransacVisible: true })} />
                <ModalTransacciones
                    visible={this.state.modalTransacVisible}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones()
                    }}
                />
                <ModalTransaccionesDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => this.setState({
                        modalTransacDetalleVisible: false,
                        transaccion_id: null
                    })}
                    transaccion_id={this.state.transaccion_id}
                />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Transacciones {...props} />)
}
import React, { Component, createRef } from 'react';
import { Row, Col, Typography, Form, Input} from 'antd';

import '../../../../Styles/Modules/Public/marketplace.css';

const { Title } = Typography;

export default class StepInfo extends Component {

    formRef = createRef();

    constructor(props) {
        super(props)
        this.state = {
            hasAccount: true
        }
    }

    componentDidMount = () => {
        this.formRef?.setFieldsValue(this.props.contactData)
    }

    componentDidUpdate = (prevProps) => {
        if(!prevProps.contactData && this.props.contactData){
            this.formRef?.setFieldsValue(this.props.contactData)
        }
    }

    onSubmit = values => {
        this.props.setContactData(values)
        this.props.nextStep()
    }

    render() {
        return (
            <>
                <Title level={3} style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                    Información de Contacto
                </Title>
                <Form
                    layout="vertical"
                    onFinish={this.onSubmit}
                    ref={(ref) => (this.formRef = ref)}
                >
                    <Row justify="space-between" style={{ margin: "3rem 0 2rem" }}>

                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Nombre(s)"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre"
                                }]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Apellidos"
                                name="apellido"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el apellido"
                                }]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="razon_social"
                                label="Empresa  / Razón Social"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la razón social"
                                }]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="rfc"
                                label="RFC"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el RFC"
                                },{
                                    pattern: /^[A-Za-zñÑ&]{3,4}\d{6}\w{3}$/,
                                    message: "El RFC es incorrecto"
                                }]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el teléfono"
                                },{
                                    pattern: /^(\+?[0-9-]{1,4})?-?[0-9-]{10}$/,
                                    message: "El teléfono solo puede tener números (0-9), guiones (-) y una lada (+52 o +1-584)"
                                }]}
                            >
                                <Input type="tel" pattern='^(\+?[0-9-]{1,4})?-?[0-9-]{10}$' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                name="departamento"
                                label="Departamento"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el departamento"
                                }]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}
import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, Select, message, Spin, Upload } from 'antd';
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;
const axios = require('axios').default;

class ModalReservacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenCompra()
    }

    /**
     * @memberof ModalReservacion
     * @method getOrdenCompra
     * @description Obtiene los datos de la orden de compra
     * 
     */
     getOrdenCompra = () => {
        this.setState({ loading: true })

        axios.get('/ordenesCompra/get', {
            params: { id: this.props.orden_compra_id }
        }).then(async response => {
            let { reservacion_flete } = response.data.data;
            if(Array.isArray(reservacion_flete?.comprobantes)) reservacion_flete.comprobantes = reservacion_flete.comprobantes.map((file, index) => ({
                uid: index,
                name: file,
                status: 'done',
                url: `${axios.defaults.baseURL}/voucher/${file}`
            }))
            this.modalRef.current?.setFieldsValue({ ...reservacion_flete })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la orden_compra')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }

    /**
     * @memberof ModalTransporte
     * @method onFinish
     * @param {object} values Objecto con los datos del formulario
     * @description Actualiza la orden de compra
     */
    onFinish = (values) => {
        this.setState({ loading: true })

        if(Array.isArray(values.comprobantes)) values.comprobantes = values.comprobantes.map(file => file.name)

        axios.post('/ordenesCompra/update', {
            id: this.props.orden_compra_id,
            reservacion_flete: values
        }).then(async response => {
            message.success("Orden de compra actualizado")
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al actualizar la orden de compra')
        }).finally(() => {

            this.setState({ loading: false })
        })
    }

      /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        if (e.file.status === "done") {
            for (let x = 0; x < e.fileList.length; x++) {
                if (e.fileList[x].response) {
                    e.fileList[x].name = e.fileList[x].response.filename
                }
            }
        }

        if (e.file.status === "error"){
            message.error("Error al subir archivo")
        }
       
        return e && e.fileList;
    };

    deleteFile = ({name}) => {
        axios.post('/voucher/delete', {filename: name})
            .then(() => {})
            .catch(error => {
                message.error("Error al eliminar el archivo")
            })
    }


    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-reservacion"
                ref={this.modalRef}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la descripción"
                                }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Comprobantes"
                                name="comprobantes"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    onRemove={this.deleteFile}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> Subir Comprobantes
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, reservacion_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        style={{ minWidth: props.clasificacion ? 600 : undefined }}
    >
        <div className="center mb-1">
            <Title level={3}>
                Reservación Flete Maritimo
            </Title>
        </div>
        <ModalReservacion {...props} />
    </Modal>

}
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import Routes from './Routes';

import './Styles/Theme/antd-manantial-theme.css';
import './App.css';
import './Styles/Global/global.css';


import { ConfigProvider } from 'antd';

import en_US from 'antd/lib/locale/en_US';

const axios = require('axios')

axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
	config.headers.Authorization = sessionStorage.getItem("token")
	return config;
});

/**
 *
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {


  /**
   *Creates an instance of App.
   * @param {*} props
   * @memberof App
   */
  constructor(props) {
    super(props);

    this.state = {
      user: 0
    }
  }

  componentDidMount() {
    axios.get('/user/logged', {
      headers: { Authorization: sessionStorage.getItem('token') }
    })
      .then(({ data }) => this.setUser(data.data))
      .catch((error) => { })
  }

  setUser = (user) => {
    this.setState({ user })
  };

  render() {
    const { setUser } = this;
    const { user } = this.state;

    return (
      <ConfigProvider locale={en_US}>
        <BrowserRouter>
          <User.Provider value={user}>
            <SetUser.Provider value={setUser}>
              <Routes />
            </SetUser.Provider>
          </User.Provider>
        </BrowserRouter>
      </ConfigProvider>
    );
  }


}

export default (App);
import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Proveedores from '../components/Admin/Proveedores/Proveedores'
import ProveedoresDahboard from '../components/Admin/Proveedores/ProveedoresDashboard'
import ProveedorDetalle from '../components/Admin/Proveedores/ProveedorDetalle';
import CentroDistribucion from '../components/Admin/CentrosDistribucion/CentroDistribucion';

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterProveedores(props) {

    return (
        <Routes>
            <Route path="" element={<Proveedores {...props} />} />
            <Route path="dashboard" element={<ProveedoresDahboard {...props} />} />
            <Route path="detalle/:proveedor_id" element={<ProveedorDetalle  {...props}  /> } />
            <Route path="detalle/:proveedor_id/centro-distribucion/:centro_id" element={<CentroDistribucion {...props}  title="Proveedor"/>}  />
        </Routes>
    )
}

export default RouterProveedores;

import React, { Component } from 'react';
import { Layout, Button, Col, Row, message, Space, Card, PageHeader, Tag, List, Spin, Typography, Modal, } from 'antd'
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { IconDelete, IconEdit, IconEye } from '../../../Widgets/Iconos';


import { useParams, useNavigate } from 'react-router-dom';

import axios from 'axios'
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton';

import ModalXML from './../../Syncfy/ModalXML';
import ModalFacturas from './ModalFacturas';

import DrawerFiltros from '../../../Widgets/DrawerFiltros/DrawerFiltros'
import { tipos_facturas } from "../../../Utils";
const moment = require('moment');
const { Text } = Typography;
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class RazonesSociales
 * @extends {Component}
 */
class Facturas extends Component {

    constructor(props) {
        super(props)
        this.state = {

            selectedFacturas: {},

            razon_social_id: this.props.params.razon_social_id,
            facturas: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,


                filters: [],
                sort: {},

                loading: false
            },
            cuenta: {},
            vista_guardadas: false,
            update: true,
        }
    }

    /**
     *
     *
     * @memberof RazonesSociales
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los Razones Sociales
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getFacturas()
    }


    /**
    * @memberof FacturasSC

    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    getFacturas = ({
        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
        filters = this.state.facturas.filters,
        sort = this.state.facturas.sort,
    } = this.state.facturas) => {
        this.setState({ facturas: { ...this.state.facturas, page, limit, filters, sort, loading: true } });
        axios.post('/facturas/list', {
            page, limit, filters, sort
        })
            .then(response => {
                console.log('facturas', response.data.data);
                this.setState({
                    facturas: {
                        ...this.state.facturas,
                        ...response.data.data,
                        loading: false,
                    }
                });
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {*} key
     * @memberof Transacciones
     */
    setSort = (key) => {
        let value;
        switch (this.state.facturas.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getFacturas({
            sort: {
                ...this.state.facturas.sort,
                [key]: value
            }
        })
    }



    /**
     *
     *
     * @memberof Facturas
     * @descripcion elimina una factura
     * @param {ObjectId} id id de la factura
     */
    deleteFactura = (_id) => {
        axios.delete('/facturas/delete', {
            params: {
                id: _id
            }
        })
            .then(e => {
                message.success("Se ha eliminado la factura")
                this.getFacturas();
            })
            .catch(error => {
                message.success("Error al eliminar la factura")
            })
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof facturas
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters } = this.state.facturas) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}

            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.nombre || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    }
    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        const { objectName } = filtro
        try {
            let values = this?.drawerFiltros?.formFiltros.getFieldValue(objectName)
            let index = values.findIndex(e => e === filtro._id)
            values.splice(index, 1)

            let newValues = {}
            newValues[objectName] = values
            this?.drawerFiltros?.formFiltros?.setFieldsValue(newValues)
            this?.drawerFiltros?.formFiltros?.submit()
        } catch (error) {
            console.log('error', error)
            this.setState({ visibleFilters: true })
        }
    }



    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Facturas
                                    </span>
                                </Col>
                                <Col span={24}>
                                    {this.renderFiltros()}
                                </Col>
                            </Row>
                        }
                        extra={
                            <Button
                                onClick={() => this.setState({ visibleFilters: true })}
                                className="button-plus"
                                icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.facturas.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Facturas" }}
                            dataSource={this.state.facturas.data}
                            pagination={{
                                current: this.state.facturas.page,
                                pageSize: 10,
                                total: this.state.facturas.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page, limit) => this.setState(state => {
                                    state.facturas.page = page;
                                    return state;
                                }, () => this.getFacturas({ page, limit }))
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >

                                <Col span={5} className="center">
                                    <Text strong>Tipo</Text>
                                </Col>
                                <Col span={8} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Cantidad</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                console.log('item fac', item),
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col span={5} className="center">
                                                <Text>{tipos_facturas(item.tipo)}</Text>
                                            </Col>
                                            <Col span={8} className="center">
                                                <span className='width-100 center'>
                                                    <strong>{item.concepto}</strong><br />
                                                    <small>{item?.receptor?.rfc}</small>
                                                </span>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>$ {item.monto ? item.monto.toMoney() : '0.00'} <small>{item.moneda}</small></Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Space>
                                                    <Button
                                                        type="primary"
                                                        icon={<IconEdit style={{ color: "currentColor" }} />}
                                                        onClick={(e) => this.setState({ modalFacturasVisible: true, factura_id: item._id })}
                                                        title="Ver XML" >
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        icon={<DownloadOutlined style={{ color: "currentColor" }} />}
                                                        disabled={!item.factura_xml}
                                                        download href={axios.defaults.baseURL + '/voucher/' + item.factura_xml}
                                                        title="Descargar XML" >
                                                    </Button>
                                                    <Button

                                                        type="primary"
                                                        icon={<IconEye style={{ color: "currentColor" }} />}
                                                        disabled={!item.factura_xml}
                                                        onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                                                        title="Ver XML" >
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        icon={<IconDelete style={{ color: "currentColor" }} />}
                                                        onClick={(e) => Modal.confirm({
                                                            title: 'Eliminar Factura',
                                                            description: '¿Está seguro de eliminar la factura?',

                                                            onOk: () => this.deleteFactura(item._id)
                                                        })}
                                                        title="Delete " >
                                                    </Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                        <FloatingButton
                            onClick={() => this.setState({ modalFacturasVisible: true })}
                            title="Nuevo registro 2"
                        />

                    </Content>

                    <ModalXML
                        visible={this.state.ModalXMLVisible}
                        onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                        factura_xml_storage={this.state.factura_xml_storage}
                    />

                    <ModalFacturas
                        visible={this.state.modalFacturasVisible}
                        onClose={() => {
                            this.setState({ modalFacturasVisible: false, factura_id: undefined })
                            this.getFacturas()
                        }}
                        factura_id={this.state.factura_id}
                    />
                    <DrawerFiltros
                        ref={ref => this.drawerFiltros = ref}
                        title="Filtrar Facturas"
                        updateFilters={filters => this.getFacturas({
                            page: 1,
                            filters
                        })}
                        csv="facturas/list"
                        onClose={() => this.setState({ visibleFilters: false })}
                        visible={this.state.visibleFilters}
                    />


                </Spin>
            </>

        )
    }
}


export default function (props) {
    return <Facturas {...props} params={useParams()} navigate={useNavigate()} />
}
import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { Typography, Space, message, Collapse, Tabs, Tooltip, Button, } from "antd";
import { IconOneCircle, IconTwoCircle, IconThreeCircle, IconFourCircle } from "../../../Widgets/Iconos";

//Componentes
import FOB from "../Collapses/FOB";
import Base from "../Collapses/Base";
import Matriz from "../Collapses/Matriz";
import ResumenCostosModelo from "../Collapses/ResumenCostosModelo";
import ResumenCostosTotal from "../Collapses/ResumenCostosTotal";

import { SetProyecto, Proyecto } from '../../../../Hooks/Proyecto'

//scss
import "../../../../Styles/Modules/ProjectManager/MatrizCostos.scss";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";


const { Text } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;


/**
 *
 *
 * @export
 * @param {*} props
 * @returns
/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function TableMatrizCostos(props) {

	const [fobChecked, setFobChecked] = useState(false)
	const [matrizChecked, setMatrizChecked] = useState(false)

	const [visibleModal, setVisibleModal] = useState(false)
	/** @type {*} Información de la orden */
	let proyecto = useContext(Proyecto)
	const refMatriz = useRef(null);


	/**
	 * @description Valida los campos del fob
	 */
	useEffect(() => {
		let validations = {
			fob: (props.productos?.length > 0 && !(props.productos?.find(p => !p.proyecto_detalle?._id)))
		}
		setFobChecked(validations.fob)
	}, [props.productos])




	/**
	 * @description Valida los campos del fob
	 */
	useEffect(() => {
		if (fobChecked == true && proyecto?.status < 1) {
			updateTrackCotizacion(1)
		}
		if (fobChecked && matrizChecked)
			props.setVisiblePreview(fobChecked)

	}, [fobChecked])



	/**
	 * @description Valida los campos de la matriz
	 */
	useEffect(() => {
		if (matrizChecked == true && proyecto?.status < 2) {
		updateTrackCotizacion(2)
	}
	if (fobChecked && matrizChecked)
		props.setVisiblePreview(fobChecked)


}, [matrizChecked])





/**
 * @memberof TableMatrizCostos
 *
 * @method updateMatriz
 * @description actualiza la informacion de la parte de la matriz
 */
const updateMatriz = () => {
	axios.post('/proyecto/matriz/update', {
		proyecto_id: props.proyecto_id,
	}).then(data => {
		message.success('Matriz Actualizada')
	}).catch(error => {
		console.log('error up matriz', error)
		message.error('Error al actualizar')
	})

	if (refMatriz.current) {
		refMatriz.current.getProductosMatriz()
		SetProyecto(refMatriz.current.getProductosMatriz())

	}
}



/**
 * @method updateTrackCotizacion
 * @description Actualiza el estatus del proyecto para poder actualizar el steptracker
 * @param {*} estatus 
 */
const updateTrackCotizacion = (estatus) => {
	axios.post('/project-manager/steptracker', {
		proyecto_id: props.proyecto_id,
		estatus
	}).then(data => {
		if (refMatriz.current) {
			SetProyecto(refMatriz.current.getProductosMatriz())
		}
		message.success('step Tracker Actualizado')
	}).catch(error => {
		console.log('error al actualizar step')
		message.error('step Tracker error al actualizar')
	})
}


return (

	<>
		<Collapse
			className="collapse-matriz"
			collapsible="header"
			bordered={false}
			destroyInactivePanel={true}
			accordion

		>
			<Panel
				className="panel-matriz"
				key={"datos-fob"}
				header={
					<Text className="collapsable-title">
						<IconOneCircle /> Lista de Productos
					</Text>
				}
				extra={<Space>
					<div className={`collapsable-icon-status ${fobChecked ? 'green' : 'red'}`}>
						{fobChecked ? <CheckOutlined size={24} /> : <CloseOutlined size={24} />}
					</div>
					<Tooltip title="Agregar un Producto">
						<Button size="small" type="primary" onClick={() => setVisibleModal(true)} className="ant-btn-primary-purple" icon={<PlusOutlined style={{ color: "#FFF" }} />}></Button>
					</Tooltip>
				</Space>}
			>
				<FOB {...props} setChecked={setFobChecked} visibleModal={visibleModal} setVisibleModal={setVisibleModal} />
			</Panel>
			<Panel
				className="panel-matriz"
				key={"datos-base"}
				header={
					<Text className="collapsable-title">
						<IconTwoCircle /> Datos Base
					</Text>
				}
			>
				<Base
					valoresIniciales={props.valoresIniciales}
					proyecto_id={props.proyecto_id}
					proyecto={props.proyecto}
				/>
			</Panel>
			<Panel
				className="panel-matriz"
				key={"matriz"}
				header={
					<Text className="collapsable-title">
						<IconThreeCircle /> Matriz de Costos
					</Text>
				}
				extra={<Space>
					<Button
						type="primary"
						size="small"
						onClick={() => updateMatriz()}
					>
						Actualizar Matriz
					</Button>
				</Space>}
			>
				<Matriz
					proyecto_id={props.proyecto_id}
					updateEstructura={value => props.setEstructura(value)}
					ref={refMatriz}
					setVisiblePreview={value => props.setVisiblePreview(value)}

				/>
			</Panel>
			<Panel
				className="panel-matriz"
				key={"resumen-costos"}
				header={
					<Text className="collapsable-title">
						<IconFourCircle /> Resumen
					</Text>
				}
			>
				<Tabs defaultActiveKey="1" centered>
					<TabPane tab="Análisis por Modelo" key="1">
						<ResumenCostosModelo
							proyecto_id={props.proyecto_id}
							proyecto={props.proyecto}
							productos={props.productos}
							estructura={props.estructura}
						/>
					</TabPane>
					<TabPane tab="Análisis Total" key="2">
						<ResumenCostosTotal
							proyecto_id={props.proyecto_id}
							proyecto={props.proyecto}
							productos={props.productos}
							estructura={props.estructura}

						/>
					</TabPane>
				</Tabs>
			</Panel>
		</Collapse>

	</>
);
}

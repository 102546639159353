import React, { Component } from 'react';
import { Button, Col, Row, message, Spin, Typography, Modal } from 'antd'
import axios from 'axios'
import { useNavigate, useParams, Link, Route, Routes } from "react-router-dom";


//componentes
import { CardCustomerRowCotizacion } from "../../../Widgets/Cards";

//Modales
import ModalRechazo from './ModalRechazo'
import ModalRecotizar from './ModalRecotizar'

const { Text, Title } = Typography;


/**
 * @export
 * @class Cotizacion
 * @extends {Component}
 */
class Cotizacion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.params.orden_id,
            data: [],
            loading: false,
            cliente: {},
            orden: {},
            modalRechazoVisible: false
        }
    }

    /**
     * @memberof Cotizaciones
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log("this.props.proyecto", this.props.proyecto);
        
    }



    back = () => {
        this.props.history.goBack();
    }

    /**
     * @memberof Cotizacion
     * 
     * @method getPDF
     * @description Generamos el PDF de la cotizacion
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/proyecto/cotizacion/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('proyecto_id', this.props.proyecto._id)
        return urlPDF.href
    }

    /**
     * @memberof Cotizacion
     * 
     * @method aceptarProyecto
     * @description abre el modal para confirmar si el proyecto se acepta
     */
    aceptarProyecto = () => {
        Modal.confirm({
            title: '¿Seguro de aceptar la cotización?',
            content: '',
            onOk: ()=>this.updateProyecto({status_cliente: 3}),
            cancelText: 'Cancelar',
            okText: 'Aceptar',
        })
    }


    /**
     * @memberof Cotizacion
     * 
     * @method updateProyecto
     * @description Actualiza la información del proyecto
     */
    updateProyecto = (values) => {
        this.setState({loading: true})
        axios.put('/customer/proyecto',{
            ...values,
            proyecto_id: this.props.proyecto._id,
        }).then(response => {
            message.success('Cotización actualizada')
            this.props.getProyecto()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al actualizar la cotización')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={24}>
                            <Title level={3}>Cotizaciones</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <CardCustomerRowCotizacion
                                proyecto={this.props.proyecto}
                                getPDF={() => this.getPDF()}
                                aceptar={() => this.aceptarProyecto()}
                                openModal={() => this.setState({ modal_recotizar: true })}
                                openModalRechazo={() => this.setState({ modal_rechazo: true })}
                                
                            />
                        </Col>
                    </Row>
                </Spin>
                <ModalRecotizar
                	visible={this.state.modal_recotizar}
                	onClose={(flag)=>{
                		this.setState({ modal_recotizar: false })
                        if(flag === true){
                            this.props.getProyecto()
                        }
                	}}
                    proyecto_id={this.props.proyecto._id}
                />

                <ModalRechazo
                    visible={this.state.modal_rechazo}
                    onClose={()=>{
                        this.setState({ modal_rechazo: false })
                    }}
                    updateProyecto={this.updateProyecto}
                    openRecotizar={()=>this.setState({modal_rechazo: false, modal_recotizar: true})}
                />
            </>

        )
    }
}


export default function	Vista(props){
	return <Cotizacion  {...props} params={useParams()} navigate={useNavigate()}/>
}
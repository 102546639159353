import React from 'react'

import { Row, Col, Space, Button, List, Typography, Card, Popconfirm, message} from "antd";
import moment from 'moment';

//componentes
import { renderMontoTransaccion } from "../../../Utils";
import { IconDelete, IconEdit, IconEye } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//modal
import ModalTransacciones from '../../Finanzas/Transacciones/ModalTransacciones';
import ModalTransaccionesDetalle from '../../Finanzas/Transacciones/ModalTransaccionesDetalle';

const {Text} = Typography
const axios = require('axios').default

export default class TransaccionesTab extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            modal_visible: false,
            modal_visible_detalle: false,
            transaccion_id: undefined,

            transacciones: {
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                pages: 0,
                loading: false
            }
        }
    }

    componentDidMount(){
        this.getTransacciones()
    }


    /**
     * @memberof EgresosList
     * @method getTransacciones
     * @description Obtiene las transacciones de el proyecto correspondiente
     */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit
    } = this.state.transacciones) => {

        this.setState({loading: true})
        axios.post('/transacciones/list', {
            page,
            limit,
            mantenimiento_id: this.props.mantenimiento_id,
        }).then(({ data }) => {
            console.log("data", data.data);
            this.setState({transacciones: data.data})
                
        }).catch(err => {
            console.log(err)
            message.error('Error al obtener las transacciones')
        })
        .finally(()=>this.setState({loading: false}))
    }

    render(){
        return(
            <>
                <List
                    loading={this.state.transacciones.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    dataSource={this.state.transacciones.data}
                    pagination={{
                        current: this.state.transacciones.page,
                        total: this.state.transacciones.total,
                        pageSize: this.state.transacciones.limit,
                        showSizeChanger: true,
                        onChange: (page, limit) => this.getTransacciones({ page, limit })
                    }}

                    header={<Row className="header-list width-100 pl-1 pr-1">
                        <Col xs={2} className=" center">
                            <Text strong >FECHA</Text>
                        </Col>
                        <Col xs={4} className="center">
                            <Text strong >CONCEPTO</Text>
                            
                        </Col>
                        <Col xs={3} className="center">
                            <Text strong >ÁREA</Text>
                            
                        </Col>
                        <Col xs={3} className="center">
                            <Text strong >RUBRO</Text>
                            
                        </Col>
                        <Col xs={3} className="center">
                            <Text strong >FOLIO</Text>
                        </Col>
                        <Col xs={3} className=" center">
                            <Text strong >CUENTA</Text>
                        </Col>
                        <Col xs={3} className="center">
                            <Text strong >MONTO</Text>
                        </Col>
                    </Row>
                    }

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100">
                                    <Col xs={2} className="center">
                                        <Text strong>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                    </Col>
                                    <Col xs={5} className="center">
                                        <Text strong>{item.concepto}</Text>
                                    </Col>
                                    <Col xs={4} className="center">
                                        <Text strong>{item.area_id.nombre}</Text>
                                    </Col>
                                    <Col xs={4} className="center">
                                        <Text strong>{item.rubro_id.nombre}</Text>
                                    </Col>
                                    <Col xs={3} className="center">
                                        <Text strong>{item.cuenta_id.nombre}</Text>
                                    </Col>
                                    <Col xs={3} className=" center">
                                        <Text strong>{renderMontoTransaccion({monto: item.monto, tipo: item.tipo})}</Text>
                                    </Col>
                                    <Col xs={3} className="center">
                                        <Space>
                                            <Button
                                                size="small"
                                                icon={<IconEye />}
                                                type="primary"
                                                title="Ver Detalle"
                                                style={{backgroundColor: "#ADFF45", borderColor: "#ADFF45"}}
                                                onClick={() => this.setState({ visible_modal_detalle: true, transaccion_id: item._id })}>
                                            </Button>
                                            <Button
                                                size="small"
                                                icon={<IconEdit />}
                                                type="primary"
                                                title="Editar"
                                                onClick={() => this.setState({ visible_modal: true, transaccion_id: item._id })}>
                                            </Button>
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar esta Transacción?"
                                                onConfirm={() => {}}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button size="small" type="primary" style={{backgroundColor: "#FF547D", borderColor: "#FF547D"}} icon={<IconDelete />} title="Eliminar" />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}

                />

                <FloatingButton title="Nuevo Reporte" onClick={() => this.setState({ visible_modal: true })}/>

                <ModalTransacciones
                    visible={this.state.visible_modal}
                    transaccion_id={this.state.transaccion_id}
                    mantenimiento_id={this.props.mantenimiento_id}
                    onClose={() => {
                        this.setState({ visible_modal: false, transaccion_id: undefined })
                        this.getTransacciones()
                    }}
                />

                <ModalTransaccionesDetalle
                    visible={this.state.visible_modal_detalle}
                    onClose={() => this.setState({
                        visible_modal_detalle: false,
                        transaccion_id: undefined
                    })}
                    transaccion_id={this.state.transaccion_id}
                />
            </>
        )
    }
}
import React, { Component } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row, Typography, message, Space, List, Card, Tag, Dropdown, Menu, Modal } from "antd";
import { FlagFilled, PaperClipOutlined, EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
//Componentes
import { IconPlusCollapsile, IconEye, IconChecked, IconCross} from '../../../Widgets/Iconos';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
//Modales
import ModalContrato from './ModalContrato'
import ModalRechazo from './ModalRechazo'

import 'moment/locale/es';
const { Title, Text } = Typography
const axios = require('axios');
const moment = require('moment')
moment.locale('es');
/**
 *
 *
 * @export
 * @class Contrato
 * @extends {Component}
 * @description Vista de los Contratos
 */
class Contrato extends Component {

    constructor(props) {
        super(props)
        this.state = {
            proyecto_id: this.props.params.proyecto_id,
            contratos: {
                data: [],
                limit: 10,
                page: 1,
                total: 0
            },
            contrato_id: undefined,
            page: 0,
            total: 0,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getContratos()
    }

     /**
     * @memberof ModalContrato
     * @method getContratos
     * @description Obtiene los contratos de la orden
     * 
     */
    getContratos = ({
        page = this.state.contratos.page,
        limit = this.state.contratos.limit,
    } = this.state.contratos) => {
        this.setState({loading: true})
        axios.get('/project-manager/contratos/list',{
            params:{
                proyecto_id: this.state.proyecto_id,
                page,
                limit,
            }
        }).then(response => {
            console.clear()
            console.log("response", response.data);
            this.setState({
                contratos: response.data
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los contratos')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalContrato
     * @method deleteContrato
     * @description Elimina un contrato
     * 
     */
    deleteContrato = (id) => {
        Modal.confirm({
            title: '¿Seguro de eliminar el contrato?',
            icon: <ExclamationCircleOutlined />,
            //content: 'Some descriptions',
            onOk: () => {
                axios.post('/project-manager/contratos/delete',{
                    id: id
                }).then(response => {
                    message.success('Contrato Eliminado')
                    this.getContratos()
                }).catch(error=> {
                    console.log(error)
                    message.error('Error al eliminar el contrato')
                })
            },
        })
    }

    /**
     * @memberof ProcesoLegal
     * @method renderTag
     * @description Obtiene los contratos de la orden
     * 
     */
    renderTag = (status) => {
        let tags = {
            0: <Tag color="red">Rechazado</Tag>,
            1: <Tag color="yellow">En Revisión</Tag>,
            2: <Tag color="green">Aceptado</Tag>,
        }

        return tags[status]
    }

    /**
     * @memberof ProcesoLegal
     * @method aceptarContrato
     * @description Cambai el status del contrato a aceptado
     * 
     */
    aceptarContrato = (id) => {
        this.setState({loading: true})
        axios.post('/project-manager/contratos/update', {
            id: id,
            aceptada: true,
        }).then(response => {
            message.success('Contrato Aceptado')
            this.getContratos()
        }).catch(error => {
            console.log(error)
            message.error('Error al Aceptar el Contrato')
        }).finally(()=> {
            this.setState({loading: false})
        })
    }

    /**
     * @memberof ProcesoLegal
     * @method sendInvite
     * @description Envia el documento pdf al cliente a firmar
     * 
     */
    sendInvite = () => {
        this.setState({loading: true})
        axios.post('/project-manager/contratos/send',{
            id: this.state.proyecto_id
        }).then(response => {
            console.log(response.data)
            message.success('Documento enviado')
        }).catch(error => {
            console.log(error.response)
            if(error.response.status === 401){
                message.error(error.response?.data?.message)
            }else{
                message.error('Error al enviar el Documento')
            }
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ProcesoLegal
     * @method sendInvite
     * @description Envia el documento pdf al cliente a firmar
     * 
     */
    resendInvite = () => {
        this.setState({loading: true})
        axios.post('/project-manager/contratos/resend',{
            id: this.state.proyecto_id
        }).then(response => {
            console.log(response.data)
            message.success('Documento enviado')
        }).catch(error => {
            console.log(error.response)
            if(error.response.status === 401){
                message.error(error.response?.data?.message)
            }else{
                message.error('Error al enviar el Documento')
            }
        }).finally(()=>this.setState({loading: false}))
    }

    render() {

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Row gutter={[16, 0]}>
                            <Row justify="space-between " className="width-100 pd-1">
                                <Title level={3}>Elaboración de Contrato</Title>
                                <Space style={{ float: "right" }}>
                                    {this.state.contratos.data.length > 0 ? <Button
                                        type="primary"
                                        size="large"
                                        className="btn-legal sqr"
                                        disabled={true}
                                    >
                                        <IconPlusCollapsile/>
                                    </Button>: <Button
                                        type="primary"
                                        size="large"
                                        className="btn-legal sqr"
                                        onClick={()=>this.setState({modalContrato: true})}
                                    >
                                        <IconPlusCollapsile/>
                                    </Button>}

                                    <Button
                                        type="primary"
                                        size="large"
                                        className="btn-legal"
                                        onClick={()=>this.sendInvite()}
                                        loading={this.state.loading}
                                        disabled={this.state.contratos.data.length === 0 || this.state.contratos.data[0]?.enviado}
                                    >
                                        Enviar a Firmar
                                    </Button>
                                    {this.state.contratos.data[0]?.enviado ? <Button
                                        type="primary"
                                        size="large"
                                        className="btn-legal"
                                        onClick={()=>this.resendInvite()}
                                        loading={this.state.loading}
                                        disabled={this.state.contratos.data[0]?.enviado === false}
                                    >
                                        Reenvio
                                    </Button>: null}
                                </Space>
                            </Row>
                            <Col span={24}>
                                <List
                                    loading={this.state.loading}
                                    className="component-list no-scroll"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Sin contrato" }}
                                    dataSource={this.state.contratos.data}
                                    renderItem={item => (
                                        <List.Item className="component-list-item">
                                            <Card 
                                                className="card-list"
                                            >
                                                <Row className="width-100 ">

                                                    <Col xs={24} lg={11} className="flex-column-left ">
                                                        <Tag className="tag-legal" color="#FFF170">Versión {item.version}</Tag>
                                                        <p className="mt-1"> {item.descripcion} </p>
                                                        <div>
                                                            <FlagFilled className="mr-05"/>
                                                            <Text className="mr-05 text-gray">{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                                            <Text className="mr-05 text-gray">{moment(item.createdAt).fromNow()}</Text>
                                                            <CustomAvatar 
                                                                size="small" 
                                                                name={[item.realizado_por?.nombre, item.realizado_por?.apellido]}
                                                                image={item.realizado_por?.avatar}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} lg={6} className="">
                                                        <Row>
                                                            <Col span={24} className="center pt-1 pb-1">
                                                                {this.renderTag(item.status)}
                                                            </Col>
                                                            <Col span={24} className="center">
                                                                <a href={`${axios.defaults.baseURL}/upload/${item.contrato}`} target="_blank"><PaperClipOutlined /> Contrato PDF</a>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={24} lg={6} className="center">
                                                        {item.firma_empresa && item.firma_cliente ? 
                                                            <a href={this.getPDF(item._id)} download target="_blank">
                                                                <Button 
                                                                    type="" 
                                                                    className="mr-05" 
                                                                    title="Ver detalle"
                                                                    icon={<IconEye />}
                                                                >
                                                                </Button>
                                                            </a> : null}
                                                        <Button
                                                            title="Aceptar"
                                                            type="success"
                                                            className="ant-btn-primary-lime mr-05"
                                                            icon={<IconChecked/>}
                                                            onClick={()=>this.aceptarContrato(item._id)}
                                                            disabled={item.status === 2}
                                                        />
                                                         
                                                        <Button
                                                            title="Rechazar"
                                                            type="primary"
                                                            danger
                                                            icon={<IconCross />}
                                                            onClick={()=>this.setState({modalRechazo: true, contrato_id: item._id})}
                                                            disabled={item.status === 2}
                                                        />
                                                    </Col>
                                                    <Col xs={24} lg={1} className="flex-right-top ">
                                                        <Dropdown 
                                                            disabled={item.status === 2}
                                                            placement="bottomRight"
                                                            overlay={<Menu>
                                                                <Menu.Item onClick={()=>{this.setState({modalContrato: true, contrato_id:item._id})}}>
                                                                    Editar
                                                                </Menu.Item>
                                                                <Menu.Item onClick={()=>this.deleteContrato(item._id)}>
                                                                    Eliminar
                                                                </Menu.Item>
                                                            </Menu>}
                                                            trigger={['click']} 
                                                        >
                                                            <EllipsisOutlined style={{ fontSize: 25 }} />
                                                        </Dropdown>
                                                    </Col>
                                                    <Col xs={24} lg={6} className="flex-column-center mt-1">
                                                        <Tag className="tag-legal" color="#FFF170">Firma Cliente</Tag>
                                                        <Text className="mt-1">{item.firma_cliente ? moment(item.firma_cliente).format('DD/MM/YYYY') : '-'}</Text>
                                                    </Col>
                                                    <Col xs={24} lg={6} className="flex-column-center mt-1">
                                                        <Tag className="tag-legal" color="#FFF170">Firma Empresa</Tag>
                                                        <Text className="mt-1">{item.firma_empresa ? moment(item.firma_empresa).format('DD/MM/YYYY') : '-'}</Text>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
                <ModalContrato
                    visible={this.state.modalContrato}
                    onClose={()=>{
                        this.setState({modalContrato: false, contrato_id: undefined})
                        this.getContratos();
                    }}
                    proyecto_id={this.state.proyecto_id}
                    contrato_id={this.state.contrato_id}
                />

                <ModalRechazo
                    visible={this.state.modalRechazo}
                    onClose={()=>{
                        this.setState({modalRechazo: false, contrato_id: undefined})
                        this.getContratos()
                    }}
                    id={this.state.contrato_id}
                />
            </>
        )
    }
}

export default function Vista(props) {
    return (<Contrato {...props} params={useParams()} navigate={useNavigate()} />)
}
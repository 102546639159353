import React, { Component } from "react";
import { message, Row, Col, Spin, Space, Typography, List, Card, Button } from "antd";
import { IconEdit, IconEye, IconDetails } from '../../../Widgets/Iconos';
import CustomAvatar from '../../../Widgets/Avatar/Avatar.js'

const moment = require("moment");
const axios = require("axios");
const { Text } = Typography;

/**
 *
 *
 * @export
 * @class Centros
 * @extends {Component}
 * @description Vista del listado de Centros
 */
class Ingresos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ingresos: {
        data: [{}, {}, {}],

        limit: 10,
        page: 1,

        total: 0,
        pages: 0,

        //filtros
        cliente_id: this.props.cliente_id,
      },
      modal_visible: false,
    };
  }

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
  }

   /**
    * @memberof AccountPurchases
    * @method getTransacciones
    * @description Obtiene las transacciones hechas por el cliente de la orden correspondiente
    */
    getTransacciones = (page) => {

      let search = this.props.search;
      const orden_id = this.props.orden_id;

      if (!orden_id) return;

      this.setState({ loading: true })

      axios.post('/transacciones/list', {
          id: orden_id,
          tipo: 1, //ingreso
          page: page,
          search
      })
          .then(({ data }) => {

              this.setState({
                  ingresos: data.data,

                  searching: true,
                  filtroSearch: search
              })
          })
          .catch(err => console.log(err))
          .finally(() => this.setState({ loading: false }))
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Row
          className="width-100"
          justify="start"
          align="space-evenly"
          gutter={[8, 8]}
        >
          <List
            loading={this.state.loading}
            className="component-list"
            dataSource={this.state.ingresos.data}
            pagination={{
              current: this.state.ingresos.page,
              pageSize: this.state.ingresos.limit,
              total: this.state.ingresos.total,
              hideOnSinglePage: false,
              position: "bottom",
              className: "flex-left",
              onChange: (page) => this.getTransacciones(page),
            }}
            renderItem={(item) => (
              <List.Item>
                <Card className="card-list">
                  <div className={`badge-card tipo-${item.tipo}`}> </div>
                  <Row className="width-100">
                    <Col xs={2} className="center">
                      <Text strong>
                        {moment(item.fecha).format("DD/MM/YYYY")}
                      </Text>
                    </Col>
                    <Col xs={7} className="center">
                      <Text className="text-gray-dark">{item.concepto ? item.concepto : '{{Concepto}}'}</Text>
                    </Col>
                    <Col xs={2} className="center">
                      <Text className="text-gray-dark">
                        {item.orden_id?.folio ? item.orden_id : '{{Folio}}'}
                      </Text>
                    </Col>
                    <Col xs={2} className="center">
                      <CustomAvatar
                        name={[
                          item.cliente_id?.nombre,
                          item.cliente_id?.apellido,
                        ]}
                      />
                    </Col>
                    <Col xs={3} className="center">
                      <Text className="text-gray-dark">
                        {item.cuenta_id?.nombre ? item.cuenta_id : '{{Nombre}}'}
                      </Text>
                    </Col>
                    <Col xs={5} className="center">
                        $ {item.monto ? item.monto.toMoney(true) : '{{Monto}}'} MXN
                    </Col>
                    <Col xs={3} className="center">
                      <Space>
                        <Button
                          icon={<IconDetails />}
                          type="primary"
                          title="Ver Detalles"
                          onClick={() =>
                            this.setState({
                              transaccion_id: item._id,
                              modalTransacDetalleVisible: true,
                            })
                          }
                          style={{
                            backgroundColor: "#ADFF45",
                            borderColor: "#ADFF45",
                          }}
                        ></Button>
                        <Button
                          icon={<IconEdit />}
                          type="primary"
                          title="Editar"
                          onClick={() =>
                            this.setState({
                              modalTransacVisible: true,
                              transaccion_id: item._id,
                            })
                          }
                          style={{
                            backgroundColor: "#456EFF",
                            borderColor: "#456EFF",
                          }}
                        ></Button>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Row>
      </Spin>
    );
  }
}

/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {
  return <Ingresos {...props} />;
}

import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd'

const { Option } = Select;
const { Text } = Typography; 
const axios = require("axios");

/**
 * @const ProveedorCentrosSelect
 * @description Select para los centros de distribucion del proveedor seleccionado registrados en el sistema
 */
const ProveedorCentrosSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el Centro de Distribución", onSelect = () => {}, disabled = false, className = "", proveedor_id } = props

    const [ centros, setCentros ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
        proveedor_id: null
    })


    /**
     * @const getCentros
     * @description Obitiene los centros
     */
    const getCentros = ({
        page, 
        limit, 
        search, 
        proveedor_id = centros.proveedor_id 
    } = centros) => {

        axios.get('/proveedores/centros-distribucion/list', {
            params: { 
                page, 
                limit, 
                search,
                proveedor_id
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setCentros({
                ...centros,
                data: response.data.data.data,
                total: response.data.data.total,
                proveedor_id: proveedor_id,
            })

        }).catch(error => {
            message('Error al obtener los clientes')
        })
    }
    
    //componentDidUpdate
    useEffect(() => {
        if(proveedor_id)
            getCentros({proveedor_id})
    }, [proveedor_id])

    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={"Seleccione el Centro de Distribución"}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getCentros({search})}
            onSelect={(centro)=> {
                onChange(centro.value)
                onSelect(centro.value)
            }}
            value={value}
        >
            {
                centros.data.map(centro => <Option value={centro._id} key={centro._id}>
                    <div className="flex-left-column">
                        <Text>{centro.identificador}</Text>
                        <small>{centro.razon_social}</small>
                    </div>
                </Option>)
            }
        </Select>
    );
}



export default ProveedorCentrosSelect;
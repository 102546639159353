
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Typography, Form, Input, Button, Space, Modal, List, Empty, Menu, message } from "antd";
import { CardOperations, CardSaldosGraph, CardOperationsList } from '../Widgets/Cards';
import { ReloadOutlined, UnorderedListOutlined, MoneyCollectOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import '../../Styles/Modules/Dashboard/dashboard.css'
import moment from 'moment';
const axios = require('axios')

const { Content } = Layout;
const { Title } = Typography;


export default class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loading_cambio: false,
            tipo_cambio_actual: 0,
            historial: [],
            page: 1,
            modal_visible: false

        }
    }



    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */
    componentDidMount() {
        this.getHistorialCambio();
    }
    /**
     *
     *
     * @memberof Dashboard
     * @description Consulta el historial de los tipos de cambios
     */
    getHistorialCambio = (page) => {
        let items = this.state.historial;

        this.setState({ loading_cambio: true })
        axios.get('/tipo-cambio', {
            params: {
                page: page
            }
        })
            .then(res => {
                const data = res.data.data.itemsList;
                console.log('datos del historial', res)
                this.setState({
                    historial: (page == 1) ? data : [...items, ...data],
                    tipo_cambio_actual: data[0].valor.toFixed(2),
                    page: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    pageCount: res.data.data.paginator.pageCount,

                })
            }
            )
            .catch(error => {
                console.log('error', error);
            })
            .finally(() => {
                this.setState({ loading_cambio: false })
            })
    }


    /**
     *
     *
     * @memberof Dashboard
     * @description Actualiza el tipo de cambio actual al hacer click al boton de refresh
     */
    updateTipoCambio = () => {
        let valor = this.state.tipo_cambio_actual;
        axios.post('/tipo-cambio/add', {
            valor: valor
        })
            .then(res => {
                message.success('Se ha actualizado el tipo de cambio');
                console.log('update tipo de cambo res', res);
                this.getHistorialCambio();
            })
            .catch(error => {
                message.error('Ha ocurrido un error al intentar actualizar el tipo de cambio');
                console.log('error al update tipo cambio', error);
            })


    }
    /**
     *
     *
     * @memberof Dashboard
     * @description Carga mas elementos al historial
     */
    onLoadMore = () => {
        let current = this.state.page;
        current = current + 1;
        this.getHistorialCambio(current);

    };


    /**
     *
     *
     * @memberof Dashboard
     * @description Abre o cierra el modal de historial
     */
    modalToogle = () => {
        let toogle = this.state.modal_visible;
        this.setState({ modal_visible: !toogle });
    }


    renderIcon = (index, valor) => {
        console.log(valor, index)
        let next = index + 1;

        if (next < this.state.historial.length) {
            console.log('?', valor.toFixed(2) > this.state.historial[next].valor.toFixed(2))
            if (valor.toFixed(2) < this.state.historial[next].valor.toFixed(2)) {
                return <ArrowDownOutlined style={{ color: '#24CA49' }} />
            }
            else
                return <ArrowUpOutlined style={{ color: '#FD4438' }} />
        }
        return <MoneyCollectOutlined />

    }

    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 18, order: 1 }} xl={{ span: 18, order: 1 }} lg={{ span: 17, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                    <span className="ant-page-header-heading-title">
                                        Dashboard
                                    </span>
                                </Col>
                                <Col xxl={{ span: 6, order: 2 }} xl={{ span: 6, order: 2 }} lg={{ span: 6, order: 2 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }}  >
                                    <Space direction="horizontal">
                                        <label className="label-tipo-cambio">Tipo de cambio </label>
                                        <div className="flex">
                                            <Input className="input-tipo-cambio" prefix={"$"} value={`${this.state.tipo_cambio_actual}`} onChange={(e) => this.setState({ tipo_cambio_actual: e.target.value })} />
                                            <Button type="primary" className="btn-tipo-cambio" loading={this.state.loading_cambio} icon={<ReloadOutlined style={{ color: '#2A5068' }} />} onClick={this.updateTipoCambio} />
                                        </div>

                                        <Button type="normal" className="btn-tipo-cambio-ouline " onClick={this.modalToogle} icon={<UnorderedListOutlined style={{ color: '#fff' }} />} />

                                    </Space>
                                </Col>
                            </Row>
                        }
                    />
                    <Content className=" content-bg pr-2 pl-2 pt-1 ">
                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <Row >
                                    <Col span={24}><Title className="subtitle-dashboard" level={2}>Supply Chain</Title></Col>
                                    <Col span={8}>
                                        <CardSaldosGraph />
                                    </Col>
                                    <Col span={8}>
                                        <CardOperations title="Profit" icon={"up"} color={"#006BFF"} />
                                    </Col>
                                    <Col span={8}>
                                        <CardOperationsList title="Loss" icon={"down"} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row >
                                    <Col span={24}><Title className="subtitle-dashboard" level={2}>Maintenance</Title></Col >
                                    <Col span={8}>
                                        <CardSaldosGraph />
                                    </Col>
                                    <Col span={8}>
                                        <CardOperations title="Income" icon={"up"} color={"#00CD98"} />
                                    </Col>
                                    <Col span={8}>
                                        <CardOperationsList title="Expensive" icon={"down"} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
                <Modal
                    onCancel={this.modalToogle}
                    visible={this.state.modal_visible}
                    className="modal-historial"
                    title={<div className="header-list no-border "><Title level={4} className="text-center">Historial de Tipo de Cambio</Title></div>}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    destroyOnClose={true} >
                    <List
                        size="small"
                        itemLayout="horizontal"
                        dataSource={this.state.historial}
                        locale={{
                            emptyText:
                                <Empty
                                    image={<MoneyCollectOutlined style={{ fontSize: 35 }} />}
                                    imageStyle={{ height: 60, padding: '5px' }}
                                    description={<>No se ha registrado ningun Valor </>}
                                />
                        }}
                        className="historial-tipo-cambio list-border-none"
                        pagination={{
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,
                            position: 'bottom',
                            className: "flex-left",
                            size: 'small'

                        }}
                        loading={this.state.loading_cambio}
                        renderItem={({ _id, valor, usuario_id, createdAt }, index) => <List.Item style={{ backgroundColor: '#fff' }}>
                            <Row className="width-100 ">
                                <Col span={1}>{this.renderIcon(index, valor)}</Col>
                                <Col span={4}>  $ {valor.toFixed(2)}</Col>
                                <Col span={11}> {usuario_id?.nombre} {usuario_id?.apellido}</Col>
                                <Col span={8}>{moment(createdAt).format('LLL')}</Col>
                            </Row>
                        </List.Item>}
                    />

                </Modal>
            </>
        )
    }
}
import React, { Children, Component } from "react";
import {
  Button,
  Col,
  Row,
  Popconfirm,
  Spin,
  Table,
  PageHeader,
  Layout,
  message,
  Space,
  Tag,
  Image,
  Typography,
  Select,
  Form,
  InputNumber,
  Input,
  List,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default class FOB extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <List
        loading={this.state.loading}
        style={{ padding: "1em 1em" }}
        className="component-list width-100"
        locale={{ emptyText: "No hay proveedores" }}
        dataSource={this.props.contenido}
        header={
          <Row className="header-list width-100 ">
            <Col span={4}>
              <Text strong>Concepto</Text>
            </Col>
            <Col span={3} className="center">
              <Text strong>Tarifa Base</Text>
            </Col>
            <Col span={3} className="center">
              <Text strong>Proporción</Text>
            </Col>
            <Col span={3} className="center">
              <Text strong>Tarifa</Text>
            </Col>
            <Col span={3} className="center">
              <Text strong>Moneda / Tipo</Text>
            </Col>
            <Col span={3} className="center">
              <Text strong>Base Prorrateo</Text>
            </Col>
            <Col span={5} className="center">
              <Text strong>Especificaciones</Text>
            </Col>
          </Row>
        }
        renderItem={(item) => (
          <Row className="width-100" align="top" gutter={[16,16]}>
            <Col span={4}>
              <Text>{item.concepto}</Text>
            </Col>
            <Col span={3} className="center">
              <Form.Item
                name={item.tarifa_base_field}

                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese una tarifa base",
                  },
                ]}
              >
                <Input placeholder="0" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={3} className="center">
              <Form.Item
                name={item.proporcion_field}

                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese una proporción",
                  },
                ]}
              >
                <Input placeholder="0" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={3} className="center">
              <Text>Valor</Text>
            </Col>
            <Col span={3} className="center">
              <Form.Item
                name={`${item.tipo_field}`}
                rules={[
                  {
                    required: true,
                    message: "Por favor, seleccione un tipo",
                  },
                ]}

                className="width-100"
              >
                <Select disabled={item.porcentaje}                 className="width-100">
                  <Option value={1}>Porcentage (%)</Option>
                  <Option value={2}>MXN</Option>
                  <Option value={3}>USD</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} className="center">
              <Form.Item
                name={item.prorrateo_field}
                rules={[
                  {
                    required: true,
                    message: "Por favor, seleccione la base de prorrateo",
                  },
                ]}
                className="width-100"
              >
                <Select className="width-100">
                  <Option value={1}>Contenedor</Option>
                  <Option value={2}>Unidad</Option>
                  <Option value={3}>Tiempo</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5} className="center" >
              <Form.Item name={item.especificaciones_field} rules={[{}]} className="width-100">
                <TextArea rows="2" className="width-100"/>
              </Form.Item>
            </Col>
          </Row>
        )}
      />
    );
  }
}

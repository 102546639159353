import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import { Link } from "react-router-dom";

import { IconDelete, IconEdit, IconDetails } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalAreas from './ModalAreas'

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista del modulo de areas, renderiza una lista
 */
class Areas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            area_id: undefined,
            areas: [],

            limit: this.props.searchParams.get("limit") ?? 10,
            page: this.props.searchParams.get("page") ?? 1,
            search: this.props.searchParams.get("search") ?? null,
            total: 0,
            filtroSearch: '',
            searching: true,

        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAreas();
    }


    /**
     *
     *
     * @memberof Areas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.getAreas();
        }
    }

    /**
     * @methodOf  Areas
     * @method getAreas
     *
     * @description Obtiene las areas
     * */
    getAreas = (page = this.state.page, limit = this.state.limit, search = this.props.search) => {
        axios.get('/areas/list', {
            params: {
                page,
                limit,
                search
            }
        }).then(response => {
         
            this.props.setSearchParams({ page, limit })
            this.setState({
                areas: response.data.data,
                page: response.data.page,
                total: response.data.total,
                searching: true,
                filtroSearch: search
            });

        }).catch(error => {
            message.error('Error al traer la información')
        })
            .finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Áreas"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Áreas " }}
                            dataSource={this.state.areas}
                            pagination={{
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getAreas(page)
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >

                                <Col xs={10} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={10} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col xs={10} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={10} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Link title="Editar" to={`/admin/areas/rubros/${item._id}`}>
                                                        <Button type="" name={item.nombre} id={item._id}
                                                            title="Ver rubros" icon={<IconDetails />}>

                                                        </Button>
                                                    </Link>
                                                    <Button
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, area_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta área?"
                                                        onConfirm={() => axios.post('/areas/delete',
                                                            { id: item._id }).then(() => {
                                                                this.getAreas()
                                                                message.success('Área eliminada')
                                                            }).catch(error => {
                                                                let msg = 'Error al eliminar el Área'
                                                                if (error.response.status === 403)
                                                                    msg = error.response.data.message
                                                                message.error(msg)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                    <ModalAreas
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, area_id: undefined })
                            this.getAreas()
                        }}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }
}


export default function (props) {

    let [searchParams, setSearchParams] = useSearchParams();

    return <Areas
        {...props}
        searchParams={searchParams}
        setSearchParams={setSearchParams}

    />

}

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Steps, Card, message, List, Button, Typography, Space } from "antd";
import { FaCheckCircle, FaTimesCircle, FaClock, FaRegCircle, FaExclamationTriangle } from "react-icons/fa";
import { LoadingOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Proyecto } from '../../../../Hooks/Proyecto'
import UserSelector from "../../../Widgets/UserSelector";
import "../../../../Styles/Modules/ProjectManager/StepTracker.css"
const { Step } = Steps
const { Title, Text } = Typography

/**
 *
 *
 * @const StepTracker
 * @extends {Component}
 * @description StepTracker del Project Manager
 */
const StepTracker = (props) => {
    let { onMenuPressed = () => { } } = props;

    /** @type Bolean si se esta cargando */
    const [loading, setLoading] = useState(false);

    /** @type {*} Información del proyecto */
    let proyecto = useContext(Proyecto)

    /**
     * 
     * @param {*} key 
     * @returns <Icon/>
     */
    const renderStatus = (key = 1) => {
        const style = { fontSize: 25, position: "relative", bottom: 3 };

        if (typeof key === "boolean")
            return key ? (
                <FaCheckCircle style={style} className="step-icon" />
            ) : (
                <FaRegCircle style={{ ...style, color: "#2C3E50" }} />
            ); //circulo sin relleno

        switch (key) {
            case 0:
                //Non started
                return <FaRegCircle style={{ ...style, color: "#2C3E50" }} />; //circulo sin relleno

            case 1:
                //Success
                return <FaCheckCircle style={style} className="step-icon" />; //check

            case 2:
                //In progress
                return <FaClock style={{ ...style, color: "#FFC107" }} />; //reloj

            case 3:
                //Error
                return <FaTimesCircle style={{ ...style, color: "#DC3545" }} />; //X

            case 4:
                //LOADING
                return <LoadingOutlined style={style} spin />;
            case 5:
                //Error
                return <FaExclamationTriangle style={{ ...style, color: "#FFC107" }} />; //X

        }
    };




    const steps = [
        {
            title: "Quotation",
            steps: [
                {
                    name: "formulario_cliente",
                    check: () => {
                        if (proyecto?.quotation?.form) return 1
                    },
                    task: "Formulario de Cliente",
                },
                {
                    name: "cotizacion_preeliminar",
                    check: () => {
                        if (proyecto?.quotation?.form && proyecto?.quotation?.cotizacion == false) return 2
                        if (proyecto?.quotation?.cotizacion) return 1
                        return 0
                    },
                    task: "Cotizacion Preeliminar",
                },
                {
                    name: "matriz_costos",
                    check: () => {
                        if (proyecto?.quotation?.cotizacion && proyecto?.quotation?.matriz_costos == false) return 2
                        if (proyecto?.quotation?.matriz_costos) return 1
                        if (proyecto?.quotation?.matriz_costos == false && proyecto?.quotation?.cotizacion) return 3

                        return 0
                    },
                    task: (
                        <Link to={`/admin/project-manager/manager/matriz-costos/${proyecto?._id}`}  >
                            Matriz de Costos
                        </Link>
                    ),
                },
                {
                    name: "envio_cotizacion",
                    check: () => {
                        if (proyecto?.quotation?.aprobacion && proyecto?.quotation?.envio_cotizacion == false) return 2
                        if (proyecto?.quotation?.envio_cotizacion) return 1
                        return 0
                    },
                    task: "Envio de Cotización Final"
                },
                {
                    name: "aprobacion",
                    check: () => {
                        if (proyecto?.quotation?.aprobacion && proyecto?.quotation?.envio_cotizacion) return 1
                        if ((!proyecto?.quotation?.aprobacion || !proyecto?.quotation?.rechazo || !proyecto?.quotation?.recotizo) && proyecto?.quotation?.envio_cotizacion) return 2
                        if (proyecto?.quotation?.rechazo && proyecto?.quotation?.envio_cotizacion) return 3
                        if (proyecto?.quotation?.recotizo && proyecto?.quotation?.envio_cotizacion) return 5

                        return 0
                    },
                    task: "Aprobación o Rechazo",
                },
            ],
            className: "step-color-blue",
        },
        {
            title: "Legal",
            steps: [
                {
                    name: "elaboracion_contrato",
                    check: () => {
                        if (proyecto?.legal?.contrato) return 1
                        if (proyecto?.legal?.contrato == false && proyecto?.quotation?.aprobacion) return 2
                        return 0
                    },
                    task:
                        <Link
                            //className={(proyecto?.quotation?.aprobacion) ? null : "disabled-link"}
                            to={`/admin/project-manager/manager/legal/${proyecto._id}`}>Elaboración de Contrato</Link>,
                },
                {
                    name: "veo_bo_contrato",
                    check: () => {
                        if (proyecto?.legal?.firmado_empresa_contrato) return 1
                        if (proyecto?.legal?.contrato && !proyecto?.legal?.firmado_empresa_contrato) return 2
                        return 0
                    },
                    task: <Text
                        className={(proyecto?.quotation?.aprobacion) ? null : "disabled-Text"}
                    >Vo. Bo. Contrato</Text>,
                },
                {
                    name: "envio_contrato",
                    check: () => {
                        if (proyecto?.legal?.envio_contrato) return 1
                        if (!proyecto?.legal?.envio_contrato && proyecto?.legal?.contrato) return 2
                        return 0
                    },
                    task: 'Envio de Contrato'
                },
                {
                    name: "firma_cliente",
                    check: () => {
                        if (proyecto?.legal?.firmado_cliente) return 1
                        if (proyecto?.legal?.envio_contrato && !proyecto?.legal?.firmado_cliente) return 2
                        return 0
                    },
                    task: 'Firma del Cliente'
                },
            ],
            className: "step-color-yellow",
        },
        {
            title: "Accounting & Purchases",
            steps: [
                {
                    name: "anticipo_cliente",
                    check: (e) => {
                        if (proyecto?.accounting?.anticipo_cliente) return 1
                        return 0
                    },
                    task: (
                        <Link
                            //className={!proyecto?.accounting?.anticipo_cliente ? "disabled-link" : null}
                            to={`/admin/project-manager/manager/contabilidad/${proyecto._id}?tab=ingresos`}
                        >
                            Anticipo del Cliente
                        </Link>
                    ),
                },
                {
                    name: "factura_anticipo",
                    check: (e) => {
                        if (proyecto?.accounting?.factura_anticipo) return 1
                        return 0
                    },
                    task: (
                        <Link
                            className={
                                !proyecto?.accounting?.factura_anticipo >= 10 ? "disabled-link" : null
                            }
                            to={`/admin/project-manager/manager/contabilidad/${proyecto?._id}?tab=facturas`}
                        >
                            Factura del Anticipo
                        </Link>
                    ),
                },
                {
                    name: "orden_compra",
                    check: (e) => {
                        if (proyecto?.accounting?.orden_compras) return 1
                        return 0
                    },
                    task: (
                        <Link
                            to={`/admin/project-manager/manager/contabilidad/${proyecto?._id}?tab=ordenes`}
                        >
                            Orden de Compra
                        </Link>
                    ),
                },
                {
                    name: "anticipo_proveedor",
                    check: (e) => {
                        if (proyecto?.accounting?.anticipo_proveedor) return 1
                        return 0
                    },
                    task: (
                        <Link
                            to={`/admin/project-manager/manager/contabilidad/${proyecto?._id}?tab=egresos`}
                        >
                            Anticipo a Proveedor
                        </Link>
                    ),
                },
                {
                    name: "plan_pagos",
                    check: (e) => false,
                    task: (
                        <Link
                            className={true ? "disabled-link" : null}
                            to={`/admin/project-manager/manager/contabilidad/${proyecto?._id}`}
                        >
                            Plan de Pagos
                        </Link>
                    ),
                },
                {
                    name: "liquidacion_proveedor",
                    check: (e) => {
                        if (proyecto?.accounting?.liquidacion_proveedor) return 1
                        if (proyecto?.accounting?.anticipo_proveedor) return 2
                        return 0
                    },
                    task: (
                        <Link
                            className={true ? "disabled-link" : null}
                            to={`/admin/project-manager/manager/contabilidad/${proyecto?._id}`}
                        >
                            Liquidación a Proveedor
                        </Link>
                    ),
                },
            ],
            className: "step-color-purple",
        },
        {
            title: "Logistics",
            steps: [
                {
                    name: "reservacion_flete",
                    check: (e) => {
                        if (proyecto?.logistica?.reservacion_flete) return 1
                        if (proyecto?.accounting?.orden_compras && !proyecto?.logistica?.reservacion_flete) return 2

                        return 0
                    },
                    task: (
                        <Link
                            to={`/admin/project-manager/manager/reservaciones/${proyecto?._id}`}
                        >
                            Reservación del Flete Marítimo
                        </Link>
                    )
                },
                {
                    name: "embarcacion",
                    check: (e) => {
                        if (proyecto?.logistica?.embarcacion) return 1
                        if (proyecto?.logistica?.reservacion_flete && !proyecto?.logistica?.embarcacion) return 2
                        return 0
                    },
                    task: (
                        <Link
                            className={!proyecto?.logistica?.reservacion_flete ? "disabled-link" : null}
                            to={`/admin/project-manager/manager/embarques/${proyecto?._id}`}
                        >
                            Embarcación de Mercancia
                        </Link>
                    )
                },
                {
                    name: "impuestos",
                    check: (e) => {
                        if (proyecto?.logistica?.impuestos) return 1
                        return 0
                    },
                    task: "Pago de impuestos",
                },
                {
                    name: "transportacion_mercancia",
                    check: (e) => {
                        if (proyecto?.logistica?.transporte_mercancia) return 1
                         if (proyecto?.logistica?.embarcacion && !proyecto?.logistica?.transporte_mercancia) return 2
                        return 0
                    },
                    task: (
                        <Link
                            className={!proyecto?.logistica?.embarcacion ? "disabled-link" : null}
                            to={`/admin/project-manager/manager/transportes/${proyecto?._id}`}
                        >
                            Transportación de Mercancia
                        </Link>
                    )
                },
                {
                    name: "aduana",
                    check: (e) => {
                        if (proyecto?.logistica?.aduana) return 1
                        // if (!proyecto?.logistica?.embarcacion) return 2
                        return 0
                    },
                    task: "Aduana",
                },
                {
                    name: "flete",
                    check: (e) => {
                        if (proyecto?.logistica?.flete) return 1
                        // if (!proyecto?.logistica?.embarcacion) return 2
                        return 0
                    },
                    task: "Flete",
                },
                {
                    name: "terminacion",
                    check: (e) => {
                        if (proyecto?.logistica?.terminacion) return 1
                        // if (!proyecto?.logistica?.embarcacion) return 2
                        return 0
                    },
                    task: "Terminación de Proyecto",
                },
            ],
            className: "step-color-pink",
        },
        {
            title: "Orden Completa",
            className: "step-color-green",
        },
    ];

    const renderList = (list = []) => (
        <List
            itemLayout="horizontal"
            dataSource={list}
            bordered={false}
            className="width-100 card-task-list list-border-none"
            locale={{ emptyText: " " }}
            renderItem={({ check, task, name }) => (
                <List.Item
                    extra={<UserSelector user={(proyecto?.asignados && proyecto?.asignados[name]) ? proyecto?.asignados[name] : undefined} name={name} proyecto_id={proyecto?._id} />}
                >
                    {/*console.log({check, task, name})*/}
                    <List.Item.Meta
                        className="card-task-list-meta"
                        avatar={renderStatus(loading === true ? 4 : check())}
                        title={task}
                    />
                </List.Item>
            )}
        />
    );
    return (
        <Card className="card-shadow step-card" >
            <Space className="w-100 step-tracker-header" style={{ width: "100%" }} justify="space-between">
                <Title level={5}>Project Tracker</Title>
                <Button
                    icon={<MenuFoldOutlined style={{ color: "currentColor" }} />} onClick={(...args) => onMenuPressed(...args)} />
            </Space>
            <Steps direction="vertical" className="step-tracker">
                {steps.map(({ title, steps, className }, index) => (
                    <Step
                        key={"step-" + index}
                        title={title}
                        description={renderList(steps)}
                        className={className}
                    />
                ))}
            </Steps>
        </Card>
    );
};

export default StepTracker;

import React from "react";
import { Row, Col, Typography, InputNumber, Form, Checkbox, Input, Select, message } from "antd";
import axios from 'axios'


const { Text } = Typography;
const { Option } = Select;

export default class BaseCampo extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			disabled: false,
			show_form: true,

		};
	}

	formRef = React.createRef()

	componentDidMount(){
		this.setFieldsValues()
	}


	componentDidUpdate(prevProps, prevState) {
		if(prevProps.show !== this.props.show){
			this.setFieldsValues()
		}
	}


	/**
     * @memberof setFieldsValues
     * @description Desabilita o habilita los input segun cambie el chekbox
     */
	toggleHidden = (check) => {
		this.setState({disabled: !check.target.checked})
	}

	/**
     * @memberof setFieldsValues
     * @description Pone en el folrmulario la informacion que se obiente del componente pagre
     */
	setFieldsValues = () => {
		//cargamos la informacion en el formulario
		if(this.props.proyecto && this.props.sectionName){

			let activado = this.props.proyecto?.[this.props.sectionName]?.[this.props.name]?.activado 

			if(activado === false && this.props.show === false){
				this.setState({
					disabled: true,
					show_form: false
				})
			}else if(activado == false && this.props.show === true) {
				this.setState({
					disabled: activado == false,
					show_form: true
				},()=>{
					if(this.formRef.current){
						this.formRef.current.setFieldsValue({
							...this.props.proyecto
						})
					}
				})
			}else{
				if(this.formRef.current){
					this.formRef.current.setFieldsValue({
						...this.props.proyecto
					})
				}
			}
		}

		//si no hay informacion disponible, inicializamos los datos en 0
		if(this.props.proyecto[this.props.sectionName] === undefined || this.props.proyecto[this.props.sectionName][this.props.name] === undefined){
			let values = {
				[this.props.sectionName]:{
					[this.props.name]:{
						tarifa_base: 0,
						tarifa_total: 0,
						proporcion: 1,
						activado: true,
						moneda: this.props.tipo === 'porcentaje' ? 2 : this.props.moneda
					}
				}
			}
			
			this.formRef.current.setFieldsValue({
				...values
			})
		}
	}

	/**
     *
     *
     * @memberof updateData
     * @description Actualiza la informacion de este fomulario
     */
    updateData = (data) => {
    	axios.post('/proyecto/campos-base',{
    		proyecto_id: this.props.proyecto_id,
    		data: data
    	}).then(response => {

    		this.formRef.current.setFieldsValue({
    			[this.props.sectionName]:{
    				[this.props.name]:{
    					tarifa_total: response.data[this.props.sectionName][this.props.name].tarifa_total
    				}
    			}
    		})

    		//guarda los nuevos datos en el state del padre
    		this.props.updateInfo({
    			sectionName: this.props.sectionName,
    			name: this.props.name,
    			newData: response.data,
    		})

    		let activado = response.data[this.props.sectionName][this.props.name].activado

    		if( activado === false && this.props.show === false){
    			this.setState({
    				show_form: false
    			})
    		}

    	}).catch(error => {
    		console.log("error", error);

    	})
    }

	render() {
		return (
			<>
				
				{this.state.show_form ?	<Form
						name="datos-base"
						size="small"
						className="datos-base"
						ref={this.formRef}	
						onValuesChange={(valoresCambiados, valoresActuales) => {
							this.updateData(valoresActuales)
						}}
					>
						<Row className="width-100" align="middle" gutter={[16, 16]}>
							<Col span={1} className="center ">
								<Form.Item
									name={[this.props.sectionName, this.props.name, "activado"]}
									valuePropName="checked"
								>
									<Checkbox 
										//checked={this.state.disabled}
										onChange={this.toggleHidden}
									/>
								</Form.Item>
							</Col>
							<Col span={4} style={{ verticalAlign: "top" }}>
								<Form.Item
									// name={[this.props.sectionName, this.props.name, "tarifa_base"]}
									className="width-100 datos-base-concepto"
								>

									<Text className="width-100" style={{textAlign: "left"}}>{this.props.fieldname}</Text>
								</Form.Item>
							</Col>
							<Col span={3} className="center ">
								<Form.Item
									name={[this.props.sectionName, this.props.name, "tarifa_base"]}
									className="width-100"
									validateFirst={'parallel'}
									rules={[{
                                    	required: true,
	                                    message: "valor invalido"
	                                }]}
								>
									<InputNumber 
										className="width-100" 
										placeholder="0" 
										disabled={this.state.disabled}
										precision={3}
										min={0}
										formatter={value =>  (this.props.tipo === 'porcentaje' ? `${value} %` : `$ ${value}`).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
										//parser={value => (this.props.tipo === 'porcentaje' ? `${value} %` : `$ ${value}`).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									/>
								</Form.Item>
							</Col>
							<Col span={3} className="center ">
								<Form.Item
									// name={`${item.name.proporcion_field}`}
									className="width-100"
									name={[this.props.sectionName, this.props.name, "proporcion"]}
									rules={[{
                                    	required: true,
	                                    message: "valor invalido"
	                                }]}
								>
									<InputNumber min={0} className="width-100" placeholder="0" disabled={this.state.disabled} />
								</Form.Item>
							</Col>
							<Col span={3} className="center ">
								<Form.Item
									name={[this.props.sectionName, this.props.name, "tarifa_total"]}
								>
									<InputNumber
										className="width-100 input-as-text" 
										disabled={true} 
										bordered={false}
										placeholder={"0"}
										formatter={value =>  (this.props.tipo === 'porcentaje' ? `${value} %` : `$ ${value}`).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
										//parser={value => (this.props.tipo === 'porcentaje' ? `${value} %` : `$ ${value}`).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									/>
								</Form.Item>
							</Col>
							<Col span={2} className="center ">
								<Form.Item
									className="width-100"
									name={[this.props.sectionName, this.props.name, "moneda"]}
								>
									<Select
										disabled={this.state.disabled || this.props.tipo === "porcentaje"}
									>
										<Option value={0}>MXN</Option>
										<Option value={1}>USD</Option>
										{this.props.tipo === "porcentaje" ? <Option value={2}>(%)</Option> : null}
									</Select>
								</Form.Item>
							</Col>
							<Col span={3} className="center ">
								<Form.Item
									className="width-100"
									name={[this.props.sectionName, this.props.name, "base_prorateo"]}
								>
									<Select
										disabled={this.state.disabled}
									>
										<Option value={0}>Contenedor</Option>
										<Option value={1}>Caja Master</Option>
										<Option value={2}>Pallet</Option>
										<Option value={3}>M2</Option>
										<Option value={4}>Unidad</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={5} className="center ">
								<Form.Item
									className="width-100"
									name={[this.props.sectionName, this.props.name, "especificaciones"]}
									rules={[{}]}
								>
									<Input.TextArea className="width-100" rows="2" disabled={this.state.disabled} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				: null }
			</>
		);
	}
}

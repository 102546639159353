import React from 'react'
import { Route, Routes } from "react-router-dom";


import OrdenMantenimientoDetalle from '../components/Admin/Mantenimiento/OrdenMantenimientoDetalle';
import CrearOrdenMantenimiento from '../components/Admin/Mantenimiento/CrearOrdenMantenimiento'
import Dashboard from '../components/Admin/Mantenimiento/Dashboard';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterMantenimiento(props) {

    return (
        <Routes>
            <Route index element={<Dashboard  {...props}  />} />
            <Route path="orden/nueva" element={<CrearOrdenMantenimiento  {...props}  />} />
            <Route path="orden/:mantenimiento_id" element={<OrdenMantenimientoDetalle  {...props}  />} />

        </Routes>
    )
}

export default RouterMantenimiento;

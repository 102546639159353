import React from 'react'
import { Route, Routes } from "react-router-dom";

import Usuarios from '../components/Admin/Usuarios/Usuarios';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterUsuarios( props) {
    return (
        <Routes>
            <Route path="" element={<Usuarios {...props} />} />
        </Routes>
    )
}

export default RouterUsuarios;

import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';

const { Title } = Typography;
const axios = require('axios').default;


class ModalCategorias  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalCategorias = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.categoria_id !== undefined) {
            this.getCategoria()
        }
    }


    /**
     * @memberof ModalCategorias
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.categoria_id) {
            this.updateCategoria(values)
        } else {
            this.addCategoria(values)
        }
    }


    /**
     * @memberof ModalCategorias
     * @method addCategoria
     * @description Añade una categoria a la BD
     */
    addCategoria = (values) => {
        this.setState({loading: true})
        axios.post('/categoria', {
            ...values
        }).then(response => {
            message.success('Categoria creada')
            this.props.onClose()
        }).catch(error => {
            if(error.response?.data?.message)
                message.error(error.response?.data?.message)
            else
                message.error('Error al crear la categoria.')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof ModalCategorias
     * @method getCategoria
     * @description Obtiene una categoria de la DB
     */
    getCategoria = () => {

        this.setState({ loading: true })

        axios.get(`categoria/${this.props.categoria_id}`)
        .then(response => {
            this.ModalCategorias.current.setFieldsValue({
                ...response.data
            })
        }).catch(error => {
            message.error('Error al traer la categoria')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalCategorias
     * @method updateCategoria
     * @description Actualiza la información de una categoria
     */
    updateCategoria = (values) => {
        this.setState({ loading: true })
        axios.put('/categoria', {
            ...values,
            categoria_id: this.props.categoria_id,
        }).then(response => {
            message.success('Categoria Actualizada')
            this.props.onClose()
        }).catch(error => {
           if(error.response?.data?.message)
                message.error(error.response?.data?.message)
            else
                message.error('Error al crear la categoria.')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.ModalCategorias}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción" ></Input.TextArea>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item className="text-center center">
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}> {props.categoria_id ? 'Editar' : 'Crear'} categoria</Title>
        </div>
        <ModalCategorias {...props} />
    </Modal>

}
import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Select, Divider } from 'antd';
import PhoneInput from "../../../Widgets/Inputs/PhoneInput";
const { Title } = Typography;
const { Option } = Select;

const axios = require('axios').default;


/**
 *
 *
 * @class ModalClienteCentroDistribucion
 * @extends {Component}
 * 
 * @description Modal para agregar un nuevo centro de distribución a un cliente
 */
class ModalClienteCentroDistribucion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cliente: undefined,

            paises: {
                data: [],

                loading: false,

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            },


            estados: {
                data: [],

                loading: false,

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            },

        }
    }

    ModalClientes = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getPaises();

        if (this.props.centro_distribucion_id)
            this.get()
    }

     /**
     * @memberof ModalClientes
     * @method getPaises
     * @description Obtenenmos los paises
     */
    getPaises = ({ search } = {}) => {

        axios.get('/paises',{
            params:{
                search
            }
        })
        .then(response => {
            this.setState({
                paises: response.data
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los paises')

        })
    }

    /**
     * @memberof ModalClienteCentroDistribucion
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario, formatea los valores y los edita
     */
    onFinish = (values) => {
        values.pais_id = (values?.pais_id?.value) ? values?.pais_id?.value : values?.pais_id
        values.estado_id = (values?.estado_id?.value) ? values?.estado_id?.value : values?.estado_id
        return (this.props.centro_distribucion_id) ? this.update(values) : this.add(values)
    }

    /**
     * @memberof ModalClienteCentroDistribucion
     * @method update
     * @description Actualiza el centro de distribución
     */
    update = values => {
        axios.put('/clientes/centros-distribucion/update', {
            ...values,
            id: this.props.centro_distribucion_id,
            cliente_id: this.props.cliente_id,
        })
            .then(e => {
                message.success("Se ha guardado el centro de distribución")
                this.props.onClose()
            })
            .catch(e => {
                message.success("No es posible guardar el centro de distribución")
            })
    }


    /**
     * @memberof ModalClienteCentroDistribucion
     * @method add
     * @description Agrega el centro de distribución
     */
    add = values => {
        axios.post(`/clientes/centros-distribucion/add`, {
            ...values,
            cliente_id: this.props.cliente_id,
        })
            .then(e => {
                message.success("Se ha guardado el centro de distribución")
                this.props.onClose()
            })
            .catch(e => {
                message.success("No es posible guardar el centro de distribución")

            })
    }

    /**
     *
     *
     * @param {*} [{
     *      centro_distribucion_id = this.props.centro_distribucion_id, El centro de distribución a buscar
     *      cliente_id = this.state.cliente_id El iD del cliente a quien pertence el centro de distribución
     * }=this.props]
     * @memberof ModalClienteCentroDistribucion
     * @method get
     * @description Obtenenmos el centro de distribución
     */
    get = (
        {
            id = this.props.centro_distribucion_id,
            cliente_id = this.state.cliente_id
        } = this.props
    ) => {
        axios.get('/clientes/centros-distribucion/get', {
            params: {
                id,
                cliente_id
            }
        })
            .then(({ data }) => {

                this.ModalClientes.current.setFieldsValue({
                    ...data.data,
                    pais_id: data.data.pais_id ? { value: data.data.pais_id, label: data.data.pais_id.name } : undefined,
                    estado_id: data.data.estado_id ? { value: data.data.estado_id, label: data.data.estado_id.name } : undefined,
                });
            })
            .catch(e => {
                message.error("No es posible obtener el centro de distribución")
            })
    }

   

    /**
     *
     *
     * @param {*} [{
     *             page = this.state.estados.page, Pagina de la lista de estados actuales
     *             limit = this.state.estados.limit, Limite de estados por pagina 
     *             search = this.state.estados.search, Busqueda de estados
     *             pais_id = this.state.estados.pais_id, ID del pais a buscar
     *         }=this.state.estados]
     * @memberof ModalClienteCentroDistribucion
     * @method getEstados
     * @description Obtenenmos los estados paginados
     */
     getEstados = (
        {
            search = this.state.estados.search,
            pais_id = this.state.estados.pais_id,
        } = this.state.estados
    ) => {

        axios.get("/estados", {
            params: {
                search,
                country_id: pais_id
            }
        })
        .then(({ data }) => {
            this.setState({
                estados: {...data, pais_id}
            })
            
        })
        .catch(err => {
            message.error('Error al obtener los estados')
        })
    }

    render() {
        return (
            <Form
                layout="vertical"
                ref={this.ModalClientes}
                onFinish={this.onFinish}
                initialValues={{ regimen_fiscal: "621" }}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Identificador del Centro de Distribución"
                        name="identificador"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el identificador"
                        }]}
                    >
                        <Input placeholder="ELEMENTO SA DE CV" />
                    </Form.Item>
                    <Form.Item
                        label="Razón Social"
                        name="razon_social"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese la razón social"
                        }]}
                    >
                        <Input placeholder="ELEMENTO SA DE CV" />
                    </Form.Item>
                    <Form.Item
                        label="Registro Federal de Contribuyentes (RFC)"
                        name="rfc"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el RFC"
                        }]}
                    >
                        <Input placeholder="XXA9999999BC2" />
                    </Form.Item>
                    <Form.Item
                        label="Dirección"
                        name="direccion"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese la dirección"
                        }]}
                    >
                        <Input placeholder="Magallanes #12012 Colinas del Sur" />
                    </Form.Item>
                    <Form.Item
                        label="País"
                        name="pais_id"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el país"
                        }]}
                    >
                        <Select
                            className="width-100"
                            showSearch
                            filterOption={false}
                            showArrow
                            onSearch={search => this.getPaises({ search })}
                            onSelect={pais_id => {this.getEstados({pais_id: pais_id.value})}}
                            labelInValue
                        >
                            {this.state.paises?.data?.map((item) => {
                                return <Option value={item._id} >{item.name}</Option>
                            })}

                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Estado"
                        name="estado_id"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el país"
                        }]}
                    >
                        <Select
                            className="width-100"
                            showSearch
                            filterOption={false}
                            showArrow
                            onSearch={search => this.getEstados({ search })}
                            labelInValue
                        >
                            {this.state.estados?.data?.map((item) => {
                                return <Option value={item._id} >{item.name}</Option>
                            })}

                        </Select>
                    </Form.Item>

                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading}>
                            Guardar
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}> Centros de Distribución </Title>
        </div>
        <ModalClienteCentroDistribucion {...props} />
    </Modal>

}

import React, { Component } from "react";
import { Button, Col, List, Row, Typography, message, Pagination, Space } from "antd";
import { CardEntrega, CardLegalInfo, CardProjectInfo, CardTransporte, CardUtilidad } from "../../../Widgets/Cards";

import ModalTransporte from "./ModalTransporte";
import { useNavigate, useParams } from "react-router-dom";
import { Proyecto } from "../../../../Hooks/Proyecto";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconChecked } from "../../../Widgets/Iconos";
const { Title, Text } = Typography

const axios = require('axios')
/**
 *
 *
 * @export
 * @class TransporteMercancia
 * @extends {Component}
 */
class TransporteMercancia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orden_compra_id: undefined,

            ordenes: {
                data: [{}],
                loading: false,
                page: 1,
                limit: 10,
                total: 10,
            }
        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenesCompra();
    }

    /**
     *
     *
     * @memberof TransporteMercancia
     * @method getOrdenesCompra
     * @descriptio Obtiene el listado de ordenes
     */
    getOrdenesCompra = ({
        page = this.state.ordenes.page,
        limit = this.state.ordenes.limit
    } = this.state.ordenes) => {
        return axios.get('/orden-compra/list', {
            params: {
                proyecto_id: this.props.proyecto_id,
                page,
                limit
            }
        })
            .then(({ data }) => this.setState(state => {
                state.ordenes = {
                    ...state.ordenes,
                    ...data
                }
                return state
            }))
            .catch(err => {
                message.error("Error al obtener las ordenes de compra")
            })
    }

    updateProyectoEstatus = () => {
        axios.put('/proyectos', {
            id: this.props.proyecto_id,
            status_logistica: 4
        }).then(({ data }) => {
            message.success("Proyecto actualizado")
        }).catch(err => {
            message.error("Error al actualizar el proyecto")
        })
    }

    finishProyecto = () => {
        axios.put('/proyectos', {
            id: this.props.proyecto_id,
            status_cliente: 8
        }).then(({ data }) => {
            message.success("Proyecto finalizado con éxito")
        }).catch(err => {
            message.error("Error al finalizar el proyecto")
        })
    }

    render() {

        return (
            <>
                <Col flex="auto" >
                    <Row gutter={[16, 16]}>
                        <Col span={8} lg={8} md={24} >
                            <CardProjectInfo
                                quote_day={this.props.proyecto?.createdAt}
                                nombre={`${this.props.proyecto?.cliente_id?.usuario?.nombre} ${this.props.proyecto?.cliente_id?.usuario?.apellido}`}
                                empresa={this.props.proyecto?.cliente_id?.razon_social}
                                telefono={this.props.proyecto?.cliente_id?.telefono}
                                correo={this.props.proyecto?.cliente_id?.usuario?.email}
                                avatar_props={{
                                    image: this.props.proyecto?.agente_compras_id?.avatar,
                                    name: `${this.props.proyecto?.agente_compras_id?.nombre} ${this.props.proyecto?.agente_compras_id?.apellido}`,
                                }}
                                rfq={this.props.proyecto.rfq_id}
                            />
                        </Col>
                        <Col span={8} lg={8} md={12}>
                            <Row gutter={[0, 16]}>
                                <Col span={24}>
                                    <CardUtilidad margen={this.props.proyecto?.margen} />
                                </Col>
                                <Col span={24}>
                                    <CardLegalInfo proyecto_id={this.props.proyecto_id} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} lg={8} md={12}>
                            <CardEntrega />
                        </Col>
                    </Row>

                    <Row >
                        <Row justify="space-between " className="width-100 mt-1">
                            <Col><Title level={3}>Transportación de Mercancias</Title></Col>
                            <Col>
                                <Space size={10}>
                                    <Button
                                        type="primary"
                                        disabled={this.props.proyecto?.status_logistica >= 4}
                                        style={{ backgroundColor: "#D6009A", borderColor: "#D6009A" }}
                                        size="large"
                                        onClick={() => this.updateProyectoEstatus()}
                                    >
                                        <span style={{ fontWeight: 400 }}>Transito Completo</span>
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        <Col span={24} className="mt-1" >
                            <List
                                loading={this.state.ordenes.loading}
                                className="component-list medium-list"
                                itemLayout="horizontal"
                                dataSource={this.state.ordenes.data}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <CardTransporte
                                            orden={item}
                                            onEdit={() => this.setState({ modalVisible: true, orden_compra_id: item._id })}
                                            onDelete={() => this.deleteTrasnporte(item._id)}
                                        />
                                    </List.Item>
                                )}
                            />

                            <Pagination
                                hideOnSinglePage={true}
                                defaultCurrent={this.state.ordenes.page}
                                current={this.state.ordenes.page}
                                defaultPageSize={this.state.ordenes.limit}
                                onChange={(page) => this.getTransporteMercancia({ page })}
                                total={this.state.ordenes.limit}
                                showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} registros`}
                            />
                        </Col>
                    </Row>
                </Col>

                <ModalTransporte
                    visible={this.state.modalVisible}
                    orden_compra_id={this.state.orden_compra_id}
                    onClose={() => {
                        this.setState({ modalVisible: false, orden_compra_id: undefined })
                        this.getOrdenesCompra()
                    }}
                />
                <FloatingButton 
                onClick={this.finishProyecto}
                title="Finalizar Proyecto" 
                icon={<IconChecked style={{ width: 40, height: 40 }}
                background='linear-gradient( 126.63deg, #74BCFF 11.98%, #52C41A 83.35%)' />} />


            </>
        )
    }
}

export default props => {

    const { proyecto_id } = useParams()
    const navigate = useNavigate()

    let { margen, ...proyecto } = React.useContext(Proyecto)

    let { ingresos, costos } = margen || {}
    margen = ingresos !== undefined && costos !== undefined ? ((ingresos - costos) / ingresos) * 100 : 0

    return <TransporteMercancia {...props} proyecto_id={proyecto_id} navigate={navigate} proyecto={{ margen, ...proyecto }} />
}
import React, { Component } from "react";
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Spin, Tooltip, Typography, Upload, } from 'antd';
import PropTypes from "prop-types";
import { LoadingOutlined, PlusOutlined, QuestionCircleFilled, DeleteOutlined } from "@ant-design/icons";
import ColorPicker from '../../Widgets/ColorPicker';

const axios = require('axios').default;
const { Title, } = Typography;



/**
 *
 *
 * @export
 * @class FormNegocio
 * @extends {Component}
 */
class FormNegocio extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {},
            loadingImage: false
        }
    }
    formModalNegocios = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.negocio_id != undefined) {
            this.getNegocio(this.props.negocio_id)
        }
    }

    /**
     * @methodOf FormNegocios
     * @function getNegocio
     *
     * @description Obtiene el negocio
     *
     * */
    getNegocio = (id) => axios.get("/negocios/get", {
        params: { id }
    })
        .then(async ({ data }) => {
            const logo = data.data.logo;
            if (data.data.logo !== undefined && data.data.logo !== null && data.data.logo !== "") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + logo,
                        name: logo
                    }
                })
                data.data.logo = [{
                    uid: -1,
                    name: data.data.logo,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.data.logo,
                    response: { filename: data.data.logo }
                }];

            } else data.data.logo = []
            this.setState({
                form: data.data,
            })
            await this.formModalNegocios.current.resetFields();
        })
        .catch(res => {
            message.error('No se pudo cargar el Negocio')
        })


    /**
     * @methodOf FormNegocios
     * @function saveNegocio
     *
     * @description Actualiza o guarda el negocio
     *
     * */
    saveNegocio = values => {
        if (this.state.image)
            values.logo = this.state.image.name;
        else
            values.logo = null;


        if (this.props.negocio_id) {
            this.updateNegocio(values)
        } else {
            this.addNegocio(values)
        }
    }
    /**
     * @methodOf FormNegocios
     * @function saveNegocio
     *
     * @description Guarda el negocio
     *
     * */
    addNegocio = (values) => {
        axios.post('/negocios/add', { ...values, id: values._id })
            .then(() => {
                message.success("¡Se ha guardado correctamente el negocio!")
                this.props.accept();
            })
            .catch((e) => {
                message.error('No se pudo guardar el negocio')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }

    /**
     * @methodOf FormNegocios
     * @function saveNegocio
     *
     * @description Actualiza el negocio
     *
     * */
    updateNegocio = (values) => {
        axios.put('/negocios/update', { ...values, id: this.props.negocio_id })
            .then(() => {
                message.success("¡Se ha guardado correctamente el negocio!")
                this.props.accept();
            })
            .catch((e) => {
                message.error('No se pudo guardar el negocio')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }


    /**
    * @methodOf FormNegocios
    * @function saveNegocio
    *
    * @description Actualiza el negocio con el logo 
    *
    * */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        if (this.props.cuenta_id !== undefined)
            axios.post('/negocios/update', { logo: null, id: this.props.negocio_id })
                .catch(error => console.log(error)).finally(() => {
                    this.setState({ loadingImage: false, image: null })
                })
        else {
            this.setState({ loadingImage: false })
        }

    };

    /**
    *
    * @memberof FormNegocio
    *
    * @method deleteImage
    * @description Elimina un archivo del WebService.
    *
    * @param images (string)
    * Recibe el nombre de la imagen.
    */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })
            if (this.state.form._id)
                axios.put('/negocios/update', { logo: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };




    render() {
        const { normFile, formModalNegocios, saveNegocio, deleteImage } = this;
        const { form, image, loading, id, loadingImage } = this.state;
        const user = this.context;
        return (
            <Spin spinning={loading}>
                <Form layout="vertical" ref={formModalNegocios} initialValues={form} onFinish={saveNegocio} >
                    <Row align="center">
                        <Form.Item name="logo" align="center" getValueFromEvent={normFile} valuePropName="fileList"
                            help={image ? <Button className="btn-upload-delete" shape="circle" danger onClick={deleteImage}><DeleteOutlined /></Button> : null} >
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={axios.defaults.baseURL + "/upload/add"}
                                accept="image/*"
                            >
                                {(loadingImage) ? <div>
                                    {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div className="ant-upload-text">Upload</div>
                                </div> : ((image) ?
                                    <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                                    :
                                    <div>
                                        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div className="ant-upload-text">Subir</div>
                                    </div>)}
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Nombre" name="nombre" rules={[{ required: true, message: "Por favor, ingrese el nombre" }]} >
                                <Input placeholder="Nombre"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item label="Descripción" name="descripcion" rules={[{ required: true, message: "Por favor, ingrese descripción" }]} >
                                <Input placeholder="Descripción"></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={12} >
                            <Form.Item label="Color" name="color" >
                                <ColorPicker
                                    colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                />
                            </Form.Item>
                        </Col>
                        {(user && user.tipo == 1) ? <Col xs={24} lg={12}  >
                            <Form.Item name="privado" valuePropName="checked" >
                                <Checkbox>
                                    Privado <Tooltip title="Al marcar el negocio como privado, no será visible para ningún usuario.">
                                        <QuestionCircleFilled />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                        </Col> : null}


                    </Row>

                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading || this.state.loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalNegocios
 *@description 
 */
export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <div className="center">
                <Title level={3}>  Negocio</Title>
            </div>
            <FormNegocio {...props} />
        </Modal>
    )
}

import React, { useState, useContext, useParams, useEffect } from 'react'
import { Route, Routes, useNavigate } from "react-router-dom";
import { LeftOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Col, Button, Row, PageHeader, Space, Layout, message } from 'antd';

import { Proyecto, SetProyecto } from '../Hooks/Proyecto'
import Contrato from '../components/Admin/ProjectManager/Legal/Contrato';
import MatrizCostos from "../components/Admin/ProjectManager/MatrizCostos";
import OrdenEmbarque from '../components/Admin/ProjectManager/Logistica/OrdenEmbarque';
import StepTracker from "../components/Admin/ProjectManager/Secciones/StepTracker";
import Accounting from '../components/Admin/ProjectManager/Contabilidad/Accounting';
import Reservaciones from '../components/Admin/ProjectManager/Logistica/Reservaciones'
import TransporteMercancia from '../components/Admin/ProjectManager/Logistica/TransporteMercancia';

const axios = require('axios')
const { Content } = Layout

/**
 *
 *
 * @param {*} props
 * @return {*} Abre y Ciera el Project Tracker. Se manejó a parte por efectos de rendimiento.
 */
function ButtonProjectManager(props) {

    const [showText, setshowText] = useState(false);

    return <Button
        className='project-tracker-button-sticky'
        onMouseEnter={() => setshowText(true)}
        onMouseLeave={() => setshowText(false)}
        style={{
            left: showText ? 0 : "-175px",
        }}
        {...props}
    >
        Abrir Project Tracker &nbsp; <MenuUnfoldOutlined style={{ color: "currentcolor" }} />
    </Button>

}

/**
 *
 *
 * @export
 * @function RouterProjectManangerAdmin
 * @extends {Component}
 */
export default function RouterProjectManangerAdmin(props) {


    const hproyecto = React.useContext(Proyecto)
    const hsetProyecto = React.useContext(SetProyecto)

    const navigate = useNavigate();

    /** */
    const [activeTracker, setActiveTracker] = React.useState(true)

    /** */
    let [proyecto_id, setProyectoID] = useState(hproyecto._id);
    let [estructura, setEstructura] = useState(1);
    let [update, setUpdate] = useState(true);

    let [proyecto, setProyecto] = useState(hproyecto)


    /**Cuando cambia el proyecto seleccionado */
    useEffect(() => {
        if (proyecto_id != null || proyecto_id != undefined)
            getProyecto()
    }, [proyecto_id])


    useEffect(() => {
        if (proyecto._id != null || proyecto._id != undefined && update)
            getProyecto()
            setUpdate(false)
    }, [])



    /**
     * @const getProyecto
     * @descripcion Obtiene el proyecto con base al ID almacenado en proyecto_id
     */
    const getProyecto = () => {
        axios.get("/project-manager/proyecto", {
            params: {
                id: proyecto_id,
                detalles: false,
                asignados: true,
                proveedor: true
            },
        })
            .then((data) => {
                console.log('response data proyect to load on components', data)
                setProyecto(data.data)
                hsetProyecto(data.data)

            })
            .catch(() => message.error("No fue posible obtener el proyecto"))
    };


    return (
        <>
            <Proyecto.Provider value={proyecto}>
                <SetProyecto.Provider value={getProyecto}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Space>
                                <Button
                                    ghost
                                    onClick={() => navigate(-1)}
                                    className="no-border"
                                    icon={<LeftOutlined className='text-white' />} />
                                <span className="ant-page-header-heading-title" >
                                    Supply Chain
                                </span>
                            </Space>

                        }
                        extra={
                            <>
                                <Button type="link" className="text-white">Supply Chain</Button>
                                <Button type="link" className="text-white" >Maintenance</Button>
                            </>
                        }
                    />

                    <Content className="content-bg">

                        <div style={{ position: "sticky", top: "2em", zIndex: 1, }}>
                            {!activeTracker ?
                                <ButtonProjectManager onClick={() => setActiveTracker(!activeTracker)} /> : null}
                        </div>
                        <Row className="project-manager" gutter={[16, 16]} align="end" >
                            <Col xs={24} lg={10} xl={9} xxl={6} className={"project-tracker " + (activeTracker ? "project-tracker-open" : "project-tracker-close")}>

                                <StepTracker
                                    proyecto={proyecto}
                                    onMenuPressed={() => setActiveTracker(!activeTracker)}
                                />
                            </Col>
                            <Col className={"project-tracker project-tracker-content " + ((activeTracker ? "project-tracker-content-open" : ""))}
                                xs={24}
                                lg={activeTracker ? 14 : 24}
                                xl={activeTracker ? 15 : 24}
                                xxl={activeTracker ? 18 : 24}

                                style={{ float: "left", clear: "left" }}>


                                <Routes>
                                    {/* Lista de Selección de Matriz Costos */}
                                    <Route
                                        path="matriz-costos/:proyecto_id"
                                        element={<MatrizCostos {...props} setProyectoID={setProyectoID} setEstructura={setEstructura} estructura={estructura} />}
                                    />


                                    <Route
                                        path="legal/:proyecto_id"
                                        element={<Contrato {...props} />}
                                    />


                                    <Route
                                        path="contabilidad/:proyecto_id"
                                        element={<Accounting {...props} />}
                                    />

                                    {/* Lista de Selección de Recepción de Mercancia */}

                                    <Route
                                        path="reservaciones/:proyecto_id"
                                        element={<Reservaciones {...props} />}
                                    />

                                    <Route
                                        path="embarques/:proyecto_id"
                                        element={<OrdenEmbarque {...props} />}
                                    />

                                    <Route
                                        path="transportes/:proyecto_id"
                                        element={<TransporteMercancia {...props} />}
                                    />
                                </Routes>
                            </Col>
                        </Row>
                    </Content>
                </SetProyecto.Provider>
            </Proyecto.Provider >
        </>

    )
}


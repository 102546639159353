import React, { Component } from "react";
import { Col, Row, Spin, Table, Layout, Tag, Image, message } from "antd";

import ResumenJSON from "./Resumen.json"

const { Content } = Layout;
const axios = require('axios')


/**
 * 
 * @param {*} name  Nombre del titulo a mostrar
 * @param {Integer} index Indice de la posicion del titulo a mostrar
 * @returns Component
 */
const RowTitles = ({ name, index }) => {
  let names = [];
  let className = "table-tag center mt-05 ";
  switch (index) {
    case 0: className += "producto-tag"; break;
    case 1: className += "blue-tag"; break;
    case 2: className += "blue-tag"; break;
    case 3: className += "blue-tag"; break;
    case 6: className += "grey-tag"; break;
    case 8: className += "blue-tag"; break;
    case 11: className += "blue-tag"; break;
    default: className += "white-tag"; break;
  }


  names.push(<Col span={24}><Tag className={className}>{name}</Tag></Col>)

  return <Row>{names}</Row>

}


/**
 * 
 * @param {Object} obj Objeto del que se desea extraer informacion
 * @param {String} path cadena de texto con el que se accederia al objeto
 * @returns value
 */
const getObject = (obj, path) => {
  let _array = path.split('.');
  let parent = obj;
  let name = _array[0];

  _array.slice(1).forEach((fieldName) => {
    parent[name] = parent[name] || {};
    parent = parent[name];
    name = fieldName;
  });
  return parent[name];
}

/**
 * 
 * @param {*} record Objeto con la informacion del producto
 * @param {Integer} index  Indice del objeto en el arreglo
 * @returns Component
 */
const RowProducto = ({ record, index }) => {

  let names = [];
  ResumenJSON.map(producto => {

    console.log('producto resumen',producto)
    //ignorar el primer elemento que es la informacion del producto
    if (!(producto.name == "producto")) {
      //si es un objeto dentro del objeto, se accede al objeto hijo para obtener el valor
      if (producto.object == true) {
        let value = getObject(record, producto.name);
        value = (producto.moneda == true && value != null) ? value.toMoney(true) : value;
        console.log('value ', value)

        names.push(<Col span={24}><Tag className="table-tag center mt-05">{value ? value : '-'}</Tag></Col>)
      }
      //Si es array, se suman los elementos del array
      else if (Array.isArray(producto.name)) {
        let suma = producto.name.map(item => record[item]).reduce((prev, curr) => prev + curr, 0);
        suma = (producto.moneda == true && suma != null) ? suma.toMoney(true) : suma
        console.log('suma ', suma)

        names.push(<Col span={24}><Tag className="table-tag center mt-05">{suma ? suma : '-'}</Tag></Col>)
      }
      else {
        let value = (producto.moneda == true && record[producto.name] != null) ? record[producto.name].toMoney(true) : record[producto.name]
        console.log('value ', value)
        names.push(<Col span={24}><Tag className="table-tag center mt-05">{value ? value : '-'}</Tag></Col>)

      }
    }

  })
  return <Row>
    <Col span={24}>
      <Tag className="table-tag producto-tag center mt-05">
        <Col span={12} className="center">
          <Image
            width={25}
            {...(record.rfq_detalle_id?.fotografia ? {
              src: `${axios.defaults.baseURL}/upload/${record.rfq_detalle_id?.fotografia}`
            } : {
              preview: false
            })}
          />
        </Col>
        <Col span={12}>{record.rfq_detalle_id?.modelo}</Col>
      </Tag>
    </Col>
    {names}
  </Row>


}


/**
 *
 *
 * @export
 * @class ResumenCostos
 * @extends {Component}
 * @description Resumen de costos de la matriz del PM
 */
export default class ResumenCostos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      proyecto: {}

    };
  }


  componentDidMount() {
    this.getProductosMatriz();
    this.setState({ proyecto: this.props.proyecto })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.estructura != this.props.estructura) {
      console.log('cambio contenedor')
      this.getProductosMatriz();
    }
  }


  /**
   * @memberof getProductosMatriz
   *
   * @method updateMatriz
   * @description Obtiene la informacion de la matriz
   * @param {Number} contenedor Es el tipo de contenedor seleccionado previamente en la matriz
   */
  getProductosMatriz = () => {
    this.setState({ loading: true })
    axios.get('/proyecto/matriz', {
      params: {
        proyecto_id: this.props.proyecto_id,
        contenedor: this.props.estructura
      }
    }).then(response => {
      console.log('response datos de la matriz', response)
      this.setState({ data: response.data.matriz })
    }).catch(error => {
      console.log("error", error);
    }).finally(() => this.setState({ loading: false }))
  }




  render() {

    let columns = [];
    let titles = {
      title: null,
      dataIndex: null,
      width: 250,
      fixed: "left",
      render: (value, index) => ResumenJSON.map((item, _index) => <RowTitles name={item.label} index={_index} />)
    }
    columns.push(titles)

    this.state.data?.map((item, index) => {
      let producto = {
        title: null,
        dataIndex: null,
        width: 250,
        render: (text, record) => <RowProducto record={item} index={index} />
      }
      columns.push(producto);
    });

    return (
      <>
        <Spin spinning={false}>
          <Content className="content-bg pd-1">
            <Table
              className="fob-table"
              size="small"
              scroll={{ x: 1000, y: 1000 }}
              columns={columns}
              dataSource={[{}]}
              pagination={false}
              locale={{ emptyText: "Sin FOB disponibles" }}
            />
          </Content>
        </Spin>
      </>
    );
  }
}
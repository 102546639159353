import React, { Component } from 'react'
import { List, Card, Row, Col, Typography, Button, message, Modal } from 'antd'

//componentes
import { IconDetails } from '../../../../Widgets/Iconos';
import { estatus_orden_compra } from "../../../../Utils";
import FloatingButton from "../../../../Widgets/Floating Button/FloatingButton";

const moment = require('moment');
const axios = require('axios');
const { Text } = Typography;


/**
 * @class OrdenesComprasList 
 * @descripcion Lista con las ordenes relacionadas al proyecto
 **/
class OrdenesComprasList extends Component {

	constructor(props){
		super(props)
		this.state = {
			loading: false,
			ordenes: {
                data: [],
                limit: 10,
                page: 1,
                total: 0
            },
		}
	}


	componentDidMount(){
		console.log(this.props.proyecto_id)
		this.getOrdenesCompra()
	}


	/**
     * @memberof OrdenesComprasList
     * @method getOrdenesCompra
     * @description Obtiene las ordenes de el proyecto correspondiente
     */
    getOrdenesCompra = ({
    	page = this.state.ordenes.page,
    	limit = this.state.ordenes.limit
    } = this.state.ordenes) => {

    	this.setState({loading: true})
        axios.get('/orden-compra/list', {
        	params:{
	            page,
	            limit,
	            proyecto_id: this.props.proyecto_id,
        	}
        }).then(({ data }) => {
        	console.log("data", data);
        	this.setState({ordenes: data})
                
        }).catch(err => {
        	console.log(err)
        	message.error('Error al obtener las ordenes de de compra')
        })
        .finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof OrdenesComprasList
     * @method createOrdenCompra
     * @description Genera una nueva orden de compra (solo una por proyecto)
     */
    createOrdenCompra = () => {

    	axios.post('/orden-compra/add',{
    		proyecto_id: this.props.proyecto_id
    	}).then(response => {
    		message.success('Orden de Compra creada')
    		this.getOrdenesCompra()
    	}).catch(error =>{
    		console.log("error", error);
    		if(error.response.status === 403)
    			message.error(error.response.data.message)
    		else
    			message.error('Error al crear la orden de Compra')
    	})
    }

     /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getPDF
     * @description Generamos el PDF de la orden de Compra
     */
    getPDF = (orden_compra_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/orden-compra/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('orden_compra_id', orden_compra_id)
        return urlPDF.href
    }

	render(){
		return(
			<>
				<List
                    loading={this.state.loading}
                    className="component-list no-scroll"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin ordenes de compra" }}
                    dataSource={this.state.ordenes.data}
                    pagination={{
                    	current: this.state.ordenes.page,
                        total: this.state.ordenes.total,
                        pageSize: this.state.ordenes.limit,
                        showSizeChanger: true,
                        onChange: (page, limit) => this.getOrdenesCompra({ page, limit })
                    }}
                    header={<Row className="width-100">
	                    <Col span={2} className="center">
	                        <Text strong>Fecha</Text>
	                    </Col>
	                    <Col span={5} className="center">
	                        <Text strong>Proveedor</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Estatus</Text>
	                    </Col>
	                    <Col span={5} className="center">
	                        <Text strong>Destinatario</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Monto Total</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Monto Pagado</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Acciones</Text>
	                    </Col>
	                </Row>}
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card 
                                className="card-list"
                            >
                                <Row>
									<Col span={2} className="center">
										<Text strong>{moment(item.fecha_vigencia).format("DD/MM/YYYY")}</Text>
									</Col>
									<Col span={5} className="center">
										<Text className="text-gray-dark">{item.proveedor_id ? item.proveedor_id.alias : '-'}</Text>
									</Col>
									<Col span={3} className="center">
										<Text> {estatus_orden_compra(item.estatus)} </Text>
									</Col>
									<Col span={5} className="center">
										<Text> {item.cliente_id?.razon_social} </Text>
									</Col>
									<Col span={3} className="center ">
										<Text className="text-gray-dark">
											$ {item.monto_total?.toMoney(true)} MXN
										</Text>
									</Col>
									<Col span={3} className="center ">
										<Text className="text-gray-dark">
											$ {item.monto_pagado?.toMoney(true)} MXN
										</Text>
									</Col>
				                    <Col span={3} className="center">
				                    	<a href={this.getPDF(item._id)} download target="_blank">
					                        <Button
	                                            icon={<IconDetails />}
	                                            type="primary"
	                                            title="Ver Detalle"
	                                            size="small"
	                                            style={{paddingTop: '3px'}}
	                                        >
	                                        </Button>
	                                    </a>
				                    </Col>
                   					
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton 
                	title="Nuevo registro" 
                	onClick={() => {
                		Modal.confirm({
                			title: '¿Desea crear una nueva Orden de Compra?',
                			cancelText: 'Cancelar',
                			onOk: ()=>{
                				this.createOrdenCompra()
                			}
                		})
                	}} 
                />
			</>
		)
	}

}

export default function Vista(props) {
    return <OrdenesComprasList {...props}/>
}
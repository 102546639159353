import React, { Component } from "react";
import { Col, Radio, Form, Row, Typography, message } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { CardAccountPurchases, CardProjectInfo, CardUtilidad } from "../../../Widgets/Cards";
import IngresosList from './Listas/IngresosList'
import EgresosList from './Listas/EgresosList'
import FacturasList from './Listas/FacturasList'
import OrdenesComprasList from './Listas/OrdenesComprasList'

import '../../../../Styles/Modules/ProjectManager/Accounting.css'


const { Title } = Typography
const axios = require('axios')


/**
 *
 *
 * @export
 * @class AccountPurchases
 * @extends {Component}
 */
class AccountPurchases extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            proyecto_id: this.props.params.proyecto_id,
            proyecto: {
                total: 0,
                egresos: 0,
                ingresos: 0,
            },

            tipo: 1,
            orden_id: this.props.params.orden_id,
            currentPage: 1,
            total: 10,

            transaccion_id: undefined,
            factura_id: undefined,
            modalFacturaVisible: false,
            modalFacturaDetalleVisible: false,
            modalTransacVisible: false,
            modalTransacDetalleVisible: false,
            productos: [],
            items: [],
            ordenes_compra: [],


            monto_venta: 0,
            costos_proyectado: 0,
            costos_real: 0,
            ingreso_proyectado: 0,
            ingreso_real: 0,
            utilidad_proyectado: 0,
            utilidad_real: 0,
            facturas_emitidas: 0,
            facturas_recibidas: 0,

        }
    }

    formRef = React.createRef();


    /**
     * @memberof AccountPurchases
     * @method componentDidMount
    */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProyecto();
    }

    /**
     * @memberof AccountPurchases
     * @method getProyecto
     * @description Obtiene la informacion general del proyecto
     */
    getProyecto = () => {
        this.setState({ loading: true });
        axios.get("/project-manager/proyecto", {
            params: {
                id: this.state.proyecto_id,
                detalles: true, //detales extra del proyecto
                contabiliad: true //obtiene informacion financiera del proyecto
            },
        })
            .then((response) => {
                console.clear()
                this.setState({
                    proyecto: response.data
                })
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al cargar Proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memberof AccountPurchases
     * @method renderList
     * @description Obtiene la informacion general del proyecto
     */
    renderList = () => {

        let props = {
            proyecto_id: this.state.proyecto_id,
            proyecto: this.state.proyecto,
            getProyecto: this.getProyecto
        }

        let tipo = this.state.tipo;

        let tab = this.props?.searchParams.get("tab");
        console.log('tab', tab)
        switch (tab) {
            case 'ingresos':
                this.state.tipo = 1;
                tipo = 1;
                break;
            case 'egresos':
                this.state.tipo = 2;
                tipo = 2;
                break;
            case 'facturas':
                this.state.tipo = 3;
                tipo = 3;
                break;
            case 'ordenes':
                this.state.tipo = 4;
                tipo = 4;
                break;
        }
        this.formRef.current?.setFieldsValue({
            tipo: tipo,
        })
        switch (tipo) {
            case 1:
                return <IngresosList {...props} />
            case 2:
                return <EgresosList {...props} />
            case 3:
                return <FacturasList {...props} />
            case 4:
                return <OrdenesComprasList {...props} />
                break;
        }
    }



    /**
    * @memberof AccountPurchases
    * @method onTabChange
    * @description Asigna al hook el tab seleccionado para ser renderizado
    */
    onTabChange = (_, values) => {
        let tabs = {1:'ingresos',2:'egresos',3:'facturas',4:'ordenes'}
        this.props.setSearchParams({ tab: tabs[values.tipo] })
    }


    render() {

        const { costos_proyectado, ingreso_proyectado, utilidad_proyectado, utilidad_real } = this.state;
        const { total, egresos, ingresos, facturas_ingreso, facturas_egreso } = this.state.proyecto

        return (
            <>
                <Row gutter={[12, 12]}>
                    <Col xs={24} xxl={12}  >
                        <CardProjectInfo
                            quote_day={this.state.proyecto?.createdAt}
                            nombre={`${this.state.proyecto?.cliente_id?.usuario?.nombre} ${this.state.proyecto?.cliente_id?.usuario?.apellido}`}
                            empresa={this.state.proyecto?.cliente_id?.razon_social}
                            telefono={this.state.proyecto?.cliente_id?.telefono}
                            correo={this.state.proyecto?.cliente_id?.usuario?.email}
                            avatar_props={{
                                image: this.state.proyecto?.agente_compras_id?.avatar,
                                name: `${this.state.proyecto?.agente_compras_id?.nombre} ${this.state.proyecto?.agente_compras_id?.apellido}`,
                            }}
                            rfq={this.state.proyecto.rfq_id}
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <Col span={24}>
                            <CardUtilidad />
                        </Col>
                    </Col>
                </Row>

                <Title level={3} className="mt-1">Accounting & Purchases</Title>

                <Row className="mb-05">
                    <CardAccountPurchases
                        monto_venta={total}
                        costos_proyectado={costos_proyectado}
                        costos_real={egresos}
                        ingreso_proyectado={ingreso_proyectado}
                        ingreso_real={ingresos}
                        utilidad_proyectado={utilidad_proyectado}
                        utilidad_real={utilidad_real}
                        facturas_emitidas={facturas_egreso}
                        facturas_recibidas={facturas_ingreso}
                    />
                </Row>
                <Row>
                    <Col span={24} className="mt-1">
                        <Form onValuesChange={this.onTabChange} ref={this.formRef}>
                            <Row className="filter-radio card-shadow ">
                                <Form.Item name="tipo" noStyle>
                                    <Radio.Group defaultValue={1} buttonStyle="solid" className="pd-1" >
                                        <Radio.Button value={1}>Ingresos</Radio.Button>
                                        <Radio.Button value={2}>Egresos</Radio.Button>
                                        <Radio.Button value={3}>Facturas</Radio.Button>
                                        <Radio.Button value={4}>Ordenes Compra</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Col>

                    <Col span={24} className="mt-1" >
                        {this.renderList()}
                    </Col>
                </Row>
            </>
        )
    }
}


export default function (props) {

    let [searchParams, setSearchParams] = useSearchParams();

    return (<AccountPurchases
        {...props}
        params={useParams()}
        navigate={useNavigate()}
        searchParams={searchParams}
        setSearchParams={setSearchParams}

    />)
}
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout, Button } from "antd";

//COMPONENTES
import { User, SetUser } from '../Hooks/Logged';
import Header from '../components/Header/Header';
import CustomerSidebar from "../components/Sidebar/CustomerSidebar";


//ROUTERS
import RouterPedidos from "./RouterPedidos";


import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';

//css
import '../Styles/Global/admin.css';


const { Content } = Layout;

class CustomerRoutes extends Component {

    static contextType = User

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false,
            collapsed: false
        };
    }

    setFilterSearch(e) {
        this.setState({ filterSearch: e })
    }

    updateFilterSearch = (ShowFilter) => {
        if (ShowFilter === undefined) { ShowFilter = false }
        this.setState({
            showSearch: ShowFilter,
        })
    };

    setCollapse = () => {
        let collapsed = this.state.collapsed;
        this.setState({ collapsed: !collapsed })
    }


    render() {
        const { back } = this.state;
        const { updateFilterSearch } = this;
        return (
            <Layout className="layout"  >
                <User.Consumer>
                    {value => { this.context = value }}
                </User.Consumer>
                <Header
                    user={this.context}
                    back={back}
                    onBack={this.onBack}
                    setUser={this.setUser}
                    showResponsiveSidebar={() => this.setState({ responsiveSidebar: !this.state.responsiveSidebar })}
                    showSearch={this.state.showSearch}
                    setFilterSearch={(e) => this.setFilterSearch(e)}
                    filterSearchVal={this.state.filterSearch}
                    admin={true}
                />
                <Layout className="layout-content ">
                    <CustomerSidebar
                        collapsed={this.state.collapsed}
                        user={this.context}
                        back={back}
                        setUser={this.setUser}
                    />

                    <Content>
                        <Button 
                        	onClick={this.setCollapse} 
                        	className={(this.state.collapsed) ? "btn-sider-collapse no-border  " : "no-border btn-sider"}  >
                            {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                        <Routes>
                            <Route
                                path="pedidos/*"
                                element={<RouterPedidos updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                        </Routes>

                    </Content>
                </Layout>

            </Layout >
        )
    }
}


export default function Vista(props) {
    return <CustomerRoutes {...props} setUser={React.useContext(SetUser)} />
}

import React, { Component } from 'react';
import { Divider, Spin, PageHeader, Layout, message, Row, Col, Card, Typography, Tabs } from 'antd'
import axios from 'axios';

import { IconArrowBack } from '../../../Widgets/Iconos';
import Facturas from './DetalleClienteSecciones/Facturas';
import Transacciones from './DetalleClienteSecciones/Transacciones';
import CentroDistribucion from './DetalleClienteSecciones/Centros';
import { useParams ,useNavigate} from 'react-router-dom';


const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;
const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class DetalleClientes extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            cliente: {},
        }
    }

    componentDidMount() {
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('this.props.params.id',this.props.params.id)
        this.getCliente();
    }



    /**
     * @memberof DetalleClientes
     * @method getCliente
     * @description Obtiene un cliente de la DB
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/get', {
            params: {
                id: this.props.params.id
            }
        })
            .then(response => {
                this.setState({ cliente: response.data.data, })
            })
            .catch(error => {
                message.error(error.response.data.message)
                console.log('error al traer usuario', error)
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        backIcon={<IconArrowBack />}
                        onBack={this.back}
                        title={(this.state.cliente != []) ?
                            <>Información de {this.state.cliente?.cliente_id?.nombre} {this.state.cliente?.apellido}</>
                            : "Cliente" 
                            (this.state.centro != []) ?  <>{`/${this.state.centro.direccion}`}</>  :'/ Direccion'
                        }
                    />

                    <Content className="admin-content content-bg pd-1">
                        <Row>
                            <Col span={24}>
                                <Card bordered >
                                    <Row>
                                        <Title level={4}>Información del Cliente</Title>
                                    </Row>
                                    <Row>
                                        {(this.state.cliente?.nombre || this.state.cliente?.apellido) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Nombre</label>
                                                <p className="pInfo"><strong>
                                                    {this.state.cliente?.nombre} {this.state.cliente?.apellido}
                                                </strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.email) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Correo Electrónico</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.email}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.cliente_id?.razon_social) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Razón Social</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.cliente_id?.razon_social}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.telefono) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Número Telefónico</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.cliente_id?.telefono}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.cliente_id?.rfc) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Registro Federal de Contribuyentes</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.cliente_id?.rfc}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.cliente_id?.direccion) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Dirección</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.cliente_id?.direccion} </strong> </p>
                                            </Col>
                                            : null}
                                        {(this.state.cliente?.regimen_fiscal) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Régimen Fiscal</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.cliente_id?.regimen_fiscal}</strong> </p>
                                            </Col>
                                            : null}
                                    </Row>
                                    <Row>
                                        <Divider />
                                        <Title level={4}>Información de Pagos</Title>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Total Vendido</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.cliente_id?.total_vendido ? this.state.cliente?.cliente_id?.total_vendido.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Total Pagado</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.cliente_id?.total_pagado ? this.state.cliente?.cliente_id?.total_pagado.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo por Pagar</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.cliente_id?.saldo_pendiente ? this.state.cliente?.cliente_id?.saldo_pendiente.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Total Facturado</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.cliente_id?.total_facturado ? this.state.cliente?.cliente_id?.total_facturado.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Tabs type="card" className="width-100">
                                <TabPane tab="Centros" key="Centros Distribución" >
                                    <CentroDistribucion
                                        cliente_id={this.props.params.id}
                                    />
                                </TabPane>
                                <TabPane tab="Transacciones" key="transacciones" >
                                    <Transacciones
                                        cliente_id={this.props.params.id}
                                        cliente={this.state?.cliente}
                                    />
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas">
                                    <Facturas
                                        cliente_id={this.props.params.id}
                                        cliente={this.state?.cliente}
                                    />
                                </TabPane>
                            </Tabs>

                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}


export default function (props) {

    return <DetalleClientes {...props} params={useParams()}  navigate={useNavigate()} />
}
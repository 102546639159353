import React, { Component } from "react";
import {  Button, Modal, Typography, Form, Input, message, Spin } from 'antd';

const { Title } = Typography;
const axios = require('axios').default;



/**
 *
 *
 * @class ModalAreas
 * @extends {Component}
 * @description Modal con el formulario para registrar/modificar areas
 */
class ModalAreas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalAreas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.area_id !== undefined) {
            this.getArea()
        }
    }


    /**
     * @memberof ModalAreas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.area_id) {
            this.updateArea(values)
        } else {
            this.addArea(values)
        }
    }


    /**
     * @memberof ModalAreas
     * @method addArea
     * @description Añade una area a la BD
     */
    addArea = (values) => {
        axios.post('/areas/add', {
            ...values
        }).then(response => {

            if (response.data.success) {
                message.success('Área creada')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al crear area, verifique los datos ingresados.')
            }
        }).catch(error => {
            console.log(error)
            message.error('Error al crear area.')
        })
    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {

        this.setState({ loading: true })

        axios.get('/areas/get', {
            params: {
                id: this.props.area_id
            }
        }).then(response => {

            let area = response.data.data
            this.setState({
                area: response.data.data,
            })

            this.ModalAreas.current.setFieldsValue({
                nombre: area.nombre,
                descripcion: area.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalAreas
     * @method updateArea
     * @description Actualiza la información de una area
     */
    updateArea = (values) => {
        this.setState({ loading: true })
        axios.post('/areas/update', {
            ...values,
            id: this.props.area_id,
        }).then(response => {

            if (response.data.success) {
                message.success('Área Actualizada')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar area, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        const formItemLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };
        return (
            <Form
                layout="vertical"
                ref={this.ModalAreas}
                onFinish={this.onFinish}
                className="ml-1 mr-1"
                {...formItemLayout}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>

                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                    >
                        <Input placeholder="Descripción" ></Input>

                    </Form.Item>

                    <Form.Item className="center text-center">
                        <Button htmlType="submit" type="primary">
                            Guardar
                        </Button>
                    </Form.Item>

                </Spin>
            </Form>
        )
    }
}



export default function vista(props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>Área</Title>
        </div>
        <ModalAreas {...props} />
    </Modal>

}
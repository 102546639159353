import React, { useState } from 'react'
import { Route, Routes } from "react-router-dom";


import ProjectManager from '../components/Admin/ProjectManager/ProjectManager'
import ProjectManagerAdmin from './RouterProjectManagerAdmin'




/**
 *
 *
 * @export
 * @function RouterProjectMananger
 * @extends {Component}
 */
function RouterProjectMananger(props) {
    return (
        <Routes>
            <Route
                path="dashboard"
                element={<ProjectManager {...props} />}
            />
            {/* Lista de Project Manager */}
            <Route
                path="manager/*"
                element={<ProjectManagerAdmin {...props} />}
            />
        </Routes>

    )
}

export default RouterProjectMananger;

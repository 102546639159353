import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Upload, Input, message, Spin, } from 'antd';
import {UploadOutlined} from "@ant-design/icons";

const { Title } = Typography;
const axios = require('axios').default;
const moment = require('moment');

/**
 * @class ModalContrato
 * @extends {Component}
 */
class ModalContrato extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            image: null

        }
    }

    ModalContrato = React.createRef();

    componentDidMount() {
        if(this.props.contrato_id){
            this.getContrato()
        }
    }

    /**
     * @memberof ModalContrato
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        this.setState({loading: true})

        if(this.state.image){
            if(this.props.contrato_id){
                this.updateContrato(values)
            }else{
                this.addContrato(values)
            }

        }else{
            message.error('El Archivo PDF es obligatorio')
            this.setState({loading: false})
        }
    }

     /**
     * @memberof ModalContrato
     * @method addContrato
     * @description Se registra un nuevo contrato
     * 
     */
    addContrato = (values) => {
        axios.post('/project-manager/contratos/add', {
            descripcion: values.descripcion,
            contrato: this.state.image.name,
            proyecto_id: this.props.proyecto_id,
        }).then(response => {
            message.success('Contrato registrado')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al registrar el Contrato')
        }).finally(()=> {
            this.setState({loading: false})
        })
    }

     /**
     * @memberof ModalContrato
     * @method updateContrato
     * @description Se actualiza un contrato
     * 
     */
    updateContrato = (values) => {
        axios.post('/project-manager/contratos/update', {
            id: this.props.contrato_id,
            descripcion: values.descripcion,
            contrato: this.state.image.name,
        }).then(response => {
            message.success('Contrato Actualizado')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al actualizar el Contrato')
        }).finally(()=> {
            this.setState({loading: false})
        })
    }   

     /**
     * @memberof ModalContrato
     * @method getContrato
     * @description Obtiene la info de un Contrato
     * 
     */
    getContrato = () => {
        axios.get('/project-manager/contratos/get', {
            params:{
                id: this.props.contrato_id
            }
        }).then(response => {
           console.log('Contrato', response.data.data)
           this.ModalContrato.current.setFieldsValue({
                descripcion: response.data.data.descripcion,
                contrato_pdf: response.data.data.contrato !== null ? [{
                    uid: '1',
                    name: 'Contrato.pdf',
                    status: 'done',
                    url: `${axios.defaults.baseURL}/upload/${response.data.data.contrato}`
                }] : []
           })

            if(response.data.data.contrato !== null)
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + response.data.data.contrato,
                        name: response.data.data.contrato
                    }
                })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener el Contrato')
        }).finally(()=> {
            this.setState({loading: false})
        })
    } 

   

      /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status == "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status == "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name);
        
        return e && e.fileList;
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };


    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalContrato}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la descripción"
                                }]}
                            >
                                <Input.TextArea placeholder="Descripción"></Input.TextArea>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                label="Contrato en PDF"
                                name="contrato_pdf"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={false}
                                    maxCount={1}
                                    beforeUpload={ file => {
                                        if (file.type !== 'application/pdf') {
                                            message.error(`${file.name} no es un archivo PDF`);
                                        }
                                        return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE;   
                                    }}
                                >
                                    <Button>
                                        <UploadOutlined /> Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row >
                        <Col span={24} className="text-center center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3} className="text-center">{factura_id != null ? "Editar " : "Nuevo "} Contrato</Title>
        </div>
        <ModalContrato {...props} />
    </Modal>

}
import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const RazonSocialSelect
 * @description Select para los razones sociales registrados en el sistema
 */
const RazonSocialSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione la razon social", onSelect = () => {}, disabled = false, className = "" } = props

    const [ razones, setRazones ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getRazones
     * @description Obitiene las razones
     */
    const getRazones = ({
        page = razones.page, 
        limit = razones.limit, 
        search = razones.search,
    } = razones) => {

        axios.get('/razones-sociales', {
            params: { 
                page, 
                limit, 
                search 
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
        	console.log("response", response.data.data);
            setRazones({
                ...razones,
                data: response.data.data.itemsList,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            message('Error al obtener las razones')
        })
    }

    //componentDidMount
    useEffect(() => {
        getRazones()
    }, [])

    return (
        <Select
            {...props}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getRazones({search})}
            onSelect={(razon)=> {
                onChange(razon.value)
                onSelect(razon.value)
            }}
            value={value}
        >
            {
                razones.data.map(razon => <Option value={razon._id} key={razon._id}>{razon.razon_social}</Option>)
            }
        </Select>
    );
}



export default RazonSocialSelect;
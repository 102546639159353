import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'
import axios from 'axios';
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalCategorias from './ModalCategorias'

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Categorias
 * @extends {Component}
 * @description Vista de Categorias
 */
export default class Categorias extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            categoria_id: undefined,
            categorias: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },

            page: 1,
            total: 0,
            filtroSearch: '',
            searching: true,

        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCategorias();
    }


    /**
     *
     *
     * @memberof Categorias
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching == true) {
            this.getCategorias();
            this.state.searching = false;
        }
    }

    /**
     * @methodOf  Areas
     * @method getCategorias
     *
     * @description Obtiene las categorias
     * */
    getCategorias = ({
        page = this.state.categorias.page,
        limit = this.state.categorias.limit,
        search = this.props.search,
    } = this.state.categorias) => {
        this.setState({ loading: true })
        axios.get('/categorias', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            this.setState({
                categorias: data
            })
        }).catch(error => {
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Categorias"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin categorias " }}
                            dataSource={this.state.categorias.data}
                            pagination={{
                                current: this.state.categorias.page,
                                pageSize: this.state.categorias.limit,
                                total: this.state.categorias.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getCategorias({ page })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >

                                <Col xs={10} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={10} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col xs={10} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={10} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, categoria_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta categoria?"
                                                        onConfirm={() => axios.delete(`/categoria/${item._id}`)
                                                            .then(() => {
                                                                this.getCategorias()
                                                                message.success('Categoria eliminada')
                                                            }).catch(error => {
                                                                if(error.response?.data?.message)
                                                                    message.error(error.response?.data?.message)
                                                                else
                                                                    message.error('Error al eliminar la categoria.')
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                    <ModalCategorias
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, categoria_id: undefined })
                            this.getCategorias()
                        }}
                        categoria_id={this.state.categoria_id}
                    />
                </Spin>
            </>
        )
    }
}
import React, { Component, useContext } from 'react';
import { Button, Modal, Typography, Form, Input, } from 'antd';
import { User, SetUser } from '../../../../Hooks/Logged';
import '../../../../Styles/Modules/Public/marketplace.css';

const axios = require('axios')
const { Title, Paragraph } = Typography;

class StepCuenta extends Component {

    static contextType = SetUser

    constructor(props) {
        super(props);
        this.state = {
            type: 2
        }
    }


    /**
     *
     *
     * @param {*} data
     * @memberof StepCuenta
     * 
     * @description Registra o inicia sesión según sea el caso.
     */
    handleAccount = data => {
        console.log('data', data)
        console.log('type', this.state.type)
        this.props.setLoading(true);
        axios.post(((this.state.type === 2) ? '/login' : '/register'), data)
            .then(({data, headers}) => {
                sessionStorage.setItem('token', headers.authorization)
                axios.defaults.headers.post["Authorization"] = sessionStorage.getItem('token')

                const user = data.user
                const cliente = data.user.cliente_id
                let step = this.state.type
                
                this.context(user)

                if( !(
                    user.nombre && 
                    user.apellido && 
                    cliente?.telefono && 
                    cliente?.razon_social && 
                    cliente?.rfc
                ) ){
                    step = 1
                    this.props.setContactData({
                        nombre: user.nombre,
                        apellido: user.apellido,
                        telefono: cliente?.telefono,
                        razon_social: cliente?.razon_social,
                        rfc: cliente?.rfc
                    })
                }

                this.props.onSuccess(step)
            })
            .catch((response) => {
                console.log('error', response?.message);
                Modal.error({
                    title: "No fue posible " + ((this.state.type === 1) ? 'iniciar sesión' : 'registrarse'),
                    content: "Revise la información ingresada."
                })
            })
            .finally(f => {
                this.props.setLoading(false);
            })
    }

    changeTypeLogin = () => this.setState({ type: (this.state.type === 1) ? 2 : 1 })


    render() {
        return (
            <Form
                name="basic"
                layout="vertical"
                ref={ref => window.formRef = this.formRef = ref}
                onFinish={this.handleAccount}
                initialValues={{
                    tipo:4
                }}
            >
                <div className="auth-container">

                    <Title level={3} style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                        {(this.state.type === 1) ? "Registrate" : "Iniciar sesión"}
                    </Title>
                    <Form.Item name="tipo" noStyle >
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        className="cuenta-form-item"
                        label="Correo Electrónico / Email"
                        name="email"
                        required={[{ required: true, message: "Debe de ingresar el correo electrónico" }]}
                    >
                        <Input placeholder="example@domain.com"/>
                    </Form.Item>
                    <Form.Item
                        className="cuenta-form-item"
                        label="Contraseña"
                        name="password"
                        rules={[{ required: true, message: 'Por favor ingresa tu  contraseña' }]}>
                        <Input type="password" placeholder="Contraseña" />
                    </Form.Item>

                    {(this.state.type === 1) ? <Form.Item
                        className="cuenta-form-item"
                        name="password_confirmation"
                        label="Confirmar Contraseña"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Debe de confirmar su contraseña',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password  placeholder="Contraseña"/>
                    </Form.Item> : null}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block className="marketplace-step-button" >
                            {(this.state.type === 1) ? "Registrate" : "Iniciar sesión"}
                        </Button>
                    </Form.Item>


                    <Paragraph style={{ textAlign: "end" }}>
                        {(this.state.type === 1) ? "¿Ya Tienes Cuenta?" : "¿No Tienes Cuenta?"}
                        <Button
                            className="cuenta-form-link"
                            onClick={this.changeTypeLogin}
                        >
                            {(this.state.type === 1) ? "Iniciar sesión" : "Registrate"}
                        </Button>
                    </Paragraph>



                </div>
            </Form>
        )
    }
}


export default function (props) {

    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <StepCuenta {...props} user={user} setUser={setUser} />
}
import React, { Component } from 'react';
import { Button, Col, Row, Spin, PageHeader, Layout, message, Typography, Statistic, Card, List } from 'antd'
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

//componentes
import { IconDetails } from '../../Widgets/Iconos';
import { CardSaldo, CardCuenta, CardGains } from "../../Widgets/Cards"
import ModalTransaccionesDetalle from './Transacciones/ModalTransaccionesDetalle';
import { renderMontoTransaccion } from '../../Utils';

//css
import 'react-multi-carousel/lib/styles.css';
import '../../../Styles/Modules/Finanzas/Dashboard.css'


const { Content, } = Layout;
const { Text, Title } = Typography;
const moment = require('moment')



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista del Dashboard
 */
 class DashboardFinanzas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuentas: [],
            transacciones: [ ],
            currentPage: 0,
            itemCount: 0,
            perPage: 0,
            pageCount: 0,
            cuentas_incluidas: 0,
            saldo_total: 0
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuentas();
        this.getTransacciones();

    }


    /**
     *
     *
     * @memberof DashboardFinanzas
     * @description Obtiene la lista de cuentas disponibles
     */
    getCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 10,
            }
        }).then(response => {
            let cuentas_total = response.data.data.itemsList.length;
            let saldo_total = 0;

            for(const cuenta of response.data.data.itemsList){
                saldo_total += parseFloat(cuenta.saldo);
            }

            this.setState({
                saldo_total: saldo_total,
                cuentas_incluidas: cuentas_total,
                cuentas: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }

    /**
    *
    *
    * @memberof DashboardFinanzas
    * @description Obtiene la lista las ultimas transacciones
    * @param values pagina
    */
     getTransacciones = (page = 1) => {
        this.setState({ loading_trans: true })
        axios.get('/transacciones/list', {
            params: {
                page: page,
                limit: 10,
            }
        })
            .then(res => {
                const data = res.data.data
                this.setState({
                    transacciones: data.data,
                    currentPage: data.page,
                    itemCount: data.total,
                    perPage: data.limit,
                    pageCount: data.pages
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
            .finally(() => this.setState({ loading_trans: false }))
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Finanzas"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={7}>
                                <CardSaldo saldo={this.state.saldo_total} cuentas={this.state.cuentas_incluidas} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={17}>
                                <Carousel
                                    responsive={responsive}
                                    className="carousel-cards"
                                >
                                    {this.state.cuentas.map(cuenta => {
                                        return <CardCuenta cuenta={cuenta} />
                                    })}

                                </Carousel>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={16} xl={17} xxl={17}>
                                <Card className="heigth-100 border-16">
                                    <Row justify='space-around'>
                                        <Col span={21}>
                                            <Title level={3} className="text-gray"> Últimas Transacciones </Title>
                                        </Col>
                                        <Col span={3} className='center'>
                                            <Button type="primary" className='mr-1'  onClick={() => this.props.navigate('/admin/finanzas/transacciones')} icon={<FaArrowRight />} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <List
                                            className="width-100"
                                            dataSource={this.state.transacciones}
                                            locale={{emptyText:'Sin Transacciones'}}
                                            renderItem={item => (
                                                <List.Item className="width-100">
                                                    <Row className="width-100" align="middle">
                                                        <Col span={3} >
                                                            <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                                        </Col>
                                                        <Col xs={7} className="center">
                                                            <Text>{item.concepto}</Text>
                                                        </Col>
                                                        <Col xs={7} className="center">
                                                            <Text>{item.cuenta_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            { renderMontoTransaccion({tipo: item.tipo, monto: item.monto}) }
                                                        </Col>
                                                        <Col xs={3} className="center">
                                                            <Button type="ghost" icon={<IconDetails />} onClick={() => this.setState({ modalTransacDetalleVisible: true,id:item._id })}/>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        />
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={7} xxl={7}>
                                <Row gutter={[16, 16]}>
                                    <CardGains />
                                    <CardGains title={'Loss'} status={'exception'} />
                                </Row>
                            </Col>
                        </Row>

                    </Content>

                    <ModalTransaccionesDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => this.setState({
                        modalTransacDetalleVisible: false,
                        id:null
                    })}
                    id={this.state.id}
                />
                </Spin>
            </>
        )
    }
}


export default function Vista(params) {


    return <DashboardFinanzas  {...params} navigate={useNavigate()} />
    
}
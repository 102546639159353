import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Servicios from '../components/Admin/Servicios/Servicios'



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterServicios(props) {

    return (
        <Routes>
            <Route path="" element={<Servicios {...props} />} />
        </Routes>
    )
}

export default RouterServicios;

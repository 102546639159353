import React, { Component } from "react";
import { message, Row, Col, Spin, Pagination } from "antd";
import { CardCentroDistribucion } from "../../../../Widgets/Cards";
import ModalClienteCentroDistribucion from "../ModalClienteCentroDistribucion";

const axios = require('axios')



/**
 * @class Centros
 * @extends {Component}
 * @description Vista del listado de Centros
 */
class Centros extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            centros: {
                data: [],
                limit: 4,
                page: 1,
                total: 0,
                pages: 0,
                cliente_id: this.props.cliente_id
            },
            modal_visible: false,
            centro_id: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCentros()
    }

    /**
    * @memberof Centros
    * @description Obtiene los centros de distribucion del client
    */
    getCentros = (
        {
            page = this.state.centros.page,
            limit = this.state.centros.limit,
            cliente_id = this.props.cliente_id
        } = this.state.centros,
        { centros } = this.state

    ) => {

        centros.page = page;
        centros.limit = limit;
        this.setState({ loading: true, centros });
        let search = this.props.search;
        axios.get('/clientes/centros-distribucion/list', {
            params: {
                search,
                limit,  
                page,
                cliente_id
            }
        })
            .then(response => {
                console.log('response.data.data', response.data.data)
                centros.data = response.data.data.data
                centros.total = response.data.data.total
                centros.pages = response.data.data.pages
                this.setState({ centros })
            })
            .catch(error => {
                message.error('Error al Cargar las Centros')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }




    /**
     *
     *
     * @memberof Centros
     * @param id ObjectId del centro de distribucion del cliente
     */
    deleteCentro = (id) => {
        axios.delete('/clientes/centros-distribucion/delete', { centro_distribucion_id: id })
            .then((response) => {
                message.success('Centro de Distribución Eliminado')
            }).catch((error) => {
                message.error('Centro de Distribución NO Eliminado')
            })
            .finally(() => this.getCentros())
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row className="width-100"  gutter={[16, 16]}>
                    {this.state.centros.data.map(item => <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                        <CardCentroDistribucion
                            item={item}
                            cliente_id={this.props.cliente_id}
                            onview={() => this.setState({ centro_id: item._id, modal_visible: true, })}
                            ondelete={() => this.deleteCentro(item._id)}
                            urlRedirect={`/admin/crm/cliente/${this.props.cliente_id}/centro-distribucion/${item._id}`} />
                    </Col>
                    )}

                </Row>
                <Row className="mt-1">
                    <Col span={24}>
                        <Pagination
                            current={this.state.centros.page}
                            total={this.state.centros.total}
                            pageSize={this.state.centros.limit}
                            hideOnSinglePage={true}
                        />
                    </Col>
                </Row>
                <ModalClienteCentroDistribucion
                    visible={this.state.modal_visible}
                    cliente_id={this.props.cliente_id}
                    centro_distribucion_id={this.state.centro_id}
                    onClose={() => {
                        this.setState({ modal_visible: false })
                        this.getCentros()
                    }}
                />
            </Spin>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function vista(props) {
    return (<Centros {...props} />)
}
import React, { useState, useContext, useEffect } from "react";
import { Button, Layout, Row, Col, Typography, Steps, List, } from "antd";

import { Navigate } from "react-router-dom";

import StepCotizador from "./Steps/StepCotizador";
import StepCuenta from "./Steps/StepCuenta";
import StepInfo from "./Steps/StepInfo";
import StepRFQ from "./Steps/StepRFQ";
import StepReview from "./Steps/StepReview";
import StepSuccess from "./Steps/StepSuccess";

import { User, SetUser } from "../../../Hooks/Logged";
import { Carrito, SetCarrito } from "../../../Hooks/Carrito";

import ModalAgregarProducto from "./Modals/ModalAgregarProducto";

import "../../../Styles/Global/landing.css";
import "../../../Styles/Modules/Public/marketplace.css";

const axios = require("axios").default;
const { Content } = Layout;
const { Title, Paragraph } = Typography;

/**
 *
 * @const CustomDot
 * @param {*} dot Componente base para agregar un dot
 * @param {*} {status, index} Objeto con valores del componente Step
 * @description Dot personalizado para el componente Step
 */
const CustomDot = (dot, { status, index }) => {
    return (
        <div
            className={`marketplace-dot ${status !== "wait" ? "marketplace-dot-active" : ""
                }`}
        >
            <Paragraph>{index + 1}</Paragraph>
        </div>
    );
};

/**
 *
 * @class Marketplace
 * @extends {Component}
 * @description Pagina de nexus para realizar un spot buy
 */

function Marketplace({ user, setUser }) {
    const cart = React.useContext(Carrito);
    const setCart = React.useContext(SetCarrito);

    let [modalCotizadorVisible, setModalCotizadorVisible] = useState(false);
    let [currentStep, setCurrentStep] = useState(user ? 1 : 0);
    let [success, setSuccess] = useState(false);

    let [accountData, setAccountData] = useState(undefined);
    let [contactData, setContactData] = useState(undefined);
    let [rfqData, setRFQData] = useState(undefined);

    let [products, setProducts] = useState([]);
    let [productId, setProductId] = useState(undefined);
    let [folio, setFolio] = useState(undefined);
    let [loading, setLoading] = useState(false);


    let reference;
    let stepInfoRef;

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] =
            sessionStorage.getItem("token");
    }, []);

    useEffect(() => {
        console.log('user efffect', typeof (user), user)
        console.log('setContactData', contactData)
        //se logeo y lleno los datos de contacto
        if (typeof (user) == 'object' && contactData?.nombre != undefined) {
            getCliente(user._id)
            setCurrentStep(2);

        }
        // se logeo y no lleno los datos de contacto
        if (typeof (user) == 'object' && contactData?.nombre == undefined) {
            getCliente(user._id)

            setCurrentStep(1)
        }
    }, [user]);


    const getCliente = (usuario) => {
        console.log('trayendo usuario')
        axios
            .post("/rfq/cliente", { id: usuario })
            .then(({ data }) => {
                console.log('data user', data.data.cliente)
                setContactData({...data.data.user, ...data.data.cliente});
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const saveCotizacion = (values) => {
        console.log('values', values)

        axios
            .post("/rfq/cotizacion", { ...values, detalles: products })
            .then(({ data }) => {
                setUser(data.data.user);
                setContactData({...contactData, ...data.data.cliente});
                setFolio(data.data.proyecto.folio);
                setSuccess(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const goToStep = (n) => {
        setCurrentStep(n);
    };

    const nextStep = () => {
        switch (currentStep) {
            case 0:
                setProducts(cart)
                break;
            case 1:
                if (user) {
                    stepInfoRef.formRef.submit()
                } else {
                    return (
                        <Navigate to={"/marketplace"} />
                    )
                }
                break;
            case 3:
                if (user && products.length > 0) {
                    goToStep(4)
                } else if (!user) {
                    return (
                        <Navigate to={"/marketplace"} />
                    )
                }
                break;
            case 4:
                if (user) {
                    reference.formContacto.submit()
                } else
                    return <Navigate to={"/marketplace"} />

                break;


            default:
                if (user) {
                    goToStep(currentStep + 1)
                } else {
                    return (
                        <Navigate to={"/marketplace"} />
                    )
                }
                break;
        }
    };

    const prevStep = () => goToStep(currentStep - 1)

    const deleteImage = (image) => {
        axios
            .post("/upload/delete", {
                filename: image,
            })
            .then((res) => {
                console.log("imagen removida con éxito", res);
            })
            .catch((res) => {
                console.log("imagen no se puedo remover", res);
            });
    };

    const openModal = (id = undefined) => {
        setProductId(id);
        setModalCotizadorVisible(true);
    };

    const cancelModal = (image) => {
        deleteImage(image);
        setModalCotizadorVisible(false);
    };

    const steps = [
        {
            title: "Requisición de Material - Spot Buy",
            content: (
                <StepCuenta
                    setAccountData={setAccountData}
                    accountData={accountData}
                    setContactData={setContactData}
                    setLoading={setLoading}
                    onSuccess={(type) => {
                        goToStep(type);
                    }}
                />
            ),
        },
        {
            title: "Requisición de Material - Spot Buy",
            content: (
                <StepInfo
                    ref={d => stepInfoRef = d}
                    setContactData={setContactData}
                    contactData={contactData}
                    nextStep={() => goToStep(2)}
                />
            ),
        },
        {
            title: "Requisición de Material - Spot Buy",
            content: (
                <StepRFQ
                    setRFQData={setRFQData}
                    rfqData={rfqData}
                    nextStep={() => goToStep(2)}
                />
            ),
        },
        {
            title: "Requisición de Material - Spot Buy",
            content: (
                <StepCotizador
                    isModalOpen={modalCotizadorVisible}
                    openModal={openModal}
                    products={products}
                    setProducts={setProducts}
                    deleteImage={deleteImage}
                />
            ),
        },
        {
            title: "Requisición de Material - Spot Buy",
            content: (
                <StepReview
                    ref={d => reference = d}
                    isModalOpen={modalCotizadorVisible}
                    openModal={openModal}

                    deleteImage={deleteImage}

                    products={products}
                    setProducts={setProducts}


                    accountData={accountData}
                    setAccountData={setAccountData}

                    contactData={contactData}
                    setContactData={setContactData}

                    rfqData={rfqData}
                    setRFQData={setRFQData}


                    saveCotizacion={saveCotizacion}
                />
            ),
        },
    ];


    console.log('currentStep', currentStep)
    console.log('contactData', contactData)


    return (
        <Content
            className="marketplace-content  "
            style={{
                minHeight: '100vh',
                background: "url('/images/bg-cot.png')",
                backgroundSize: "cover",
                overflow: "initial",
                backgroundAttachment: "fixed",
            }}
        >
            <Row justify="center" style={{ margin: "3rem 0" }}>
                <Col
                    style={{ width: "100%", maxWidth: 1000 }}
                    className="marketplace-form"
                >
                    <Row justify="center">
                        <Col>
                            <img
                                src={"/images/logo.png"}
                                alt=""
                                style={{ maxWidth: 250 }}
                                className="marketplace-brand"
                            />
                        </Col>
                    </Row>
                    {success ? (
                        <StepSuccess
                            folio={folio}
                            nombre={contactData?.nombre || user?.nombre}
                        />
                    ) : (
                        <>
                            <Row justify="center" style={{ marginTop: "2rem" }}>
                                <Col
                                    xs={24}
                                    lg={12}
                                    style={{ textAlign: "center" }}
                                >
                                    <Title level={3}>
                                        {steps[currentStep].title}
                                    </Title>
                                    <Paragraph>
                                        En un plazo de 48 horas, recibiras la
                                        cotización en tu email.
                                    </Paragraph>
                                </Col>
                            </Row>
                            <Row
                                justify="center"
                                style={{ margin: "2.5rem 0" }}
                            >
                                <Col lg={22}>
                                    <Steps
                                        progressDot={CustomDot}
                                        className="marketplace-steps"
                                        current={currentStep}
                                    >
                                        <Steps.Step title="Cuenta" />
                                        <Steps.Step title="Contacto" />
                                        <Steps.Step title="Cotización" />
                                        <Steps.Step title="Productos" />
                                        <Steps.Step title="Review" />
                                    </Steps>
                                </Col>
                            </Row>

                            <List
                                loading={loading}
                                dataSource={[steps[currentStep]]}
                                renderItem={(item) => item.content}
                            />

                            {
                                currentStep > 0 ? (
                                    <Row justify="space-between">
                                        <Col>
                                            {currentStep > 0 ? (
                                                <Button
                                                    className="marketplace-step-button"
                                                    onClick={prevStep}
                                                >
                                                    ANTERIOR
                                                </Button>
                                            ) : null}
                                        </Col>
                                        <Col>
                                            <Button
                                                className="marketplace-step-button"
                                                onClick={nextStep}
                                            >
                                                {currentStep === 4
                                                    ? "CONFIRMAR"
                                                    : "SIGUIENTE"}
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : null
                            }
                        </>
                    )}
                </Col>
            </Row>
            <ModalAgregarProducto
                marketplace
                products={products}
                setProducts={setProducts}
                productId={productId}
                visible={modalCotizadorVisible}
                setVisible={setModalCotizadorVisible}
                onCancel={cancelModal}
                deleteImage={deleteImage}
            />
        </Content>
    );
}

export default function MarketplaceModal(props) {
    let user = useContext(User);
    const setUser = useContext(SetUser);

    return <Marketplace {...props} user={user} setUser={setUser} />;
}

import React from 'react'
import { Route, Routes } from "react-router-dom";
import Dashboard from '../components/Admin/Dashboard';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterDashboard( props) {
    return (
        <Routes>
            <Route path="" element={<Dashboard />} />
        </Routes>
    )
}

export default RouterDashboard;

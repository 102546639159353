import React, { Component } from 'react';
import { Spin, PageHeader, Layout, Card, Row, Radio, Col, Form, DatePicker, Select, Input, message, InputNumber, Tag } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { WarningOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';

//componentes
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { ProveedorSelect, ClienteSelect, ClienteCentrosSelect }  from "../../Widgets/Inputs/Selects";


//scss
import '../../../Styles/Modules/Mantenimiento/mantenimiento.scss'

const { Content } = Layout;
const { Option } = Select;
const moment = require('moment');

/**
 * @export
 * @class CrearOrdenMantenimiento
 * @extends {Component}
 * @description Vista de Nueva  Orden de Mantenimiento 
 */
export default class CrearOrdenMantenimiento extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            tipo_solicitud: 0,

            cliente_id: undefined,
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.mantenimiento_id){
            this.getOrden()
        }


    }

    /**
     *
     *
     * @memberof CrearOrdenMantenimiento
     * @method onChange_tipo_solicitud
     * @description Actualiza el valor de tipo_solicitud para ocultar o mostrar secciones del formulario
     */
    onChange_tipo_solicitud = (value) => {
        this.setState({ tipo_solicitud: value.target.value })
    }


    /**
     *
     *
     * @memberof CrearOrdenMantenimiento
     * @method addOrden
     * @description Crea una nueva orden de manatenimiento
     */
    addOrden = (values) => {
        this.setState({ loading: true })
        axios.post('/mantenimiento',{
            tipo: this.state.tipo_solicitud,
            ...values
        }).then(response => {
            console.log("response", response);
            message.success('Orden de Mantenimiento creada')
            this.formRef.current.resetFields()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al crear la orden')
        }).finally(()=>this.setState({loading: false}))
    }

     /**
     *
     *
     * @memberof CrearOrdenMantenimiento
     * @method updateOrden
     * @description Crea una nueva orden de manatenimiento
     */
    updateOrden = (values) => {
        axios.put('/mantenimiento',{
            ...values,
            mantenimiento_id: this.props.mantenimiento_id,
        }).then(response => {
            //actualizado
        }).catch(error => {
            console.log("error", error);
            message.error('Error al crear la orden')
        })
    }

    /**
     *
     *
     * @memberof CrearOrdenMantenimiento
     * @method getOrden
     * @description Crea una nueva orden de manatenimiento
     */
    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/mantenimiento',{
            params:{
                mantenimiento_id: this.props.mantenimiento_id,
            }
        }).then(response => {

            let orden = response.data

            this.formRef.current.setFieldsValue({
                ...orden,
                fecha_solicitud: moment(orden.fecha_solicitud),
                cliente_id:{
                    value: orden.cliente_id?._id,
                    label: orden.cliente_id?.razon_social,
                    key: orden.cliente_id?._id,
                },
                centro_cliente_distribucion_id:{
                    value: orden.centro_cliente_distribucion_id?._id,
                    label: orden.centro_cliente_distribucion_id?.identificador,
                    key: orden.centro_cliente_distribucion_id?._id,
                },
                proveedor_id:{
                    value: orden.proveedor_id?._id,
                    label: orden.proveedor_id?.identificador,
                    key: orden.proveedor_id?._id,
                },
            })

            this.setState({
                cliente_id: orden.cliente_id._id,
                tipo_solicitud: orden.tipo,
            })
            
        }).catch(error => {
            console.log("error", error);
            message.error('Error al crear la orden')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof CrearOrdenMantenimiento
     * @method onFinish
     * @description Metodo que se ejecuta al finalizar el formulario
     * @param values 
     */
    onFinish = (values) => {
        let form = this.formRef.current.getFieldsValue()

        this.addOrden(form)
    }

    /**
     * @memberof CrearOrdenMantenimiento
     * @method renderTipo
     * @description Renderiza el tipo de orden de mantenimiento
     */
    renderTipo = () => {

        let tipo = {
            0 : <Tag className='tag-correctiva'><InfoCircleOutlined />&nbsp; Correctiva</Tag>,
            1 : <Tag className='tag-preventiva'><WarningOutlined />&nbsp; Preventiva</Tag>
        }

        return this.state.tipo_solicitud !== undefined ? tipo[this.state.tipo_solicitud] : 'N/A'
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form 
                    layout='vertical' 
                    className="form-blank" 
                    ref={this.formRef}

                    onValuesChange={(fields) => {
                        if(this.props.mantenimiento_id){
                            this.updateOrden(fields)
                        }

                    }}
                >
                    <Card bordered={false}>
                        <Row align='end'>
                            <Col>
                                {this.props.mantenimiento_id ? this.renderTipo() : <Form.Item name="tipo_solicitud">
                                    <Radio.Group 
                                        defaultValue={this.state.tipo_solicitud} 
                                        onChange={this.onChange_tipo_solicitud}
                                    >
                                        <Radio.Button className='radio-red' value={0}><InfoCircleOutlined />&nbsp; Correctiva</Radio.Button>
                                        <Radio.Button className='radio-yellow' value={1}><WarningOutlined />&nbsp; Preventiva</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>}
                            </Col>
                        </Row>
                        {(this.state.tipo_solicitud == 0) ?
                            <Card bordered={false} className=" card-inner card-inner-title" size='small' title="Datos de la Solicitud" >
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={12} xl={6}>
                                        <Form.Item 
                                            name="nombre_solicitante" 
                                            label="Nombre"
                                        >
                                            <Input placeholder="Indique el nombre del solicitante" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <Form.Item name="rol_solicitante" label="Rol / Tipo de Usuario">
                                            <Select placeholder="Indique el rol del solicitante" >
                                                <Option value={1}>Rol 1</Option>
                                                <Option value={2}>Rol 2</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <Form.Item name="email_solicitante" label="Correo Electrónico">
                                            <Input placeholder='Indique el correo del solicitante' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <Form.Item name="tipo_aviso" label="Tipo de Aviso">
                                            <Input placeholder='Indique el tipo de aviso' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <Form.Item name="numero_solicitud" label="Número">
                                            <Input placeholder='Indique el número de la Solicitud' type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={24} xl={12}>
                                        
                                    </Col>
                                    <Col xs={24} lg={12} xl={6}>
                                        <Form.Item name="fecha_solicitud" label="Fecha de Solicitud">
                                            <DatePicker placeholder='Indique fecha de solicitud' className=" width-100" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            : null}

                        <Card bordered={false} className="borderless card-inner card-inner-title" size='small' title="Datos de la Orden de Trabajo" >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="no_orden" label="No. Orden">
                                        <Input placeholder='Indique el numero de orden' type="number" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item 
                                        name="cliente_id" 
                                        label="Cliente"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el cliente"
                                        }]}
                                    >
                                        <ClienteSelect 
                                            onSelect={(cliente_id) => {
                                                this.setState({cliente_id})
                                                this.formRef.current.setFieldsValue({centro_cliente_distribucion_id:  null})
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item 
                                        name="centro_cliente_distribucion_id" 
                                        label="Centro de Distribución"
                                    >
                                        <ClienteCentrosSelect cliente_id={this.state.cliente_id}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="estatus_esm" label="ESM Estado">
                                        <Select placeholder="Indique el estatus esm" >
                                            <Option value={1}>Centro</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item 
                                        label="Proveedor Asignado"
                                        name="proveedor_id" 
                                    >
                                        <ProveedorSelect/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="numero_orden" label="Número">
                                        <Input placeholder='Indique el numero' type="number" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="tipo_aviso_orden" label="Tipo de Aviso">
                                        <Input placeholder='Indique el tipo de aviso' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="tipo_incidencia_orden" label="Tipo de Incidencia">
                                        <Input placeholder='Indique el tipo de incidencia' />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} lg={12} xl={12}>
                                    <Form.Item name="observaciones" label="Observaciones">
                                        <Input.TextArea placeholder="Agregue las observaciones correspondientes de la orden" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={12}>
                                    <Form.Item name="descripcion" label="Descripción Diagnóstico">
                                        <Input.TextArea placeholder='Agregue la descripción correspondiente' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>


                        <Card bordered={false} className="borderless card-inner card-inner-title" size='small' title="Información de los Costos" >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="pedido" label="Pedido">
                                        <Input placeholder='Ingrese el nombre que Solicito' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="recibo" label="Recibo">
                                        <Input placeholder='Ingrese el nombre que Recibio' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="serie" label="Serie / Folio">
                                        <Input placeholder='Ingrese Serie - Folio' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} xl={6}>
                                    <Form.Item name="costo_proveedor" label="Costo Proveedor" className='label-upper'>
                                        <Input placeholder='Ingrese el Costo' type="number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Card>

                    {this.props.mantenimiento_id ? null : <FloatingButton
                        title="Confirmar"
                        icon={<CheckOutlined style={{ color: '#fff', fontSize: 30 }} />}
                        onClick={this.onFinish} /> }
                </Form>
            </Spin>
        )
    }
}
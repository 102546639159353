import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Breadcrumb, Typography, Space, List } from 'antd'
import axios from 'axios';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { IconDelete, IconEdit, IconArrowBack } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import ModalRubros from './ModalRubros'

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Rubros
 * @extends {Component}
 * @description Vista de Rubros
 */
class Rubros extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            area_id: this.props.params.area_id,
            area: [],

            rubro_id: undefined,
            rubros: [],
            limit: this.props.searchParams.get("limit") ?? 10,
            page: this.props.searchParams.get("page") ?? 1,
            search: this.props.searchParams.get("search") ?? null,
            total: 0
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getArea();
        this.getRubros();
    }

    /**
    *
    *
    * @memberof Rubros
    * 
    * @method componentDidUpdate
    * @description Actualiza la vista si se buscan datos
    */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.getRubros();
        }
    }


    /**
     * @memberof Rubros
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {
        axios.get('/areas/get', {
            params: {
                id: this.state.area_id
            }
        }).then(response => {
            this.setState({
                area: response.data.data,
            })
        }).catch(error => {
            message.error('Error al traer el área')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Rubros
     * @method getRubros
     * @description Obtiene los rubros relacionados al area
     */
    getRubros = (page = this.state.page, limit = this.state.limit, search = this.props.search) => {
        axios.get('/rubros/list', {
            params: {
                page: page,
                limit,
                search,
                area_id: this.state.area_id,
            }
        }).then(response => {
            this.props.setSearchParams({ page, limit })
            this.setState({
                rubros: response.data.data,
                page: response.data.page,
                total: response.data.total,
                searching: true,
                filtroSearch: search
            });

        }).catch(error => {
            message.error('Error al traer la información')
        })
            .finally(() => this.setState({ loading: false }))

    }


    back = () => {
        this.props.navigate(-1);
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        onBack={this.back}
                        backIcon={<IconArrowBack />}
                        title={(this.state.area != []) ?
                            (
                                <Breadcrumb
                                    separator=" de ">
                                    <Breadcrumb.Item>Rubros</Breadcrumb.Item>

                                    <Breadcrumb.Item>
                                        <Link className="text-white" title="Ir a areas" to={`/admin/areas`}>
                                            {this.state.area.nombre}
                                        </Link>
                                    </Breadcrumb.Item>

                                </Breadcrumb>
                            )
                            : "Rubros"}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Rubros " }}
                            dataSource={this.state.rubros}
                            pagination={{
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getRubros(page)
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >

                                <Col xs={10} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={10} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col xs={10} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={10} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, rubro_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este rubro?"
                                                        onConfirm={() => axios.post('/rubros/delete',
                                                            { id: item._id }).then(() => {
                                                                this.getRubros()
                                                                message.success('Rubro eliminado')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalRubros
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, rubro_id: undefined })
                            this.getRubros()
                        }}
                        rubro_id={this.state.rubro_id}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }

}

export default function (props) {
    let [searchParams, setSearchParams] = useSearchParams();

    return <Rubros
        {...props}
        params={useParams()}
        navigate={useNavigate()}
        searchParams={searchParams}
        setSearchParams={setSearchParams} />

}
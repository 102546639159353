import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Auth } from "./AuthVerify";
/**
 *
 *
 * @const PrivateRoute
 * @description Restringe el acceso a las rutas privadas si no se ha iniciado sesion
 */
export default function PrivateRoute({ children }) {

  const location = useLocation();

  console.log("Auth()", Auth())
  if (!Auth()) {
      // not logged in so redirect to login page with the return url
      console.log("      const { pathname } = useLocation();", location)
      return <Navigate to="/login" state={{ from: location }} />
  }

  

  return children;
}
import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const NegocioSelect
 * @description Select para los negocios registrados en el sistema
 */
const NegocioSelect = (props) => {

    const { value, onChange, placeholder = "Seleccione el negocio", onSelect = () => {}, disabled = false, className = "" } = props

    const [ negocios, setNegocios ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getNegocios
     * @description Obitiene los negocios
     */
    const getNegocios = ({
        page = negocios.page, 
        limit = negocios.limit, 
        search = negocios.search,
    } = negocios) => {

        axios.get('/negocios/list', {
            params: { 
                page, 
                limit, 
                search 
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setNegocios({
                ...negocios,
                data: response.data.data.data,
                total: response.data.data.total,
            })

        }).catch(error => {
            message('Error al obtener los negocios')
        })
    }

    //componentDidMount
    useEffect(() => {
        getNegocios()
    }, [])

    return (
        <Select
            {...props}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getNegocios({search})}
            onSelect={(negocio)=> {
                onChange(negocio.value)
                onSelect(negocio.value)
            }}
            value={value}
        >
            {
                negocios.data.map(negocio => <Option value={negocio._id} key={negocio._id}>{negocio.nombre}</Option>)
            }
        </Select>
    );
}



export default NegocioSelect;
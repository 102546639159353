import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, } from 'antd';

const { Title } = Typography;
const axios = require('axios').default;

/**
 *
 *
 * @class ModalRechazo
 * @extends {Component}
 */
class ModalRechazo extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedores: [],
            razones_sociales: [],
            proveedor: undefined,
            razon_social: undefined,
            tipo_cambio: 0,
            disabled: false

        }
    }

    ModalRechazo = React.createRef();

    componentDidMount() {

    }

    /**
     * @memberof ModalRechazo
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        console.log(values)
        axios.post('/project-manager/contratos/update', {
            id: this.props.id,
            observaciones: values.observaciones,
            aceptada: false,
        }).then(response => {
            message.success('Contrato Rechazado')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al Rechazar el Contrato')
        }).finally(()=> {
            this.setState({loading: false})
        })
    }


    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalRechazo}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Observaciones"
                                name="observaciones"
                            >
                                <Input.TextArea placeholder="Descripción"></Input.TextArea>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row >
                        <Col span={24} className="text-center center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3} className="text-center">{factura_id != null ? "Editar " : "Rechazo de "} Contrato</Title>
        </div>
        <ModalRechazo {...props} />
    </Modal>

}
import React, { Component } from 'react'
import { List, Card, Row, Col, Typography, Button, message, Space, Modal, Popconfirm } from 'antd'

//componentes
import { IconDetails, IconEdit, IconDelete } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { renderMontoFactura, tipos_facturas, estatus_facturas } from "../../../Utils";

//Modales
import ModalFacturas from '../../Contabilidad/Facturas/ModalFacturas'


const moment = require('moment');
const axios = require('axios');
const { Text } = Typography;


/**
 * @class FacturasList 
 * @descripcion Lista con las facturas relacionadas al proyecto
 **/
class FacturasList extends Component {

	constructor(props){
		super(props)
		this.state = {
			loading: false,
			facturas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0
            },
		}
	}


	componentDidMount(){
		this.getFacturas()
	}


	/**
     * @memberof FacturasList
     * @method getFacturas
     * @description Obtiene las facturas de el proyecto correspondiente
     */
    getFacturas = ({
    	page = this.state.facturas.page,
    	limit = this.state.facturas.limit
    } = this.state.facturas) => {

    	this.setState({loading: true})
        axios.post('/facturas/list', {
            page,
            limit,
            mantenimiento_id: this.props.mantenimiento_id,
        }).then(({ data }) => {
        	this.setState({facturas: data.data})
        }).catch(err => {
        	console.log(err)
        	message.error('Error al obtener las facturas')
        })
        .finally(()=>this.setState({loading: false}))
    }

    /**
     *
     *
     * @memberof FacturasList
     * @descripcion elimina una factura
     * @param {ObjectId} id id de la factura
     */
    deleteFactura = (_id) => {
        axios.delete('/facturas/delete', {
            params: {
                id: _id
            }
        })
            .then(e => {
                message.success("Se ha eliminado la factura")
                this.getFacturas();
            })
            .catch(error => {
                message.error("Error al eliminar la factura")
            })
    }

	render(){
		return(
			<>
				<List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin facturas" }}
                    dataSource={this.state.facturas.data}
                    pagination={{
                    	current: this.state.facturas.page,
                        total: this.state.facturas.total,
                        pageSize: this.state.facturas.limit,
                        showSizeChanger: true,
                        onChange: (page, limit) => this.getfacturas({ page, limit })
                    }}
                    header={<Row className="header-list width-100 pl-1 pr-1">
	                    <Col span={2} className="center">
	                        <Text strong>Fecha</Text>
	                    </Col>
	                    <Col span={4} className="center">
	                        <Text strong>Concepto</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Estatus</Text>
	                    </Col>
	                    <Col span={3} className="center">
	                        <Text strong>Tipo</Text>
	                    </Col>
	                    <Col span={4} className="center">
	                        <Text strong>Emisor</Text>
	                    </Col>
	                    <Col span={4} className=" center">
	                        <Text strong>Receptor</Text>
	                    </Col>
	                    <Col span={2} className=" center">
	                        <Text strong>Monto</Text>
	                    </Col>
	                    <Col span={2} className="center">
	                        <Text strong>Acciones</Text>
	                    </Col>
	                </Row>}
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card 
                                className="card-list"
                            >
                                <Row>
					                <Col xs={12} xl={2} className="center">
										<Text strong>{moment(item.fecha_vigencia).format("DD/MM/YYYY")}</Text>
									</Col>
									<Col xs={12} xl={4} className="center">
										<Text className="text-gray-dark">{item.concepto}</Text>
									</Col>
									<Col xs={12} xl={3} className="center">
										<Text className="text-gray-dark">
											{estatus_facturas(item.estatus)}
										</Text>
									</Col>
									<Col xs={12} xl={3} className="center">
										<Text className="text-gray-dark">
											{tipos_facturas(item.tipo)}
										</Text>
									</Col>
									<Col xs={12} xl={4} className="center">
										<Text className="text-gray-dark">{item.emisor?.nombre ? item.emisor?.nombre : '-'}</Text>
									</Col>
									<Col xs={12} xl={4} className=" center">
										<Text className="text-gray-dark">{item.receptor?.nombre ? item.receptor?.nombre : '-'}</Text>
									</Col>
									<Col xs={12} xl={2} className=" center">
										{renderMontoFactura({monto: item.monto, tipo: item.tipo})}
									</Col>
				                    <Col xs={12} xl={2} className="center">
				                         <Space>
                                            <Button
                                                type="primary"
                                                icon={<IconEdit style={{ color: "currentColor" }} />}
                                                onClick={(e) => this.setState({ modal_visible: true, factura_id: item._id })}
                                                title="Ver XML" 
                                                size="small"
                                            >
                                            </Button>
                                            <Button
                                            	style={{paddingTop: '3px'}}
                                            	size="small"
                                                type="primary"
                                                icon={<IconDetails />}
                                                disabled={!item.factura_xml}
                                                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                                                title="Ver XML" >
                                            </Button>

                                            <Popconfirm
                                                placement="topRight"
                                                title={"¿Está seguro de eliminar la factura?"}
                                                onConfirm={() => this.deleteFactura(item._id)}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                	style={{paddingTop: '3px'}}
                                                	size="small"
                                                    type="primary"
                                                    danger
                                                    icon={<IconDelete style={{ color: "currentColor" }} />}
                                                    title="Delete " >
                                                </Button>
                                            </Popconfirm>
                                        </Space>
				                    </Col>
                   					
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton 
                	title="Nuevo registro" 
                	onClick={() => {
                		this.setState({ modal_visible: true })
                	}} 
                />
                <ModalFacturas
                	visible={this.state.modal_visible}
                	onClose={(flag)=>{
                		this.setState({modal_visible: false})
                		if(flag === true) {
                            this.getFacturas()
                        }
                	}}
                    factura_id={this.state.factura_id}
                    mantenimiento_id={this.props.mantenimiento_id}
                />
			</>
		)
	}

}

export default function Vista(props) {
    return <FacturasList {...props}/>
}
import React, { Component } from 'react';
import {
    Button, Popconfirm, Spin, Tooltip, Popover
    , PageHeader, Layout, Space, List, Row, Col, Typography, Card,Modal
} from 'antd'

import { IconDelete, IconEdit,IconMail } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import Logged from "../../../Hooks/Logged";
import axios from 'axios';
import ModalUsuario from './ModalUsuarios';
const { Content } = Layout;
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
export default class Usuarios extends Component {
    static contextType = Logged;



    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            _id: undefined,
            loading: false,
            modal_visible: false,


            /* Paginado */
            page: 1,
            limit: 10,
            total: 0,
            data: [],
            search: ""
        }
    }


    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getUsuarios();
    }

    /**
     * @memberof Usuarios
     *
     * @method   componentDidUpdate
     * @description Si se realiza una un busqueda por la barra, actuliza el contenido
     *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search }, () => { this.getUsuarios() })
        }
    }


    /**
     * @methodOf  Usuarios
     * @method getUsuarios
     *
     * @description Obtiene la paginación de usuario
     *
     * */
    getUsuarios = (page = this.state.page, limit = this.state.limit) => {
        this.setState({
            loading: true,
        });
        axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                page,
                limit,
                search: this.state.search,

            },
        })
            .then(({ data }) => {

                console.log('data', data)
                this.setState({
                    page: data.data.currentPage,
                    limit: limit,
                    total: data.data.itemCount,
                    data: data.data.itemsList,
                    loading: false
                })

            })
            .catch(e => console.log('er', e))
    };


    /**
     * @methodOf  Usuarios
     * @method deleteUsuario
     *
     * @description Elimina un Usuario
     *
     * */
    deleteUsuario = (id) => {
        axios.delete('/usuarios/delete', {
            params: { id: id }
        })
            .then(() => this.getUsuarios()
            )
            .catch(error => {
                console.log('error', error);
            })
    }
    /**
     * @methodOf  Usuarios
     * @method showModal
     *
     * @description Muestra el modal del usuario
     *
     * */
    showModal = (id = undefined) => {
        this.setState({
            visible: true,
            id: id
        });
    };

    /**
     * @methodOf  Usuarios
     * @method hideModal
     *
     * @description Oculta el modal del usuario
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };
    /**
    * @methodOf  Usuarios
    * @method sendEmail
    *
    * @description Envia un correo para recuperar la contraseña
    *
    * */
    sendEmail = (email) => {
        this.setState({ loading: true })
        axios.put("/password/recovery", { email: email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    }

    render() {

        const { getUsuarios, deleteUsuario, showModal, hideModal, state, props } = this;
        const { visible, page, limit, total, data, id, loading } = state;

        const user = this.context;


        return (
            <>
                <Spin spinning={loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Usuarios"
                        extra={<span className="page-header-extra"> {total} Usuarios</span>}
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Usuarios " }}
                            dataSource={data}
                            pagination={{
                                current: page,
                                pageSize: limit,
                                total: total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page, limit) => this.getUsuarios(page)
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >

                                <Col xs={1} className="center">
                                    <Text strong>Logo</Text>
                                </Col>
                                <Col xs={4} lg={8} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={4} lg={4} className="center">
                                    <Text strong>Apellido</Text>
                                </Col>
                                <Col xs={4} lg={4} className="center">
                                    <Text strong>Posición</Text>
                                </Col>
                                <Col xs={8} lg={4} className="center">
                                    <Text strong>Email</Text>
                                </Col>
                                <Col xs={3} lg={3} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col xs={1} lg={1} className="center">
                                                <CustomAvatar name={[item.nombre, item.apellido]} image={item.avatar} />
                                            </Col>
                                            <Col xs={4} lg={8} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={4} lg={4} className="center">
                                                <Text>{item.apellido}</Text>
                                            </Col>
                                            <Col xs={4} lg={4} className="center">
                                                <Text>{item.posicion}</Text>
                                            </Col>
                                            <Col xs={8} lg={4} className="center">
                                                <Text>{item.email}</Text>
                                            </Col>
                                            <Col xs={3} lg={3} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Si" cancelText="No"
                                                        onConfirm={() => this.sendEmail(item.email)}
                                                    >
                                                        <Button type="primary" title="Recuperar Contraseña" icon={<IconMail />} />
                                                    </Popconfirm>
                                                    <Button type="primary" onClick={() => showModal(item._id)} title="Editar" icon={<IconEdit />} />
                                                   
                                                    {(user?.tipo == 1) ? <Popconfirm title="¿Deseas eliminar este usuario?" okText="Si" cancelText="No"
                                                        onConfirm={() => deleteUsuario(item._id)}

                                                    >
                                                        <Button type="primary" danger title="Eliminar" icon={<IconDelete />} />
                                                    </Popconfirm> : null}
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    {
                        (user && user.tipo != 3) ?
                            <FloatingButton title="Nuevo registro" onClick={() => showModal()} /> : null
                    }

                </Spin>
                <ModalUsuario
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        getUsuarios();
                    }}
                    update={() => getUsuarios()}
                    id={id}
                >
                </ModalUsuario>

            </>
        )
    }
}

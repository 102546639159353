import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Typography, Space, List } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalServicios from './ModalRefacciones';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Refacciones
 * @extends {Component}
 * @description Vista de Refacciones
 */
class Refacciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            servicio_id: undefined,
            refacciones: [],
            area: [],
            search: undefined,
            page: 1,
            limit: 10,
            total: 0
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getRefacciones();
    }

    /**
     * @memberof Refacciones
     * @method   componentDidUpdate
     * @description Si se realiza una un busqueda por la barra, actuliza el contenido
     *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search }, () => { this.getRefacciones() })
        }
    }


    /**
     * @memberof Refacciones
     * @method getRefacciones
     * @description Obtiene los refacciones relacionados al area
     */
    getRefacciones = (page = this.state.page, limit = this.state.limit) => {

        axios.get('/refacciones/list', {
            params: {
                page,
                limit,
                search: this.state.search
            }
        }).then(response => {
            this.setState({
                refacciones: response.data.data.data,
                page: response.data.data.page,
                total: response.data.data.total
            });

        }).catch(error => {
            message.error('Error al traer la información')
        })
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Refacciones"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Refacciones" }}
                            dataSource={this.state.refacciones}
                            pagination={{
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getRefacciones(page)
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >

                                <Col span={4} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Código Cliente</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Precio</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Unidad</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={4} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item.codigo_cliente}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item.precio}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item.unidad}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, refaccion_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta refacción?"
                                                        onConfirm={() => axios.delete('/refacciones/delete',
                                                            { params: { id: item._id } }).then(() => {
                                                                this.getRefacciones()
                                                                message.success('Refacción eliminado')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalServicios
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, refaccion_id: undefined })
                            this.getRefacciones()
                        }}
                        refaccion_id={this.state.refaccion_id}
                    />
                </Spin>
            </>
        )
    }

}

export default function (props) {

    return <Refacciones {...props} params={useParams()} navigate={useNavigate()} />

}
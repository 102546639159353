import { CloseOutlined, WarningOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, } from "@ant-design/icons"
import { Typography, Tag } from 'antd'

const { Text } = Typography;

const colors = ['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']


/** 
 * @param {Number} value Tipo de factura 
*/
const tipos_facturas = (value) => {
    switch (value) {
        case 0:
            return "Sin cambios"
        case 1:
            return "Ingreso"
        case -1:
            return "Egreso"
    }
}

/** 
 * @param {Number} value Estatus de facturas (PROVISIONAL) 
*/
const estatus_facturas = (value) => {
    switch (value) {
        case 0:
            return "Rechazada"
        case 1:
            return "Cotización"
        case 2:
            return "Factura"
        case 3:
            return "Transacción"
        default:
            return "Cancelada"
    }
}

/** 
 * @param {Number} value Estatus de Ordenes de compra
*/
const estatus_orden_compra = (value) => {
    switch (value) {
        case 0:
            return "Creada"
        case 1:
            return "Aceptada"
        case 2:
            return "Confirmada"
        case 3:
            return "Incompleta"
        case 4:
            return "Completa"
        case 5:
            return "En entrega"
        case 6:
            return "Entregada"
        case 7:
            return "Completa"
        case 8:
            return "Enviada"
    }
}

/** 
 * @param {Number} tipo tipo de transaccion
*/
const color_transaccion_tipo = (tipo) => {
    switch (tipo) {
        case -1:
            return "#DC3545"
        case 1: // ingreso
            return "#28A745"
        case 2: // egreso
            return "#DC3545"
        case 3: // traspaso
            return "#007BFF"
        case 4: // nulo
            return "#17A2B8"
    }
}

/**
 * @param {*} tipo
 * @description Renderiza el icono según el estatus
 */
const renderIconTransaccion = (tipo) => {
    switch (tipo) {
        case 1:
            return <PlusOutlined style={{ color: "currentColor" }} />
        case 2:
            return <MinusOutlined style={{ color: "currentColor" }} />
        case 3:
            return <SwapOutlined style={{ color: "currentColor" }} />
        case 4:
            return <StopOutlined style={{ color: "currentColor" }} />
    }
}

/**
 * @param {*} tipo
 * @description Renderiza el icono según el estatus de factura
 */
const renderIconFactura = (tipo) => {
    switch (tipo) {
        case 1:
            return <PlusOutlined style={{ color: "currentColor" }} />
        case -1:
            return <MinusOutlined style={{ color: "currentColor" }} />
        case 0:
            return <StopOutlined style={{ color: "currentColor" }} />
    }
}

/**
 * @param {*} tipo
 * @description Renderiza el monto de transacciones con estilos dependiendo del tipo
 */
const renderMontoTransaccion = ({monto = 0, tipo = 4}) => {

    let style = {
        color: color_transaccion_tipo(tipo),
        fontWeight: "bold"
    }

    return <Text style={{...style}}>{renderIconTransaccion(tipo)} $ {monto.toMoney(true)} MXN</Text>

}

/**
 * @param {*} tipo
 * @description Renderiza el monto de facturas con estilos dependiendo del tipo
 */
const renderMontoFactura = ({monto = 0, tipo = 0}) => {

    let style = {
        color: color_transaccion_tipo(tipo),
        fontWeight: "bold"
    }

    return <Text style={{...style}}> {renderIconFactura(tipo)} {monto.toMoney(true)} MXN</Text>

}


/**
 * @param status_cliente num
 * @description Renderiza el tag con el estatus en el que se encuantra la orden, desde la vistas de cliente
 */
const renderEstatusCotizacion = (status) => {

        let tags = {
            1: <Tag color="blue">Creada</Tag>,
            2: <Tag color="purple">Por evaluar</Tag>,
            3: <Tag color="green">Aceptada</Tag>,
            4: <Tag color="red">Rechazada</Tag>,
            5: <Tag color="purple">Recotizando</Tag>,
            6: <Tag color="yellow">Completada</Tag>,

        }
        return status != undefined ? tags[status] : <Tag>N/A</Tag>
    }


export {
    estatus_facturas,
    colors,
    tipos_facturas,
    color_transaccion_tipo,
    renderMontoTransaccion,
    renderMontoFactura,
    estatus_orden_compra,
    renderEstatusCotizacion
}
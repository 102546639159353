import React, { Component } from 'react';
import { Button, Popconfirm, Tag, Spin, PageHeader, Collapse, Layout, Row, Col, Typography, message, Space, List } from 'antd'
import axios from 'axios';
import { Link, useSearchParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";

import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import ModalClientes from './ModalClientes'
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import DrawerFiltros from '../../../Widgets/DrawerFiltros/DrawerFiltros'
import ModalClientesMultiple from './ModalClientesMultiple';

import ModalClienteCentroDistribucion from './ModalClienteCentroDistribucion';


const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

/**
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista de Clientes del CRM
 */
 class Clientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            cliente: undefined,


            clientes: {
                data: [],

                limit: this.props.searchParams.get("limit") ?? 10,
                page: this.props.searchParams.get("page") ?? 1,

                total: 0,
                pages: 0,


                filters: [],
                sort: {},

                loading: false
            },
            search: this.props.searchParams.get("search") ?? null,
            filtroSearch: '',
            searching: true,
            visibleFilters: false
        }
    }

    componentDidMount() {
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getClientes();
    }
    filterForms = React.createRef();


    /**
     * @memberof Clientes
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching === true) {
            this.getClientes();
            this.setState({ searching: false })
        }
    }

    /**
    * @memberof Clientes
    * 
    * @method getClientes
    * @description Obtiene la lista de clientes
    * @param {Integer} page Pagina actual de la lista
    */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        filters = this.state.clientes.filters,
        search = this.props.search,
        sort = this.state.clientes.sort,
    } = this.state.clientes) => {
        this.setState({ clientes: { ...this.state.clientes, page, limit, filters, sort, loading: true } });

        axios.post('/clientes/list', {
            page, limit, filters, sort, search
        })
            .then(response => {
                this.props.setSearchParams({ page, limit })
                this.setState({
                    clientes: {
                        ...this.state.clientes,
                        data: response.data.data.itemsList,
                        total: response.data.data.paginator.itemCount,
                        pages: response.data.data.paginator.pageCount,
                        loading: false,
                        searching: true,
                        filtroSearch: search
                    }
                });

            }).catch(error => {
                message.error(error?.response?.message)
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters } = this.state.clientes) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}

            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.nombre || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    }

    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        const { objectName } = filtro
        try {
            let values = this?.drawerFiltros?.formFiltros.getFieldValue(objectName)
            let index = values.findIndex(e => e === filtro._id)
            values.splice(index, 1)

            let newValues = {}
            newValues[objectName] = values
            this?.drawerFiltros?.formFiltros?.setFieldsValue(newValues)
            this?.drawerFiltros?.formFiltros?.submit()
        } catch (error) {
            this.setState({ visibleFilters: true })
        }
    }

    render() {

        const contentMenuPlus = (
            <div>
                <Row>
                    <Button htmlType="submit" type="primary" className="btn-azul width-100" onClick={() => this.setState({ modalClientes: true })}>
                        Agregar Cliente
                    </Button>
                </Row>
                <Row className='mt-1'>
                    <Button htmlType="submit" type="primary" className="btn-azul" onClick={() => this.setState({ modalClientesMultiples: true })}>
                        Agregar multiples
                    </Button>
                </Row>


            </div>
        );

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={<Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                <span className="ant-page-header-heading-title" >
                                    Clientes
                                </span>
                            </Col>
                            <Col span={24}>
                                {this.renderFiltros()}
                            </Col>
                        </Row>}
                        extra={<Button
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Clientes" }}
                            dataSource={this.state.clientes.data}
                            pagination={{
                                current: this.state.clientes.page,
                                pageSize: this.state.clientes.limit,
                                total: this.state.clientes.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page, limit) => this.setState(state => {
                                    state.page = page;
                                    return state;
                                }, () => this.getClientes({ page, limit }))
                            }}
                            header={
                                <Row className="header-list" >
                                    <Col xs={2} lg={1} className="center">
                                        <Text ></Text>
                                    </Col>
                                    <Col xs={3} lg={5} className="center">
                                        <Text strong>Nombre</Text>
                                    </Col>
                                    <Col xs={4} lg={4} className="center">
                                        <Text strong>Email</Text>
                                    </Col>
                                    <Col xs={4} lg={4} className="center">
                                        <Text strong>Teléfono</Text>
                                    </Col>
                                    <Col xs={4} lg={3} className="center">
                                        <Text strong>RFC</Text>
                                    </Col>
                                    <Col xs={3} lg={4} className="center">
                                        <Text strong>Razón Social</Text>
                                    </Col>
                                    <Col xs={4} lg={3} className="center">
                                        <Text strong>Acciones</Text>
                                    </Col>
                                </Row>
                            }
                            renderItem={item => (
                                <Collapse className="custom-collapse" >
                                    <Panel
                                        header={
                                            <Row className="width-100">
                                                <Col xs={2} lg={1} className="center">
                                                    <CustomAvatar name={[item.usuario_id?.nombre, item.usuario_id?.apellido]} image={item.avatar} />
                                                </Col>
                                                <Col xs={3} lg={5} className="center">
                                                    <Text ellipsis>{item.usuario_id?.nombre} {item.usuario_id?.apellido}</Text>
                                                </Col>
                                                <Col xs={4} lg={4} className="center">
                                                    <Text>{item.usuario_id?.email}</Text>
                                                </Col>
                                                <Col xs={4} lg={4} className="center">
                                                    <Text>{item.telefono}</Text>
                                                </Col>
                                                <Col xs={4} lg={3} className="center">
                                                    <Text>{item.rfc}</Text>
                                                </Col>
                                                <Col xs={3} lg={4} className="center">
                                                    <Text ellipsis>{item.razon_social}</Text>
                                                </Col>
                                                <Col xs={4} lg={3} className="center">
                                                    <Space wrap>
                                                        <Button
                                                            onClick={event => {
                                                                event.stopPropagation()
                                                                this.setState({
                                                                    modalClienteCentroDistribucion: true,
                                                                    cliente_id: item._id
                                                                })
                                                            }}
                                                            size='small'
                                                            className='ant-btn-primary-lime'
                                                            type="primary"
                                                            icon={<PlusOutlined style={{ color: "currentcolor" }} />}
                                                            title="Agregar Centro de Distribución" />
                                                        <Link to={`/admin/crm/cliente/${item._id}`}>
                                                            <Button
                                                                size='small'
                                                                className='ant-btn-primary-green'
                                                                type="primary"
                                                                icon={<EyeOutlined style={{ color: "currentcolor" }} />}
                                                                title="Ver detalle" />
                                                        </Link>
                                                        <Button
                                                            size='small'
                                                            type="primary"
                                                            icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                                            title="Editar"
                                                            onClick={event => {
                                                                event.stopPropagation()
                                                                this.setState({
                                                                    modalClientes: true,
                                                                    cliente_id: item._id,
                                                                    centro_distribucion_id: item._id
                                                                })
                                                            }}
                                                        />
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="¿Deseas eliminar este cliente?"
                                                            onConfirm={event => {
                                                                event.stopPropagation()
                                                                axios.post('/clientes/delete', { id: item._id })
                                                                    .then(() => {
                                                                        this.getClientes()
                                                                        message.success('Cliente eliminado')
                                                                    })
                                                                    .catch(error => console.log(error))
                                                            }}
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button
                                                                onClick={event => event.stopPropagation()}
                                                                size='small'
                                                                type="primary"
                                                                danger
                                                                icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                                title="Eliminar"
                                                            />
                                                        </Popconfirm>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        }
                                        key="1"
                                    >

                                        <Row className="header-list width-100 mb-1" >
                                            <Col xs={12} lg={5} className="center">
                                                <Text strong>IDENTIFICADOR</Text>
                                            </Col>
                                            <Col xs={12} lg={4} className="center">
                                                <Text strong>RFC</Text>
                                            </Col>
                                            <Col xs={12} lg={4} className="center">
                                                <Text strong>RAZÓN SOCIAL</Text>
                                            </Col>
                                            <Col xs={12} lg={4} className="center">
                                                <Text strong>ESTADO</Text>
                                            </Col>
                                            <Col xs={12} lg={4} className="center">
                                                <Text strong >PAÍS</Text>
                                            </Col>
                                        </Row>

                                        {item.clientes_centros_distribuciones.map(centro => (
                                            <List.Item className="component-list-item mb-1">
                                                <Row className="width-100 ">
                                                    <Col xs={12} lg={5} className="center">
                                                        <Text>{centro.identificador}</Text>
                                                    </Col>
                                                    <Col xs={12} lg={4} className="center">
                                                        <Text>{centro.rfc}</Text>
                                                    </Col>
                                                    <Col xs={12} lg={4} className="center">
                                                        <Text>{centro.razon_social}</Text>
                                                    </Col>
                                                    <Col xs={12} lg={4} className="center">
                                                        <Text>{centro.estado_id?.name}</Text>
                                                    </Col>
                                                    <Col xs={12} lg={4} className="center">
                                                        <Text>{centro.pais_id?.name}</Text>
                                                    </Col>
                                                    <Col xs={12} lg={3} className="center">
                                                        <Space wrap>
                                                            <Link to={`/admin/crm/cliente/${centro.cliente_id}/centro-distribucion/${centro._id}`}>
                                                                <Button
                                                                    size='small'
                                                                    className='ant-btn-primary-green'
                                                                    type="primary"
                                                                    icon={<EyeOutlined style={{ color: "currentcolor" }} />}
                                                                    title="Ver detalle" />
                                                            </Link>
                                                            <Button
                                                                size='small'
                                                                type="primary"
                                                                icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                                                title="Editar"
                                                                onClick={event => {
                                                                    event.stopPropagation()
                                                                    this.setState({
                                                                        modalClienteCentroDistribucion: true,
                                                                        cliente_id: item._id,
                                                                        centro_distribucion_id: centro._id
                                                                    })
                                                                }}
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar este Centro de Distribución?"
                                                                onConfirm={
                                                                    // 
                                                                    () => axios.delete('/clientes/centros-distribucion/delete',
                                                                        {
                                                                            params: {
                                                                                id: centro._id

                                                                            }
                                                                        })
                                                                        .then(() => {
                                                                            this.getClientes()
                                                                            message.success('Centro de Distribución eliminado')
                                                                        })
                                                                        .catch(error => console.log(error))}
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    size='small'
                                                                    type="primary"
                                                                    danger
                                                                    icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                                    title="Eliminar"
                                                                />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        ))}

                                    </Panel>
                                </Collapse>
                            )}
                        />
                    </Content>
                    <FloatingButton title="Nuevo registro" content={contentMenuPlus} />

                    <ModalClientes
                        visible={this.state.modalClientes}
                        onClose={() => {
                            this.setState({ modalClientes: false, cliente_id: undefined })
                            this.getClientes(this.state.page)
                        }}
                        cliente_id={this.state.cliente_id}
                    />

                    <ModalClientesMultiple
                        visible={this.state.modalClientesMultiples}
                        onClose={(flag) => {
                            this.setState({ modalClientesMultiples: false })
                            this.getClientes(this.state.page)
                        }}
                    />

                    <DrawerFiltros
                        ref={ref => this.drawerFiltros = ref}
                        title="Filtrar Clientes"
                        updateFilters={filters => this.getClientes({
                            page: 1,
                            filters
                        })}
                        onClose={() => this.setState({ visibleFilters: false })}
                        visible={this.state.visibleFilters}
                        csv="clientes/list"
                        cuentas={false}
                        ordenes={false}
                        ordenes_compras={false}
                        area_rubro={false}
                        clasificadores={false}
                        negocios={false}
                        proveedores={false}

                    />

                    <ModalClienteCentroDistribucion
                        visible={this.state.modalClienteCentroDistribucion}
                        cliente_id={this.state.cliente_id}
                        centro_distribucion_id={this.state.centro_distribucion_id}
                        onClose={() => {
                            this.setState({ modalClienteCentroDistribucion: false })
                            this.getClientes()
                        }}
                    />
                </Spin>
            </>
        )
    }
}
export default function (props) {

    let [searchParams, setSearchParams] = useSearchParams();

    return <Clientes
        {...props}
        searchParams={searchParams}
        setSearchParams={setSearchParams}

    />

}
import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import CuentasCompras from '../components/Admin/CuentasCompras/CuentasCompras'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RuterCuentasCompras(props) {

    return (
        <Routes>
            <Route path="" element={<CuentasCompras {...props} />} />
        </Routes>
    )
}

export default RuterCuentasCompras;
